import React ,{useRef,useEffect,useContext}from 'react';
import HomeCss from '../../css/homeCss.module.css';
import {Link} from 'react-router-dom';
import {BsChevronDoubleRight as RBtn ,BsChevronDoubleLeft as LBtn} from 'react-icons/bs';
import { FaRegFile as Doc,FaToolbox as Training } from "react-icons/fa";
import '../../css/bootstrapIcons/bootstrap-icons.css';
import {ModalContext} from './Home';

var slideInt = 0;
var end = 3;
let slide;
let conSlide;
let slideFun;

const left = "left";
const right = "right";

const HomeContent = () => {
  const slideRef = useRef('slide');
  const {setModalObject,setModalToggle} = useContext(ModalContext);

  const startSlide= (arg)=>{
    slide = setInterval(()=>{
         
        let slideStep ; 
        if(arg === left){
           slideStep = slideInt+=1
           if(slideStep > (end*100)){
            slideStep = 1;
            slideInt = slideStep;
          }
        }
        else if(arg === right){
           slideStep = slideInt-=1;
           if(slideStep < 0){
             slideStep = (end*100) - 1;
             slideInt = slideStep;
           }
        }
        if(slideRef.current == null){
          clearInterval(slide);
          return false;
        }
        slideRef.current.style.transform = `translateX(${-1*slideStep}%)`;
        slideInt = slideStep;
        // console.log(slideStep+" "+slideInt)
        if(slideStep%100 === 0){
          clearInterval(slide);
          clearTimeout(conSlide)
          // console.log(slideStep)
          if(slideStep === (end*100) || slideStep === 0){
            slideInt = 0;
            slideStep = slideInt;
            slideRef.current.style.transform = `translateX(${slideStep}%)`;
          }
         
          slideFun(left);
          
          
        }
        
    },5)
  }

  const handleArrowBtn =(arg)=>{
     
    if(arg === right){
      
      clearInterval(slide);
      clearTimeout(conSlide)
      slideFun('right','hello');
    }
    else if(arg === left){
      clearInterval(slide);
      clearTimeout(conSlide)
      slideFun('left','hello');
    }
    
  }

  const showModalMessage=(arg) =>{
      setModalObject({header:"Service Notice",body:<><span style={{fontSize:"25px"}}>{arg} Coming Soon</span></>});
      setModalToggle(true);
  }

  useEffect(()=>{
      slideFun = (arg,arg2) =>{
        if(arg2){
          conSlide = setTimeout(()=>{
            startSlide(arg);
            clearTimeout(conSlide)
          },5)
        }
        else{
          conSlide = setTimeout(()=>{
            startSlide(arg);
            clearTimeout(conSlide)
          },8000)
        }
      }

      slideFun(left);
      return clearInterval(slide);
    }
      ,[]
  )

  
  return (
    <React.Fragment>
        <section className={HomeCss.homeContainer}>
          <div className={HomeCss.homeOverlay} ></div>
          <ul className={HomeCss.slideUl} ref={slideRef}>
            <li>
               <div>
                  <h1>Getting Started with <span className={HomeCss.name}>EDUPLUS</span> for Free</h1>
                  <p>Eduplus is Free and helps with services that makes teaching, learning and other
                    educational activities more efficient. <Link to="/account/sign-up">Get&nbsp;Started</Link>
                  </p>
               </div>
               <div style={{backgroundImage:"url(images/edu3.png)"}}></div>
            </li>
            <li>
              <div>
                  <h1> <span className={HomeCss.name}>EDUPLUS</span> is for everyone</h1>
                  <p>
                    Whether you own a school, a training center, content creator, knowledge experts or you just want to set cbt test/exam 
                    for a group of students, potential employees, employees etc, Eduplus carters for your needs. <Link to="/account/sign-up"> Learn&nbsp;more</Link> 
                  </p>
              </div>
              <div style={{backgroundImage:"url(images/edu1.png)"}}></div>
            </li>
            <li>
               <div>
                  <h1> Convert your knowledge/educational services to earnings with <span className={HomeCss.name}>EDUPLUS</span> </h1>
                  <p>
                     You can can monetizing your knowledge or service with just few clicks with EduPlus. EduPlus streamlines all education management processes for greater efficiency, lower cost and better profit optimization.<Link to="/account/sign-up">Learn&nbsp;more</Link>
                  </p>
               </div>
               <div style={{backgroundImage:"url(images/edu2.png)"}}></div>
            </li>
            <li>
               <div>
                  <h1>Getting Started with <span className={HomeCss.name}>EDUPLUS</span> for Free</h1>
                  <p>Eduplus is Free and helps with services that makes teaching, learning and other
                    educational activities conducive. <Link to="/account/sign-up">Get&nbsp;Started</Link>
                  </p>
               </div>
               <div style={{backgroundImage:"url(images/edu3.png)"}}></div>
            </li>
          </ul>
          <div className={HomeCss.btnDiv}>
            <button onClick={()=>{handleArrowBtn('right')}}><LBtn/></button>
            <button onClick={()=>{handleArrowBtn('left')}}><RBtn/></button>
          </div>

        </section>

        <section className={HomeCss.eduSection}>
          <h1>EDUCATION FOR ALL</h1>
          <div className={HomeCss.eduDiv}>
            <span>
              <div>
                <div></div>
                <div>
                  <div>COMPUTER BASE TEST</div>
                </div>
              </div>
            </span>
            <span>
              <div>
                <div></div>
                <div>
                  <div>RESULT, TRANSCRIPT, {'&'} CERTIFICATE MANAGEMENT</div>
                </div>
              </div>
            </span>
            <span>
              <div>
                <div></div>
                <div>
                  <div>QUESTION BANKS <br/>(Exam Preparation {`&`} Practice)</div>
                </div>
              </div>
            </span>
            <span>
              <div>
                <div></div>
                <div>
                  <div>WEBSITE {`&`} FORMS CREATION</div>
                </div>
              </div>
            </span>
            
            
            
           
          </div>
        </section>
         
        <section className={HomeCss.exploreMore}>
          <h1>EXPLORE MORE SOLUTIONS</h1>
          <h2>For Institutions</h2>
          <div className={HomeCss.exploreDiv}>
            <ul className={HomeCss.exploreUl}>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-award ${HomeCss.exploreIcon}`}></i></div>
                    <div>Result Management</div>
                    <div>
                      Manage your institution(s),group or department result/report with ease and in the most cost effective way.
                    </div>
                    <Link to="/services#institution">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-display ${HomeCss.exploreIcon}`}></i></div>
                    <div>CBT Management</div>
                    <div>
                      Test students, potential employees, workers in an easy, fast, secure, efficient and cost effective way.
                    </div>
                    <Link to="/services#institution">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-building ${HomeCss.exploreIcon}`}></i></div>
                    <div>Institute Management</div>
                    <div>
                      Manage your institution(s), group(s) efficiently with information, attendance, performance managment and many more.
                    </div>
                    <Link to="/services#institution">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-credit-card-2-back ${HomeCss.exploreIcon}`}></i></div>
                    <div>E-payment</div>
                    <div>
                      Receive and manage payments for your institution(s), group(s), work(s) easily through Eduplus. 
                    </div>
                    <Link to="/services#institution">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
            </ul>
            <div>
              {/* <Link to="/">View All Solutions&nbsp;<i className="bi-chevron-double-right"></i></Link> */}
            </div>
          </div>
          <h2>For Tutors/Educators</h2>
          <div className={HomeCss.exploreDiv}>
            <ul className={HomeCss.exploreUl}>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-file-earmark-pdf ${HomeCss.exploreIcon}`}></i></div>
                    <div>E-Learning</div>
                    <div>
                      Train students, workers with Eduplus advance E-learning tools which include online class portal,
                       assignment, notes, lesson management and more.
                    </div>
                    <Link to="/services#tutor">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-display ${HomeCss.exploreIcon}`}></i></div>
                    <div>CBT Management</div>
                    <div>
                      Test students, potential employees, workers in an easy, fast, secure, efficient and cost effective way.
                    </div>
                    <Link to="/services#tutor">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-bank ${HomeCss.exploreIcon}`}></i></div>
                    <div>Question Bank</div>
                    <div>
                      Access more 10,000 already made questions to test your students/mentees across different areas like ICANN, SAT, IELT, WAEC, programming etc.
                    </div>
                    <Link to="/services#tutor">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-hdd-rack ${HomeCss.exploreIcon}`}></i></div>
                    <div>Data & Research</div>
                    <div>
                      Access awesome tools and resources to aid academic research, teaching and study.
                    </div>
                    <Link to="/services#tutor">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
            </ul>
            <div>
              {/* <Link to="/">View All Solutions&nbsp;<i className="bi-chevron-double-right"></i></Link> */}
            </div>
          </div>
          <h2>For Students/Professionals</h2>
          <div className={HomeCss.exploreDiv}>
            <ul className={HomeCss.exploreUl}>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-journal-bookmark ${HomeCss.exploreIcon}`}></i></div>
                    <div>Study Guide</div>
                    <div>
                      Get access to plethora of academic materials, questions bank and a personal computer based
                      test tool to test your knowledge and performance in a study area.
                    </div>
                    <Link to="/services#student">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-bank ${HomeCss.exploreIcon}`}></i></div>
                    <div>Question Bank</div>
                    <div>
                      Access more 10,000 already made questions to test your self across different areas like ICANN, WAEC, SAT, programming etc.
                    </div>
                    <Link to="/services#student">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-graph-up ${HomeCss.exploreIcon}`}></i></div>
                    <div>Result Analysis</div>
                    <div>
                      Get relevant, detailed and crucial result and performance analysis with eduplus.
                      Experience a new level of result analysis. 
                    </div>
                    <Link to="/services#student">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span onClick={()=>{}}>
                    <div><i className={`bi-controller ${HomeCss.exploreIcon}`}></i></div>
                    <div>Gamified Learning</div>
                    <div>
                      Manage your learning in a fun and gamified way which can improve learning and knowledge retention
                      by more than 15%.
                    </div>
                    <Link to="/services#student">Learn More&nbsp;<i className="bi-chevron-double-right"></i></Link>
                  </span>
                </div>
              </li>
            </ul>
            <div>
              {/* <Link to="/">View All Solutions&nbsp;<i className="bi-chevron-double-right"></i></Link> */}
            </div>
          </div>
          
        </section>

        <section className={HomeCss.edu4all}>
              <div>
                  {/* <img alt="alt"/> */}
              </div>
              <div>
                <h1>EDUCATION FOR ALL</h1>
                <div>Whether teaching, Learning or Management, Eduplus got you covered</div>
                <div><Link to="/account/sign-up">START FOR FREE</Link></div>
              </div>
        </section>

        <section className={HomeCss.accessSection}>
          <div>
            Access Amazing Services for FREE, no credit card or upfront payment
            required
            <div><Link to="/account/sign-up">START FOR FREE</Link></div>
          </div>
          <div></div>
        </section>

        <section className={HomeCss.docSection}>
          <div>
            <div>
              <Doc/>
              <Training/>
            </div>
            <h1>TRAINING & DOC</h1>
            <div>Get hands-on training & documentation of how to use sight-eduplus</div>
            <div><Link to="/account/sign-up">Get Started for Free</Link></div>
          </div>

        </section>
        
    </React.Fragment>
  )
}

export default HomeContent