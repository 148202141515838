import { Fragment } from "react";
import { BsX, BsPersonDash as DisabledIcon, BsPersonCheck as EnabledIcon } from "react-icons/bs";
import { FaEdit, FaRegTrashAlt, FaTimes } from "react-icons/fa";
import { BiReset as ResetIcon } from 'react-icons/bi';
import { MdAddToQueue } from "react-icons/md";


const SideOption = ({ element, index, changeSelected, handleDelete, handleEdit, showOption, resetPassword, onEnable, handleAddGroup }) => {

    return <Fragment>
        <span onClick={() => { changeSelected(index); }}>
            <span></span>
            <span></span>
            <span></span>
        </span>
        {showOption &&
            <div style={{ display: 'flex', flexDirection: 'column', width: "fit-content", alignSelf: 'flex-end', position: 'absolute', right: '0' }} >
                <span onClick={() => changeSelected(null)}><FaTimes /></span>
                {handleEdit && <button onClick={() => handleEdit(element)}><FaEdit /> Edit</button>}
                {handleDelete && <button onClick={() => handleDelete(element)} ><FaRegTrashAlt /> Delete</button>}
                {onEnable && <button onClick={onEnable}>
                    {!element.isEnabled ? <EnabledIcon /> : <DisabledIcon />}{element.isEnabled ? 'Disable User' : 'Enable User'}
                </button>}
                {resetPassword && <button onClick={resetPassword}><ResetIcon /> Reset Password</button>}
                {handleAddGroup && <button onClick={()=>handleAddGroup(element)}><MdAddToQueue /> Add Group</button>}
            </div>}
    </Fragment>
}

export default SideOption