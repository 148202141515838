import styles from './css/Categories.module.css';
import { useEffect, useState, useContext, memo } from 'react';
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import Api from "../../../Api";
import { useSelector, useDispatch } from "react-redux";
import { setQuestions, setCategory, editCategory } from "../../../redux/reducers/questionReducer";
import { EachQuestion, QuestionPageNumber } from './index';
import Select from '../../../components/Select';
// import { EditContext } from '../views/userAdmin/Questions';
export const NO_OF_QUESTION_PER_PAGE = 10;
const ViewQuestions = ({ setTitle }) => {
    let questions = useSelector((state) => state.questions.value);
    //let [questions,setQuestions] = useState([])
    const dispatch2 = useDispatch();
    const [loadingState, setLoadingState] = useState("loading");
    const [shouldShow, setShouldShow] = useState(true);
    const categories = useSelector((state) => state.questions.categories);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentSetOfQuestions, setCurrentSetOfQuestions] = useState([]);
    const [currentInd, setCurrentInd] = useState(null);
    const [currentOpened, setCurrentOpened] = useState(null);
    const [pageStartNum, setPageStartNum] = useState(0);
    const [numOfPages, setNumberOfPages] = useState(0);
    const [pagesArr, setPagesArr] = useState([]);

    // const {editQuestion,setEditQuestion} = useContext(EditContext)

    const changeQuestionSet = () => {
        // if(!selectedCategory){
        //     if(categories.length <= 0){
        //         await fetchItems2();
        //     }
        //     if(categories.length > 0){
        //         setSelectedCategory(categories[0].id)
        //     }       
        // }
        // if(questions.length <= 0){
        //     await fetchItems();  
        // }
        if (questions.length > 0) {
            const questionSet = questions.filter(question => question.category_id == selectedCategory);
            //console.log(questionSet,'hello')
            setCurrentSetOfQuestions(questionSet);
            //console.log(questionSet)
        }
    }

    const fetchItems = async () => {
        Api.getApi('/question/get-user-questions', true)
            .then(response => response.json())
            .then(result => {
                //console.log(result)
                if (shouldShow) {
                    dispatch2(setQuestions(result.data));
                    // setQuestions(result.data);
                    setLoadingState("ready");
                    // setCurrentSetOfQuestions()
                    if (result.data.length > 0) {
                        const questionSet = result.data.filter(question => question.category_id == selectedCategory);
                        //console.log(questionSet,'hello')
                        setCurrentSetOfQuestions(questionSet);
                    }
                }
            })
            .catch(err => {
                if (shouldShow) setLoadingState("error");
            });
    };

    const fetchItems2 = async () => {
        Api.getApi('/question/get-category', true)
            .then(response => response.json())
            .then(result => {
                //console.log(result)
                if (shouldShow) {
                    dispatch2(setCategory(result.data));
                    setLoadingState("ready");
                }
            })
            .catch(err => {
                if (shouldShow) setLoadingState("error");
            });
    };

    const handleSelectedCategory = (category) => {
        // console.log(category);
        setSelectedCategory(category.id);
        if (category.id && !category.fetched) {
            handleQuestions(category);
        }
        //console.log(index)
    }

    const calculateNewStart = (arg) => {
        let start = arg;
        if (start === 0) {
            return 0
        }
        start *= NO_OF_QUESTION_PER_PAGE;
        //console.log(start,'##### HELLO WORLD #####');
        return start;
    }
    const handlePageStartNum = (pageNum) => {
        // console.log(pageNum,'##### HELLO WORLD #####');
        setPageStartNum(calculateNewStart(pageNum));
    }

    const handleQuestions = (category) => {
        setShowLoading(true);
        Api.getApi(`/question/open-user-question/${category.id}`, true)
            .then(result => result.json())
            .then(result => {
                if (result.isSuccessful) {
                    const q = questions.filter(q => q.category_id != category.id)
                    let newcategory = {...category, ...result.data, fetched: true }
                    dispatch2(setQuestions([...q, ...newcategory.questions]))
                    dispatch2(editCategory(newcategory))
                }

            })
            .catch(error => {
                setModalText('Action failed, please try again or re-login');
                setModalToggle(true);
            })
            .finally(() => {
                setShowLoading(false);
            })
    }
    useEffect(() => {
        setShouldShow(true);
        // if (questions.length === 0) {
        //     fetchItems();
        // }
        // else {
        //     if (shouldShow) setLoadingState("ready");
        // }
        // if(categories.length === 0){
        //     fetchItems2();

        // }
        // else {
        //     if(shouldShow) setLoadingState('ready')
        // }
        return () => {
            setShouldShow(false);
        };
    }, []);

    useEffect(() => {
        setTitle('Questions');
        setCurrentInd(null);
        setCurrentOpened(null);
        setPageStartNum(0)
    }, []);

    useEffect(() => {
        changeQuestionSet();
    }, [selectedCategory, categories, loadingState])

    useEffect(() => {
        if (categories.length > 0) {
            if (selectedCategory) {
                setSelectedCategory(categories.find(cat=> cat.id == selectedCategory).id)
                changeQuestionSet();
                return
            }
            setSelectedCategory(categories[0].id);
            // console.log(categories)
        }
        // console.log(questions)
    }, [questions])




    useEffect(() => {
        // console.log(selectedCategory)
        setCurrentInd(null);
    }, [selectedCategory])

    useEffect(() => {
        setCurrentOpened(null)
        setPageStartNum(0)
    }, [selectedCategory])

    // useEffect(()=>{
    //     setQuestions(questions)
    //     if(questions.length){
    //         changeQuestionSet();
    //     }
    // },[questions]);

    useEffect(() => {
        if (currentSetOfQuestions.length > 0) {
            const totalPages = Math.ceil(currentSetOfQuestions.length / 10)
            setNumberOfPages(totalPages);
        }
    }, [currentSetOfQuestions, selectedCategory])

    useEffect(() => {
        if (numOfPages && numOfPages > 0) {
            let i = 0;
            let arr = [];
            for (i = 0; i < numOfPages; i++) {
                arr.push(i);
            }
            setPagesArr(arr);
        }
        // console.log(numOfPages);
        if(pageStartNum >= numOfPages * NO_OF_QUESTION_PER_PAGE){
            handlePageStartNum(numOfPages - 1)        }

    }, [numOfPages])


    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <div className={`${styles.viewContainer} ${styles.viewContainer2} `}>
                <p>Select question category</p>
                <div>
                    <div className={styles.viewSelectContainer}>
                        <Select className={styles.viewSelect} value='id' label='name' options={categories} onChange={handleSelectedCategory} defaultValue={selectedCategory?categories.find(cat => cat.id == selectedCategory): categories.length > 0 ? categories[0] : ''} />
                    </div>

                    <div className={styles.viewQuestionContainer}>
                        {currentSetOfQuestions.length ? currentSetOfQuestions.map((question, qIndex) => (
                            <EachQuestion ind={currentInd} changeInd={setCurrentInd} style={styles.eachQuestion} key={question.id} eQuestion={question} currentOpened={currentOpened} changeCurrentOpened={setCurrentOpened} setQuestions={setQuestions} questions={questions} questionIndex={qIndex} pageStartNum={pageStartNum} setShowLoading={setShowLoading}/>
                        )) : "No questions for this Category"}
                    </div>
                    {currentSetOfQuestions.length > 0 &&
                        <div className={styles.pageContainer}>{pagesArr.map((element) =>
                            // <span style={(pageStartNum === element) || (pageStartNum === element + NO_OF_QUESTION_PER_PAGE) ?{backgroundColor:'#f2f2f2'}:{backgroundColor:'#fff'}} key={element} onClick={()=>{handlePageStartNum(element)}}>{element+1}</span>
                            <QuestionPageNumber key={element} element={element} click={handlePageStartNum} pageStartNum={pageStartNum} />
                        )}</div>
                    }
                </div>
            </div>
        </>
    );
};




export default ViewQuestions;


/*********************************************************************** */