import { useDispatch, useSelector } from "react-redux";
import templateCss from "../../../css/ResultTemplate.module.css";
import CategoriesCss from '../../../css/Categories.module.css';
import ResultCss from '../../../css/Result.module.css';
import { useContext, useEffect, useState } from "react";
import Select from "../../../components/Select";
import { ModalContext } from "../../../App";
import { ResultContext } from "./ResultMgmt";
import { MenuContext } from "../DashBoard";
import PageNumber from "../../../components/PageNumber";
import SideOption from "../../../components/SideOption";
import { useNavigate } from "react-router-dom";
import restApi from "../../../helperFunctions/Api2";
import { deleteAllUserResult } from "../../../redux/reducers/resultReducer";
import { getCertificatesValues } from "./AddUserResult";

const NO_OF_ITEMS_PER_PAGE = 30

export default function ViewUserResult() {
    const resultSets = useSelector(state => state.results.sets);
    const resultTemplates = useSelector(state => state.results.templates);
    const allUserResult = useSelector(state => state.results.allUserResult);
    const { setEditing, setEditData, setEditType, setTitle } = useContext(ResultContext);
    const { popup } = useContext(ModalContext);
    const { selected, changeSelected, setShowLoading } = useContext(MenuContext);


    const [selSet, setSelSet] = useState({});
    const [Sets, setSets] = useState(Object.values(resultSets))
    const [resultGroups, setResultGroups] = useState([])
    const [selResultGroup, setSelResultGroup] = useState({});
    const [template, setTemplate] = useState({});
    const [results, setResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleDelete = (element) => {
        if (window.confirm('Are you sure you want to delete this result?')) {
            setShowLoading(true);
            let payload = { ...element }
            payload.result_set_id = selSet.id;
            restApi.PostApi('/result/delete-user-result', payload, true)
                .then(result => {
                    popup(result.message);
                    console.log(payload, result);
                    if (result.isSuccessful || result.isSuccesful) {
                        dispatch(deleteAllUserResult(payload))
                    }
                })
                .catch(error => {
                    popup(error.message);
                })
                .finally(() => {
                    setShowLoading(false);
                })
        }
    }
    const handleEdit = (element) => {
        setEditData(element);
        setEditType('result');
        setEditing(true);
        navigate('../add-result');
    };

    useEffect(() => {
        if (selSet.id && resultTemplates[selSet.template_id]) {
            const temp = JSON.parse(resultTemplates[selSet.template_id].template).sections;
            setTemplate(temp);
            setResultGroups(selSet.groups)
        } else {
            setTemplate({})
            setResultGroups([])
        }
        setSelResultGroup({});
        setResults(allUserResult.filter(result => (result.result_set_id === selSet.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selSet])

    useEffect(() => {
        if (selResultGroup.id) {
            setResults(allUserResult.filter(result => (result.result_set_id === selResultGroup.set_id || result.result_set_id === null) && (result.result_group_id === selResultGroup.id || result.resultGroup === selResultGroup.id )))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selResultGroup])


    useEffect(() => {
        if (selResultGroup.id) {
            setResults(allUserResult.filter(result => (result.result_set_id === selResultGroup.set_id || result.result_set_id === null) && result.result_group_id === selResultGroup.id))
        } else {
            setResults(allUserResult.filter(result => (result.result_set_id === selSet.id)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUserResult])
    // useEffect(() => {
    //     console.log(results);
    // }, [results])
    useEffect(() => {
        setTitle('View Result');
        setSets(Object.values(resultSets));
        setSelSet({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSets])

    useEffect(() => {
        if (results.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((results.length / NO_OF_ITEMS_PER_PAGE) + ((results.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [results.length]);

    return <>
        <div className={CategoriesCss.form}>
            <label htmlFor='set' className={`${CategoriesCss.label}`}>
                <span className={CategoriesCss.isRequired}>Select Result Set</span>
                <Select name="resultSet" options={Sets} onChange={setSelSet} label="title" value="id" />
            </label>
            <label htmlFor='group' className={`${CategoriesCss.label}`}>
                <span className={CategoriesCss.isRequired}>Select Result Group</span>
                <Select options={resultGroups} onChange={setSelResultGroup} label="title" value="id" />
            </label>
            <div className={CategoriesCss.viewQuestionContainer} onClick={() => (selected !== null) && changeSelected(null)}>
                {results.length ? results.map((result, index) => {
                    return <EachResult key={index} result={result} index={index} handleDelete={handleDelete} handleEdit={handleEdit} currentPage={currentPage} selected={selected} changeSelected={changeSelected} template={template}/>
                }) : 'No Result To Display'}
            </div>
            {results.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
        </div>
    </>
}
const EachResult = ({ index, currentPage, result, selected, changeSelected, handleDelete, handleEdit, template }) => {
    const allResults = JSON.parse(result.result);
    const [showResult, setShowResult] = useState(false);
    const [showOption, setShowOption] = useState(false);
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {
        if (selected === index) {
            setShowOption(true);
        } else {
            setShowOption(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    useEffect(() => {
        if (index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE)) {
            setShowResult(true)
        }
    }, [index, currentPage])

    return <>{showResult && <div className={CategoriesCss.eachQuestion} style={{minHeight: '180px'}}>
        <div>
            <div>ID: <span className="purpleText">{result.user_reg_no}</span></div>
            <div>Name: <span className="purpleText">{result.user_details}</span></div>
            <div>Email: <span className="purpleText">{result.email}</span></div>
            <div>Password: <span className="purpleText">{result.password}</span></div>
            <div>Paid: <span className="purpleText">{result.as_paid === null?'Free': result.as_paid === 1? 'Paid': 'Not Paid'}</span></div>
        </div>
        <SideOption element={result} index={index} changeSelected={changeSelected} handleEdit={handleEdit} handleDelete={handleDelete} showOption={showOption} />
        <button onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'hide result...' : 'see result...'} </button>
        {seeMore && <div style={{ display: 'flex', }}>
            {template && Object.values(template).map((section, index) => (
                <div key={index} className={ResultCss.resDiv}>
                    <h2>{section.name}</h2>
                    {
                        section.type === 'table' ? (
                            <div className={templateCss.ColDiv}>
                                <table className={templateCss.table}>
                                    <thead>
                                        <tr>{
                                            Object.values(section.content).map((column, index) => (
                                                <th key={index}>
                                                    {column.name}
                                                </th>
                                            ))
                                        }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allResults[section.id].map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {
                                                    Object.values(section.content).map((column, index) => (
                                                        <td key={index}>{
                                                            column.type === 'img' ?
                                                                <img alt="" src=""/>
                                                                :
                                                                <span>{row[index] || ''}</span>
                                                        }
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ) : <>{section.type === 'comment' ?

                            <div className={ResultCss.viewComment}>
                                {
                                    Object.values(section.content).map((row, index) => (
                                        <div key={index}>
                                            <span>{row.name}:</span>
                                            {row.type === 'img' ?
                                                <img src='' alt="" />
                                                : <span>{allResults[section.id][0][row.id] || ''}</span>
                                            }
                                            <br />
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <div className={ResultCss.viewComment}>
                                {getCertificatesValues(section.content['0'].name).map((vals, index) => (
                                    <div key={index}>
                                        <span>{vals}:</span>
                                        <span>{allResults[section.id][0][index] || ''}</span>

                                        <br />
                                    </div>
                                ))
                                }
                            </div>
                        }</>
                    }
                </div>
            ))}
        </div>}
    </div>}</>
}