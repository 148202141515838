
import React, { useContext, useState, useEffect } from 'react';
import styling from '../../../css/Categories.module.css';
import Loading from '../../../components/loading';
import { useDispatch, useSelector } from 'react-redux';
import { LearningContext } from './AssignmentIndex';
import style from '../Calender/Css/event.module.css';
import HTMLReactParser from 'html-react-parser';
//import { SiGooglemeet as Meeting } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import { deleteAssignment } from "../../../redux/reducers/learningReducer";
import { FaEdit, FaPaperPlane, FaRegClipboard, FaRegTrashAlt, FaTimes } from 'react-icons/fa';
//import { GrScorecard } from 'react-icons/gr';
import { MenuContext } from '../DashBoard';
import Api from "../../../helperFunctions/Api2";
import SideOption from '../../../components/SideOption';
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;


const ViewAssignment = ({ setTitle }) => {
   const [showLoading, setShowLoading] = useState(false);
   const { editAssignment, editAssObj, setModalObject, setModalToggle, setEditAssignment, setEditAssObj } = useContext(LearningContext)
   const Assignment = useSelector(state => state.learning.assignments);
   const institutions = useSelector(state => state.questions.user_institutions);
   const { selected, changeSelected } = useContext(MenuContext);
   const [indx, chgInd] = useState(-1);
   const [allAssignment, setAll] = useState([]);
   const [openStyle, setOpenStyle] = useState(false);
   const Navigate = useNavigate();
   const dispatchFunc = useDispatch();
   const [currentPage, setCurrentPage] = useState(1);
   const [pageNum, setPageNum] = useState(0);


   const handleChange = (eachassignment) => {
      if (eachassignment) {
         changeSelected(eachassignment.id);
      }
      else {
         changeSelected(null);
      }
   }
   const handleOpen = (index) => {
      if (index >= 0) {
         chgInd(index)
      }
      else {
         chgInd(-1);
      }
   }

   const handleEdit = (eachassignment) => {
      setShowLoading(true);
      setEditAssignment(true);
      setEditAssObj({...eachassignment, type: 'edit'});
      setShowLoading(false);
      Navigate('../create-assignment')
   }
   const handleDelete = (eachassignment) => {
      window.confirm('Are you sure you want to delete this assignment');
      if (eachassignment.id) {
         setShowLoading(true);
         Api.PostApi('/learning/delete-assignment', {id: eachassignment.id}, true)
         .then(result => {
            setShowLoading(false);
            setModalObject({ header: '', body: result.message, footer: '' });
            setModalToggle(true);
            if (result.isSuccessful) {
               dispatchFunc(deleteAssignment(eachassignment.id))
            };
         })
         .catch(error => {
            setShowLoading(false);
            setModalObject({ header: '', body: error.message, footer: '' });
            setModalToggle(true);
         })
      }
   }
   const handleSubmit = () => {

   }
   const handleScore = (eachassignment) => {
      setEditAssignment(true);
      setEditAssObj({...eachassignment, type: 'score'});
      Navigate('../users-assignment')

   }
   useEffect(() => {
      setTitle('View Assignments/Tasks');
      // console.log(Assignment)
   },[]);

   useEffect(() => {
      if (Assignment) {
         setAll(Assignment);
      }

   }, [Assignment])
   useEffect(() => {
       if (Assignment.length > NO_OF_ITEMS_PER_PAGE) {
           setPageNum((Assignment.length / NO_OF_ITEMS_PER_PAGE) + ((Assignment.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
       }
   }, [Assignment.length]);

   /*useEffect(() => {
      if (Assignment && Assignment.length > 0) {
         Assignment.forEach(note => {
            if (note.id === indi) {
               setOpenStyle(true);
            }
            else {
               setOpenStyle(false);
            }
         })
      }
   }, [Assignment, indi])*/

   return (
      <>
         <Loading shouldShow={showLoading} />
         <div className={styling.form}>
            <div className={styling.viewQuestionContainer}>
               {Assignment && Assignment.length > 0 ? Assignment.map((eachassignment,index) => {
                  return (
                     index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                     <div style={{ minHeight: '190px', paddingBottom: '30px' }} className={styling.eachQuestion} key={eachassignment.id} >
                        <div>
                           <div>Title: <span className={styling.eachQuestionValue}>{HTMLReactParser(eachassignment.title)}</span></div>
                           <div>Score: <span className={styling.eachQuestionValue}>{eachassignment.score} </span></div>
                           <div>Institution: <span className={styling.eachQuestionValue}>{institutions.find(inst => inst.id === eachassignment.institution_id).name}</span></div>
                           <div>Submission Date/Time: <span className={styling.eachQuestionValue}>{eachassignment.end_date.split('T')[0]} {eachassignment.end_date.split('T')[1].split('Z')[0]}</span></div>
                           <div className={style.groups}>Groups: {JSON.parse(eachassignment.institution_category) && JSON.parse(eachassignment.institution_category).map((catId) => <span key={catId}>{institutions.find(inst => inst.id === eachassignment.institution_id).categories[catId].name}</span>)}</div>
                        </div>
                        <span className={style.eventSpan} onClick={() => { handleChange(eachassignment) }}>
                           <span></span>
                           <span></span>
                           <span></span>
                        </span>
                        <div style={eachassignment.id === selected ? { display: 'flex' } : { disyplay: 'none' }}>
                           <span onClick={() => { handleChange() }}><FaTimes /></span>
                           <button onClick={() => { handleEdit(eachassignment) }}><FaEdit /> Edit</button>
                           <button onClick={() => { handleDelete(eachassignment) }}><FaRegTrashAlt /> Delete</button>
                           <button style={{display:'inline'}} onClick={() => { handleScore(eachassignment) }}><FaRegClipboard /> View Assignment</button>
                           {/*<button onClick={() => { handleScore(eachassignment) }}><GrScorecard /> Score</button> */}
                        </div>
                        {indx != index ? <button className={style.eventBtn} onClick={() => { handleOpen(index) }}>Show more ...</button> : <button className={style.eventBtn} onClick={() => { handleOpen() }}>Show less ...</button>}
                        <div style={indx == index ? { display: 'flex' } : { display: 'none' }} className={style.moreDetails}>
                           <div><u><b>Assignment</b></u><br /> {HTMLReactParser(eachassignment.assignment)}</div>
                        </div>
                     </div>


                  )
               }) : 'No Assignment Added yet'}
            </div>
            {Assignment.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

         </div>
      </>
   );
}

export default ViewAssignment;
const Each = ({each, index, selected, changeSelected, handleEdit, handleDelete}) => {

   const institutions = useSelector(state => state.questions.user_institutions);
	const [seeMore, setSeeMore] = useState(false);
   const [showOption, setShowOption] = useState(false);


	useEffect(() => {
      if (selected === index) {
          setShowOption(true);
      } else {
          setShowOption(false);
      }
      console.log(selected, index)
  }, [selected, index])

	return (
      
      <div style={{ minHeight: '130px' }} className={styling.eachQuestion} key={each.id} >
      <div>
         <div>{HTMLReactParser(each.title)} </div>
         <div>Score: {each.score} </div>
         <div>Institution: {institutions.find(inst => inst.id === each.institution_id).name}</div>
      </div>
      <SideOption element={each} index={index} changeSelected={changeSelected} handleDelete={handleDelete} handleEdit={handleEdit} showOption={showOption} />
      <button onClick={() => setSeeMore(!seeMore)}>{!seeMore ? 'show' : 'hide'} pages...</button>
      <div style={seeMore ? { display: 'flex' } : { display: 'none' }} className={style.moreDetails}>
         <div><u><b>Assignment</b></u><br /> {HTMLReactParser(each.assignment)}</div>
         <p>Submission Date & Time:&nbsp;{each.end_date.split('T')[0]} {each.end_date.split('T')[1].split('Z')[0]} </p>
      </div>
   </div>
	)
}