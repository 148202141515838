import React,{useState,useEffect,createContext,useContext} from 'react';
import styles from '../../../css/CreateInst.module.css';
import { eventModalContext } from './mailSettings';
import { useSelector,useDispatch } from 'react-redux';
import Loading from '../../../components/loading';
import {addEmailGroup,editEmailGroup} from '../../../redux/reducers/eventReducer';
import Api from '../../../helperFunctions/Api2';
import { useNavigate } from 'react-router-dom';


const AddEmails = ({seTTitle}) => {
    const [showLoading, setShowLoading] = useState(false);
    const [emailTitle, setEmailTitle] = useState('');
    const [emailDescription,setEmailDescription] = useState('');
    const {setModalObject,setModalToggle,editEmailObj,setEditEmailObj} = useContext(eventModalContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clearForm = () =>{
        setEmailTitle('');
        setEmailDescription('');
        setEditEmailObj({})
    }
    
    const handleSubmit = (e) => {
       e.preventDefault();
       if(emailTitle === '' || emailTitle === null || typeof emailTitle === 'undefined'){
            setModalObject({header:"",body:"Please Enter Title",footer:""});
            setModalToggle(true);
            return false;
       }
       if(emailDescription === '' || emailDescription === null || typeof emailDescription === 'undefined'){
            setModalObject({header:"",body:"Please Enter Description",footer:""});
            setModalToggle(true);
            return false;
        }
    
       const emailObject = {title:emailTitle,description:emailDescription};


       if(!Api.checkNetwork()) return false;
        setShowLoading(true)

        Api.PostApi('/event/add-email-group',emailObject, true)
        .then(result=>{
            setShowLoading(false);
            setModalObject({header:"",body:result.message,footer:""});
            setModalToggle(true);
            console.log(result, "Server result");
            if(result.isSuccessful){
                dispatch(addEmailGroup({id:result.data.id,title:emailTitle,description:emailDescription, emails:[]}));
                clearForm();
            }
        })
        .catch(error=>{
            setShowLoading(false);
            console.log(error, "Server Error")
        })
    }

    const handleEdit = (e) =>{
        e.preventDefault();
        if(emailTitle === '' || emailTitle === null || typeof emailTitle === 'undefined'){
            setModalObject({header:"",body:"Please Enter Title",footer:""});
            setModalToggle(true);
            return false;
       }
       if(emailDescription === '' || emailDescription === null || typeof emailDescription === 'undefined'){
            setModalObject({header:"",body:"Please Enter Description",footer:""});
            setModalToggle(true);
            return false;
        }
        const emailObject = {
                event:{
                    title:emailTitle,
                    description:emailDescription
                },
                group_id:editEmailObj.id,
                add:[],
                remove:[]

            }
    
        
        console.log(emailObject);
        const newObject = {
            id:editEmailObj.id,
            emails:editEmailObj.emails,
            title:emailTitle,
            description:emailDescription
        }
        if(!Api.checkNetwork()) return false;
        setShowLoading(true)

        Api.PostApi('/event/edit-email-group',emailObject, true)
        .then(result=>{
            setShowLoading(false);
            setModalObject({header:"",body:result.message,footer:""});
            setModalToggle(true);
            if(result.isSuccessful){            
                dispatch(editEmailGroup(newObject));
                clearForm();
            }
        })
        .catch(error=>{
            setShowLoading(false);
            console.log(error, "Server Error")
        })
    }

    useEffect(()=> {
        seTTitle('Create Email Group');
    },[])

    useEffect(()=>{
        if(editEmailObj && editEmailObj.id){
            setEmailTitle(editEmailObj.title);
            setEmailDescription(editEmailObj.description);
        }
    },[editEmailObj])
    
    return ( 
        <>
            <Loading  shouldShow={showLoading}/>
            <form className={`${styles.form} ${styles.form2}`} >
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Email Group Title</span>
                    <input name='title' value={emailTitle ?emailTitle:'' } className={`${styles.fields}`} type='text' placeholder="Enter Group Name" onChange={(e)=>{setEmailTitle(e.target.value)}}/>
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Email Group Description</span>
                    <textarea style={{outline:"none"}} name='description' value={emailDescription ?emailDescription:'' } className={`${styles.fields}`} type='text' placeholder="Enter Group Description" onChange={(e)=>{setEmailDescription(e.target.value)}}/>
                </label>
                <div className={`${styles.addBtnDiv} ${styles.div}`}>
                {!editEmailObj || !editEmailObj.id? 
                    <button type="submit" className={styles.addBtn} 
                        onClick={handleSubmit}>Add Email Group</button>
                :
                <span style={{display:'flex', gap:'30px'}}>
                    <button onClick={()=>{clearForm(); navigate('../view-email-group')}} className='cancelEditButton'>Cancel Edit</button>
                    <button type="submit" onClick={handleEdit} className={styles.addBtn} >Save Change(s)</button>
                </span>
                }
                    
                </div>
           </form>
        </>
    );
}
 
export default AddEmails;