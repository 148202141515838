import React,{useRef,useState} from 'react'
import { useEffect } from 'react';
import css from './select.module.css';
import {BiChevronDown as DownIcon,BiChevronUp as UpIcon} from 'react-icons/bi';



const Select = ({options,onChange,defaultValue,className,label,value,disabled,reset,placeholder}) => {
    const [showList,setShowList] = useState(false);
    const [selectVal,setSelectVal] = useState('');
    const [click,setClick] = useState(false);
    const [inputVal,setInputVal] = useState('');
    const [selOptions,setSelOptions] = useState(options);
    const inputRef = useRef(null);
    const divRef = useRef(null);
    const handleClick = () =>{
        
        if(inputRef && inputRef.current){
            // console.log(showList);
            if(showList){
                inputRef.current.autofocus = false;
                setShowList(false)
                return false;
            }
            inputRef.current.focus();
            inputRef.current.autofocus = true;
            setShowList(true);
        }
        //console.log('clicked')
    }
    const handleBlur = () =>{
        if(inputRef && inputRef.current){
            if(!click){
                inputRef.current.autofocus = false;
                setShowList(false);
                setInputVal('')
            }                                 
        }
    }

    const handleFocus = () =>{
        if(divRef && divRef.current){
            setClick(true);
        }
    }

    const handleUnFocus = () =>{
        if(divRef && divRef.current){
            setClick(false);
            if(!inputRef.current.autofocus){setShowList(false)}
        }
    }
 
    const handleSelect = (val)=>{
        onChange(val);
        setSelectVal(val[label]);
        setShowList(false);
        setInputVal('');
        setSelOptions(options)
    }

    const handleInput = (e) =>{
        const value = e.target.value;
        setInputVal(value);

    }


    useEffect(()=>{
        if(!inputVal && !inputRef.current.autofocus){
            setShowList(false);
            setSelOptions(options);
            
        }
        else if(!inputVal && !click && !inputRef.current.autofocus ){
            setShowList(false);
            setSelOptions(options);  
        }
        else if(inputVal){
            const newOptions = options.filter(element => element[label].toLowerCase().includes(inputVal.toLowerCase()))
            if(newOptions.length > 0){
                setSelOptions(newOptions); 
            }
        }
        else{
            setSelOptions(options);  
        }
    },[selectVal,inputVal,showList])

    useEffect(()=>{
        if(options && options.length > 0){
            setSelOptions(options);
            setSelectVal('');
        }
        //changes made here
        else if (options && options.length==0) {
            setSelectVal(undefined)
        }
        //end
    },[options])

    useEffect(()=>{
        setSelectVal('');
        setInputVal('');
        onChange({})
    },[reset])
    useEffect(()=>{
        if(defaultValue && label && value){
            let tempVal = options.find(opt => opt[value] == defaultValue[value]);
            if(tempVal) handleSelect(tempVal);
            // console.log(defaultValue,defaultValue[label])
        }
    },[defaultValue,label,value])

    // useEffect(()=>{
    //     console.log(showList)
    // },[showList])

  const computeStyle = ()=>{
      let inpVal = inputVal?{width:'fit-content'}:{width:'4px'};
      let disVal = disabled?{backgroundColor:'#eee'}:{backgroundColor:'transparent'};
      return {width:inpVal.width,backgroundColor:disVal.backgroundColor}
  }
     
  return (
    <div className={`${className?className:''} ${css.selectContainer}`} style={disabled?{backgroundColor:'#eee'}:{backgroundColor:'transparent'}}>
        <div>
            <div></div>
            <input onChange={(e)=>{handleInput(e)}} style={computeStyle()} ref={inputRef} onBlur={()=>{handleBlur()}} disabled={disabled}/>
            {<div onClick={!disabled?()=> handleClick():()=>{}} style={!selectVal&&!inputVal?{color:'lightgrey'}:{color:'#222'}}>{selectVal && !inputVal?selectVal:inputVal?'':(defaultValue && defaultValue[label]?defaultValue[label]:!inputVal && !showList && placeholder?placeholder:'Select ...') }</div>}
            <span title={disabled?'disabled':''} onClick={()=>{handleClick()}} className={`${css.icons} ${disabled?`${css.disabled}`:''}`}>{!disabled ?!showList?<DownIcon/>:<UpIcon />:<DownIcon/>}</span>
        </div>
        { !disabled && showList && <div ref={divRef} onMouseOver={()=>{handleFocus()}} onMouseOut={()=>{handleUnFocus()}}> 
            {showList && selOptions && selOptions.length > 0 && selOptions.map((element,index) =>(
                <div key={index} onClick={()=>handleSelect(element)}>{element[label]}</div>
            ))}
        </div>}
    </div>
  )
}

export default Select