import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormContext } from "./Form";
import styles from '../../../css/AddQuestions.module.css';
import ResultCss from '../../../css/Result.module.css';
import CategoriesCss from '../../../css/Categories.module.css';
import { addForm, editForm, } from "../../../redux/reducers/portalReducer";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import { useNavigate } from "react-router-dom";
import Api from '../../../helperFunctions/Api2';
import TextEditor from "../../../components/TextEditor";

const defpage = {
    id: 1,
    title: '',
    add_instruction: false,
    instruction: '',
    questions: [{
        id: 0,
        question: '',
        answer_type: 'short_text',
        is_required: false,
    }]
};
const CreateForm = () => {
    const { setTitle, editing, editData, clearEdit } = useContext(FormContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const [pages, setPages] = useState([defpage]);
    const [formName, setFormName] = useState('');
    const [add_instruction, setadd_instruction] = useState(false);
    const [instruction, setInstruction] = useState('');


    const addsPage = (index) => {
        setPageNo(index);
        const page = {
            ...defpage,
            id: index,
        };
        setPages([...pages, page]);
    };
    const delPage = (id) => {
        let p = pages.filter(e => e.id !== id);
        setPages(p);
    };

    const changePage = (page) => {
        let p = pages.map(e => e.id === page.id ? page : e);
        setPages(p);
    };
    const clearFields = () => {
        setPageNo(1);
        setPages([defpage]);
        setFormName('');
        setadd_instruction(false);
        setInstruction('');
    };

    //this function validates select before going to the database.
    const validate = (value, body) => {
        if (Object.keys(value).length <= 0) {
            setModalText(`Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        else if (value && value.length <= 0) {
            setModalText('Form could not be created, ' `Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        else if (value === null || value === '' || value === 'undefined' || value <= 0) {
            setModalText('Form could not be created ' `Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate(formName, 'A Title')) {
            return false;
        }
        if (add_instruction && !validate(instruction, 'An instruction')) {
            return false;
        }
        let valid = true;
        pages.forEach(page => {
            if (!validate(page.title, 'All page titles')) {
                return valid = false;

            }
            if (page.add_instruction && !validate(page.instruction, 'All page instructions')) {
                return valid = false;
            }
            page.questions.forEach((question) => {
                if (!validate(question.question, 'All Questions')) {
                    return valid = false;
                }
                if (question.answer_type === 'select') {
                    // console.log(question.options)
                    question.options.forEach(option => {
                        if (!validate(option, 'All Options')) {
                            return valid = false;
                        }
                    });
                }
            });
        });
        if (!valid) {
            return false;
        }
        if (true) {
            const form = {
                title: formName,
                add_instruction,
                instruction,
                // pages
                form: JSON.stringify(pages)
            };
            // console.log(form, "forms");
            if (editing) {
                form.id = editData.id;
                setShowLoading(true);
                Api.PostApi('/form/edit-form', form, true)
                    .then(result => {
                        setShowLoading(false);
                        setModalText(result.message);
                        setModalToggle(true);
                        console.log(result);
                        if (result.isSuccessful) {
                            dispatch(editForm(form));
                            clearEdit();
                            clearFields();
                        };
                    })
                    .catch(error => {
                        setShowLoading(false);
                        setModalText(error.message);
                        setModalToggle(true);
                    });
            }
            else {
                setShowLoading(true);
                Api.PostApi('/form/add-form', form, true)
                    .then(result => {
                        setShowLoading(false);
                        setModalText(result.message);
                        setModalToggle(true);
                        if (result.isSuccessful) {
                            // form.id = result.data;
                            dispatch(addForm(result.data));
                            clearFields();
                        };
                    })
                    .catch(error => {
                        setShowLoading(false);
                        setModalText(error.message);
                        setModalToggle(true);
                    });

            }

        }
    };

    const handleCancel = () => {
        clearFields();
        clearEdit();
        navigate('../view-forms');
    };

    useEffect(() => {
        setTitle('Create Form');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // **editing useffect**
    useEffect(() => {
        if (editing && editData.form) {
            const pages = JSON.parse(editData.form);
            // console.log(pages)
            setPages(pages);
            setFormName(editData.title);
            setadd_instruction(editData.add_instruction);
            setInstruction(editData.instruction);
        };
    }, [editing, editData]);

    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={ResultCss.mainView}>
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Form Title</span>
                    <input className={CategoriesCss.input} value={formName} onChange={(e) => setFormName(e.target.value)} />
                </label>
                <label className={CategoriesCss.label} >
                    <span>Add Instruction?</span>
                    <input type="checkbox" className={ResultCss.switchBtn} checked={add_instruction} onChange={() => setadd_instruction(!add_instruction)} />
                </label>
                {add_instruction &&
                    <TextEditor text={"instruction"} value={instruction} handleChange={setInstruction} navigate={navigate} setShowLoading={setShowLoading} />
                }

                {pages.map((page, index) => <Page key={index} page={page} delPage={delPage} changePage={changePage} />)}
                <button className={ResultCss.addColumnBtn} onClick={() => addsPage(pageNo + 1)}><FaPlus />Add Page</button>

                <div className={CategoriesCss.buttonContainer}>
                    {editing && <button className={'cancelEditButton'} onClick={handleCancel} >Cancel</button>}
                    <button className={CategoriesCss.viewBtn} onClick={handleSubmit} >{!editing ? 'Add' : 'Save'} form</button>
                </div>
            </div>
        </>
    );

};
export default CreateForm;


const Page = ({ page, delPage, changePage }) => {

    const [index, setIndex] = useState(1);
    const [questions, setQuestions] = useState(page.questions);

    const addsQuestion = (index) => {
        setIndex(index);
        let question = defpage.questions[0];
        question.id = index;
        setQuestions([...questions, question]);
    };
    const delQuestion = (id) => {
        let q = questions.filter(e => e.id !== id);
        setQuestions(q);
    };
    const changeQuestion = (ques) => {
        let q = questions.map(e => e.id === ques.id ? { ...e, ...ques } : e);
        // setQuestions(q);
        changePage({ ...page, questions: q });
        // console.log(ques, questions[0])
    };

    useEffect(() => {
        setQuestions(page.questions);
    }, [page.questions]);
    useEffect(() => {
        let i = 0;
        if (i < 1 && page.questions === defpage.questions) {
            i++;
            setQuestions(defpage.questions);
        }
    }, [page.questions]);
    useEffect(() => {
        if (index < -1) {
            setIndex(-1);
        }
    }, [index]);

    return (
        <>
            <div className={ResultCss.section} style={{ flexDirection: 'column' }}>
                <span className={ResultCss.deleteBtn + ` ${ResultCss.deleteSection}`} onClick={() => delPage(page.id)}><FaRegTrashAlt />Delete Page</span>
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Page Title</span>
                    <input className={CategoriesCss.input} value={page.title} onChange={(e) => changePage({ ...page, title: e.target.value })} />
                </label>
                <label className={CategoriesCss.label} >
                    <span>Add instruction?</span>
                    <input type="checkbox" checked={page.add_instruction} className={ResultCss.switchBtn} value={page.add_instruction} onChange={() => changePage({ ...page, add_instruction: !page.add_instruction })} />
                </label>
                {page.add_instruction && <label className={CategoriesCss.label}>
                    Instruction
                    <textarea className={`${CategoriesCss.input} ${CategoriesCss.textarea}`} value={page.instruction} onChange={(e) => changePage({ ...page, instruction: e.target.value })} />
                </label>}
                {questions.map((question, index) => <Question key={index} index={index} pageId={page.id} question={question} delQuestion={delQuestion} changeQuestion={changeQuestion} />)}
                <button className={ResultCss.addColumnBtn} onClick={() => addsQuestion(index + 1)}><FaPlus />Add Question</button>
            </div>
        </>
    );
};

const Question = ({ index, question, delQuestion, changeQuestion }) => {
    const [number, setNumber] = useState(2);
    const [options, setOptions] = useState(question.options || ['', '']);
    const [multichoice, isMultichoice] = useState(false);

    const handleQuestionTypeChange = (answer_type) => {
        // changeQuestion({ ...question });
        if (answer_type === 'select') {
            isMultichoice(true);
            changeQuestion({ ...question, answer_type, options });
        } else {
            isMultichoice(false);
            changeQuestion({ ...question, answer_type, options: [] });

        }
    };
    const onNumberInputChange = (e) => {
        let value = e.target.value;
        while (/[a-zA-Z]+/.test(value)) {
            value = String(value).slice(0, -1);
        }

        if (value.length > 1)
            value = String(value).slice(1);
        //console.log('value', value);
        if (value > 5) {
            value = 5;
        } else if (value < 2) {
            value = 2;
        }
        setNumber(Number(value));
    };
    useEffect(() => {

        let opts = [];
        for (let i = 0; i < number; i++) {
            let el = options[i];
            if (el) {
                opts.push(el);
            } else {
                opts.push('');
            }
        }
        setOptions(opts);
        // console.log(options)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [number]);

    useEffect(() => {
        changeQuestion({ ...question, options });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    return (
        <>
            <span className={ResultCss.index}>{index + 1}</span>
            <button onClick={() => delQuestion(question.id)} className={ResultCss.deleteBtn}><FaRegTrashAlt />Delete Question</button>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Question</span>
                <input className={CategoriesCss.input} value={question.question} onChange={(e) => changeQuestion({ ...question, question: e.target.value })} />
            </label>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Answer Type</span>
                <select value={question.answer_type} onChange={(e) => handleQuestionTypeChange(e.target.value)} className={CategoriesCss.select} >
                    <option value={'text'}>short text</option>
                    <option value={'long_text'}>long text</option>
                    <option value={'checkbox'} >true/false</option>
                    <option value={'select'} >multi choice</option>
                    <option value={'email'} >email</option>
                    <option value={'tel'} >phone number</option>
                    <option value={'date'} >date</option>
                    {/* <option value={'time'}></option> */}
                    <option value={'datetime-local'}>Date/Time</option>
                    <option value={'number'} >number</option>
                    <option value={'file'} >file</option>
                </select>
            </label>
            {multichoice && <>
                <label htmlFor='options' className={`${styles.optionAmount} ${styles.label}`} >
                    Number of Options
                    <input type='number' id='options' className={styles.input} value={number} onChange={onNumberInputChange} required />
                </label>
                <div className={`${styles.choices} ${styles.div}`}>
                    {options.map((option, num) => <Option option={option} key={num} num={num} options={options} setOptions={setOptions} />)}
                </div>
            </>}
            <label className={CategoriesCss.label} >
                <span>Answer Required/Compulsory? </span>
                <input type="checkbox" className={ResultCss.switchBtn} checked={question.is_required} onChange={() => changeQuestion({ ...question, is_required: !question.is_required })} />
            </label>
            <hr className={ResultCss.hr} />
        </>
    );
};
const Option = ({ options, setOptions, option, num }) => {

    return (
        <>
            <label htmlFor={`option-${Number(num + 1)}`} className={`${styles.label}`}>
                <span className={styles.isRequired}> Option {Number(num + 1)}</span>
                <textarea type='text' id={`option-${Number(num + 1)}`} value={option}
                    onInput={(e) => setOptions(options.map((option, index) => index === num ? e.target.value : option))} name={`option-${Number(num + 1)}`} required rows='3' className={styles.textarea} />

            </label>
        </>
    );
};