import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './../css/Login.module.css';
import Loading from './loading';
import Modal from "./modal";
import InputField from './InputField';
import Api from "../Api";

const ForgotPassword = () => {
    const [input, setInput] = useState('');
    const [email, setEmail] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const onInputChange = (e = this) => {
        setInput(e.target.value);
        if (/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/.test(e.target.value)) {
            setEmail(true);
        } else {
            setEmail(false);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    return (
        <div className={`${styles.formParent}`}>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <form className={`${styles.defaultLog}`} onSubmit={handleSubmit}>
                <h1 className={`${styles.signIn}`}>
                    Forgot Password
                </h1>
                <span className={`${styles.notice}`}>A mail will be sent to the email provided with the link to reset your password.</span>
                <InputField title='Email' inputID='user-mail' placeholder='Enter your Email' type='email' onChange={onInputChange} />

                <div className={`${styles.btnDiv}`}>
                    <button type='submit' className={`${styles.submitButton}`} onClick={() => {
                        setShowLoading(true);
                        Api.PostApi('/account/login/verify-email', { email: input }, false)
                        .then(response=>response.json())
                            .then((result) => {
                                setModalText(result.message);
                                setShowLoading(false);
                                setModalToggle(true);
                            })
                            .catch(error => {
                                //console.log(error, error)
                                setModalText(error.message);
                                setShowLoading(false);
                                setModalToggle(true);
                            });
                    }} disabled={!email}>Send Email</button>
                    <span className={`${styles.littleNotice}`}>Click <Link to='/account/sign-in' className={`${styles.reverseButton}`}>here</Link> to log in</span>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;