import React from "react";
import { useEffect, useState,useContext } from 'react';
import HTMLReactParser from 'html-react-parser';
import style from './Css/event.module.css';

const SendMsg = ({message, currentInd, currentOpened, changeInd, handleEditMsg,handleDeleteMsg, changeCurrentOpened, viewEventStyle}) => {
    const [openMsg, setOpenMsg] = useState(false);
    const [hamstyle, setHamStyle] = useState('none');
  

    const handleDisplay = (ind) => {
        if(ind) {
            changeInd(ind)
        }
        else{
            currentInd(null)
        }
    }
    const handleDetails = (ind) => {
        if(ind) {
            changeCurrentOpened(ind)
        }
        else {
            currentOpened(null)
        }
    }
    useEffect(() => {
      if(message.id == currentInd) {
        setHamStyle('flex');
      }
      else{
        setHamStyle('none')
      }
    },[currentInd])


    useEffect(() => {
      if(message.id == currentOpened) {
          setOpenMsg(true)
      }
      else{
           setOpenMsg(false)
      }
    },{})



    return ( 
        <>
         <div className={viewEventStyle}>
          <div style={openMsg ? {height:'auto'} : {height:'60px'}}>
             Message:  {HTMLReactParser(message.title)}
          </div>
          <span className={style.eventSpan} onClick={()=>{handleDisplay(message.id)}}>
            <span></span>
            <span></span>
            <span></span>
          </span>
          <div style={{display:hamstyle}} className={style.handle}>
            <span onClick={()=>{handleDisplay(null)}}>x</span>
            <button onClick={()=>{handleEditMsg(message)}}>Edit</button>
            <button  onClick={()=>{handleDeleteMsg(message)}}>Delete</button>
          </div> 
       

         </div>
        </>
     );
}
 
export default SendMsg;