import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/loading";
import styling from '../../../css/Categories.module.css';
import style from '../Calender/Css/event.module.css';
import { LearningContext } from './NoteIndex';
import HTMLReactParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import Api from "../../../helperFunctions/Api2";
import { deleteNote } from "../../../redux/reducers/learningReducer";
import { userCategory } from "./addNote";
import { FaEdit, FaRegTrashAlt, FaTimes } from "react-icons/fa";
import { MenuContext } from "../DashBoard";
import styled from 'styled-components';
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;

const ViewNote = ({ setTitle }) => {
	const [showLoading, setShowLoading] = useState(false);
	const notes = useSelector(state => state.learning.notes);
	const { setModalObject, setModalToggle, setEditNote, setEditObj } = useContext(LearningContext)
	const { selected, changeSelected } = useContext(MenuContext);
	// const [allnotes, setAll] = useState([]);
	const Navigate = useNavigate();
	const dispatchFunc = useDispatch();
	const [copyText, setCopyText] = useState(-1);
	const Institution = useSelector(state => state.questions.user_institutions);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNum, setPageNum] = useState(0);

	const handleChange = (eachnote) => {
		if (eachnote) {
			changeSelected(eachnote.id);
		}
		else {
			changeSelected(null);
		}
	}

	const handleEdit = (eachnote) => {
		setShowLoading(true);
		setEditNote(true);
		setEditObj(eachnote);
		setShowLoading(false);
		Navigate('../create-note');
	}

	const handleBtn = (text, index) => {
		navigator.clipboard.writeText(text);
		setCopyText(index);

		setTimeout(() => {
			setCopyText(-1);
		}, 3000);
	}

	const handleDelete = (eachnote) => {
		window.confirm('Are you sure you want to delete this note');
		if (eachnote.id) {
			setShowLoading(true);
			Api.PostApi('/learning/delete-note', {
				id: eachnote.id
			}, true)
				.then(result => {
					setShowLoading(false);
					setModalObject({ header: '', body: result.message, footer: '' });
					setModalToggle(true);
					if (result.isSuccessful) {
						dispatchFunc(deleteNote(eachnote.id))
					};
				})
				.catch(error => {
					setShowLoading(false);
					setModalObject({ header: '', body: error.message, footer: '' });
					setModalToggle(true);
				})
		}
	}

	useEffect(() => {
		setTitle('View Notes/Ebooks')
	}, [setTitle]);

	// useEffect(() => {
	// 	if (notes) {
	// 		setAll(notes);
	// 	}
	// 	console.log(notes)

	// }, [notes])
    useEffect(() => {
        if (notes.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((notes.length / NO_OF_ITEMS_PER_PAGE) + ((notes.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [notes.length]);

	return (
		<>
			<Loading shouldShow={showLoading} />
			<div className={styling.form}>
				<div className={styling.viewQuestionContainer}>
					{notes.length > 0 ? notes.map((each, index) =>
						index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
						<Each key={each.id} Institution={Institution} each={each} index={index} handleBtn={handleBtn} copyText={copyText} selected={selected} handleChange={handleChange} handleEdit={handleEdit} handleDelete={handleDelete} />)
						: 'No note added yet'}
				</div>
				{notes.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

			</div>
		</>
	)
}
export default ViewNote;
const Each = ({ each, index, Institution, selected, handleChange, handleEdit, handleDelete, handleBtn, copyText }) => {

	const [hamstyle, setHamStyle] = useState('none');
	const [openStyle, setopenStyle] = useState(false);

	useEffect(() => {
		if (selected === each.id) {
			setHamStyle('flex')
		}
		else setHamStyle('none')
	}, [selected, each.id])

	return (
		<div className={styling.eachQuestion} style={{ minHeight: '140px' }}>
			<div>
				<div>Title: <span className={styling.eachQuestionValue}>{HTMLReactParser(each.title)}</span></div>
				<div>
					Audience: <span className={styling.eachQuestionValue}>{HTMLReactParser(userCategory.find(u => u.id === each.audience).name)}</span>
				</div>
				{
					each.audience === 1 && (
						<div>
							Note Link:&nbsp;&nbsp;
							<Button style={copyText === index ? { color: 'white', backgroundColor: 'var(--secondary-color)' } : {}} onClick={() => handleBtn(`https://notes.sight-eduplus.com/${each.link}?d=0`, index)} disabled={copyText === index}>{copyText === index ? 'Link Copied' : 'Copy Link'}</Button>
						</div>
					)}
				{
					each.audience === 2 && (
						<div>
							Institution: <span className={styling.eachQuestionValue}>{Institution.filter(x => x.id === each.institution_id)[0].name}</span>
						</div>
					)
				}
			</div>
			<span onClick={() => { handleChange(each) }}>
				<span></span>
				<span></span>
				<span></span>
			</span>
			<div style={{ display: hamstyle }} >
				<span onClick={() => { handleChange() }}><FaTimes /></span>
				<button onClick={() => { handleEdit(each) }}><FaEdit /> Edit</button>
				<button onClick={() => { handleDelete(each) }}><FaRegTrashAlt /> Delete</button>
			</div>
			<button className={style.eventBtn} onClick={() => { setopenStyle(!openStyle) }}>{!openStyle ? 'See Note' : 'Hide Note'} ...</button>
			<div style={openStyle ? { display: 'flex' } : { display: 'none' }} className={style.moreDetails}>
				<b><u>Note</u></b>
				{each.is_created ? (
					<div>{HTMLReactParser(each.note)}</div>
				) : (
					<div style={{ paddingBottom: 10 }}><a target="_blank" style={{ textDecoration: 'underline' }} className={styling.eachQuestionValue} href={`https://api.sight-eduplus.com/upload/notes/${each.note}`} rel="noreferrer">View note</a></div>
				)}
			</div>
		</div>
	)
}

const Button = styled.button`
    color:var(--secondary-color);
    border:1px solid var(--secondary-color);
    padding:3px 6px;
    border-radius: 5px;
    width: fit-content;
    font-size:12px;
    background-color: white;
`