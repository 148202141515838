import { useContext, useEffect, useState } from "react";
import style from './../../../css/ManualAdd.module.css';
import styles from './../../../css/Categories.module.css';
import EmailLIst from "../../../components/EmailList";
import Select from "../../../components/Select";
import { useSelector } from "react-redux";
import { MenuContext } from "../DashBoard";
import restApi from "../../../helperFunctions/Api2";

const AddInvite = ({ setTitle }) => {

    const institutions = useSelector(state => state.questions.user_institutions);
    const [selInst, setSelInst] = useState(null);
    const [emailArrayList, setEmailArrayList] = useState([]);
    const [resetEmail, setResetEmail] = useState(false);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);

    function handleSubmit (e) {
        e.preventDefault();
        if (!selInst || !selInst.id) {
            setModalText("Please select an institution");
            setModalToggle(true); return
        }else if (emailArrayList.length < 1) {
            setModalText("Please add an email");
            setModalToggle(true); return
        }
        setShowLoading(true);
        const payload = {
            institution: selInst.name, institution_id: selInst.institution_id, inst_id: selInst.id,
            users: emailArrayList
        };
        // console.log(payload, 'invit user')
        restApi.PostApi('/institution/invite-users', payload, true)
            .then(result => {
                console.log(result);
                if (result.isSuccessful) {
                    setResetEmail(!resetEmail);
                    setSelInst(null);
                }
                setModalText(result.message);
            }).catch(() => setModalText('error')).finally(() => {
                setModalToggle(true);
                setShowLoading(false);
            });
        console.log(payload);

    }
    useEffect(() => {
        setTitle('Send Invite');
    }, [setTitle]);

    return <div className={style.mainView}>

        <label className={styles.label}>
            <span className={styles.isRequired}>Select Institution</span>
            <Select name='institution' options={institutions} onChange={setSelInst} className={styles.fields} label='name' value='institution_id' defaultValue={selInst} reset={selInst === null} />
        </label>
        <label className={`${styles.label}`} >
            <span>Add List to Selected Email Group</span>
            <EmailLIst className={styles.fileds} onChange={setEmailArrayList} reset={resetEmail} />
        </label>
        <small style={{ width: '100%', marginBottom: '20px' }} >
            NOTICE: Emails should be separated by a comma(<b>,</b>) or space
        </small>
        <div className={` ${styles.buttonContainer}`}>
            <button type='submit' className={styles.viewBtn} onClick={(e) => handleSubmit(e)}>Send Invite</button>
        </div>
    </div>;
};
export default AddInvite;