import React from "react";
import styling from '../../../css/Categories.module.css';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Loading from '../../../components/loading';
import { eventModalContext } from "./events";
import { useSelector, useDispatch } from 'react-redux';
import css from './Css/view-event.module.css';
import { EditComponent } from "../../../components/EditComponent";
import Api, { convertDatetoString } from "../../../helperFunctions/Api2";
import { deleteEvent } from "../../../redux/reducers/eventReducer";
import PageNumber from '../../../components/PageNumber';
import HTMLReactParser from "html-react-parser";
const NO_OF_ITEMS_PER_PAGE = 30;


const ViewEvents = ({ setTitle }) => {



  const navigate = useNavigate();
  const [menuIndex, setMenuIndex] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();
  const events = useSelector(state => state.event.events);
  const emailGroups = useSelector(state => state.event.email_group);
  const institutions = useSelector(state => state.questions.user_institutions);
  const { setEditEventCon, setModalToggle, setModalObject } = useContext(eventModalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(0);
  const [allInstCategories, set_allInstCategories] = useState({});

  const handleDelete = (id) => {
    if (!Api.checkNetwork()) return false;

    setShowLoading(true)
    Api.PostApi('/event/delete-event', { id }, true)
      .then(result => {

        console.log(result, "Server result")
        if (result.isSuccessful) {
          dispatch(deleteEvent({ id }));
          // setMenuIndex(null);
          setShowLoading(false);
          setModalObject({ header: 'Message', body: "Event deleted successfully", footer: '' });
          setModalToggle(true);
        }
      })
      .catch(error => {
        setShowLoading(false);
        console.log(error, "Server Error")
      })
  }

  const handleDeleteBtn = (id) => {
    setModalObject({
      header: <>Confirmation Message</>,
      body: <>Are you want to delete this Event. Deleting this
        This event will remove all related record to the event.
      </>,
      footer: <>
        <div>
          <button onClick={() => setModalToggle(false)}>
            Cancel
          </button>
          <button onClick={() => handleDelete(id)}>
            Delete
          </button>
        </div>

      </>
    })
    setModalToggle(true);
  }

  const handleEditBtn = (ev) => {
    setEditEventCon(ev);
    navigate('../create-event');
  }

  useEffect(() => {
    setTitle('View events')
  }, [setTitle])
  // useEffect(() => {
  //   console.log(events.map(eEvent => eEvent.categories.map((category, ind) => category.is_institution ?( allInstCategories[category.category_id].name || 'Not found'):( emailGroups.find(group => group.id === category.category_id)? emailGroups.find(group => group.id === category.category_id).title : 'error') )))
  // }, [emailGroups])

  useEffect(() => {
    let obj = {}
    institutions.forEach(institution => {
      obj = {...obj, ...institution.categories}
    })
    set_allInstCategories(obj)
  }, [institutions])

  useEffect(() => {
    if (events.length > NO_OF_ITEMS_PER_PAGE) {
      setPageNum((events.length / NO_OF_ITEMS_PER_PAGE) + ((events.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
    }
  }, [events.length]);

  return (
    <>
      <Loading shouldShow={showLoading} />
      <div className={styling.form}>
        <div className={`${styling.viewQuestionContainer} ${css.container}`}>
          {events.length > 0 ? events.map((eEvent, index) =>
            index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
            <div key={index}>
              <EditComponent index={index} menuIndex={menuIndex} clickDelete={() => handleDeleteBtn(eEvent.id)} setMenuIndex={setMenuIndex}
                clickEdit={() => handleEditBtn(eEvent)}
              />
              <div>
                <span>Title: </span>
                <span>{eEvent.title}</span>
              </div>
              <div>
                <span>Description: </span>
                <span>{HTMLReactParser(eEvent.description)}</span>
              </div>
              <div>
                <span>Groups: </span>
                <div className={css.emailGroups}>
                  {eEvent.categories.map((category, ind) => <span key={ind}>{category.is_institution ? (allInstCategories[category.category_id]? allInstCategories[category.category_id].name : 'Error' ): emailGroups.find(group => group.id === category.category_id)? emailGroups.find(group => group.id === category.category_id).title : 'Error'}</span> )}
                </div>
              </div>
              <div>
                <span>Start Date: </span>
                <span>{convertDatetoString(eEvent.start_date, 1)}</span>
              </div>
              <div>
                <span>End Date: </span>
                <span>{convertDatetoString(eEvent.end_date, 1)}</span>
              </div>
              {/* <pre style={{wordSpacing:"wrap"}}>{JSON.stringify(eEvent)}</pre> */}
            </div>
          ) : "No Event Created yet"}
        </div>
        {events.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

      </div>
    </>
  );
}

export default ViewEvents;