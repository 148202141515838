export const daysOfWeek = {
    '0': 'Sun',
    '1': 'Mon',
    '2': 'Tue',
    '3': 'Wed',
    '4': 'Thur',
    '5': 'Fri',
    '6': 'Sat'
};

const date = new Date()
const curYear = date.getFullYear();
// const startCountingDay = 1;

export const monthOfTheYear = {
    '0': {
        days: 31, name: 'January'
    },
    '1': {
        days: 28, name: 'February'
    },
    '2': {
        days: 31, name: 'March'
    },
    '3': {
        days: 30, name: 'April'
    },
    '4': {
        days: 31, name: 'May'
    },
    '5': {
        days: 30, name: 'June'
    },
    '6': {
        days: 31, name: 'July'
    },
    '7': {
        days: 31, name: 'August'
    },
    '8': {
        days: 30, name: 'September'
    },
    '9': {
        days: 31, name: 'October'
    },
    '10': {
        days: 30, name: 'November'
    },
    '11': {
        days: 31, name: 'December'
    }
};

export const getWeekDays = (bookings = null, editingDay) => {
    let calendarDays = [];
    for (let i in daysOfWeek) {
        let val = daysOfWeek[i];
        let obj = {};
        obj.dayNum = i;
        obj.dayName = val;
        obj.isAvailable = false;
        if (editingDay) {
            obj.disabled = `${i}-${val}` !== editingDay;
        }
        if (bookings) {
            if (bookings[`${i}-${val}`]) {
                const times = bookings[`${i}-${val}`]; const timesLen = times.length;
                obj.bookings = [];
                for (let j = 0; j < timesLen; j++) {
                    obj.bookings[j] = bookings[`${i}-${val}`][j];
                    if (bookings[`${i}-${val}`][j].slots > 0) {
                        obj.isAvailable = true;
                        obj.bookings[j].isAvailable = true;
                    }
                    else {
                        obj.bookings[j].isAvailable = false;
                    }
                }
            }
            else {
                obj.isAvailable = false;
            }
        }

        calendarDays.push(obj);
    }

    // console.log(calendarDays);
    return calendarDays;
};

export const getCalendarDays = (year, mon, bookings = null, editingDay) => {
    let totalDays = 0;
    for (let i = 0; i < (year - curYear); i++) {

        if (i % 4) {
            totalDays += 366;
        }
        else {
            totalDays += 365;
        }
    }
    // console.log(mon, 'month')
    for (let i = 0; i < mon; i++) {
        if (year % 4 === 0 && i === 1) {
            totalDays += 29;
        }
        else {
            totalDays += monthOfTheYear[`${i}`].days;
        }
    }
    // console.log(totalDays, 'totalDays')
    let startDay = (totalDays + 1) % 7;
    let monthDays = year % 4 === 0 && mon === 1 ? 29 : monthOfTheYear[`${mon}`].days;
    // console.log(startDay, 'startDay')
    let calendarDays = [];

    for (let i = 0; i < startDay; i++) {
        calendarDays.push({});
    }

    for (let i = 1; i <= monthDays; i++) {
        let obj = {};
        obj.dayNum = i;
        obj.isAvailable = false;
        obj.disabled = (year < curYear) || (year === curYear && mon < date.getMonth()) ||
            (year === curYear && mon === date.getMonth() && i < date.getDate())
        if (editingDay) {
            obj.disabled = `${year}-${mon}-${i}` !== editingDay;
        }
        
        if (bookings) {
            if (bookings[`${year}-${mon}-${i}`]) {
                const times = bookings[`${year}-${mon}-${i}`]; const timesLen = times.length;
                obj.bookings = [];
                for (let j = 0; j < timesLen; j++) {
                    obj.bookings[j] = bookings[`${year}-${mon}-${i}`][j];
                    if (bookings[`${year}-${mon}-${i}`][j].slots > 0) {
                        obj.isAvailable = true;
                        obj.bookings[j].isAvailable = true;
                    }
                    else {
                        obj.bookings[j].isAvailable = false;
                    }
                }
            }
            else {
                obj.isAvailable = false;
            }
        }

        calendarDays.push(obj);
    }

    // console.log(calendarDays);
    return calendarDays;
};

export function formatDate (dayKey) {
    let datestr = dayKey.split('-');
    if (datestr.length === 3) {
        let newdate = new Date([parseInt(datestr[1]) + 1, datestr[2], datestr[0]].join('-'));
        return newdate.toDateString();
    } else if (datestr.length === 2) {
        return datestr[1];
    }
}