import React,{useContext,useState} from 'react';
import css from './css/cbt.module.css';
import HTMLReactParser from 'html-react-parser';
import { CbtQuestionId } from './createCbt';
import { useEffect } from 'react';
import Input from './CbtQuestionTimeInput';
import { AllQuestionContext } from './EachCategorySetting';

const NUMBER_OF_QUESTIONS_PER_SET = 10;

const AllQuestions = ({allQuestions,timeType,setNumOfQuestions,index,timeVal}) => {
    // console.log(allQuestions);
    const [activeShow,setActiveShow] = useState([]);

    const {inputSet,setInputSet,selectedId,setSelectedId,timeArrVal,setTimeArrVal} = useContext(AllQuestionContext);
 
    const {allQuestionId,setAllQuestionId} = useContext(CbtQuestionId);

    const createNumArr = (ind,initArr) =>{
        let i;
        let arr = [];
        const newArr = initArr.filter(element => (Number(element) < Number(ind) || Number(element) >= Number(ind+NUMBER_OF_QUESTIONS_PER_SET)))
        for(i=0;i<NUMBER_OF_QUESTIONS_PER_SET;i++){
            if(ind+i < allQuestions.length)
            {arr.push(ind+i);}
            else{
                break
            }
        }
        return [...arr,...newArr];
    }

    const getQuestionId = (ind) =>{
        let start = Number(ind);
        let i;
        let len = (start + NUMBER_OF_QUESTIONS_PER_SET) > allQuestions.length ? allQuestions.length : (start+NUMBER_OF_QUESTIONS_PER_SET);
        const id_arr = [];
        for(i = start;i<len;i++){
            let obj = {
                id:allQuestions[Number(i)].id,
                score:allQuestions[Number(i)].score,
            }
            // id_arr.push(allQuestions[Number(i)].id)
            id_arr.push(obj);
        }
        return id_arr;
    }

    const getDurations = (ind,len) =>{
        let start = Number(ind);
        let i;
        let arr = [];
        for(i = start;i<len;i++){
            // let duration = timeType.value !== '2' ?null:timeArrVal.filter(element => Number(element.id) === Number(i+1)).length > 0 ? timeArrVal.filter(element => Number(element.id) === Number(i+1))[0].value  : null;
            const duration = timeVal;
            arr.push(duration);
        }
        return arr;
    }

    const removeSetOfId = (arr) => {
        // console.log(arr,'remove');
        let newArr = [];
        let len = arr.length;
        let i;
        let resultArr = [];
        resultArr = allQuestionId.filter((e,ind) => Number(ind) === Number(index));
        if(resultArr.length > 0){
            for(i = 0;i<len;i++){
                if(i == 0){
                    newArr = resultArr[0].questions.filter(elem => elem.id !== arr[i].id );
                }
                else{
                    newArr = newArr.filter(elem => elem.id !== arr[i].id );
                }
            }
        }
        return newArr;  
    }

    const editSetOfId = (arr,ind) =>{
        // console.log(arr,'edit');
        let newArr = [];
        let oldArr = []
        let len = arr.length;
        let i;
        let resultArr = [];
        resultArr = allQuestionId.filter((e,num) => Number(num) === Number(index));
        if(resultArr.length > 0){
            for(i = 0;i<len;i++){
                if(i == 0){
                    oldArr = resultArr[0].questions.filter(elem => elem.id !== arr[i].id );
                }
                else{
                     oldArr = oldArr.filter(elem => elem.id !== arr[i].id );
                }
            }
        }
        const durationArr = getDurations(ind,len);
        for(i = 0;i<len;i++){
            newArr[i] = {id:arr[i].id,score:arr[i].score,duration:durationArr[i]};
        }
        return [...newArr,...oldArr];
    }

    const handleInputSet = (ind)=>{
        if(inputSet && inputSet.length > 0){
            const isExist = inputSet.some(element => Number(element) === Number(ind))
            
            if(isExist){
                setInputSet(inputSet.filter((element) => (Number(element) !== Number(ind) )))
                setSelectedId(selectedId.filter((element) => (Number(element) < Number(ind) || Number(element) >= Number(ind+NUMBER_OF_QUESTIONS_PER_SET))));
                const all_id = getQuestionId(ind);
                const newIdArr = removeSetOfId(all_id);
                setAllQuestionId([...allQuestionId.map((element,num) => Number(num) === Number(index)?{questions:[...newIdArr]}:element)])
            }
            else{
                setInputSet([...inputSet,ind])
                setSelectedId(createNumArr(ind,selectedId))
                const all_id = getQuestionId(ind);
                const editedIdArr = editSetOfId(all_id,ind);
                setAllQuestionId([...allQuestionId.map((element,num) => Number(num) === Number(index)?{questions:[...editedIdArr]}:element)]);
            }
        }
        else{
            setInputSet([ind])
            setSelectedId(createNumArr(ind,selectedId));
            const all_id = getQuestionId(ind);
            // console.log(all_id,'all_id');
            let len = all_id.length;
            const all_duration = getDurations(ind,len);
            const newData = all_id.map((each_id,num) => ({...each_id,duration:all_duration[num]}))
            setAllQuestionId([...allQuestionId.map((element,num) => Number(num) === Number(index)?{questions:newData}:element )]);
        }
    }

    const getEachQuestionId = (ind) =>{
        const idArr = allQuestions.filter((element,num) => Number(num) === Number(ind) )
        return idArr[0].id;
    }

    const handleSelectedId = (ind) =>{
        if(selectedId && selectedId.length > 0){
            const isExist = selectedId.some(element => Number(element) === Number(ind))
            if(isExist){
                setSelectedId(selectedId.filter((element) => (Number(element) !== Number(ind) )));
                const q_id = allQuestions[Number(ind)].id
                setAllQuestionId([...allQuestionId.map((element,num) => Number(num) === Number(index)?{questions:[...element.questions.filter(e => Number(e.id) !== Number(q_id))]}:element)])
            }
            else{
                setSelectedId([...selectedId,ind]);
                const id= allQuestions[Number(ind)].id;
                let duration=null;
                
                let score = 0;
                if(allQuestions[Number(ind)].score) score = allQuestions[Number(ind)].score;
                let durationArr = timeArrVal.filter(element => element.id === (ind+1));
                if(durationArr.length > 0){
                    duration = durationArr[0].value;
                }
                setAllQuestionId([...allQuestionId.map((element,num)=> Number(num) === Number(index)?{questions:[...element.questions,{id,score,duration}]}:element)])
            }
        }
        else{
            setSelectedId([ind]);
            const duration = null;
            const id = allQuestions[Number(ind)].id;
            let score = 0;
            if(allQuestions[Number(ind)].score) score = allQuestions[Number(ind)].score;
            //console.log(allQuestionId)
            setAllQuestionId([...allQuestionId.map((element,num) => Number(num) === Number(index) ?{questions:[{id,duration,score}]}:element )])
        }
    }

    const setCheckedInput = (ind) =>{
        let isExist = false;
        if(inputSet && inputSet.length > 0){
            if(inputSet.some(element => Number(element) === Number(ind)))
                isExist = true;
        }
        return isExist;
    }

    const setCheckedInput2 = (ind) =>{
        let isExist = false;
        if(selectedId && selectedId.length > 0){
            if(selectedId.some(element => Number(element) === Number(ind)))
                isExist = true;
        }
        return isExist;
    }

    const handleTimeArr = (e,ind) =>{
        if(timeArrVal.length > 0){
            setTimeArrVal([...timeArrVal.map((element) => Number(element.id) === Number(ind+1) ? {...element,value:e.target.value} :element)])
        }
        else{
            setTimeArrVal([{id:ind+1,value:e.target.value}])
        }
        const id= allQuestions[Number(ind)].id;
        let score = 0;
        if(allQuestions[Number(ind)].score) score = allQuestions[Number(ind)].score;
        //console.log(ind);
        let duration=e.target.value;
        // let durationArr = timeArrVal.filter(element => element.id === (ind+1));
        // if(durationArr.length > 0){
        //     duration = durationArr[0].value;
        // }
        setAllQuestionId([...allQuestionId.map((element,idx)=> Number(idx) === Number(index)?{questions:[...element.questions.filter(e => Number(e.id) !== Number(id)),{id,duration,score}]}:element)])
    }

    const handletimeDisable = (ind) =>{
        if(selectedId.length > 0){
            if(selectedId.some(element => Number(element) === Number(ind) )){
                return false;
            }
            else{
                return true;
            }
        }
        else return true;
    }

    const handleShowMore = (index,ind) =>{
        const [index0,ind0] = activeShow;
        //console.log(index0,index,ind0,ind);
        if(!index0 || !ind0){
            setActiveShow([index,ind]);
        }
        else{
            if(index === index0 && ind === ind0){
                setActiveShow([]);
            }else{
                setActiveShow([index,ind]);
            }
        }
       
    }


    useEffect(()=>{
        if(selectedId.length > 0){
            //setNumOfQuestions(selectedId.length);
            const questionArr = allQuestionId.filter((e,num) => Number(num) === Number(index));
            if(questionArr.length > 0){
                setNumOfQuestions(questionArr[0].questions.length);
            }
            else{setNumOfQuestions(0)}
        }
        else{
            setNumOfQuestions(0);
        }
    },[selectedId]);

    
  return (
    <div>{
        allQuestions && allQuestions.length > 0 &&
        allQuestions.map((element,ind) => (
            ind%NUMBER_OF_QUESTIONS_PER_SET === 0 ?
            <div className={css.setInputDiv} key={ind}>
                <span>
                    <input type="checkbox" onChange={()=>{handleInputSet(ind)}} checked={setCheckedInput(ind)} />
                    <span></span>
                </span>
                <div>
                    <input type="checkbox" className={css.selectedInput} onChange={()=>{handleSelectedId(ind)}} checked={setCheckedInput2(ind)}/>
                    {timeType && timeType.value === '2' && <Input type="number" placeholder='time in minutes' onChange={(e)=>{handleTimeArr(e,ind)}} disabled={handletimeDisable(ind)} timeType={timeType} timeVal={timeVal} timeArrVal={timeArrVal} />}
                    <div style={activeShow.length === 0 ?{maxHeight:'40px'}:activeShow[0] === index+1 && activeShow[1] === ind+1 ? {minHeight:'fit-content'}:{maxHeight:'40px'}}>
                        {HTMLReactParser(element.question)}
                        <span className={css.seeMore} onClick={()=>handleShowMore(index+1,ind+1)}>{activeShow.length === 0?'see more...':activeShow[0]==index+1 && activeShow[1] == ind+1 ? 'see less ...':'see more...'}</span>
                    </div>
                </div>
            </div>
            :
            <div className={css.eachInputDiv} key={ind} >
                <input type="checkbox"  className={css.selectedInput} onChange={()=>{handleSelectedId(ind)}} checked={setCheckedInput2(ind)}/>
                {timeType && timeType.value === '2' && <Input type="number" placeholder='time in minutes' onChange={(e)=>{handleTimeArr(e,ind)}} disabled={handletimeDisable(ind)} timeType={timeType} timeVal={timeVal} timeArrVal={timeArrVal} id={ind+1} index={index} q_id={getEachQuestionId(ind)} />}
                <div style={activeShow.length === 0 ?{maxHeight:'40px'}:activeShow[0] === index+1 && activeShow[1] === ind+1 ? {minHeight:'fit-content'}:{maxHeight:'40px'}}>
                    {HTMLReactParser(element.question)}
                    <span className={css.seeMore} onClick={()=>handleShowMore(index+1,ind+1)} >{activeShow.length === 0?'see more...':activeShow[0]==index+1 && activeShow[1] == ind+1 ? 'see less ...':'see more...'}</span>
                </div>
            </div>
            
        ))
        }</div>
  )
}

export default AllQuestions