import React, { useContext, useState } from 'react';
import Nav from '../../components/Nav';
import LoginCom from '../../components/Login';
import SignUpCom from '../../components/SignUp';
import styles from './../../css/MainLogin.module.css';
import { ModalContext } from '../../App';
import { useParams } from "react-router-dom";

const Login = ({defaultMode}) => {
    const [mode, setMode] = useState(defaultMode);
    const {customizeData, isCustReady} = useContext(ModalContext);
    const { d } = useParams();
    const switchMode = () => {
        setMode(!mode);
    };
    return (
        <div className={`${styles.main}`} >
            <div className={`${styles.navDiv}`} style={{display: !d || isCustReady ? 'unset': 'none'}}><Nav /></div>
            <div className={`${styles.heroDiv}`} style={customizeData && customizeData.bgUrl?{backgroundImage: `url(${customizeData.bgUrl})`,display: !d || isCustReady ? 'flex': 'none'} :{display: !d || isCustReady ? 'flex': 'none'}}></div>
            <div className={`${styles.loginDiv}`} > {mode ? <LoginCom switchMode={switchMode} /> : <SignUpCom switchMode={switchMode}/>} </div>
            <div className={`${styles.footer}`} style={{display: !d || isCustReady ? 'unset': 'none'}}>Copyright &copy; 2024 &nbsp; 
                {customizeData && customizeData.displayName ? customizeData.displayName : 'SIGHT-EDUPLUS' } 
            </div>
        </div>
    );
};
export default Login;