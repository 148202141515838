import { createSlice } from "@reduxjs/toolkit";

export const EventReducer = createSlice({
    name: "event",
    initialState: { events: [], email_group: [], appointments: [] },
    reducers: {
        setEvents: (state, action) => {
            //state.cbts = action.payload;
            return {
                ...state,
                events: action.payload
            };
        },
        setAppointments: (state, action) => {
            //state.cbts = action.payload;
            return {
                ...state,
                appointments: action.payload
            };
        },
        setEmailGroup: (state, action) => {
            return {
                ...state,
                email_group: action.payload
            };
        },
        addEvent: (state, action) => {
            return {
                ...state,
                events: [action.payload, ...state.events]
            };
        },
        addAppointment: (state, action) => {
            return {
                ...state,
                appointments: [action.payload, ...state.appointments]
            };
        },
        addAppointmentSession: (state, action) => {
            state.appointments.forEach(appointment => {
                if (appointment.id === action.payload.appointment_id) {
                    appointment.sessions.push(...action.payload.sessions);
                }
            });
        },
        addEmailGroup: (state, action) => {
            return {
                ...state,
                email_group: [action.payload, ...state.email_group]
            };
        },
        deleteEvent: (state, action) => {
            return {
                ...state,
                events: state.events.filter(eEvent => eEvent.id !== action.payload.id)
            };
        },
        deleteAppointment: (state, action) => {
            return {
                ...state,
                appointments: state.appointments.filter(eApp => eApp.id !== action.payload.id)
            };
        },
        deleteTimeSlot: (state, action) => {
            console.log(action.payload);
            state.appointments.forEach(appointment => {
                if (appointment.id === action.payload.appointment_id) {
                    appointment.sessions.forEach(session => {
                        if (session.id === action.payload.session_id) {
                            session.bookings = session.bookings.filter(book => book.id !== action.payload.id)
                        }
                    });
                }
            });
        },
        deleteAppointmentSession: (state, action) => {
            state.appointments.forEach(appointment => {
                if (appointment.id === action.payload.appointment_id) {
                    appointment.sessions = appointment.sessions.filter(session => session.id !== action.payload.session_id);
                }
            });
        },
        deleteEmailGroup: (state, action) => {
            return {
                ...state,
                email_group: state.email_group.filter(eGroup => eGroup.id !== action.payload.id)
            };
        },
        editEvent: (state, action) => {
            const editedEvent = state.events.map(eEvent => eEvent.id === action.payload.id ? action.payload : eEvent);
            return {
                ...state,
                events: editedEvent,
            };
        },
        editAppointment: (state, action) => {
            const editedApp = state.appointments.map(eApp => eApp.id === action.payload.id ? action.payload : eApp);
            return {
                ...state,
                appointments: editedApp,
            };
        },
        editAppointmentSession: (state, action) => {
            state.appointments.forEach(appointment => {
                if (appointment.id === action.payload.appointment_id) {
                    appointment.sessions.forEach(session => {
                        if (session.id === action.payload.session_id) {
                            session.bookings = action.payload.bookings;
                        }
                        if (action.payload.session) {
                            session.recurrency_value = action.payload.session.recurrency_value;

                        }
                    });
                }
            });
        },
        editEmailGroup: (state, action) => {
            const editedGroup = state.email_group.map(eGroup => eGroup.id === action.payload.id ? { ...action.payload } : eGroup);
            return {
                ...state,
                email_group: editedGroup
            };
        }
    }
});
export const {
    setEvents, addEvent, deleteEvent, editEvent,
    setAppointments, addAppointment, deleteAppointment, editAppointment,
    addAppointmentSession, deleteAppointmentSession, editAppointmentSession, deleteTimeSlot,
    setEmailGroup, addEmailGroup, deleteEmailGroup, editEmailGroup,
} = EventReducer.actions;
export default EventReducer.reducer;