import { Routes, Route } from "react-router-dom";
import CookieNotice from '../../components/CookieNotice';
import CustomerAgreement from '../../components/CustomerAgreement';
import PrivacyNotice from '../../components/PrivacyNotice';
import PageNotFound from '../404/PageNotFound';

const Policies = () => {

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Routes>
                <Route path="terms-of-use" element={<CustomerAgreement />} />
                <Route path="privacy-policy" element={<PrivacyNotice />} />
                <Route path="privacy-policy/cookie-notice" element={<CookieNotice />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </div>
    );
};

export default Policies;