import {createSlice} from "@reduxjs/toolkit";

const initialStateValue = {email: "", userName:"", userRole:"", token: "",id:0};

export const accountSlice = createSlice({
    name: "account",
    initialState: {
        value: initialStateValue,
        isLogin: false,
        count: 0,
        timeCount: 0,
        banks:{},
        paymentLinks:{},
        service:[],
        username: {},
        transactions: [], total_bills: []
    },
    reducers:{
        setTransactions: (state,action)=>{
            state.transactions = action.payload;
        },
        setTotalBills: (state,action)=>{
            state.total_bills = action.payload;
        },
        loginUser:(state, action)=>{
            state.value=action.payload;
            state.isLogin = true;
        },
        logoutUser:(state)=>{
            state.isLogin = false;
            state.value = initialStateValue;
        },
        incrementCount: (state)=>{
            state.count = state.count + 1;
            state.timeCount = state.timeCount + 1;
        },
        resetCount: (state)=>{
            state.count = 0;
        },
        addBank: (state,action)=>{
            state.banks = {...state.banks, [action.payload.id]:action.payload}
            //console.log(state.banks);
        },
        deleteBank: (state,action)=>{
            delete state.banks[action.payload]
        },
        editBank:(state,action)=>{
            state.banks = {...state.banks,[action.payload.id]:action.payload}
        },
        setBanks: (state,action)=>{
            state.banks = {...action.payload}
        },
        addPaymentLink:(state,action)=>{
            state.paymentLinks[action.payload.id] = { ...action.payload};
            //console.log(state.paymentLinks);
        },
        editPaymentLink:(state, action) => {
            //Rework on this
            state.paymentLinks[action.payload.id] = {...state.paymentLinks[action.payload.id], ...action.payload};
            //console.log(state.paymentLinks);
        },
        deletePaymentLink:(state,action)=>{
            // state.paymentLinks = state.paymentLinks.filter(x => x.id != action.payload);
            delete state.paymentLinks[action.payload]
        },
        setPaymentLink:(state,action)=>{
            state.paymentLinks = action.payload;
            //console.log(state.paymentLinks);
        },
        setService:(state, action) => {
            state.service = action.payload;
            //console.log(state.service);
        },
        setUser: (state, action)=>{
            state.username = action.payload
        }

    }
});

export const {loginUser, logoutUser, incrementCount, resetCount, addBank,
    deleteBank,editBank,setBanks,addPaymentLink,deletePaymentLink,setPaymentLink,
    setService, editPaymentLink, setUser, setTotalBills, setTransactions} = accountSlice.actions;

export default accountSlice.reducer;