import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Student per percentile Score',
    },
  },
};

const labels = [
  'start',
  '0 - 10%',
  '11 - 20%',
  '21 - 30%',
  '31 - 40%',
  '41 - 50%',
  '51 - 60%',
  '61 - 70%',
  '71 - 80%',
  '81 - 90%',
  '91 - 100%'
];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Dataset 2',
      data: [0,100,50,40,55,67,80,30,40,55,67],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export default function App(props) {
  const chart_options = props.options || options;
  const chart_data = props.data || data;
  return <Line options={chart_options} data={chart_data} />;
}