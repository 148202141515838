import { useEffect, useRef } from 'react';
import PrivacyNotice from './PrivacyNotice';

const CookieNotice = () => {
    const cookieRef = useRef();
    const cookieDesRef = useRef();

    useEffect(() => {
        window.scrollTo({
            top: cookieDesRef.current.offsetTop - (window.screen.availHeight / 5),
            left: 0,
            behavior: 'smooth'
        });
        cookieDesRef.current.style.display = 'inline-block !important';
        cookieDesRef.current.style.boxShadow = '0 0 5px 5px #fff';
        cookieDesRef.current.style.transition = 'all 0.5s ease-out';
        const fadeIn = setTimeout(() => {
            cookieDesRef.current.style.boxShadow = '0 0 5px 5px #b0fffb';
            clearTimeout(fadeIn);
        }, 500);
        const fadeOut = setTimeout(() => {
            cookieDesRef.current.style.boxShadow = '0 0 5px 5px #fff';
            clearTimeout(fadeOut);
        }, 1000);
        const fadeIn1 = setTimeout(() => {
            cookieDesRef.current.style.boxShadow = '0 0 5px 5px #b0fffb';
            clearTimeout(fadeIn1);
        }, 1500);
        const fadeOut1 = setTimeout(() => {
            cookieDesRef.current.style.boxShadow = '0 0 5px 5px #fff';
            clearTimeout(fadeOut1);
        }, 2000);
    }, []);

    return (
        <>
            <PrivacyNotice cookieRef={cookieRef} cookieDesRef={cookieDesRef} />
        </>
    );
};

export default CookieNotice;