import ForgotPassword from '../../components/ForgotPassword';
import Nav from '../../components/Nav';
import styles from './../../css/MainLogin.module.css';
import { Routes, Route, useNavigate } from "react-router-dom";
import PageNotFound from '../404/PageNotFound';
import ResetPassword from '../../components/ResetPassword';

const MainForgotPassword = () => {
    return (
        <div className={`${styles.forgotMain}`}>
            <div className={`${styles.navDiv}`}><Nav /></div>
            <div className={`${styles.heroDiv}`}></div>
            <div className={`${styles.loginDiv}`}>
                <Routes>
                    <Route path="/" element={<ForgotPassword />} />
                    <Route path="/abcdefghijklmnopqrstuvwxyz/:d" element={<ResetPassword />} />
                    <Route path="/*" element={<PageNotFound/>} />
                </Routes>
            </div>
            <div className={`${styles.footer}`}>Copyright &copy; 2024 Sight-Innovation LLC</div>
        </div>
    );
};

export default MainForgotPassword;