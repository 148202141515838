import styles from './../../css/ComingSoon.module.css';

const ComingSoon = () => {
    return (
        <div className={`${styles.div}`}>
            <div className={`${styles.sDiv}`}><span>Coming Soon!</span></div>
        </div>
    );
};

export default ComingSoon;