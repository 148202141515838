import { Link } from "react-router-dom";
import LogoImg from '../views/user/Logo_img';
import styles from './../css/Nav.module.css';

const Nav = () => {
  return (
    <>
      <div className={`${styles.nav}`} >
        <Link to='/'><LogoImg width='100' /></Link>
      </div>
    </>
  );
};

export default Nav;