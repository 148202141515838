import React, { useContext, useEffect, useState } from 'react'
import css from './css/cbt.module.css';
import EachCategorySetting from './EachCategorySetting';


const CbtCategorySettings = ({questions,categories,questCategory,id,timeType,}) => {
 

  return (
    <div className={css.settingContainer}>
        <span>CBT SETTINGS</span>
        {
          questCategory && questCategory.length > 0 && questCategory.map((element,index) => (
            <EachCategorySetting key={element.id} cateId={element.id} id={id} timeType={timeType} categories={categories} questions={questions} index={index}  />          
        ))
        }
    </div>
  )
}

export default CbtCategorySettings