import styles from './../../../css/Categories.module.css';
import { useEffect, useState,useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import { useSelector,useDispatch } from 'react-redux';
import { deleteUserInstitution } from "../../../redux/reducers/questionReducer";
import Api from '../../../helperFunctions/Api2';
import { EditContext } from './Institution';
import styled from "styled-components";
import { EditComponent } from "../../../components/EditComponent";
import { convertDatetoString } from "../../../helperFunctions/Api2";
import PageNumber from '../../../components/PageNumber';

const NO_OF_ITEMS_PER_PAGE = 3;

const ViewInstitutions = ({ setTitle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const institutions = useSelector(state=>state.questions.user_institutions);
    const {setEditInst,setAllInst} = useContext(EditContext);
   const [selected,changeSelected] = useState(null);
   const [menuIndex,setMenuIndex] = useState(null);
   const [currentPage, setCurrentPage] = useState(1);
   const [pageNum, setPageNum] = useState(0)


    useEffect(() => {
        setTitle('Institutions');
    }, [setTitle]);
    useEffect(() => {
        if(institutions.length > NO_OF_ITEMS_PER_PAGE){
            setPageNum((institutions.length / NO_OF_ITEMS_PER_PAGE)+((institutions.length % NO_OF_ITEMS_PER_PAGE)?1:0))
        }
    }, [institutions.length]);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);

    
    const handleEdit=(institution)=>{
        // console.log(institution,'edit')
        setEditInst(institution);
        setAllInst(institutions);
        navigate("/account/services/institution-management/institution/create-institution")
    }

    const handleDelete= async (institution)=>{ 
        // console.log(institution,'delete');
        if(Object.keys(institution.categories)>0){
            setModalText('Institution with groups cannot be deleted, delete all groups before deleting institution');
            setModalToggle(true);
            //setShowLoading(false);
        }
        else if(!window.confirm('Are you sure you want to delete this institution and all its users?')){
            setShowLoading(false);
        }
        else{
            if(!Api.checkNetwork()) return;
                // console.log(institution.categories, 'institution')
                setShowLoading(true);
                Api.PostApi('/institution/delete-institution', {
                    id: institution.id, institution_id: institution.institution_id
                },true)
                .then(result=>{
                    // console.log(result, 'yes')
                    setShowLoading(false);
                    if(result.isSuccessful){
                        dispatch(deleteUserInstitution(institution));
                        setModalText('Institution deleted successfully.');
                        setModalToggle(true);
                    }
                    else{
                        setModalText(result.message);
                        setModalToggle(true);
                    }
                })
                .catch(err=>{
                    // console.log(err);
                    setModalText('Delete institution unsuccesful, Please try again, or Re-Login if problem persists')
                    setModalToggle(true);
                    setShowLoading(false);
                });
        }
    }

    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <div className={styles.form} >
                <div className={styles.viewQuestionContainer}>
                    {institutions.length  >0?institutions.map((institution,index)=>{
                        return <EachInstitution currentPage={currentPage} menuIndex={menuIndex} setMenuIndex={setMenuIndex} index={index} handleEdit={()=>handleEdit(institution)} handleDelete={()=>handleDelete(institution)} institution={institution} changeSelected={changeSelected} selected={selected} key={'institution'+index} />
                    }):' No institution available!'}
                </div>
                {institutions.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
            </div>
        </>
    );
};

export default ViewInstitutions;


/*********************************************************************** */

const EachInstitution =({institution,index,changeSelected,selected,handleEdit,handleDelete,menuIndex,setMenuIndex, currentPage })=>{
    const [copyText,setCopyText] = useState(true);
    const [copyLink,setCopyLink] = useState(true);
    const onSeeMore = () =>{
        if(index === selected){
            changeSelected(null);
        }
        else{
            changeSelected(index);
        }
    }

    const handleBtn = (text,type)=>{
        navigator.clipboard.writeText(text);
        if(!type)setCopyText(false);
        else setCopyLink(false);
    
        setTimeout(() => {
            if(!type)setCopyText(true);
            else setCopyLink(true);
        }, 3000);
  }

    return (
    <>
        {index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1)* NO_OF_ITEMS_PER_PAGE)&&  <Wrapper>
            <EditComponent menuIndex={menuIndex} setMenuIndex={setMenuIndex} index={index} clickDelete={handleDelete} clickEdit={handleEdit} />
            <div>
                <img src={`http://api.sight-eduplus.com/upload/institution/${institution.image}`} alt="logo" />
            </div>
            <div>
                <span>Name: </span>
                <span>{institution.name}</span>
            </div>
            <div>
                <span>Address: </span>
                <span>{institution.address}</span>
            </div>
            {selected === index &&
            <>
            <div>
                <span>Motto: </span>
                <span>{institution.motto}</span>
            </div>
            <div>
                <span>total groups: </span>
                <span>{Object.keys(institution.categories).length}</span>
            </div>
            <div>
                <span>Created at: </span>
                <span>{convertDatetoString(institution.create_at)}</span>
            </div>
            <div>
                <span>Timezone:</span>
                <span title={`${institution.timezone} Greenwich Mean Time`}>G.M.T {institution.timezone}</span>
            </div>
            <div>
                <span>Institution Id:</span>
                <span>
                    <Button style={!copyText ? {color:'white',backgroundColor:'var(--secondary-color)'}:{}} onClick={()=>handleBtn(institution.institution_id)} disabled={!copyText}>{copyText?'Copy Id':'Id Copied'}</Button>
                </span>
            </div>
            <div>
                <span>Login Link</span>
                <span> 
                    <Button style={!copyLink ? {color:'white',backgroundColor:'var(--secondary-color)'}:{}} onClick={()=>handleBtn(`https://sight-eduplus.com/account/sign-in/${institution.institution_id}`,true)} disabled={!copyLink}>{copyLink?'Copy Link':'Link Copied'}</Button>
                </span>
            </div>
            
            </>          
            }
            <div>
                <span onClick={()=>onSeeMore(index)} style={{
                    color:'var(--primary-color)',
                    fontWeight:'light !important',
                    cursor:'pointer'
                }}>{selected === index?'See Less...':'See More...'}</span>
            </div>

            

        </Wrapper>}
    </>)
}


const Wrapper = styled.div`
    width:100%;
    /* margin-bottom: 20px; */
    padding:20px;
    display:flex;
    position: relative;
    flex-direction: column;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    gap:10px;
    & > div{
        width:100%;
        display: flex;
        gap:10px;
    }
    & div > span:nth-of-type(2){
       color:var(--primary-color);
    }
    & > div > img{
        /* width:40px; */
        height:70px;
        border-radius: 4px;
        object-fit: contain;
    }
`


const Button = styled.button`
    color:var(--secondary-color);
    border:1px solid var(--secondary-color);
    padding:3px 6px;
    border-radius: 5px;
    width: fit-content;
    font-size:12px;
    background-color: white;
`