import React from 'react';
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import styles from '../../../css/InstitutionMgmt.module.css';
import Cbt from './cbt.js';


const InstitutionMgmt = () => {
    return (
        <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Routes>
                <Route index element={<Cbt/>} /> 
                <Route path="/*" element={<Cbt />} />
                {/* <Route path="/*" element={<PageNotFound />} /> */}
            </Routes>
        </div>
    );
};

export default InstitutionMgmt;