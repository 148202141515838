import styles from '../../../css/StaffMgmt.module.css';
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import Form from './Form';
import Website from './Website';

const Portal = () => {
    return (
        <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
                <h1 className={`${styles.title}`}>Forms Management</h1>
                <div className={`${styles.subView}`}>
                    <Routes>
                        <Route path='*' element={<Form />} />
                        <Route path='forms/*' element={<Form />} />
                        {/* <Route index element={<Form />} />
                        <Route path='website/*' element={<Website />} />
                        <Route path="/*" element={<PageNotFound />} /> */}
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Portal;