import React from "react"
import { Link, Route, Routes } from "react-router-dom";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import PageNotFound from "../../404/PageNotFound";
import { useState, createContext } from "react";
import Modal from '../../../components/modal';
import { useContext } from "react";
import { BsList } from 'react-icons/bs';
import Create from './addNote';
import View from "./viewNote"
import { MenuContext } from "../DashBoard";



export const NoteContext = createContext();
export const LearningContext = createContext();


const NoteIndex = () => {
    const [title, setTitle] = useState('');
    const [modalObject, setModalObject] = useState({});
    const [modalToggle, setModalToggle] = useState(false);
    const [editNote, setEditNote] = useState(false);
    const [editObj, setEditObj] = useState({});
    const {showMenu, setShowMenu} = useContext(MenuContext);
    const [view, setView] = useState('');
    const clearEdit = ()=>{
        setEditNote(false);
        setEditObj({});
    }

    return(
        <>
        <NoteContext.Provider value={{view, setView}} >
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
               <h1 className={`${styles.title}`}>Note/Ebook</h1>
               <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`}>
                                    <ul onClick={()=>clearEdit()}>
                                        <li><Link to='create-notes'>Add Notes</Link></li>
                                        <li><Link to='view-notes'>View Notes</Link></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                  <LearningContext.Provider value={{modalObject,setModalObject,modalToggle,setModalToggle,editNote,setEditNote,editObj,setEditObj}}>
                      <Routes >
                         <Route path="*" element={<Create setTitle={setTitle}/>} />
                         <Route path="/create-notes" element={<Create  setTitle={setTitle}/>} />
                         <Route path="/view-notes" element={<View setTitle={setTitle} />} />
                         <Route path="/*" element={<PageNotFound setTitle={setTitle}/>}/>
                      </Routes>
                      <Modal modalObject={modalObject} modalTogglee={modalToggle} closeModal={()=>{setModalToggle(false)}} />
                 </LearningContext.Provider>
               </div>
            </div>
        </NoteContext.Provider>
      
        </>
    );
}

export default NoteIndex;