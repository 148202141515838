import { useEffect } from "react";
import styles from '../../../css/Categories.module.css';

const MakeWithdrawal = ({ setTitle }) =>{

    useEffect(() => {
        setTitle('Manage Withdrawals')
    },);

    return(
        <>
            <div className={styles.form}>
                <h3>COMING SOON</h3>
            </div>
            
        </>
    )
}

export default MakeWithdrawal;