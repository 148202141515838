import React,{useState,useEffect,useContext} from 'react';
import styles from '../../../css/CreateInst.module.css';
import eventstyle from './Css/event.module.css'
import { eventModalContext } from './smsSettings';
import { useSelector,useDispatch } from 'react-redux';
import Loading from '../../../components/loading';

import Select from '../../../components/Select';


const AddContactList = ({seTTitle}) => {
    const [groupList, setGroupList] = useState('');
    const [selSms, setSelSms] =  useState(null);
    const {setModalObject,setModalTogglee} = useContext(eventModalContext);
    const [showLoading, setShowLoading] = useState(false);
    const SMS = useSelector(state => state.questions.user_sms);

    const dispatch = useDispatch();
   
    let testId = Math.floor(Math.random()*1000 + 1);

    const validateValue = (value, body) => {
        if(value === null || value === '' || value === 'undefined' ){
            setModalObject({header:'Email Group List could not be created',body:`PLEASE SET ${body}`,footer:''});
            setModalTogglee(true);
            return false;
       }
       return true;
    }
    const validateEmailGroup = ({selSms}) => {
        if(Object.keys(selSms).length <= 0) {
            setModalObject({header:'Sms Group List could not be created', body:'Select an Email Group before adding a list', footer:''});
            setModalTogglee(true);
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!validateEmailGroup({selSms})) {
            return false
        }
        if(!validateValue(groupList, 'Group List')) {
            return false;
        }
     
      
        else {
             let sms = []
             let list2 = groupList.split(',')
             let i = 0;
             let len = list2.length;
             for(i; i<len; i++) {
                if(list2[i] === ' ') continue;
                const SmsList = {};
                SmsList.id = testId++;
                SmsList.contact = list2[i];
                SmsList.groupId = selSms.id

                sms.push(SmsList);
             }
           

            
          setShowLoading(true);
          //console.log(sms);
          setShowLoading(false);
          setModalTogglee(true)
          setModalObject({header:'Emails',body:`Email group added successfully`,footer:''});
          setGroupList('');
          setSelSms('');
        }

    }

    useEffect(() => {
        if(selSms) {
            //console.log(selSms.id)
        }
    },[selSms])

    useEffect(() => {
         seTTitle('Add Contacts')
    }, [])
    return ( 
        <>
          <Loading  shouldShow={showLoading}/>
         <form className={`${styles.form} ${styles.form2}`} >
           <label className={`${styles.label}`}>
                <span className={styles.isRequired}>Select SMS Group to add List</span>
                {<Select name='EmailSelect' options={SMS}  onChange={setSelSms} className={styles.selectFields}  style={{width:'100%'}}defaultValue="" value='Id' label='groupName'/>}
            </label>
            <label className={`${styles.label}`} >
                <span>Add List to Selected SMS Group</span>
                <textarea name='emaillist' value={groupList? groupList:''} className={`${styles.fields}`} onChange={(e)=> {setGroupList(e.target.value)}}  />
            </label>
            <small>NOTICE: Contacts should be separated by a comma(<b>,</b>)</small>
            <div className={`${styles.addBtnDiv} ${styles.div}`}>
                    <button type="submit" className={styles.addBtn} onClick={handleSubmit}>Add SMS List</button>
            </div>
         </form>
           
        </>
     );
}
 
export default AddContactList;