import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WebsiteContext } from "./Website";
import styles from '../../../css/AddQuestions.module.css';
import { deleteWebsite, editWebsite, } from "../../../redux/reducers/portalReducer";
import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Api from '../../../helperFunctions/Api2';
import TextEditor from "../../../components/TextEditor";
import { MenuContext } from "../DashBoard";
import Select from "../../../components/Select";
import styled from "styled-components";
// import img1 from '../../../images/2107.jpg';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import WebsiteTemplate from "./WebsiteTemplate";
import { Button } from "../../../components/Button";
import { UploadedFileOnly } from "../../../components/UploadedFiles";

const defWebsiteData = {
    primaryColor: '#428bca',
    secondaryColor: '#224f77',
    logo: '',
    navbar: true,
    eduplus: true,
    result: false,
    instResult: null,
    form: false,
    forms: [],
    topBanner: true,
    bannerHead: 'Best Tech/High School',
    bannerText: 'This is the best/high school in the whole of Africa',
    bannerFiles: {0: ''},
    bannerFileType: { value: 'null', title: 'None'},
    about: true,
    aboutImage: '',
    aboutText: '',
    stat: true,
    statOptions: [
        { icon:'bi-people-fill', option: 'No. of students registered', selected: true, text: 'Students', adj: 'Enrolled', value: '50000+' },
        { icon:'bi-easel-fill', option: 'No. of certified teachers/educators', selected: true, text: 'Educators', adj: 'Enrolled', value: '5000+' },
        { icon:'bi-trophy', option: 'Total students taught', selected: true, text: 'Students', adj: 'Taught', value: '' },
        { icon:'bi-emoji-heart-eyes', option: 'No. of satisfied clients', selected: false, text: 'Clients', adj: 'Satisfied', value: '' },
        { icon:'bi-broadcast-pin', option: 'Total followers', selected: false, text: 'Followers', adj: '', value: '' },
        { icon:'bi-rss', option: 'Total subscribers', selected: true, text: 'Subscribers', adj: '', value: '1000+ ' },
        { icon:'bi-ui-checks', option: 'Total courses', selected: false, text: 'Courses', adj: 'available', value: '' },
        { icon:'bi-calendar-check', option: 'Years of experience', selected: false, text: 'Years', adj: 'experience', value: '' },
        { icon:'bi-building', option: 'Average class size', selected: false, text: 'avg Students', adj: 'per class', value: '' },
    ],
    offering: true,
    offeringData: [{ image: '', text: '' }],
    events: true,
    eventsData: [{ image: '', title: '', date: '' }],
    courses: true,
    coursesData: [{ image: '', title: '', description: '', duration: '', price: '' }],
    messageHead: true,
    messageImage: '',
    messageText: '',
    awards: true,
    awardsData: [{ image: '', title: '' }],
    gallery: true,
    gallerySlides: [{ image: '', title: '', subtitle: '' }],
    testimony: true,
    testimonySlides: [{ image: '', title: '', subtitle: '' }],
    footer: true,
    contactInfo: `
    <ul>
        <li>Address: No 1 Africa close Uk</li>
        <li>Phone: +447 0000000000</li>
    </ul>`,
};
const options = [
    { value: 'null', title: 'None' },
    { value: 'single', title: 'Single Image' },
    { value: 'multi', title: 'Multiple Images' },
    { value: 'video', title: 'Video' },
];
function HandleFileSelect ({ handleWebsiteData, setShowLoading, websiteData }) {

    return <label className={`${styles.label}`} >
        Select File type
        <Select options={options} className={styles.selectFields}
            onChange={(data) => handleWebsiteData({ bannerFileType: data })} value="value" label="title" defaultValue={websiteData.bannerFileType} />
        {websiteData.bannerFileType.value === 'single' && <>
            <UploadedFileOnly type="image" title='Select Image' setShowLoading={setShowLoading} onCopy={(image) => handleWebsiteData({ bannerFiles:{0: image} })} value={websiteData.bannerFiles[0]} labelStyle={{ marginBottom: 'auto', paddingRight: '25px' }} />
        </>}
        {websiteData.bannerFileType.value === 'multi' && Object.keys(websiteData.bannerFiles).map((key) => <MultiDiv key={key}>
            <FaRegTrashAlt title="Delete" onClick={() => {
                let data = websiteData.bannerFiles
                delete data[key];
                handleWebsiteData({bannerFiles: Object.assign({},Object.values(data))})
            }} />
            <UploadedFileOnly type="image" title='Select Image' setShowLoading={setShowLoading} onCopy={(image) => handleWebsiteData({ bannerFiles: { ...websiteData.bannerFiles, [key]: image } })} value={websiteData.bannerFiles[key]} labelStyle={{ marginBottom: 'auto', paddingRight: '25px' }} />
        </MultiDiv>)}
        {websiteData.bannerFileType.value === 'multi' && <button onClick={() => handleWebsiteData({ bannerFiles: { ...websiteData.bannerFiles, [Object.values(websiteData.bannerFiles).length]: '' } })}>Add Image</button>}
        
        {websiteData.bannerFileType.value === 'video' && <UploadedFileOnly setShowLoading={setShowLoading} type={'video'} onCopy={(video) => handleWebsiteData({ bannerFiles: { 0: video } })} value={websiteData.bannerFiles[0]} />}
    </label>;
}
const DesignWebsite = () => {
    const { setTitle, setFullsc } = useContext(WebsiteContext);
    const { setModalToggle, setModalText, setShowLoading, setFullScreenComponent } = useContext(MenuContext);
    const websites = useSelector(store => store.portal.websites);
    const forms = useSelector(store => store.portal.forms);
    const institutions = useSelector(store => store.questions.user_institutions);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [websiteData, setWebsiteData] = useState(JSON.parse(JSON.stringify(defWebsiteData)));
    const [sectionIndex, setSectionIndex] = useState(-1);
    const [websiteName, setWebsiteName] = useState('');
    const [selWebsite, setSelWebsite] = useState(null);


    const clearFields = () => {
        setWebsiteName('');
        setWebsiteData(JSON.parse(JSON.stringify(defWebsiteData)));
        setSelWebsite(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selWebsite || !selWebsite.id) {
            setModalText('Please select a website');
            setModalToggle(true);
            return false;
        }
        const website = {
            ...selWebsite,
            website: JSON.stringify(websiteData)
        };
        setShowLoading(true);
        Api.PostApi('/form/edit-website', website, true)
            .then(result => {
                setShowLoading(false);
                setModalText(result.message);
                setModalToggle(true);
                console.log(result);
                if (result.isSuccessful) {
                    dispatch(editWebsite(website));
                    clearFields();
                };
            })
            .catch(error => {
                setShowLoading(false);
                setModalText(error.message);
                setModalToggle(true);
            });
    };
    const handleDelete = () => {
        
        if (!selWebsite || !selWebsite.id) {
            setModalText('Please select a website');
            setModalToggle(true);
            return false;
        }
        setShowLoading(true);
        Api.PostApi('/form/delete-website', {id: selWebsite.id}, true)
            .then(result => {
                setShowLoading(false);
                setModalText(result.message);
                setModalToggle(true);
                console.log(result, selWebsite);
                if (result.isSuccessful) {
                    dispatch(deleteWebsite(selWebsite.id));
                    clearFields();
                };
            })
            .catch(error => {
                setShowLoading(false);
                setModalText(error.message);
                setModalToggle(true);
            });
    }

    function handleWebsiteData (data = {}) {
        setWebsiteData({ ...websiteData, ...data });
        // console.log(data);
    }
    function handleSectionIndex (index) {
        setSectionIndex(sectionIndex === index ? -1 : index);
    }
    function handleMultiData (obj, key, index) {
        let data = websiteData;
        data[key][index] = { ...data[key][index], ...obj };
        setWebsiteData({ ...data });
    }
    function addMultiData (key) {
        let data = websiteData;
        data[key].push(defWebsiteData[key][0]);
        setWebsiteData({ ...data });
    }
    function deleteMultiData (key, index) {
        let data = websiteData;
        data[key].splice(index, 1);
        setWebsiteData({ ...data });
    }

    useEffect(() => {
        setTitle('Design Website');
        setFullsc('full-port');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // **selWebsite useffect**
    useEffect(() => {
        // console.log(selWebsite)
        if (selWebsite) {
            const pages = selWebsite && selWebsite.website ? JSON.parse(selWebsite.website) : {};
            setWebsiteName(selWebsite.title);
            handleWebsiteData(pages);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selWebsite]);

    return (
        <>
            <Container>
                <div className={styles.web}>
                    <label className={`${styles.label}`}>Select Website
                        <Select options={websites} className={styles.selectFields}
                            onChange={setSelWebsite} defaultValue={selWebsite} value="id" label="title" />
                    </label>
                    <br />
                    <br />
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(0)}>Basic Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 0 ? 'block' : 'none' }}>
                            <label className={`${styles.label}`}>
                                <span>Select Primary Color</span><br />
                                <input type='color' value={websiteData.primaryColor} onChange={(e) => handleWebsiteData({ primaryColor: e.target.value })} />
                            </label>
                            <label className={`${styles.label}`}>
                                <span>Select Secondary Color</span> <br/>
                                <input type='color' value={websiteData.secondaryColor} onChange={(e) => handleWebsiteData({ secondaryColor: e.target.value })} />
                            </label>
                            <UploadedFileOnly type="image" title='Logo Image' setShowLoading={setShowLoading} onCopy={(image) => handleWebsiteData({ logo: image })} value={websiteData.logo} />
                        </div>
                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(1)}>Navbar Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 1 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.navbar} onChange={(e) => handleWebsiteData({ navbar: e.target.checked })} /><span>Enable Navbar</span></CheckLabel>
                            <CheckLabel><input type='checkbox' checked={websiteData.form} onChange={(e) => handleWebsiteData({ form: e.target.checked, forms: e.target.checked ? forms : [] })} /><span>Enable Forms</span></CheckLabel>
                            <CheckLabel><input type='checkbox' checked={websiteData.result} onChange={(e) => handleWebsiteData({ result: e.target.checked })} /><span>Enable Result</span></CheckLabel>
                            {websiteData.result &&
                                <label className={`${styles.label}`}>
                                    <span className={styles.isRequired}>Select Institution</span>
                                    <Select options={institutions} className={styles.selectFields}
                                        onChange={inst => handleWebsiteData({ instResult: inst })} value="id" label="name" />
                                </label>
                            }
                            <CheckLabel><input type='checkbox' checked={websiteData.eduplus} onChange={(e) => handleWebsiteData({ eduplus: e.target.checked })} /><span>Enable EduPlus Login</span></CheckLabel>
                        </div>

                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(2)}>Top Banner Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 2 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.topBanner} onChange={(e) => handleWebsiteData({ topBanner: e.target.checked })} /><span>Enable Top Banner</span></CheckLabel>
                            {websiteData.topBanner && <div>
                                <HandleFileSelect handleWebsiteData={handleWebsiteData} websiteData={websiteData} setShowLoading={setShowLoading}/>
                                <label className={`${styles.label}`}>
                                    <span>Banner Header Text</span><br />
                                    <input className={`${styles.input}`} type='text' placeholder="Enter banner header text" value={websiteData.bannerHead} onChange={(e) => handleWebsiteData({ bannerHead: e.target.value })} />
                                </label>
                                <label className={`${styles.label}`}>
                                    <span>Banner Text</span>
                                    <textarea className={`${styles.input}`} type='text' placeholder="Enter banner text" value={websiteData.bannerText} onChange={(e) => handleWebsiteData({ bannerText: e.target.value })} />
                                </label>
                            </div>}
                        </div>
                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(3)} >About Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 3 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.about} onChange={(e) => handleWebsiteData({ about: e.target.checked })} /><span>Enable About Section</span></CheckLabel>
                            {websiteData.about && <>
                                <label className={`${styles.label}`}>
                                    <span>About Image Link</span><br />
                                    <input className={`${styles.input}`} type='text' placeholder="Enter about image" value={websiteData.aboutImage} onChange={(e) => handleWebsiteData({ aboutImage: e.target.value })} /><br />
                                </label>
                                {/**Text editor here */}
                                <TextEditor text={'About'} value={websiteData.aboutText} handleChange={(aboutText) => handleWebsiteData({ aboutText })} navigate={navigate} setShowLoading={setShowLoading} onCopy={(aboutImage) => !websiteData.aboutImage && handleWebsiteData({ aboutImage})} />

                            </>}
                        </div>

                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(4)} >Stats Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 4 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.stat} onChange={(e) => handleWebsiteData({ stat: e.target.checked })} /><span>Enable Stats Section</span></CheckLabel><br />
                            {websiteData.stat && websiteData.statOptions && websiteData.statOptions.map((opt, ind) => <label key={ind}>
                                <CheckLabel>
                                    <input type='checkbox' checked={opt.selected} onChange={(e) => handleMultiData({ selected: e.target.checked }, 'statOptions', ind)} />{opt.option}
                                </CheckLabel>
                                <input className={styles.input} type='text' placeholder={opt.text + ' ' + opt.adj} value={opt.value} onChange={(e) => handleMultiData({ value: e.target.value }, 'statOptions', ind)} />
                            </label>)}
                        </div>
                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(5)} >Offerings Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 5 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.offering} onChange={(e) => handleWebsiteData({ offering: e.target.checked })} /><span>Enable Offering Section</span></CheckLabel><br />
                            {websiteData.offering && websiteData.offeringData.map((off, ind) => <MultiDiv key={ind}>
                                <FaRegTrashAlt title="Delete" onClick={() => deleteMultiData('offeringData', ind)} />
                                <div>
                                    <label className={`${styles.label}`}>
                                        <span>Offering Image Link</span><br />
                                        <input className={`${styles.input}`} type='text' placeholder="Enter offering image" value={off.image} onChange={(e) => handleMultiData({ offeringImage: e.target.value }, 'offeringData', ind)} /><br />
                                    </label>
                                    {/**Text editor here */}
                                    <TextEditor text={'Text'} value={off.text} handleChange={(text) => handleMultiData({ text }, 'offeringData', ind)} navigate={navigate} setShowLoading={setShowLoading} onCopy={(image) => !off.image && handleMultiData({ image }, 'offeringData', ind)} />
                                </div>
                            </MultiDiv>)}
                            <button onClick={() => addMultiData('offeringData')}>Add +</button>
                        </div>

                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(6)} >Events Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 6 ? 'block' : 'none' }} >
                            <CheckLabel><input type='checkbox' checked={websiteData.events} onChange={(e) => handleWebsiteData({ events: e.target.checked })} /><span>Enable Events Section</span></CheckLabel><br />
                            {websiteData.events && websiteData.eventsData.map((event, ind) => <MultiDiv key={ind}>
                                <FaRegTrashAlt title="Delete" onClick={() => deleteMultiData('eventsData', ind)} />
                                <div>
                                    <label className={`${styles.label}`}>
                                        <UploadedFileOnly type="image" title='Event Image' setShowLoading={setShowLoading} onCopy={(image) => handleMultiData({ image }, 'eventsData', ind)} value={event.image} labelStyle={{ marginBottom: 'auto', paddingRight: '25px' }} />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Event Title</span><br />
                                        <input className={`${styles.input}`} type='text' placeholder="Enter event title" value={event.title} onChange={(e) => handleMultiData({ title: e.target.value }, 'eventsData', ind)} /><br />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Event Date</span><br />
                                        <input className={`${styles.input}`} type='text' placeholder="Enter event date" value={event.date} onChange={(e) => handleMultiData({ date: e.target.value }, 'eventsData', ind)} /><br />
                                    </label>
                                </div>
                            </MultiDiv>)}
                            <button onClick={() => addMultiData('eventsData')}>Add +</button>
                        </div>
                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(7)} >Courses Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 7 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.courses} onChange={(e) => handleWebsiteData({ courses: e.target.checked })} /><span>Enable Courses Section</span></CheckLabel><br />
                            {websiteData.courses && websiteData.coursesData.map((course, ind) => <MultiDiv key={ind}>
                                <FaRegTrashAlt title="Delete" onClick={() => deleteMultiData('coursesData', ind)} />
                                <div>
                                    <label className={`${styles.label}`}>
                                        <UploadedFileOnly type="image" title='Course Image' setShowLoading={setShowLoading} onCopy={(image) => handleMultiData({ image }, 'coursesData', ind)} value={course.image} labelStyle={{ marginBottom: 'auto', paddingRight: '25px' }} />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Course Title</span><br />
                                        <input className={`${styles.input}`} type='text' placeholder="Enter course title" value={course.title} onChange={(e) => handleMultiData({ title: e.target.value }, 'coursesData', ind)} /><br />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Course Duration</span><br />
                                        <input className={`${styles.input}`} type='text' placeholder="Course duration" value={course.duration} onChange={(e) => handleMultiData({ duration: e.target.value }, 'coursesData', ind)} /><br />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Course Price</span><br />
                                        <input className={`${styles.input}`} type='text' placeholder="Enter course price" value={course.price} onChange={(e) => handleMultiData({ price: e.target.value }, 'coursesData', ind)} /><br />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Course Description</span><br />
                                        <textarea className={`${styles.input}`} type='text' placeholder="Enter description" value={course.description} onChange={(e) => handleMultiData({ description: e.target.value }, 'coursesData', ind)} /><br />
                                    </label>
                                </div>
                            </MultiDiv>)}
                            <button onClick={() => addMultiData('coursesData')}>Add +</button>
                        </div>
                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(8)} >Message From Head Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 8 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.messageHead} onChange={(e) => handleWebsiteData({ messageHead: e.target.checked })} /><span>Enable Message From Head Section</span></CheckLabel>
                            {websiteData.messageHead && <>
                                <label className={`${styles.label}`}>
                                    <span>Message Image Link</span><br />
                                    <input className={`${styles.input}`} type='text' placeholder="http://" value={websiteData.messageImage} onChange={(e) => handleWebsiteData({ messageImage: e.target.value })} /><br />
                                </label>
                                {/**Text editor here */}
                                <TextEditor text={'About'} value={websiteData.messageText} handleChange={(messageText) => handleWebsiteData({ messageText })} navigate={navigate} setShowLoading={setShowLoading} onCopy={(messageImage) => !websiteData.messageImage && handleWebsiteData({ messageImage})} />

                            </>}
                        </div>
                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(9)} >Award Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 9 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.awards} onChange={(e) => handleWebsiteData({ awards: e.target.checked })} /><span>Enable Award Section</span></CheckLabel><br />
                            {websiteData.awards && websiteData.awardsData.map((award, ind) => <MultiDiv key={ind}>
                                <FaRegTrashAlt title="Delete" onClick={() => deleteMultiData('awardsData', ind)} />
                                <div>
                                    <label className={`${styles.label}`}>
                                        <UploadedFileOnly type="image" title='Award Image' setShowLoading={setShowLoading} onCopy={(image) => handleMultiData({ image }, 'awardsData', ind)} labelStyle={{ marginBottom: 'auto', paddingRight: '25px' }} value={award.image} />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Award Text</span><br />
                                        <input className={`${styles.input}`} type='text' placeholder="Enter award title" value={award.title} onChange={(e) => handleMultiData({ title: e.target.value }, 'awardsData', ind)} /><br />
                                    </label>
                                </div>
                            </MultiDiv>)}
                            <button onClick={() => addMultiData('awardsData')}>Add +</button>
                        </div>

                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(10)} >Gallery Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 10 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.gallery} onChange={(e) => handleWebsiteData({ gallery: e.target.checked })} /><span>Enable Gallery Section</span></CheckLabel><br />
                            {websiteData.gallery && websiteData.gallerySlides.map((photo, ind) => <MultiDiv key={ind}>
                                <FaRegTrashAlt title="Delete" onClick={() => deleteMultiData('gallerySlides', ind)} />
                                <div>
                                    <label className={`${styles.label}`}>
                                        <UploadedFileOnly type="image" title='Select Image' setShowLoading={setShowLoading} onCopy={(image) => handleMultiData({ image }, 'gallerySlides', ind)} labelStyle={{ marginBottom: 'auto', paddingRight: '25px' }} value={photo.image} />
                                    </label>
                                </div>
                            </MultiDiv>)}
                            <button onClick={() => addMultiData('gallerySlides')}>Add image</button>
                        </div>
                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(11)} >Testimonies Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 11 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.testimony} onChange={(e) => handleWebsiteData({ testimony: e.target.checked })} /><span>Enable Testiomony Section</span></CheckLabel><br />
                            {websiteData.testimony && websiteData.testimonySlides.map((photo, ind) => <MultiDiv key={ind}>
                                <FaRegTrashAlt title="Delete" onClick={() => deleteMultiData('testimonySlides', ind)} />
                                <div>
                                    <label className={`${styles.label}`}>
                                        <UploadedFileOnly type="image" title='Select Image' setShowLoading={setShowLoading} onCopy={(image) => handleMultiData({ image }, 'testimonySlides', ind)} value={photo.image} labelStyle={{ marginBottom: 'auto', paddingRight: '25px' }} />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Name</span><br />
                                        <input className={`${styles.input}`} type='text' placeholder="Enter Name of user" value={photo.title} onChange={(e) => handleMultiData({ title: e.target.value }, 'testimonySlides', ind)} />
                                    </label>
                                    <label className={`${styles.label}`}>
                                        <span>Testimony</span>
                                        <textarea className={`${styles.input}`} type='text' placeholder="Enter testiomony" value={photo.subtitle} onChange={(e) => handleMultiData({ subtitle: e.target.value }, 'testimonySlides', ind)} />
                                    </label>
                                </div>
                            </MultiDiv>)}
                            <button onClick={() => addMultiData('testimonySlides')}>Add Testimony</button>
                        </div>
                    </div>
                    <div className={styles.secDiv}>
                        <div onClick={() => handleSectionIndex(12)} >Footer Section Setting <span>< MdOutlineKeyboardArrowDown /></span></div>
                        <div style={{ display: sectionIndex === 12 ? 'block' : 'none' }}>
                            <CheckLabel><input type='checkbox' checked={websiteData.footer} onChange={(e) => handleWebsiteData({ footer: e.target.checked })} /><span>Enable Footer Section</span></CheckLabel>
                            {websiteData.footer && <>
                                {/**Text editor here */}
                                <TextEditor text={'Contact Information'} value={websiteData.contactInfo} handleChange={(contactInfo) => handleWebsiteData({ contactInfo })} navigate={navigate} setShowLoading={setShowLoading} />

                            </>}
                        </div>
                    </div>
                    { selWebsite && selWebsite.id && <div style={{ display: 'flex', gap:'10px', justifyContent: 'space-evenly'}}>
                        <Button onClick={handleSubmit} style={{marginTop: '10px'}}>Save Design</Button>
                        <Button onClick={handleDelete} style={{marginTop: '10px'}} color={'#111'} bg={'var(--border-color)'}>Delete Website</Button>
                    </div>}
                </div>
                { selWebsite && selWebsite.id && <div>
                    <label className={styles.label}>
                        {selWebsite.domain}.sight-eduplus.com
                        <button onClick={() =>setFullScreenComponent(<WebsiteTemplate websiteData={websiteData} title={websiteName} />)} style={{
                            width: 'fit-content', border: '1px solid #fff',
                            borderRadius: '5px', padding: '5px 5px',
                            fontSize: '16px', marginBottom: '10px', color: 'var(--primary-color)',
                            backgroundColor: '#fff', textDecoration: 'underline', float: 'right'
                        }}>Preview</button>
                    </label>
                    {/* <TemplateSelector>
                        <span><FaChevronLeft /></span>
                        <div>
                            <img src={img1} alt="" />
                        </div>
                        <span><FaChevronRight /></span>
                    </TemplateSelector> */}
                    <div>
                        <WebsiteTemplate websiteData={websiteData} title={websiteName} />
                    </div>
                </div>}

            </Container>
        </>
    );

};
export default DesignWebsite;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    & > div:first-child{
        border-right: 1px solid #d9d9d9;
        width: 45%;
        padding: 10px;
    }
    & > div:last-child{
        width: 55%;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        & > div:last-child{
            height: 100%;
            position: relative;
        }
    }
    @media (max-width: 768px){
        flex-direction: column;
        & > div:first-child{
            border-right-width: 0px;
        }
        & > div{
            width: 100% !important;
        }
        & > div:last-child > div:last-child{
            position: unset;
            & > div:first-child{
                position: relative;
            }
        }
    }
`;

// const TemplateSelector = styled.div`
//     display: flex;
//     flex-direction: row;
//     width: 100%; height: 120px;
//     justify-content: center;
//     align-items: center;
//     & > span{
//         padding: 0px 5px;
//     }
//     & > div{
//         height: 100%;
//         max-width: 90%;
//     }
//     & > div > img{
//         height: 100%; width: 150px;
//         cursor: pointer;
//     }
// `;

const CheckLabel = styled.label`
    display:flex;
    flex:1;
    cursor: pointer;
    align-items: center;
    @media screen and (max-width:850px){
        &.second{
            flex-direction: row;
        }
        
    }
    
`;
const MultiDiv = styled.div`
    margin: 5px 0;
    position: relative;
    >div{
        border: 1px solid var(--border-color);
        border-radius: 5px;
    }

    >svg{
        position: absolute;
        cursor: pointer;
        right: 2px;
        top: 5px;
        z-index: 2;
        :hover{
            color: var(--secondary-color);
        }
    }
    >svg:hover +div{
        box-shadow: 0 0 10px -2px var(--secondary-color);
    }
}`

