import React from "react";
import Event from './events';
import Msg from './msgSettings';
import Sms from './smsSettings';
import Email from './mailSettings'
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import styles from '../../../css/InstitutionMgmt.module.css';
import {default as Dashboard} from './Dashboard';
import Appointments from "./appointments";


const CalenderMgt = () => {
    return ( 
        <>
         <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Routes>
                <Route path="dashboard" element={<Dashboard/>}/>
                <Route path="events/*" element={<Event />} />
                <Route path="appointments/*" element={<Appointments />} />
                <Route path="messages/*" element={<Msg />} />
                <Route path="email-settings/*" element={<Email />} />
                <Route path="sms-settings/*" element={<Sms />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </div>
        </>
     );
}
 
export default CalenderMgt;