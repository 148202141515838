import { useContext, useEffect, useState } from "react";
import { CourseContext } from "./Courses";
import { MenuContext } from "../DashBoard";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../components/Select";
import styled from "styled-components";
import CategoriesCss from '../../../css/Categories.module.css';
import { Button } from "../../../components/Button";
import { DisplayFile, UploadedFileOnly } from "../../../components/UploadedFiles";
import restApi from "../../../Api";
import { addCourseResource } from "../../../redux/reducers/resultReducer";
import { IoTrashOutline } from "react-icons/io5";

export default function ManageResources () {
    const { setTitle } = useContext(CourseContext);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const coursess = useSelector((state) => state.results.courses);
    // .sort((a, b) => b.id - a.id)
    const [courses, setCourses] = useState(coursess);
    const [selCourse, setSelCourse] = useState(null);

    function onDrag (index, e) {
        e.dataTransfer.setData('text/plain', index);
    }
    function onDrop (toIndex, e) {
        e.preventDefault();
        const fromIndex = parseInt(e.dataTransfer.getData('text'))
        const arr = selCourse.resources;
        let newarray;

        if (fromIndex > toIndex) {
            newarray = [...arr.slice(0, toIndex), arr[fromIndex], ...arr.slice(toIndex, fromIndex), ...arr.slice(fromIndex+1, arr.length)];
        } else {
            newarray = [...arr.slice(0, fromIndex), ...arr.slice(fromIndex + 1, toIndex + 1), arr[fromIndex], ...arr.slice(toIndex + 1, arr.length)];
        }

        setSelCourse({...selCourse, resources:newarray});
        e.dataTransfer.clearData();
    }
    function handleDelete (res) {
        
    }

    useEffect(() => {
        setTitle('Manage Resources');
    }, [setTitle]);
    useEffect(() => {
        // setCourses(coursess.map(course => ({
        //     ...course,
        //     resources: [...files]
        // })), [coursess]);
        // console.log(coursess);
        if (selCourse && selCourse.id) {
            setSelCourse(courses.find(course => course.id === selCourse.id))
        }
        setCourses(coursess)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursess]);
    useEffect(() => {
        console.log(selCourse);
    }, [selCourse]);
    return <Resource className={CategoriesCss.form}>
        <label className={CategoriesCss.label}>Select Course
            <Select options={courses} label="title" value="id"  onChange={setSelCourse} />
        </label>
        {selCourse && selCourse.id && <div className="container" >
            {selCourse.resources && selCourse.resources.length && <small>drag to reorder items</small>}
            {selCourse.resources && selCourse.resources.length ?
                selCourse.resources.map((res, index) =><div className="file-atc-box">
                    <span className="index">{index + 1}</span>
                    <div className="file-cont" key={res.id} draggable onDragStart={e => onDrag(index, e)} onDrop={e => onDrop(index, e)} onDragOver={e => {e.preventDefault();}} >
                        <DisplayFile file={res} />
                        <span className="icon" onClick={handleDelete(res)}><IoTrashOutline /></span>
                    </div></div>) :
                'No resourses added yet'}
            {selCourse.id && <Button onClick={() => { setModalToggle(true); setModalText(<AddFile setShowLoading={setShowLoading} course_id={selCourse.id} setModalText={setModalText} />); }} >Add Resource</Button>}
        </div>}
    </Resource>;
}

const Resource = styled.div`
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    text-align: center;
}
.file-atc-box{
    width: 100%;
    display: flex;
    cursor: grab;
}
.index{
    padding-right: 3px;
    height: fit-content;
    align-self: center;
    cursor: default;
}
.file-cont{
    display: flex;
    width: 100%;
    border-radius: 5px;
    box-shadow: inset 0px 0px 6px -0px var(--border-color);
    .icon{
        padding: 5px;
        cursor: pointer;
        height: fit-content;
        :hover{
            color: var(--secondary-color);
        }
    }
}

`;
const types = [
    {type: 'audio', name: 'Audio'},
    {type: 'application|text|doc', name: 'Document'},
    {type: 'image', name: 'Image'},
    {type: 'video', name: 'Video'},
]

const AddFile = ({ setShowLoading, setModalText, course_id, }) => {
    const [file, setFile] = useState('');
    const [type, setType] = useState(types[0]);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();

    const handleFile = () => {
        if (!file) {
            setMessage('Please Select a file');
            return false;
        }
        const payload = {
            course_id, resource: file, resource_type: type.type
        };
        console.log(payload);
        setShowLoading(true);
        restApi.PostApi('/learning/add-course-resource', payload, true)
            .then(result => {
                console.log(result.json());
                setModalText(result.message);
                if (result.isSuccessful || result.isSuccesful) {
                    dispatch(addCourseResource(payload));
                }
            })
            .catch(error => {
                setModalText(error.message);
            })
            .finally(() => {
                setShowLoading(false);
            });

    };
    return <div>
        <strong>{message}</strong>
        <label className={CategoriesCss.label}>
            <span style={{ float: 'left' }}>File Type</span>
            <Select options={types} value="type" label="name" defaultValue={type} onChange={setType} />
        </label>
        <UploadedFileOnly setShowLoading={setShowLoading} onCopy={setFile} value={file} title="Select File" type={type} />
        <Button onClick={handleFile}>Add File</Button>
    </div>;
};