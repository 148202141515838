import { createSlice } from "@reduxjs/toolkit";

export const ServiceReducer = createSlice({
    name:"service",
    initialState:{services:[], files: []},
    reducers:{
       setService:(state, action) => {
            state.services = action.payload;   
        },
        addFile: (state, action) => {
            state.files.push(action.payload)
        },
        removeFile: (state, action) => {
            state.files = state.files.filter(file => file.id !== action.payload.id)
        },
        setFiles: (state, action) => {
            state.files = action.payload;
        },
       
    }
})
export const { setService,
    addFile, removeFile, setFiles
} = ServiceReducer.actions;
export default ServiceReducer.reducer;