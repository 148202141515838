import {Routes,Route} from 'react-router-dom';
import {default as QuestionBankView} from './QuestionBankView';
import {default as Dashboard} from './Dashboard';
import TestDashboard from '../Test/Dashboard';


const QuestionsView = () =>{
    return(
        <>
           <Routes>
                <Route path='/test-dashboard' element={<TestDashboard/>} />
                <Route path='/dashboard' element={<Dashboard/>} />
                <Route path='/*' element={<QuestionBankView/>} />
           </Routes>
        
        </>
    )
}

export default QuestionsView
export {default as Questions} from './Questions';
export {default as QuestionBankView} from './QuestionBankView';
export {default as Categories} from './Categories';
export {default as Dashboard} from './Dashboard';
export {default as EachQuestion} from './EachQuestion';
export {default as QuestionPageNumber} from './QuestionPageNumber';
export {default as ViewQuestions} from './ViewQuestions';
export {default as AddQuestions} from './AddQuestions';
export {default as QuestionChart} from './QuestionChart';
export {default as TakeTest} from './TakeTest';
export {default as AnalysisPage} from './AnalysisPage';
export {default as Test} from './Test';
export {default as CreateQuestionCategory} from './CreateQuestionCategory';
export {default as ViewQuestionCategories} from './ViewQuestionCategories';