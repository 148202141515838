import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ResultContext } from "./ResultMgmt"
import ResultCss from '../../../css/Result.module.css';
import CategoriesCss from '../../../css/Categories.module.css';
import { addResultSet } from "../../../redux/reducers/resultReducer";
import Select from "../../../components/Select/Single";
import Api from "../../../helperFunctions/Api2";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const AddResultSet = () => {
    const { clearEdit, editing, editType, editData, setTitle  } = useContext(ResultContext);
    //const set = useSelector(state => state.results.currentSet);
    const paymentLinks = useSelector(state => state.account.paymentLinks);
    const institutions = useSelector(state => state.questions.user_institutions);
    const templates = useSelector(state => state.results.templates);
    const dispatch = useDispatch();
    const [selLink,setSelLink] = useState('');
    const [defaultLink, setDefaultLink] = useState('');
    const [templateArr, setTemplateArr] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [defaultTemplate, setDefaultTemplate] = useState('')
    const [requirePayment, setRequirePayment] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [PLinks, setPLinks] = useState([]);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [title, setRSetTitle] = useState('');
    const [description, setDescription] = useState('');
    const [resultYear, setResultYear] = useState('');
    const [forInstitution, setForInstitution] = useState(false);
    const [selInstitution, setSelInstitution] = useState('');
    const [resultType, setResultType] = useState('0');

    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        let payload = {
            title: title,
            description: description,
            session: resultYear,
            template_id: selectedTemplate.id,
            result_type: resultType
        }
        if(Object.values(payload).some(v=> v == "" || v == null)){
            setModalText("Please fill all required field");
            setModalToggle(true);
            return false
        }
        if(requirePayment && !selLink.id){
            setModalText("Please select a Payment Link");
            setModalToggle(true);
            return false
        }
        payload = {
            ...payload,
            is_paid: requirePayment,
            payment_link: selLink.id
        }
        if(forInstitution && !selInstitution.id){
            setModalText("Please select an Institution");
            setModalToggle(true);
            return false
        }
        payload.institution_id = selInstitution.id;
        if (editing && editType =='result-set') {
            payload.id = editData.id;
        }
        
        setShowLoading(true);
        Api.PostApi(editing && editType =='result-set' ? '/result/edit-result-set':'/result/add-result-set', payload, true)
        .then(result=>{
            setShowLoading(false)
            setModalText(result.message);
            setModalToggle(true);
            console.log(result);
            if(result.isSuccessful || result.isSuccesful){
                payload = editing && editType =='result-set'? {...editData, ...payload, ...result.data} : {...result.data, groups:[]};
                dispatch(addResultSet(payload));
                clearFields();
            }
        })
        .catch(error=>{
            setModalText(error.message);
            setModalToggle(true);
        })
    }
    const handleCancel = () => {
        clearFields();
        navigate('../view-result-set')
    }
    const clearFields = () => {
        editing && editType == 'result-set' && clearEdit();
        setDefaultLink(''); setRequirePayment(false); setForInstitution(false);
        setSelInstitution(''); setRSetTitle(''); setDescription(''); setResultYear('');
        setSelectedTemplate({}); setDefaultTemplate('')
    }
    useEffect(() => {
        setTemplateArr(Object.values(templates));
    }, [templates])
    useEffect(()=>{
        if(Object.values(paymentLinks).length > 0){
            let PLinks = Object.values(paymentLinks);
            // PLinks = paymentLinks.filter(x => x.service_id == 6);
            setPLinks(PLinks);
        }
    }, [paymentLinks])
    
    useEffect(() => {
        setTitle('Add Result Set');
    }, [])

    useEffect(()=>{
        if (editing && editType == 'result-set') {
            setRSetTitle(editData.title);
            setDescription(editData.description);
            setResultYear(editData.session);
            if (editData.payment_link) {
                setRequirePayment(true);
                setDefaultLink(PLinks.find(l => l.id == editData.payment_link));
            } else {
                setRequirePayment(false)
            }
            if (editData.institution_id) {
                setForInstitution(true);
                setSelInstitution(institutions.find(inst => inst.id === editData.institution_id));
            } else {
                setForInstitution(false)
            }
            editData.template_id && setDefaultTemplate(templates[editData.template_id]);
        }
        else clearFields()
    }, [editType])

    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={ResultCss.mainView}>
                <label className={CategoriesCss.label}>
                    Title<span className={CategoriesCss.isRequired}></span>
                    <input value={title} onChange={(e)=>setRSetTitle(e.target.value)} placeholder='Title of the result set' className={CategoriesCss.input} />
                </label>
                <label className={CategoriesCss.label}>
                    Description<span className={CategoriesCss.isRequired}></span>
                    <textarea style={{height: 70, outline:'none'}} value={description} onChange={(e)=>setDescription(e.target.value)} placeholder='Breifly describe the result set' className={CategoriesCss.input}/>
                </label>
                <label className={CategoriesCss.label}>
                    Year/Session<span className={CategoriesCss.isRequired}></span>
                    <input value={resultYear} onChange={(e)=>setResultYear(e.target.value)} placeholder='year or session of result set' className={CategoriesCss.input} type='text'/>
                </label>
                <label className={CategoriesCss.label}>
                    Result Type<span className={CategoriesCss.isRequired}></span>
                    <select onChange={(e)=>setResultType(e.target.value)} className={CategoriesCss.input} type='text'>
                        <option value="0">Result</option>
                        <option value="1">Transcript</option>
                        <option value="2">Certificate</option>
                    </select>
                </label>
                <label className={CategoriesCss.label}>
                    Result Template to use<span className={CategoriesCss.isRequired}></span>
                    <Select reset={false} options={templateArr} onChange={setSelectedTemplate} label="title" value="id" defaultValue={defaultTemplate}/>
                </label>
                <label className={CategoriesCss.label}>
                    <input className={ResultCss.reqpay} type='checkbox' checked={requirePayment} onChange={()=> requirePayment ? setRequirePayment(false):setRequirePayment(true)}/>
                    <span>Requires Payment ? </span> 
                </label>
                 
                {
                    requirePayment && (
                    <label className={CategoriesCss.label}>
                        <span className={CategoriesCss.isRequired}>Select payment link</span>
                        <Select onChange={setSelLink} options={PLinks} className={CategoriesCss.fields} label='title' value='id' defaultValue={defaultLink}/>
                    </label>)
                }
                <label className={CategoriesCss.label}>
                    <input className={ResultCss.reqpay} type='checkbox' checked={forInstitution} onChange={()=> setForInstitution(!forInstitution)}/>
                    <span>For an institution ? </span> 
                </label>
                {
                    forInstitution && (
                    <label className={CategoriesCss.label}>
                        <span className={CategoriesCss.isRequired}>Select Institution</span>
                        <Select onChange={setSelInstitution} options={institutions} className={CategoriesCss.fields} label='name' value='id' defaultValue={selInstitution}/>
                    </label>)
                }
                <div className={CategoriesCss.buttonContainer}>
                {editing && editType =='result-set' && <button className='cancelEditButton' onClick={handleCancel} >Cancel Edit</button>}
                    <button className={CategoriesCss.viewBtn}  onClick={handleSubmit}>{editing && editType =='result-set' ? 'Save':'Add'} Result Set</button>
                    
                </div>
            </div>
        </>
    )
}
export default AddResultSet;

/* In result set, you will have the title, description, year/session, Audience(Email, institution), 
is Paid(which will have options like free, Paid). 
if the is paid selected is paid, a dropdown to select the payment link should show
*/