import React,{useEffect,useState} from 'react'
import css from './css/eachServices.module.css';
import { useLocation } from 'react-router-dom';

const EachService = ({name,image,categories,description,features,ind}) => {
    const location = useLocation();
  return (
    <>
      <div className={css.container}>
           
            <div>
                {(ind+1 )% 2 != 0 && <div>
                    <img  src={image}/>
                    <h3>{name}</h3>
                </div>}
                <div className={css.otherDiv}>
                    <div>
                        <div>DESCRIPTION</div>
                        <div>{description}</div>
                    </div>
                    <div>
                        <div>FEATURES</div>
                        <ul>
                            {features.map((feature,ind) => <li key={ind}>{feature}</li>)}
                        </ul>
                    </div>
                </div>
                {(ind+1) % 2 == 0 && <div>
                    <img  src={image}/>
                    <h3>{name}</h3>
                </div>}
            </div>
            
        </div>
        
    </>
  )
}

export default EachService