import styles from '../../../../css/CreateInst.module.css';
import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../components/Select/index.jsx";
import Modal from '../../../../components/modal.js';
import Loading from '../../../../components/loading';
import Api from "../../../../helperFunctions/Api2.js";
// import { EditContext } from '../../StaffMgmt.jsx';
import { addUserPrivileges } from '../../../../redux/reducers/questionReducer';
// import { useNavigate } from 'react-router-dom';

const SetUserPrivileges = ({setTitle})=>{
    useEffect(() => {
        setTitle('Set Users Privileges');
    }, [setTitle]);

    const institutions = useSelector(state=>state.questions.user_institutions);
    const privileges = useSelector(state=>state.questions.user_privileges);
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [ showLoading,setShowLoading] = useState(false);
    const [selInst,setSelInst] = useState(null);
    const [selCatArr,setSelCatArr] = useState([])
    const [selCat,setSelCat] = useState(null);
    const [selPrivileges,setSelPrivileges]= useState(null);
    const [defaultValue,setDefaultValue] = useState(false);
    const [defaultPre,setDefaultPre] = useState([]);
    const [reset, setReset] = useState(false);
    
    const clearFields = ()=>{
        setSelInst(null);
        setSelCat(null);
        setSelCatArr([])
        setSelPrivileges(null);
        setDefaultValue(!defaultValue);
        setReset(true);
        setReset(false);
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        // console.log(selPrivileges,'pri',selInst,'inst',selCat,'cat')
       if (selInst==null||selCat==null||selPrivileges==null||selPrivileges.length ===0) {
            setModalText('Please completely select all fields');
            setModalToggle(true);
        } else {
            let privilegess = [ ...selPrivileges];
            let obj = {}
            privilegess.forEach(value=>{
                obj[value.id] = value;
            })
            
        // console.log(selCat.privileges,privilegess,"pp");
            setShowLoading(true);
            let payload = {
                instId:selInst.id,
                category_id:selCat.id,
                privileges: Object.keys(obj)
            }
            console.log(payload, 'payload')
            if(!Api.checkNetwork()) return
            Api.PostApi('/privileges/set-user-privileges',payload, true)
            .then(result=>{
                setShowLoading(false);
                setModalText(result.message);
                setModalToggle(true)
                console.log(result, "privilege")
                if (result.isSuccessful){
                    payload.privileges = Object.values(obj)
                    dispatch(addUserPrivileges(payload))
                    clearFields();
                }
            })
            .catch(error => {
                setShowLoading(false);
            });
        }
    }


    useEffect(()=>{
        if(selInst && selInst.id){
            setSelCatArr(Object.values(selInst.categories))
        }
        else{
            setSelCatArr([]);
        }
        setSelCat(null);
    },[selInst]);

    useEffect(()=>{
        // console.log(selCat)
        if (selCat!==null) {
            setDefaultPre(selCat.privileges);
        }
        // else if (defaultValue === undefined){}
        else{
            setDefaultValue(!defaultValue);
            setDefaultPre('');
        }
    },[selCat])

    useEffect(()=>{
    //    setDefaultPre(privileges.filter((ePre,index) => index === 0));
    },[privileges, reset])


    return (
    <Fragment>
        <Loading shouldShow={showLoading} />
        <Modal modalObject={{ body : modalText }} modalTogglee={modalToggle} closeModal={()=>setModalToggle(false)}/>
        <div className={styles.form}>
            <label className={styles.label}>
                <span className={styles.isRequired}>User Institution</span>
                <Select options={institutions} onChange={setSelInst} className={styles.fields} value="id" label="name" reset={reset}/>      
            </label>
            <label className={styles.label}>
                <span className={styles.isRequired}>User Group</span>
                <Select options={selCatArr} onChange={setSelCat} className={styles.fields} value="id" label="name" reset={reset}/>
            </label>
            <label className={styles.label}>
                <span className={styles.isRequired}>Set Privileges for this group</span>
                <Select options={privileges} onChange={setSelPrivileges} className={styles.fields} value="id" defaultValue={defaultPre} label="name" multi reset={defaultValue}/>
            </label>
            <div className={`${styles.addBtnDiv} ${styles.div} ${styles.buttonContainer}`}>
                <button type='submit' className={styles.addBtn} onClick={(e) => {handleSubmit(e)}}>
                    {'Set Privileges'}
                </button>
            </div>
        </div>
    </Fragment>
    )
}

export default SetUserPrivileges;