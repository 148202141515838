import React,{useEffect,useState,useContext,createContext} from 'react'
import css from './css/cbt.module.css';
import {FaAngleDown as Down, FaAngleUp as Up} from 'react-icons/fa';
import AllQuestions from './AllQuestions';
import { CbtQuestionId } from './createCbt';
import { setQuestions } from '../../../redux/reducers/questionReducer';

export const AllQuestionContext = createContext();
const EachCategorySetting = ({cateId ,timeType ,categories ,questions,index}) => {
    const [categoryName, setCategoryName] = useState('');
    const [added_by,setAdded_by] = useState(null);
    const [cateQuestions,setCateQuestions] = useState([]);
    const [showQuestions,setShowQuestions] = useState(false);
    const [numOfQuestions,setNumOfQuestions] = useState(0);
    const [timeVal,setTimeVal] = useState(null);
    const [inputSet,setInputSet] = useState([]);
    const [selectedId,setSelectedId] = useState([]);
    const [timeArrVal,setTimeArrVal] = useState([]);
    const [enableEdit,setEnableEdit] = useState(true);
    // const {editObject} = useContext(CbtQuestionId);

    const {allQuestionCategory,setAllQuestionCategory,allQuestionId,setAllQuestionId,editObject} = useContext(CbtQuestionId);

    const toggleShowQuestions = () =>{
        setShowQuestions(!showQuestions)
    }

    const handleTimeVal = (e) =>{
        const value = e.target.value;
        setTimeVal(value);
    }

    const handleNumberOfQuestion = (e) =>{
        const value = e.target.value;
        setNumOfQuestions(value);
        setAllQuestionCategory([...allQuestionCategory.map((element,num) => Number(num) === Number(index) ?{...element,questions_num:value}:element)])
    }

    const handleComponentUnmount = () =>{
        setAllQuestionCategory([...allQuestionCategory.filter((e,ind) => Number(ind) !== Number(index))]);
        setAllQuestionId([...allQuestionId.filter((e,ind) => Number(ind) !== Number(index))]);
    }

    useEffect(()=>{
        if(!categoryName){
            const cateName = categories.filter(element => element.id === cateId);
            if(cateName.length > 0){
                setCategoryName(cateName[0].name);
            }
        }
        if(!added_by){
            const addby = categories.filter(element => element.id === cateId);
            if (addby) {
                if(addby[0].isOwned)
                setAdded_by(true);
            }
            else{
                setAdded_by(false)
            }
            if(cateQuestions.length <= 0){
            }
        }
    }, [])
    useEffect(() => {
        const cQuestions = questions.filter(element => Number(element.category_id) === Number(cateId));
        if (cQuestions.length > 0) {
            setCateQuestions(cQuestions);
        }
        
    }, [cateId, questions])
    

    useEffect(()=>{
        if(allQuestionCategory){
            setAllQuestionCategory([...allQuestionCategory.map((e,ind)=> Number(ind) === Number(index)?{
                ...e,duration:(timeType && timeType.value === '1'?timeVal:null),questions_num:numOfQuestions,is_selected:added_by?1:0,duration_per_question:(timeType && timeType.value === '2' ?timeVal:null)
            }:e) ]);
            //console.log(allQuestionCategory)
        }
    },[numOfQuestions,timeType,timeVal]);

    useEffect(()=>{
        if(timeType && timeType.value === '2'){
            let len = cateQuestions.length;
            let i = 0;
            let arr = [];
            for(i = 0;i<len;i++){
                arr[i] = {id:i+1,duration:timeVal}
            }
            setTimeArrVal(arr);
            setAllQuestionId([...allQuestionId.map((element,num) => Number(num) === Number(index)?{questions:[...element.questions.map(elem => ({...elem,duration:timeVal}))]}:element )])
           
        }
    },[timeType,timeVal])

    useEffect(()=>{
        if(cateId){
            const is_selected = added_by ? false: true;
            const numberOfQuestions = 0;
            const sections_time = null;
            setAllQuestionCategory([...allQuestionCategory.filter((e,ind) => Number(ind) !== Number(index)),
                {
                    // question_category_id:cateId,
                    question_category_id:cateId,
                    is_selected,
                    questions_num:numberOfQuestions?Number(numberOfQuestions):0,
                    duration:sections_time,
                    duration_per_question:null
                }
            ]);
            setAllQuestionId([...allQuestionId.filter((e,ind) => Number(ind) !== Number(index)),{questions:[]}])
            //console.log('hello')
        }        
        return ()=> handleComponentUnmount();
    },[cateId])

    useEffect(()=>{
        setTimeVal('')
    },[timeType])

    

    useEffect(()=>{
        //console.log(selectedQuestionId);
        if(editObject && Object.keys(editObject).length > 0){
            let check = editObject.categories.find(eachCate => eachCate.category_id == cateId);
            //console.log(check);
            if(check){
                setEnableEdit(false);
                setNumOfQuestions(check.questions.length);
                setTimeVal(check.duration);
            }
          
            
        }
    },[editObject])

  return (
      <React.Fragment>
        {categoryName && <div className={css.eachSettingDiv}>
            <div style={!showQuestions ?{paddingBottom:'0',borderBottom:'none'}:{paddingBottom:'15px',borderBottom:'1px solid var(--border-color)'}}>
                <div>{categoryName}</div>
                {timeType && timeType.value != '0' && 
                <div>
                    <span>{timeType.value == '1' ? 'Set Category Time':'Equal Question time'}</span>
                    <input type="number" placeholder='In minutes' onChange={(e)=>{handleTimeVal(e)}} min={0} value={timeVal?timeVal:''} disabled={editObject?true:false}/>
                </div>
                }
                 
                <div>
                    <span>No of Questions</span>
                    <input  type="number" disabled={editObject?true:added_by} value={numOfQuestions} onChange={(e)=>{handleNumberOfQuestion(e)}} min={0} />
                </div>
                
                { added_by && 
                <div className={css.showIconDiv} title={enableEdit?"edit enable":"edit not allowed"} style={!enableEdit?{cursor:"not-allowed"}:{cursor:"pointer"}}>
                    {showQuestions? <Up onClick={()=>toggleShowQuestions()} />:<Down onClick={enableEdit?()=>toggleShowQuestions():()=>{}} />}
                </div>               
                }
            </div>
            {
                added_by && cateQuestions && cateQuestions.length > 0 && showQuestions && 
                <div className={css.questionSettingDiv} >
                    <AllQuestionContext.Provider value={{inputSet,setInputSet,selectedId,setSelectedId,timeArrVal,setTimeArrVal}}>
                        <AllQuestions allQuestions={cateQuestions} timeType={timeType}  index={index} setNumOfQuestions={setNumOfQuestions} timeVal={timeVal}  />
                    </AllQuestionContext.Provider>
                   
                </div>
            }
        </div>} 
      </React.Fragment>
    
  )
}

export default EachCategorySetting