import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ResultCss from '../../../css/Result.module.css';
import CategoriesCss from '../../../css/Categories.module.css';
import Api from "../../../helperFunctions/Api2";
import { addCourse, editCourse } from "../../../redux/reducers/resultReducer";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { CourseContext } from "./Courses";
import { MenuContext } from "../DashBoard";
import { UploadedFileOnly } from "../../../components/UploadedFiles";

export default function AddCourse() {
    const { setTitle, editing, editData, clearEdit } = useContext(CourseContext);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const dispatch = useDispatch();
    const [title, setRSetTitle] = useState('');
    const [description, setDescription] = useState('');
    const [code, setCode] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [file, setFile] = useState('');
    const [unit, setUnit] = useState(1);
    const [edit, setEdit] = useState(false);
    const navigate = useNavigate()

    const clearFields = ()=>{
        setRSetTitle('');
        setDescription('');
        setCode('');
        setUnit(1);
        setThumbnail('');
        setFile('')
        if(editing)
            clearEdit()
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let payload = {
            title: title,
            description: description,
            code, 
            unit
        }
        if (Object.values(payload).some(e => e === '')) {
            setModalText('Please completely fill all fields');
            setModalToggle(true);
            return false;
        }
        payload = {
            ...payload,
            image: thumbnail,
            preview_video: file
        }
        if (edit) payload = {...editData, ...payload}
        setShowLoading(true);
        Api.PostApi(edit?'/result/edit-course':'/result/add-course', payload, true)
            .then(result => {
                setModalText(result.message);
                console.log(result);
                if (result.isSuccessful || result.isSuccesful) {
                    clearFields();
                    dispatch(edit?editCourse(payload):addCourse(result.data))
                }
            })
            .catch(error => {
                setModalText(error.message);
            })
            .finally(() => {
                setModalToggle(true);
                setShowLoading(false);
            })
    }
    useEffect(() => {
        setTitle('Add Course');
    }, [setTitle])
    useEffect(()=>{
        if (editing) {
            setCode(editData.code);
            setDescription(editData.description);
            setRSetTitle(editData.title);
            setEdit(true);
            setThumbnail(editData.image || '');
            setFile(editData.preview_video || '');
        }else {
            setCode('');
            setDescription('');
            setRSetTitle('');
            setEdit(false);
            setThumbnail('');
            setFile('');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[editing])

    return (<>
        <div className={ResultCss.mainView}>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Title</span>
                <input value={title} onChange={(e) => setRSetTitle(e.target.value)} placeholder='Title of the course' className={CategoriesCss.input} />
            </label>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Description</span>
                <textarea style={{ height: 70, outline: 'none' }} value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Breifly describe the course' className={CategoriesCss.input} />
            </label>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Course code</span>
                <input value={code} onChange={(e) => setCode(e.target.value.toString().toUpperCase())} placeholder='short code alias of the course' className={CategoriesCss.input} type='text' />
            </label>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Course Unit</span>
                <input value={unit} onChange={(e) => setUnit(e.target.value)} min={1} placeholder='weight of each credit' className={CategoriesCss.input} type='number' />
            </label>
            <label className={`${CategoriesCss.label}`}>
                <UploadedFileOnly type="image" title='thumbnail' setShowLoading={setShowLoading} onCopy={setThumbnail} value={thumbnail} />
            </label>
            <UploadedFileOnly setShowLoading={setShowLoading} onCopy={setFile} value={file} title="Preview Video Url" type={'video'} />
            <div className={CategoriesCss.buttonContainer}>
                {edit && <button onClick={() => { clearFields(); navigate('../view-course') }} className='cancelEditButton'>Cancel Edit</button>}
                <button className={CategoriesCss.viewBtn} onClick={handleSubmit}>{edit?"Edit Course":"Add Course"}</button>
            </div>
        </div>
    </>)
}