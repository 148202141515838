import { Fragment, useContext, useEffect, useState, useRef } from "react";
import ResultCss from '../../../css/Result.module.css';
import CategoriesCss from '../../../css/Categories.module.css';
import Modal from "../../../components/modal";
import { FaPlus, FaRegMinusSquare, FaRegTrashAlt } from "react-icons/fa";
import { CgAddR } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import {
    addSection, addTemplate, deleteSection, setExtra, setExtraValue, changeTemplate, setRowName,
    setNewContent, setSectionName, setSectionType, setTemplateName, addColumn, deleteColumn, setColumnName,
    setColumnType, setDefaultResultSection,
    updateExtraData, removeExtraData, setSumVal
} from "../../../redux/reducers/resultReducer";
//import {  } from "../../../redux/reducers/resultReducer";
//import Select from "../../../components/Select";
import { ResultContext } from "./ResultMgmt";
import { useNavigate } from "react-router-dom";
import Api from "../../../helperFunctions/Api2";
import Loading from "../../../components/loading";
import TextEditor from "../../../components/TextEditor";

const style = {
    height: 400,
    width: '100%',
    readonly: false
};

const AddTemplate = () => {
    const template = useSelector(state => state.results.currentTemplate);
    const [modalText, setModalText] = useState('');
    const [modalToggle, setModalToggle] = useState(false);
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { editing, editType, editData, clearEdit, setTitle } = useContext(ResultContext);
    const [showLoading, setShowLoading] = useState(false);
    //console.log(index)

    const handleSubmit = () => {
        let a = { ...template.template };
        if (a.name.length < 2) {
            setModalText('Template needs a name');
            setModalToggle(true);
            return;
        }
        else if (Object.values(a.sections).length = 0) {
            setModalText('Every template needs at least 1 section');
            setModalToggle(true);
            return;
        }
        else if (Object.values(a.sections).filter(section => section.name.trim() === '').length > 0) {
            setModalText('Give each section a name or delete before saving');
            setModalToggle(true);
            return;
        }
        setShowLoading(true);
        let payload = { ...template, title: a.name, template: JSON.stringify(a) };
        Api.PostApi(editing && editType === 'result-template' ? '/result/edit-template' : '/result/add-template', payload, true)
            .then(result => {
                console.log(result, payload);
                setModalText(result.message);
                if (result.isSuccessful || result.isSuccesful) {
                    dispatch(addTemplate({ ...payload, ...result.data }));
                    dispatch(changeTemplate(''));
                }
            })
            .catch(error => {
                setModalText(error);
            })
            .finally(() => {
                setModalToggle(true);
                setShowLoading(false);
            });
    };

    const addSect = (index) => {
        setIndex(index);
        dispatch(addSection(index));
    };
    const delSection = (id) => {
        dispatch(deleteSection({ sectionId: id }));
    };
    const handleCancel = () => {
        dispatch(changeTemplate('newTemplate'));
        clearEdit();
        navigate('../view-result-template');
    };

    useEffect(() => {
        setTitle('Add Result Template');
        if (editing && editType === 'result-template') {
            dispatch(changeTemplate(editData));
        } else {
            dispatch(changeTemplate('newTemplate'));
        }
    }, [editType]);
    useEffect(() => {
        setIndex(Math.max(...Object.keys(template.template.sections)));
        // console.log(template.sections)
    }, [template.template.sections]);
    useEffect(() => {
        if (index < -1) {
            setIndex(-1);
        }
    }, [index]);
    useEffect(() => {
    }, []);

    return (
        <Fragment>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={ResultCss.mainView}>
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Template Name</span>
                    <input placeholder='Enter template title' className={CategoriesCss.input} value={template.template.name} onChange={(e) => dispatch(setTemplateName({ name: e.target.value }))} />
                </label>
                {Object.keys(template.template.sections).map((section, index) => <Section key={index} templateId={template.template.id} element={template.template.sections[section]} delSection={delSection} setShowLoading={setShowLoading} />)}
                <button className={ResultCss.addColumnBtn} onClick={() => addSect(index + 1)}><FaPlus />Add Section</button>

                <div className={CategoriesCss.buttonContainer}>
                    {editing && editType === 'result-template' && <button className={'cancelEditButton'} onClick={handleCancel} >Cancel Edit</button>}
                    <button className={CategoriesCss.viewBtn} onClick={handleSubmit} >Save template</button>
                </div>
            </div>
        </Fragment>
    );
};

const Section = ({ element, delSection, setShowLoading, showLoading }) => {
    const dispatch = useDispatch();
    const editor = useRef(null);
    const Navigate = useNavigate();

    const handleTypeChange = (value) => {
        dispatch(setSectionType({ sectionId: element.id, type: value }));
        dispatch(setNewContent({ sectionId: element.id }));
    };
    const handleTextChange = (value) => {
        dispatch(setColumnName({ sectionId: element.id, id: 0, name: value }));
    };
    return (
        <Fragment>
            <div className={ResultCss.section}>
                <span className={ResultCss.deleteBtn + ` ${ResultCss.deleteSection}`} onClick={() => delSection(element.id)}><FaRegTrashAlt />Delete Section</span>
                <label className={CategoriesCss.label} >
                    <span className={CategoriesCss.isRequired}>Section Name</span>
                    <input placeholder='...section heading' type={'text'} value={element.name} onChange={(e) => dispatch(setSectionName({ sectionId: element.id, name: e.target.value }))} className={CategoriesCss.input} />
                </label>
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Section Type</span>
                    <select value={element.type} onChange={(e) => handleTypeChange(e.target.value)} className={CategoriesCss.select} >
                        <option value={'table'}>Table</option>
                        <option value={'comment'} >Comment</option>
                        <option value={'text'}>Text(Certificate)</option>
                    </select>
                </label>
                {
                    element.type === "table" && (
                        <label className={CategoriesCss.label}>
                            <input style={{ height: 15 }} name="isDefaultResult" type="radio" onChange={(e) => dispatch(setDefaultResultSection({ secId: element.id }))} /><span>Set as Default Result Table</span>
                        </label>
                    )
                }
                {
                    element.type === 'text' ? (
                        <TextEditor
                            text={'Result/Certificate info'}
                            setShowLoading={setShowLoading}
                            navigate={Navigate}
                            value={element.content[0].name}
                            reff={editor}
                            style={style}
                            handleChange={handleTextChange}
                            labelStyle={{ marginBottom: 0 }}
                        />
                    ) : <AddSubjectTemplate content={element.content} sectionId={element.id} typeName={element.type === 'comment' ? `Row` : `Column`} setShowLoading={setShowLoading} />
                }

            </div>
        </Fragment>
    );
};

const AddSubjectTemplate = ({ content, sectionId, typeName, setShowLoading }) => {

    const [index, setIndex] = useState(0);
    const [numTypes, setNumTypes] = useState({});
    const dispatch = useDispatch();

    const addIndex = (index) => {
        const payload = { sectionId, column: { id: index, name: '', type: 'text' } };
        // console.log(payload, "payload")
        dispatch(addColumn(payload));
    };

    useEffect(() => {
        // console.log('cnt', content)
    }, [content]);


    useEffect(() => {
        content && setIndex(Math.max(...Object.keys(content)));

        let a = {};
        for (const key in content) {
            if (Object.hasOwnProperty.call(content, key)) {
                const element = content[key];
                if (element && element.type === 'num') {
                    a[key] = element;
                } else {
                    delete a[key];
                }
            }
        }
        setNumTypes(a);
    }, [content]);

    useEffect(() => {
        if (index < -1) {
            setIndex(-1);
        }
    }, [index]);

    return (
        <Fragment>
            <table className={ResultCss.table}>
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>{typeName} Name</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {content && Object.keys(content).map((key, index) => <TableRow key={key} column={content[key]} sectionId={sectionId} id={content[key].id} rowIndex={index} typeName={typeName} setShowLoading={setShowLoading} />)}
                    <tr>
                        <td></td>
                        <td>
                            <button className={ResultCss.addColumnBtn} onClick={() => addIndex(index + 1)}><FaPlus />Add {typeName}</button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </Fragment>
    );
};

const placeholder = {
    "comtxt": "Enter formula for computed number",
    "infdtxt": "Enter formula for inferred value",
    "fixedtxt": "Enter fixed text/number"
};

const TableRow = ({ column, sectionId, id, rowIndex, typeName, setShowLoading }) => {
    const dispatch = useDispatch();
    const [inputType, setInputType] = useState("");
    const [infdTxt, setInfdTxt] = useState([['', '']]);
    const [colRowName, setColRowName] = useState('');
    const uploadedImage = useRef();

    const changeColRowName = (name) => {
        setColRowName(name);
        dispatch(setColumnName({ sectionId, id, name }));
    };

    const AddInfdTxt = () => {
        let tab = infdTxt;
        tab.push(['', '']);
        let len = tab.length;
        let tab2 = [];
        for (let i = 0; i < len; i++) {
            tab2.push(tab[i]);
        }
        setInfdTxt(tab2);
        //Add to result
    };

    /*useEffect(()=>{
        setInputType(inputType)
    },[infdTxt])*/

    const changeInfdTxt = (index, text, pos) => {
        let tab = infdTxt;
        tab[index][pos] = text;
        setInfdTxt(tab);
        dispatch(updateExtraData({ sectionId, id, text, pos, index }));
        //Update result
    };

    const removeInfdTxt = () => {
        let tab = infdTxt;
        let len = tab.length;
        let tab2 = [];
        for (let i = 0; i < len - 1; i++) {
            tab2.push(tab[i]);
        }
        setInfdTxt(tab2);
        dispatch(removeExtraData({ sectionId, id }));
    };

    return (
        <>
            <tr ><td>{rowIndex + 1}</td>
                <td><input placeholder={`...title of the ${typeName}`} value={column.name} onChange={(e) => changeColRowName(e.target.value)} className={CategoriesCss.input} /></td>
                <td>
                    <select value={column.type} onChange={(e) => {
                        dispatch(setColumnType({ sectionId, id, type: e.target.value, typeName }));
                        setInputType(e.target.value);
                    }} className={ResultCss.select}>
                        <option value='txt'>Inputed Text/Number</option>
                        <option value='img'>Inputed Image</option>
                        <option value='comtxt'>Computed Text/Number</option>
                        <option value='infdtxt'>Inferred Text/Number</option>
                        <option value='fixedtxt'>Fixed Text/Number</option>
                        <option value='fixedimg'>Fixed Image</option>
                        <option value='course'>Courses/Subjects</option>
                        <option value='users'>Users</option>
                    </select>
                </td>
                <td className={column.type === 'infdtxt' && ResultCss.deleteBtnTd}><button onClick={() => dispatch(deleteColumn({ sectionId, id, typeName }))} className={ResultCss.deleteBtn}><FaRegTrashAlt /><span>Delete</span></button></td>
            </tr>
            {(inputType == "comtxt" || inputType == "fixedtxt") && (<tr>
                <td></td>
                <td>
                    <input className={CategoriesCss.input} type="text" placeholder={placeholder[inputType]} onChange={(e) => dispatch(setExtra({ sectionId, id, extra: e.target.value, type: 'string' }))
                    } />
                </td>
            </tr>)}
            {
                (inputType == 'infdtxt') && (
                    infdTxt.length > 0 && (
                        infdTxt.map((tab, index) => (
                            <tr key={index}>
                                <td></td>
                                <td>
                                    <input value={infdTxt[index][0]} onChange={(e) => changeInfdTxt(index, e.target.value, 0)} className={CategoriesCss.input} type="text" placeholder={placeholder[inputType]} />
                                </td>
                                <td>
                                    <input value={infdTxt[index][1]} onChange={(e) => changeInfdTxt(index, e.target.value, 1)} className={CategoriesCss.input} type="text" placeholder="Enter value/result" />
                                </td>
                                <td>
                                    {index == infdTxt.length - 1 && (
                                        <span className={ResultCss.AddInfdTxt} onClick={() => AddInfdTxt()}><CgAddR /></span>)
                                    }
                                    {
                                        index == infdTxt.length - 1 && infdTxt.length > 1 && (
                                            <span className={ResultCss.rmvInfdTxt} onClick={() => removeInfdTxt()}><FaRegMinusSquare/></span>
                                        )
                                    }
                                </td>
                            </tr>
                        ))
                    )
                )
            }
            {
                inputType == "fixedimg" && (
                    <tr>
                        <td style={{padding: '35px 0'}}></td>
                        <td style={{display: 'flex', flexDirection: 'column', gap: '8px', left: 0, position: 'absolute', maxWidth: '200px'}}>
                            <span>Select image</span>
                            <input ref={uploadedImage} type="file" onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                    const reader = new FileReader();
                                    reader.onload = function (a) {
                                        alert(a.target.result);
                                        //uploadedImage.current.src = a.target.result;
                                        dispatch(setExtra({ sectionId, id, extra: e.target.value, type: 'string' }));
                                    };
                                    reader.readAsDataURL(e.target.files[0]);
                                }
                            }} />
                            {/*<a className={ResultCss.uploadImg} onClick={()=>{
                            
                            if(uploadedImage.current.files.length > 0){
                                setShowLoading(true);
                                let imageToBeUploaded = new FormData();
                                imageToBeUploaded.append('file', uploadedImage.current.files[0]);
                                imageToBeUploaded.append('file_type', 'image');
                                imageToBeUploaded.append('SectionId', sectionId);
                                imageToBeUploaded.append('Id', id);

                                Api.PostFormData('/result/save-template-image', imageToBeUploaded, true)
                                .then(result=>{
                                    setShowLoading(false);
                                    alert(result.message)
                                    if(result.isSuccessful){
                                        dispatch(setExtra({sectionId, id, extra: result.data, type: 'string'}))
                                    }
                                })
                            }
                            else{
                                alert("Please select an image file")
                            }
                        }}>Upload image</a>*/}
                        </td>
                    </tr>
                )
            }
            {typeName == 'Column' && <tr>
                <td></td>
                <td>
                    <label className={CategoriesCss.label} >
                        <input type="checkbox" onChange={(e) => {
                            //alert(e.target.checked)
                            dispatch(setSumVal({ sectionId, id, sumval: e.target.checked }));
                        }} /><span>Add column total</span>
                    </label>
                </td>
            </tr>}
        </>
    );
};


export default AddTemplate;