import { Fragment } from "react";
import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/loading";
import { EditContext } from "./Payments";
import { useNavigate } from "react-router-dom";
import styling from '../../../css/Categories.module.css';
import HTMLReactParser from 'html-react-parser';
import style from '../Calender/Css/event.module.css';
import { FaEdit, FaRegTrashAlt, FaTimes } from "react-icons/fa";
import { MenuContext } from "../DashBoard";
import Api from '../../../helperFunctions/Api2';
import Modal from "../../../components/modal";
import { deletePaymentLink } from "../../../redux/reducers/accountReducer";
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;


const ViewPayments = ({ setTitle }) => {

    const [showLoading, setShowLoading] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalObject, setModalObject] = useState({ header: '', body: '', footer: '' });
    const paymentlinks = Object.values(useSelector(state => state.account.paymentLinks)).sort((a, b) => b.id - a.id);
    const { setEditData, setEditing } = useContext(EditContext);
    const { selected, changeSelected } = useContext(MenuContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);

    const handleChange = (eachnote) => {
        if (eachnote) {
            changeSelected(eachnote.id);
        }
        else {
            changeSelected(null);
        }
    }

    const handleEdit = (links) => {
        setEditData(links);
        setEditing(true);
        navigate("../add-payment-link")
    }

    const handleDelete = (each) => {
        window.confirm('Are you sure you want to delete this note');
        if (each.id) {
            setShowLoading(true);
            Api.PostApi('/payment/delete-payment-link', { id: each.id }, true)
                .then(result => {
                    setShowLoading(false);
                    setModalObject({ header: '', body: result.message, footer: '' });
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        dispatch(deletePaymentLink(each.id))
                    };
                })
                .catch(error => {
                    setShowLoading(false);
                    setModalObject({ header: '', body: error.message, footer: '' });
                    setModalToggle(true);
                })
        }
    }


    useEffect(() => {
        setTitle('View Payment Links')

    }, [setTitle]);
    useEffect(() => {
        if (paymentlinks.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((paymentlinks.length / NO_OF_ITEMS_PER_PAGE) + ((paymentlinks.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [paymentlinks.length]);
    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal closeModal={() => setModalToggle(false)} modalObject={modalObject} modalTogglee={modalToggle} />
            <div className={styling.form}>
                <div className={styling.viewQuestionContainer}>
                    {paymentlinks && paymentlinks.length > 0 ? paymentlinks.map((links, ind) =>
                        ind < (currentPage * NO_OF_ITEMS_PER_PAGE) && ind >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                        <EachLink each={links} handleDelete={handleDelete} handleEdit={handleEdit} handleChange={handleChange} selected={selected} key={ind} />
                    )
                        : <p style={{}}>Create payment links to view Links</p>
                    }
                </div>
                {paymentlinks.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

            </div>
        </>

    )
}

export default ViewPayments;
const EachLink = ({ each, selected, handleChange, handleDelete, handleEdit }) => {
    const serviceArr = useSelector(state => state.account.service)
    const [hamstyle, setHamStyle] = useState('none');
    const [openStyle, setopenStyle] = useState(false);
    const institutions = useSelector(state=>state.questions.user_institutions);

    useEffect(() => {
        if (selected === each.id) {
            setHamStyle('flex')
        }
        else setHamStyle('none')
    }, [selected, each.id])


    return (<Fragment>
        <div className={styling.eachQuestion} style={openStyle ? { height: 'auto', gap: 0 } : { height: '70px' }}>
            <div>
                <span>Name: <span style={{ color: 'var(--primary-color)' }}>{HTMLReactParser(each.title)}</span></span>
            </div>
            <span onClick={() => { handleChange(each) }}>
                <span></span>
                <span></span>
                <span></span>
            </span>
            <div style={{ display: hamstyle }} >
                <span onClick={() => { handleChange() }}><FaTimes /></span>
                <button onClick={() => { handleEdit(each) }}><FaEdit /> Edit</button>
                <button onClick={() => { handleDelete(each) }}><FaRegTrashAlt /> Delete</button>
            </div>
            <div style={openStyle ? { display: 'flex' } : { display: 'none' }} className={style.moreDetails}>
                <div>Service: <span style={{ color: 'var(--primary-color)' }}>{serviceArr.find(service => service.id === each.service_id).name}</span></div>
                <div>Amount: <span style={{ color: 'var(--primary-color)' }}>{each.amount}</span></div>
                <div>Currency: <span style={{ color: 'var(--primary-color)' }}>{each.currency}</span></div>
                <div>Description: <span style={{ color: 'var(--primary-color)' }}>{each.description}</span></div>
                {each.institution_id && <div>Institution: <span style={{ color: 'var(--primary-color)' }}>{institutions.find(inst => inst.id === each.institution_id).name}</span></div>}
            </div>
            <button className={style.eventBtn} onClick={() => { setopenStyle(!openStyle) }}>{!openStyle ? 'More details' : 'less details'} ...</button>



        </div>
    </Fragment>)
}
