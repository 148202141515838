import React,{useState,useEffect} from 'react'
import {EachPrice} from './components';
import css from './css/pricing.module.css';
import { Link } from 'react-router-dom';

const list = [
   {
    name:'CBT SERVICE',
    image:'./images/cbt.png',
    pricing:[
      {
        name:'Free Tier',
        labels:[
          '20 CBT taken per month',
        ]
      },
      {
        name:'Paid Tier',
        labels:[
          '0.49 USD per standard CBT taken',
          '0.79 USD per recorded CBT taken',
          '0.99 USD per AI monitored CBT taken',
          '0.99 USD per live/proctored CBT taken'
        ]
      }
    ]
  },
  // {
  //   name:'EVENT MANAGEMENT',
  //   image:'./images/calender.png',
  //   pricing:[
  //     {
  //       name:'Free',
  //       labels:[
  //         '20 CBT taken per month, l.e commutative sum of free CBT taken per month',
  //       ]
  //     },
  //     {
  //       name:'Mini tier',
  //       labels:[
  //         '0.01 USD per 21 - 199 CBT taken per month '
  //       ]
  //     },
  //     {
  //       name:'Semi-Large tier',
  //       labels:[
  //         '0.008 USD per 200 and above taken per month'
  //       ]
  //     }
  //   ]
  // },
  {
    name:'INSTITUTION MANAGEMENT',
    image:'./images/institution.png',
    pricing:[
      {
        name:'Free Tier',
        labels:[
          'Free for first 4 months',
          // 'Access to User Management',
          // 'Access to payment',
          // 'Access to attendace',
        ]
      },
      {
        name:'Paid Tier',
        labels:[
          '0.2 USD per institution per sub-user per month',
          // 'Access to User Management',
          // 'Access to payment',
          // 'Access to attendace',
        ]
      },
      // {
      //   name:'Semi-Large Tier',
      //   labels:[
      //     '0.46 per institution per sub-user USD per 6months',
      //     // 'Access to User Management',
      //     // 'Access to payment',
      //     // 'Access to attendace',
      //   ]
      // },
      // {
      //   name:'Large Tier',
      //   labels:[
      //     '0.72 USD per year',
      //     // 'Access to User Management',
      //     // 'Access to payment',
      //     // 'Access to attendace',
      //   ]
      // }
    ]
  },
  {
    name:'LEARNING SERVICE',
    image:'./images/learning.png',
    pricing:[
      {
        name:'Free Tier Note',
        labels:[
          `10 free Notes per month`,
          // 'Assignment free',
          '2 free online class with up to 30 participant per month',
          'Assignment free',
        ]
      },
      {
        name:'Paid Tier',
        labels:[
          '0.09 USD per Note',
          '0.1 USD per online class per participant'
          // '11- 25 notes',
        ]
      }
      // {
      //   name:'Mini Tier Online Class',
      //   labels:[
      //     '0.005 USD per months',
      //     // '11- 25 notes',
      //     // 'Assignment free',
      //   ]
      // },
      // {
      //   name:'Semi-Large Tier Online Class',
      //   labels:[
      //     '0.004 USD per 6months',
      //     // '25+ notes',
      //     // 'Assignment free',
      //   ]
      // },
    ]
  },
  // {
  //   name:'ONLINE CLASS',
  //   image:'./images/learning.png',
  //   pricing:[
  //     {
  //       name:'Basic Tier Online Class',
  //       labels:[
  //         '0.79 per participant per meeting ',
  //         // 'Assignment free',
  //       ]
  //     },
  //     {
  //       name:'Mini Tier Online Class',
  //       labels:[
  //         '0.005 USD per months',
  //         // '11- 25 notes',
  //         // 'Assignment free',
  //       ]
  //     },
  //     {
  //       name:'Semi-Large Tier Online Class',
  //       labels:[
  //         '0.004 USD per 6months',
  //         // '25+ notes',
  //         // 'Assignment free',
  //       ]
  //     },
  //   ]
  // },
  // {
  //   name:'PAYMENT SERVICE',
  //   image:'./images/payment.png',
  //   pricing:[
  //     {
  //       name:'Basic Tier',
  //       labels:[
  //         '0.1 USD per month',
  //         // 'Access to User Management',
  //         // 'Access to payment',
  //         // 'Access to attendace',
  //       ]
  //     },
  //     {
  //       name:'Mini Tier',
  //       labels:[
  //         '0.27 USD per 3months',
  //         // 'Access to User Management',
  //         // 'Access to payment',
  //         // 'Access to attendace',
  //       ]
  //     },
  //     {
  //       name:'Semi-Large Tier',
  //       labels:[
  //         '0.46 USD per 6months',
  //         // 'Access to User Management',
  //         // 'Access to payment',
  //         // 'Access to attendace',
  //       ]
  //     },
  //     {
  //       name:'Large Tier',
  //       labels:[
  //         '0.72 USD per month',
  //         // 'Access to User Management',
  //         // 'Access to payment',
  //         // 'Access to attendace',
  //       ]
  //     }
  //   ]
  // },
  {
    name:'PORTAL SERVICE',
    image:'./images/portal.png',
    pricing:[
      {
        name:'Free Tier',
        labels:[
          '5 forms response in a month',
          '1 month free website & subdomain',
          // 'Access to payment',
          // 'Access to attendace',
        ]
      },
      {
        name:'Paid Tier',
        labels:[
          '0.19 USD per form response',
          '19.99 USD per website & subdomain per year',
          // 'Access to payment',
          // 'Access to attendace',
        ]
      },
      // {
      //   name:'Semi-Large Tier',
      //   labels:[
      //     '0.66 USD per webpage per 6months',
      //     // 'Access to User Management',
      //     // 'Access to payment',
      //     // 'Access to attendace',
      //   ]
      // },
      // {
      //   name:'Large Tier',
      //   labels:[
      //     '1.59 USD per webpage per year',
      //     // 'Access to User Management',
      //     // 'Access to payment',
      //     // 'Access to attendace',
      //   ]
      // }
    ]
  },
  // {
  //   name:'QUESTION BANK',
  //   image:'./images/question.png',
  //   pricing:[
  //     {
  //       name:'Basic Tier',
  //       labels:[
  //         '0.1 USD per month',
  //         // 'Access to User Management',
  //         // 'Access to payment',
  //         // 'Access to attendace',
  //       ]
  //     },
  //     {
  //       name:'Mini Tier',
  //       labels:[
  //         '0.27 USD per 3months',
  //         // 'Access to User Management',
  //         // 'Access to payment',
  //         // 'Access to attendace',
  //       ]
  //     },
  //     {
  //       name:'Semi-Large Tier',
  //       labels:[
  //         '0.46 USD per 6months',
  //         // 'Access to User Management',
  //         // 'Access to payment',
  //         // 'Access to attendace',
  //       ]
  //     },
  //     {
  //       name:'Large Tier',
  //       labels:[
  //         '0.72 USD per month',
  //         // 'Access to User Management',
  //         // 'Access to payment',
  //         // 'Access to attendace',
  //       ]
  //     }
  //   ]
  // },
  {
    name:'RESULT MANAGEMENT',
    image:'./images/result.png',
    pricing:[
      {
        name:'Free Tier',
        labels:[
          'templates free',
          '10 results, for free per months',
          // 'Access to User Management',
          // 'Access to payment',
          // 'Access to attendace',
        ]
      },
      {
        name:'Paid Tier',
        labels:[
          '0.29 USD per result',
          // 'Access to User Management',
          // 'Access to payment',
          // 'Access to attendace',
        ]
      },
      // {
      //   name:'Semi-Large Tier',
      //   labels:[
      //     '0.46 USD per 6months',
      //     // 'Access to User Management',
      //     // 'Access to payment',
      //     // 'Access to attendace',
      //   ]
      // },
      // {
      //   name:'Large Tier',
      //   labels:[
      //     '0.72 USD per month',
      //     // 'Access to User Management',
      //     // 'Access to payment',
      //     // 'Access to attendace',
      //   ]
      // }
    ]
  },
  {
    name:'TEST SERVICE',
    image:'./images/test.png',
    pricing:[
      {
        name:'Free Tier',
        labels:[
          '2 free test per month',
          // 'Access to User Management',
          // 'Access to payment',
          // 'Access to attendace',
        ]
      },
      {
        name:'Paid Tier',
        labels:[
          '0.29 USD per test ',
          // 'Access to User Management',
          // 'Access to payment',
          // 'Access to attendace',
        ]
      },
      // {
      //   name:'Semi-Large Tier',
      //   labels:[
      //     '0.46 USD per 6months',
      //     // 'Access to User Management',
      //     // 'Access to payment',
      //     // 'Access to attendace',
      //   ]
      // },
      // {
      //   name:'Large Tier',
      //   labels:[
      //     '0.72 USD per month',
      //     // 'Access to User Management',
      //     // 'Access to payment',
      //     // 'Access to attendace',
      //   ]
      // }
    ]
  }

]

const Pricing = () => {
  const [priceList,setPriceList] = useState([]);

  useEffect(()=>{
    setPriceList(list);
  },[])
  return (
    <React.Fragment>
        <section className={css.pricingContainer}>
          <div className={css.banner}>
              <div></div>
              <div>
                <div>
                  <img src='./images/edu2.png' />
                </div>
                <div>
                  Enjoy amazing sight-eduplus services for free, no credit card required to start. There is a free plan for each service and when you exceed the free plan limit, you only pay for what you use.
                </div>
                <div><Link to='/account/login'>Get Started</Link></div>
              </div>
             
          </div>
          <div className={css.pricingTitle}>
            <h1>Price Listing</h1>
          </div>
          <div className={css.pricingDiv}>
            {priceList.length > 0 ? priceList.map(({name,image,pricing},i) =><EachPrice key={i} name={name} image={image} pricing={pricing} />):''}
          </div>
        </section>
    </React.Fragment>
  )
}



export default Pricing