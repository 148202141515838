import { useState,useEffect, useContext } from 'react';
import Logo from '../user/Logo_img';
import { Link } from "react-router-dom";
import styles from './../../css/SideNav.module.css';
import NavService from '../../components/NavService';
import { BsChevronLeft, BsChevronRight, BsColumnsGap } from 'react-icons/bs';
import {GrClose} from "react-icons/gr"
import { ModalContext } from '../../App';

export const serviceObject = {
    services: [
        {
            serviceName: 'Learning Service',
            subServices: [
                {
                    subService: 'Dashboard',
                    subServiceLink: 'learning'
                },
                {
                    subService: 'Live Class',
                    subServiceLink: 'learning/online'
                },
                // {
                //     subService: 'Communication',
                //     subServiceLink: 'learning/communication'
                // },
                {
                    subService: 'Assignment/Task Mgmt.',
                    subServiceLink: 'learning/assignment'
                },
                {
                    subService: 'Note/Ebook Mgmt.',
                    subServiceLink: 'learning/note'
                },
                {
                    subService: 'Course Management',
                    subServiceLink: 'learning/courses'
                }
            ],
            serviceLink: 'learning',
            serviceIcon: 'bi-book-half'
        },
        {
            serviceName: 'Test Service',
            subServices: [
                {
                    subService: 'Dashboard',
                    subServiceLink: 'question-bank/test-dashboard'
                },
                {
                    subService: 'Test',
                    subServiceLink: 'question-bank/test'
                }
            ],
            serviceLink: 'question-bank/test',
            serviceIcon: 'bi-journal-check'
        },
        {
            serviceName: 'CBT Service',
            subServices: [
                {
                    subService: 'Dashboard',
                    subServiceLink: 'cbt/dashboard'
                },
                {
                    subService: 'CBT',
                    subServiceLink: 'cbt/create-cbt'
                }
            ],
            serviceLink: 'cbt/dashboard',
            serviceIcon: 'bi-ui-radios'
        },
        {
            serviceName: 'Question Bank',
            subServices: [
                {
                    subService: 'Dashboard',
                    subServiceLink: 'question-bank/dashboard'
                },
                {
                    subService: 'Categories',
                    subServiceLink: 'question-bank/categories'
                },
                {
                    subService: 'Questions',
                    subServiceLink: 'question-bank/questions'
                },
                {
                    subService: 'Question Marketplace',
                    subServiceLink: 'question-bank/question-marketplace'
                }
            ],
            serviceIcon: 'bi-bank',
            serviceLink: 'question-bank/dashboard'
        },
        {
            serviceName: 'Result Management',
            subServices: [
                {
                    subService: 'Dashboard',
                    subServiceLink: 'result-management/dashboard'
                },
                {
                    subService: 'Result Template',
                    subServiceLink: 'result-management/result-template'
                },
                {
                    subService: 'Result Set',
                    subServiceLink: 'result-management/result-set'
                },
                {
                    subService: 'Result Group',
                    subServiceLink: 'result-management/result-group'
                },  
                {
                    subService: 'Users Result',
                    subServiceLink: 'result-management/result'
                },
                // {
                //     subService: 'Report',
                //     subServiceLink: 'result-management/report'
                // }
                
            ],
            // serviceLink: 'coming-soon',
            serviceIcon: 'bi-award'
        },
        {
            serviceName: 'Institution Management',
            subServices: [
                {
                    subService: 'Dashboard',
                    subServiceLink: 'institution-management/dashboard'
                },
                {
                    subService: 'Institution',
                    subServiceLink: 'institution-management/institution'
                },
                {
                    subService: 'Group Management',
                    subServiceLink: 'institution-management/group-management'
                },
                {
                    subService: 'Users Management',
                    subServiceLink: 'institution-management/user-management'
                },
                {
                    subService: 'Attendance Management',
                    subServiceLink: 'institution-management/group-attendance'
                },
                {
                    subService: 'Timetable',
                    subServiceLink: 'institution-management/timetable'
                }
            ],
            serviceIcon: 'bi-building'
        },
        {
            serviceName: 'Payment Service',
            subServices: [
                {
                    subService: 'Dashboard',
                    subServiceLink: 'payments/dashboard'
                },
                {
                    subService: 'Billing',
                    subServiceLink: 'payments/billing/add'
                },
                {
                    subService: 'Payment Links',
                    subServiceLink: 'payments/payments'
                },
                {
                    subService: 'Withdrawals',
                    subServiceLink: 'payments/withdrawals'
                }
                // ,
                // {
                //     subService: 'Reports',
                //     subServiceLink: 'service-1'
                // }
            ],
            // serviceLink: 'payments',
            serviceIcon: 'bi-credit-card'
        },
        {
            serviceName: 'Calendar/Event Management',
            subServices: [
                {
                    subService: 'Dashboard',
                    subServiceLink: 'event-management/dashboard'
                },
                {
                    subService: 'Calender/Events',
                    subServiceLink: 'event-management/events'
                },
                {
                    subService: 'Appointments',
                    subServiceLink: 'event-management/appointments'
                },
                // {
                //     subService: 'Message',
                //     subServiceLink: 'event-management/messages'
                // },
                {
                    subService: 'Email settings',
                    subServiceLink:'event-management/email-settings'
                },
                // {
                //     subService: 'SMS settings',
                //     subServiceLink:'event-management/sms-settings'
                // }
            ],
            serviceLink: 'event-management/dashboard',
            serviceIcon: 'bi-calendar-event'
        },
        {
            serviceName: 'Portal/Website Service',
            subServices: [
                {   
                    subService: 'Dashboard', 
                    subServiceLink: 'portal/dashboard'
                },
                {   
                    subService: 'Forms', 
                    subServiceLink: 'portal/forms/forms'
                },
                {   
                    subService: 'Websites', 
                    subServiceLink: 'portal/website'
                },
                {   
                    subService: 'Customize EduPlus', 
                    subServiceLink: 'portal/customize'
                },
            //     {
            //         subService: 'Manage Your Website',
            //         subServiceLink: 'service-4'
            //     }
            ],
            // serviceLink: 'portal',
            serviceIcon: 'bi-layout-wtf'
        }
    ]
};
const sortedServices = [...serviceObject.services.map(el => el.serviceName + " " + serviceObject.services.indexOf(el)
)];


const SideNav = ({ showDetail, setShowDetail, showSidebar, setShowSidebar }) => {

    const {customizeData} = useContext(ModalContext);
    const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 767 && window.innerWidth > 500);

    window.addEventListener('resize', () => {
        setSmallScreen(window.innerWidth <= 767 && window.innerWidth > 500);
    });

    const [screenSize,setScreenSize] = useState(window.innerWidth);

    const [index, setIndex] = useState(-1);
    const toggleDetail = () => {
        setShowDetail(!showDetail);
    };

    useEffect(()=>{
        const handleScreenSize2 = () =>{
            setScreenSize(window.innerWidth);
        }
        window.addEventListener('resize',handleScreenSize2);
        handleScreenSize2();
        return ()=>window.removeEventListener('resize',handleScreenSize2);
    },[])

    useEffect(()=>{
        if(screenSize > 500){
            setShowSidebar(true);
        }
        else{
            setShowSidebar(false);
        }
    },[screenSize])

    return (
        <>
            <div className={`${styles.navParent}`} style={{display: showSidebar? `flex`: `none`}}>
                {(smallScreen && showDetail) && <div className={`${styles.displayToggler}`} onClick={toggleDetail} title={showDetail ? 'Collapse the Side Bar' : 'Expand the Side Bar'}>
                    {showDetail ? <BsChevronLeft /> : <BsChevronRight />}
                </div>}
                <span onClick={()=> setShowSidebar(false)} className={styles.closeSidebar}><GrClose /></span>
                <div className={`${styles.logoDiv}`}>
                    <div className={`${styles.logo}`}>
                        <Link to='/'><Logo smallScreen={true} /></Link>
                    </div>
                    {showDetail && <h1 className={`${styles.logoText}`}>{customizeData && customizeData.displayName? customizeData.displayName : 'EduPlus'}{/*<sub style={{fontSize: 10}}>(1.0)</sub>*/}</h1>}
                </div>
                <div className={`${styles.serviceMenu}`}>
                    <div className={`${styles.dashDiv}`} title='Dashboard'>
                        <div className={`${styles.dashLogo}`} >
                            <Link to=''> <BsColumnsGap /> </Link>
                        </div>
                        {showDetail && <Link to='' className={`${styles.dashText}`}>Dashboard</Link>}
                    </div>

                    <div className={showDetail ? `${styles.allServices}` : `${styles.serviceDivCollapsed} ${styles.allServices}`}>
                        {showDetail ? <h3 className={`${styles.allText}`}>All Services</h3> : <h3 className={`${styles.allText}`}>&nbsp;</h3>}
                    </div>
                </div>
                <div className={!showDetail ? `${styles.serviceDivCollapsed} ${styles.navServicesDiv}` : `${styles.navServicesDiv}`}>
                    {
                        sortedServices.sort().map((service, i) => {
                            const serviceIndex = parseInt(service.slice(service.length - 2, service.length));

                            return <NavService key={i} showDetail={showDetail} serviceName={serviceObject.services[serviceIndex].serviceName} subServices={serviceObject.services[serviceIndex].subServices ? serviceObject.services[serviceIndex].subServices : false} serviceLink={serviceObject.services[serviceIndex].serviceLink ? serviceObject.services[serviceIndex].serviceLink : false} serviceIcon={serviceObject.services[serviceIndex].serviceIcon} thisIndex={i} index={index} setIndex={setIndex} />;
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default SideNav;