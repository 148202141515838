import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import Select from '../../../components/Select';
import { IoReloadCircleSharp as ReloadIcon } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/Button';
import { CbtModalContext } from './cbt';
import restApi from '../../../helperFunctions/Api2';
import Loading from '../../../components/loading';
import { BarChart, DoughnutChart } from '../../../components/Charts';
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30

const colors = {
  passColor: 'rgba(10,230,50,0.9)',
  failedColor: 'rgba(220,10,50,0.9)',
  absendColor: 'rgba(100,100,100,0.9)',
}

const ViewResult = ({ setTitle }) => {
  const cbts = useSelector(store => store.cbt.cbts);
  const [cbt_user, set_cbt_user] = useState([]);
  const [active_cbt, set_active_cbt] = useState(null);
  const [active_user, set_active_user] = useState(null);
  const [list, set_list] = useState([]);
  const [reset, set_reset] = useState(false);
  const [menuIndex, setMenuIndex] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [show_result, set_show_result] = useState(true);
  const [show_analysis, set_show_analysis] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(0);



  useEffect(() => {
    setTitle('View CBT result');
  }, [setTitle])
  useEffect(() => {
    if (active_cbt) {
      // console.log(active_cbt);
      set_cbt_user(active_cbt.users)
      set_active_user(active_cbt.users)

    } else {
      set_cbt_user([]);
      set_active_user(null)
    }
    setMenuIndex(null);
  }, [active_cbt]);
  useEffect(() => {
    if (active_user) {
      if (Array.isArray(active_user)) {
        set_list([...active_user]);
      } else {
        set_list([active_user])
      }
      //console.log(active_user)
    }
    else {
      set_list([]);
    }
  }, [active_user])
  useEffect(() => {
    if (list.length > NO_OF_ITEMS_PER_PAGE) {
      setPageNum((list.length / NO_OF_ITEMS_PER_PAGE) + ((list.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
    }
  }, [list.length]);
  return (
    <>
      <Loading shouldShow={showLoading} />
      <Wrapper>
        <div>
          <div>
            <span>Select Cbt</span>
            <Select options={cbts} onChange={set_active_cbt} placeholder="Select Cbt" label="title" value="id" reset={reset} />
          </div>
          <div>
            <span>Select User</span>
            <Select options={cbt_user} onChange={set_active_user} placeholder="Select User" label="email" value="id" reset={reset} />
          </div>
          <ReloadBtn onClick={() => set_reset(!reset)}>
            <ReloadIcon />
          </ReloadBtn>
        </div>
        <p>
          <label>
            <input type="checkbox" checked={show_result} onChange={() => set_show_result(!show_result)} />
            <span>Show Results</span>
          </label>
          <label>
            <input type="checkbox" checked={show_analysis} onChange={() => set_show_analysis(!show_analysis)} />
            <span>Show General Analysis</span>
          </label>
        </p>
        <div>
          {list && list.length > 0 && show_result ? list.map((eUser, index) =>
            index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
            <EachUser key={index} eUser={eUser} index={index} menu={{ menuIndex, setMenuIndex }} setShowLoading={setShowLoading} />
          ) : list.length === 0 || !show_result ? '' : list.length === 0 && show_result ? 'Result Not Available' : ''}
        </div>
        <div>
          {active_cbt && active_cbt.analytics && show_analysis ?
            <GeneralAnalysis data={active_cbt.analytics} cbt={active_cbt} />
            : active_cbt && !active_cbt.analytics && show_analysis ? 'Analytics Not Available'
              : active_cbt && (!active_cbt.analytics || !show_analysis) ? ''
                : ''}
        </div>
        {list.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

      </Wrapper>
    </>

  )
}

export default ViewResult;

const Wrapper = styled.div`
  padding:0 20px 20px;
  width:100%;
  & > div:first-of-type{
    display:flex;
    gap:10px;
    align-items: flex-end;
    @media only screen and (width <= 400px) {
      flex-wrap: wrap;
      position: relative;
      > button{
        position: absolute;
        right: -15px;
        top: -35px;
        font-size: 35px;
      }
    }
  }
  & > div:first-of-type > div{
    width:calc(50% - 35px);
    @media only screen and (width <= 400px) {
      width: 100%;
    }
  }
  & > p{
    display: flex;
    gap:20px;
    align-items: center;
    @media only screen and (width <= 300px) {
      flex-wrap: wrap;
    }
  }
  & > p > label{
    display: flex;
    gap:10px;
    align-items: center;

  }
  & > div:nth-of-type(2){
    display:flex;
    flex-direction: column;
    gap:20px;
    width:100%;
    margin-top:20px;
  }

`

const StyledEachUser = styled.div`
    width:100%;
    padding:10px 20px 20px;
    border:none;
    box-shadow:var(--box-shadow);
    border-radius: 8px;

    & > div{
      width:100%;
      ${'' /* display:flex; */}
      align-items: center;
      gap:20px;
      margin-top:5px;
      @media only screen and (width <= 400px) {
        flex-wrap: wrap;
        gap: 0px;
       word-break: break-all;
      }
    }
    & > div > span:nth-of-type(2){
       color:var(--primary-color);
    }

`

const ReloadBtn = styled.button`
  
  margin-left:auto;
  width:55px;
  height:55px;
  background-color: transparent;
  color:var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size:45px;
  outline:none;
  border:none;


`

export const EachUser = (props) => {
  const { setModalTogglee, setModalObject } = useContext(CbtModalContext);
  const setShowLoading = props.setShowLoading;
  const index = props.index;
  const eUser = props.eUser;
  const menuIndex = props.menu.menuIndex;
  const onChange = props.menu.setMenuIndex;
  const analytics = JSON.parse(eUser.analytics || '{}');
  const hasEnded = eUser.have_ended;


  const calTimeSpent = (t) => {
    // console.log(t,'t');
    const time = t.toString();
    let hour = (time.split(':')[0] / 60).toFixed(0);
    let min = (time.split(':')[0] % 60).toFixed(0);
    let sec = (time.split(':')[1]);

    return hour > 0 ?
      `${hour}hours ${min}min ${sec}sec` :
      `${min}min ${sec}sec`
      ;
  }

  const handleSeeMore = () => {
    if (index === menuIndex) onChange(null);
    else {
      onChange(index);
    }
  }

  const setRetake = () => {
    const id = eUser.id;
    const cbt_id = eUser.cbt_id;
    const obj = {
      id,
      cbt: {
        id: cbt_id,
      }
    }
    if (!restApi.checkNetwork()) return
    setModalTogglee(false);
    setShowLoading(true);

    restApi.PostApi('/test/retake-cbt', obj, true)
      .then(result => {
        setShowLoading(false);
        setModalObject({ header: '', body: result.message, footer: '' });
        setModalTogglee(true);

      })
      .catch(err => {
        setShowLoading(false);
        setModalObject({ header: '', body: err.message, footer: '' });
        setModalTogglee(true);
      })
  }

  const handleRetake = () => {
    setModalObject({
      header: 'Message',
      body: <p>
        Are you sure you want {eUser.email} to retake this test
      </p>,
      footer: <div>
        <Button label="Ok" onClick={setRetake} />
        <Button label="Cancel" onClick={() => setModalTogglee(false)} bg="var(--white-color)" color="var(--secondary-color)" />
      </div>
    })
    setModalTogglee(true);
  }
  // useEffect(()=>{
  //   if(eUser && eUser.analytics)console.log(JSON.parse(JSON.parse(eUser.analytics)).timeSpent,'analytics')
  // },[eUser])


  const getAnalystics = (key) => {
    if (!props.eUser.analytics) return "not Available";
    const analytics = JSON.parse(eUser.analytics);
    // console.log(analytics,'analytics');
    return analytics[key] ? analytics[key] : 'Not Available';
  }
  return (
    <StyledEachUser>
      <div>
        <span>Username: </span>
        <span>{eUser.email}</span>
      </div>
      <div>
        <span>Started CBT: </span>
        <span>{eUser.analytics || eUser.has_ended || eUser.score || eUser.cbt_state ? 'Yes' : 'No'}</span>
      </div>
      <div>
        <span>Ended CBT: </span>
        <span>{eUser.have_ended ? 'Yes' : 'No'}</span>
      </div>
      <div>
        <span>CBT Score: </span>
        <span>{isNaN(eUser.score) ? 'Not Available' : eUser.score}</span>
      </div>
      {index === menuIndex && analytics && Object.keys(analytics).length > 0 ?
        <>
          <div>
            <span>Total Correct Answers: </span>
            <span>{typeof getAnalystics('passed') === 'string' ? getAnalystics('passed') : getAnalystics('passed').length}</span>
          </div>
          <div>
            <span>Total Wrong Answers: </span>
            <span>{typeof getAnalystics('failed') === 'string' ? getAnalystics('failed') : getAnalystics('failed').length}</span>
          </div>
          <div>
            <span>Time spent: </span>
            <span>{eUser.analytics ? calTimeSpent(getAnalystics("timeSpent")) : 'Not Available'}</span>
          </div>
          <div>
            <span>Set retake: </span>
            <span>
              <Button onClick={handleRetake} style={{ padding: "5px" }} hBg="var(--secondary-color)" hColor="var(--white-color)" bg="var(--white-color)" color="var(--secondary-color)">Enable Retake</Button>
            </span>
          </div>

        </> : ''
      }
      {hasEnded ? <div>
        <span onClick={handleSeeMore} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}>
          {index === menuIndex ? 'See Less' : 'See More...'}
        </span>
      </div> : ''}


    </StyledEachUser>
  )
}

export const GeneralAnalysis = (props) => {
  const [score_data, set_score_data] = useState(null);
  const [time_data, set_time_data] = useState(null);
  const [percentile_data, set_percentile_date] = useState(null);
  const [doughnut_data, set_doughnut_data] = useState(null);
  const data = JSON.parse(props.data);
  const containerRef = useRef();
  const containerRef2 = useRef();
  const cbt = props.cbt;
  const analytics = JSON.parse(cbt.analytics || '{}');
  console.log(cbt, 'cbt');
  useEffect(() => {
    let s_data = {
      labels: ['Highest Score', 'Average Score', 'Lowest Score'],
      datasets: [
        {
          label: 'Score Chart',
          data: [data.highestScoreDetails.score, data.averageScore, data.lowestScoreDetails.score],
          backgroundColor: [
            '#8884d8', 'var(--secondary-color)',
            '#999',

          ],
          borderWidth: 1,
        }
      ]
    }
    set_score_data(s_data);
    // let temp_data = {
    //   shortest_time:0,
    //   average_time:0,
    //   longest_time:0
    // }
    cbt.users.forEach(eUser => {
      if (eUser.analytics) {
        const p_analytics = JSON.parse(eUser.analytics);
        console.log(p_analytics, 'p_analytics')
      }
    });

    let t_data = {
      labels: ['Shortest Time', 'Average Time', 'Longest Time'],
      datasets: [
        {
          label: 'Time Chart',
          data: [30, 60, 70],
          backgroundColor: [
            '#8884d8', 'var(--secondary-color)',
            '#999',

          ],
          borderWidth: 1,
        }
      ]
    }
    set_time_data(t_data);
    let p_d = [20, 11, 12, 4, 5, 23, 8, 4, 9, 19, 2];

    p_d = (analytics.rangeScore && Object.values(analytics.rangeScore).map(eRange => eRange.length)) || [];
    // console.log(p_d,'range');

    const p_data = {
      labels: ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90-99', '100'],
      datasets: [
        {
          label: 'Score Chart',
          data: p_d,
          backgroundColor: [
            '#f00', '#ec3b0e',
            '#f55c04', '#e2bb0f',
            '#999e6c', '#525348',
            '#8884d8', '#176fc2',
            '#16b4b9', '#0e7973',
            '#08af48'
          ],
          borderWidth: 1,
        }
      ]
    }
    set_percentile_date(p_data)

    const d_data = {
      labels: ['Passed Users', 'Failed Users', 'Absent Users'],
      datasets: [
        {
          label: 'Pass/Fail Chart',
          data: [analytics.passUsers.length, analytics.failedUsers.length, (cbt.users.length - data.cbtTakersNum)],
          backgroundColor: [
            `${colors.passColor}`,
            `${colors.failedColor}`,
            `${colors.absendColor}`
          ],
          borderWidth: 1,
          cutout: '50%',
        }
      ]
    }
    set_doughnut_data(d_data);

    const handleResize = () => {
      if (containerRef.current) {
        let c_width = containerRef.current.clientWidth;
        // console.log(containerRef.current.clientWidth);
        if (c_width <= 600) {
          containerRef.current.style = `
           grid-template-columns:1fr
         `
        }
        else {
          containerRef.current.style = `
           grid-template-columns:1fr 1fr
         `
        }
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <AnalysisWrapper>
      <p>General Result Analysis</p>
      <div>
        <div>
          <span>Total Numbers of Users:</span>
          <span>{cbt.users.length}</span>
        </div>
        <div>
          <span>Total Numbers of Tested Users:</span>
          <span>{data.cbtTakersNum}</span>
        </div>
        <div>
          <span>Total Passed Users:</span>
          <span>{analytics.passUsers.length}</span>
        </div>
        <div>
          <span>Total Failed Users:</span>
          <span>{analytics.failedUsers.length}</span>
        </div>
      </div>
      <div ref={containerRef}>
        <div>
          <BarChart data={score_data} />
        </div>
        <div>
          <BarChart data={time_data} />
        </div>
      </div>
      <div ref={containerRef2}>
        <div>
          <BarChart data={percentile_data} />
        </div>
      </div>
      <div>
        <div>
          <DoughnutChart doughnut_data={doughnut_data} />

        </div>
      </div>
    </AnalysisWrapper>
  )
}

const AnalysisWrapper = styled.div`
  padding:10px 20px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  background-color: var(--white-color);
  margin-top:15px;
  display: flex;
  flex-direction: column;
  gap:15px;
  & > p{
    flex:1;
    margin:0;
    font-weight: bold;
  }
  & > div:first-of-type{
      display: grid;
      grid-template-columns:1fr;
      gap:10px;
  }
  & > div:first-of-type > div{
    display: flex;
    gap:20px;
  }
  & > div:first-of-type > div > span:last-of-type{
    color:var(--primary-color);
  }
  & > div:nth-of-type(2){
    display: grid;
    grid-template-columns:1fr 1fr ;
    gap:20px;
  }
  & > div:nth-of-type(2) > div{
    display: flex;
    border:1px solid var(--border-color);
    border-radius: 5px;
  }
  & > div:nth-of-type(2) > div > canvas{
    width: 100% !important;
    height: 100% !important;
  }
  & > div:nth-of-type(3){
    display: flex;
    flex:1;
  }
  & > div:nth-of-type(3) > div{
    display: flex;
    flex:1;
    border:1px solid var(--border-color);
    padding:20px;
    border-radius: 6px;
  }
  & > div:nth-of-type(3) > div > canvas{
    width:100% !important;
    height: 100% !important;
    /* height: 250px !important; */
    /* object-fit: contain; */
  }
`

// export const UserAnalysis = (props) =>{
//   const data = JSON.parse(props.data);
//   return (
//     <>


//     </>
//   )
// }

