import{ useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import styles from './../css/NavService.module.css';
import './../css/bootstrapIcons/bootstrap-icons.css';

const NavService = ({ showDetail, serviceName, subServices, serviceLink = false, serviceIcon, thisIndex, index, setIndex }) => {
    const [isCollapse, setIsCollapse] = useState(false);

    const collapse = () => {
        setIsCollapse(!isCollapse);
        setIndex(thisIndex);
    };

    useEffect(() => {
        if (Number(index) !== Number(thisIndex)) {
            setIsCollapse(false);
        }
    }, [thisIndex, index]);

    return (
        <div className={`${styles.navService} ${Number(index) === Number(thisIndex) ? styles.expanded : ''}`} >
            <div className={`${styles.serviceLabel}`} title={serviceName} onClick={collapse}>
                <div className={`${styles.serviceLogo}`} >
                    <Link to={serviceLink ? `${serviceLink}` : subServices && subServices[0] ? `${subServices[0].subServiceLink}` : ''} >
                        <em className={serviceIcon} />
                    </Link>
                </div>
                {showDetail &&
                    <div className={`${styles.serviceDes}`}  >
                        <span className={`${styles.collapsible}`}>{serviceName.length > 20 ? `${serviceName.slice(0, 21)}...` : serviceName}</span>
                        {subServices &&
                            <span onClick={collapse}>
                                {!isCollapse ?
                                    <BsChevronDown className={`${styles.collapsible}`} /> :
                                    <BsChevronUp className={`${styles.collapsible}`} />}
                        </span>}
                    </div>}
            </div>
            {showDetail &&
                <>
                    {subServices && isCollapse &&
                        <div className={`${styles.subServices} ${styles.expandedList}`}>
                            {subServices.map((item, index) => {
                                return <Link key={index} to={`${item.subServiceLink}`} className={`${styles.serviceLink}`} title={item.subService}>{item.subService.length > 20 ? `${item.subService.slice(0, 21)}...` : item.subService}</Link>;
                            })}
                        </div>
                    }
                </>}

        </div>
    );
};

export default NavService;