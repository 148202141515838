
import React,{useState,useEffect,createContext,useContext} from 'react';
import styles from '../../../css/CreateInst.module.css';
import eventstyle from './Css/event.module.css'
import { eventModalContext } from './msgSettings';
import { useSelector,useDispatch } from 'react-redux';
import Loading from '../../../components/loading';
import Select from '../../../components/Select';



const commsArr = [
    {value:'0', label:'Through Eduplus'},
    {value:'1', label:'Through Email'},
    {value:'2', label:'Through SMS'}
 ]

 let testId = Math.floor(Math.random()*1000 + 1);


const AddMsg = () => {

    const [showLoading, setShowLoading] = useState(false);
    const [msgTitle, setMsgTitle] = useState('');
    const [msgBody, setMsgBody] = useState('');
    const [communicationArr, setCommunicationArr] = useState([]);
    const [checkedEvent, setCheckedEvent] = useState(true);
    const [checkEmails, setCheckEmails] = useState(false);
    const [checkSms, setCheckSms] = useState(false);
    const [emailList, setEmailList] = useState([]);
    const [institutionArr, setInstitutionArr] = useState([]);
    const [categoryArr, setCategoryArr] = useState([]);
    const [msgChecked, setMsgChecked] = useState(true);
    const [timeChecked, setTimeChecked] = useState(false);
    const [preferedDate, setPreferredDate] = useState('');
    const [preferedTime, setPreferredTime] = useState('');
    const [sms, setSms] = useState([]);
    const [selInstitution, setselInstitution] = useState(null);
    const [userCategory,setuserCategory] = useState(null);
    const [selComms, setSelComms] = useState(null);

    const dispatch = useDispatch();
    const institution = useSelector(state => state.questions.user_institutions);
    const category = useSelector(state=> state.questions.user_categories);
    const emailGroup = useSelector(state =>state.questions.user_emailGroup);
    const smsgroup = useSelector(state => state.questions.user_sms);
    const {setModalTogglee, setModalObject} = useContext(eventModalContext);


    const validateValue = (value,body) => {
        if(value === null || value === '' || value === 'undefined' ){
             setModalObject({header:'Message Draft could not be created',body:`PLEASE SET ${body}`,footer:''});
             setModalTogglee(true);
             return false;
        }
        return true;
    }
    

    const validateCommsArr = (arr,body) => {
        if(arr.length <= 0)  {
           setModalObject({header:'Message Draft could Not be Created',body:`SELECT ${body}`,footer:''});
           setModalTogglee(true);
           return false;
        }
        return true;
        /*if(institutionArr.length <= 0)  {
            setModalObject({header:'Message Draft could Not be Created',body:`SELECT Institution`,footer:''});
            setModalTogglee(true);
            return false;
         }
         if(categoryArr.length <= 0)  {
            setModalObject({header:'Message Draft could Not be Created',body:`SELECT Category for Institution`,footer:''});
            setModalTogglee(true);
            return false;
         }**/
     
        
      }
      

    const handleSubmit = (e) => {
        e.preventDefault();

        if(!validateValue(msgTitle, 'Messae Title')){
            return false
        }
        if(!validateValue(msgBody, 'Message Body')) {
            return false;
        }
        
        if(checkedEvent && !validateCommsArr(institutionArr, 'Institution') && !validateCommsArr(categoryArr, 'User Group'))  {
            return false;
        }
        if(checkEmails && !validateCommsArr(emailList, 'an Email Group')) {
           return false;
        }
        if(checkSms && !validateCommsArr(sms, 'SMS Group')) {
            return false;
        }
        if(timeChecked && !validateValue(preferedDate,  'Prefered date')) {
            return false;
       }
        if(timeChecked && !validateValue(preferedTime, 'Prefered time')) {
            return false;
         }
        else {
            const msgObj = {};
            msgObj.id = testId++;
            msgObj.title = msgTitle;
            msgObj.body = msgBody;
            msgObj.means_of_communication = communicationArr;
            msgObj.select_Institution = checkedEvent;
            msgObj.categoryId = userCategory.value;
            msgObj.selectedInstitutionId = selInstitution.value;
            msgObj.importEmails = checkEmails;
            msgObj.selectedEmailGroup = emailList;
            msgObj.importSms = checkSms;
            msgObj.selectedSmsGroup = sms;
            msgObj.sendByDefault = msgChecked;
            msgObj.sendByPreference = timeChecked;
            msgObj.prefered_time = `${preferedTime} ${preferedDate}`;

            setShowLoading(true);
            console.log(msgObj);
            setShowLoading(false);
            setModalObject("Messages created");
            setModalTogglee(true);
                    
           

        }
    }
     
    useEffect(() => {
       setInstitutionArr(institution);
    },[institution])

    useEffect(()=>{
      if(selInstitution){
         let newCategory = Object.values(selInstitution.categories).length > 0 ?Object.values(selInstitution.categories):[{id:0,name:'No group in this institution'}];
         setCategoryArr(newCategory);
      }
   },[selInstitution])
    return ( 
        <>
        <Loading  shouldShow={showLoading}/>
         <form className={`${styles.form} ${styles.form2}`} >
            <label className={`${styles.label}`}>
                <span className={styles.isRequired}>Message Title</span>
                <input name='eventName' value={msgTitle ?msgTitle:'' } className={`${styles.fields}`} type='text' placeholder="Message Title" onChange={(e)=>{setMsgTitle(e.target.value)}}/>
            </label>
            <label className={`${styles.label}`}>
                <span className={styles.isRequired}>Message Body</span>
                <textarea name='eventName' value={msgBody ?msgBody:'' } className={`${styles.fields}`} type='text' placeholder="Message Title" onChange={(e)=>{setMsgBody(e.target.value)}}/>
            </label>
            <label className={`${styles.label} `}>
                   <span className={styles.isRequired}>Audience</span>
             </label>
            <span className={eventstyle.pre}>
            <span className={eventstyle.check}>
                <input name='institution'   type='checkbox' value={checkedEvent ? checkedEvent: ''} checked={checkedEvent} onChange={() => {setCheckedEvent(!checkedEvent)} }/>{'Select from Institution'}
            </span>
            {checkedEvent ? 
              <>
              <label className={`${styles.label}`}>
              <span className={styles.isRequired}>Select Institution</span>
                {<Select name='calenderInsti'  options={institutionArr} onChange={setselInstitution} className={styles.selectFields} defaultValue=""  value='id' label='name'/>}
              </label>
                <label className={`${styles.label}`}>
                <span className={styles.isRequired}>Select User Groups for institution</span>
                  {<Select name='calenderInsti' options={categoryArr} onChange={setuserCategory} className={styles.selectFields} defaultValue="" value='id' label='name' />}
                </label> 
              </> : ''
            }
            <span className={eventstyle.check}>
                <input name='institution'  type='checkbox' onChange={(e) => {setCheckedEvent(checkedEvent)} }/>{'Import users from Excel'}
            </span>
            <span className={eventstyle.check}>
                <input name='institution'  type='checkbox'  onChange={(e) => {setCheckEmails(!checkEmails)} }/>{'Import users from Email'}
            </span>
            {checkEmails ? 
              <>
              <label className={`${styles.label}`}>
              <span className={styles.isRequired}>Select Email Group</span>
                {<Select name='calenderInsti'  options={emailGroup} onChange={setEmailList} className={styles.selectFields} defaultValue="" value='Id' label='groupName'  />}
              </label>
              </>

            : ''
            }
            <span className={eventstyle.check}>
                <input name='institution'  type='checkbox' onChange={(e) => {setCheckSms(!checkSms)} }/>{'Import users from SMS'}
            </span>
            
            {checkSms ? 
              <>
                <label className={`${styles.label}`}>
                <span className={styles.isRequired}>Select SMS Group</span>
                  {<Select name='calenderInsti'  options={smsgroup} onChange={setSms} className={styles.selectFields} defaultValue=""  multi value='id' label='groupName'  />}
                </label>
              </>
                : ''
            }
            </span>
                    <label className={`${styles.label}`}>
                      <span className={styles.isRequired}>Message Execution</span>
                    </label>
                    <span className={eventstyle.pre}>
                        <span className={`${eventstyle.check}` }>
                           <input name='institution' value={msgChecked} type='radio' checked={msgChecked} onChange={(e) => {
                              if(!msgChecked) {
                                 setMsgChecked(!msgChecked);
                                 setTimeChecked(false)
                              }
                           } }/>{'Send Message Immediately'}
                        </span>
                        <span className={eventstyle.check}>
                           <input name='institution' value={timeChecked} checked={timeChecked} type='radio' onChange={(e) => {
                              if(!timeChecked){
                                 setTimeChecked(!timeChecked)
                                  setMsgChecked(false)
                              }} }/>{'Send Message at a Preferred Time'}
                        </span>
                    </span>
                    {timeChecked ?
                   <>
                   <label className={`${styles.label2}`}>
                     <span className={styles.isRequired}>Preferred Date</span>
                     <input name='eventenddate'type='date'  value={preferedDate} className={`${styles.fields}`}  onChange={(e) => {setPreferredDate(e.target.value)} }/>
                   </label>
                    <label className={`${styles.label2}`}>
                    <span className={styles.isRequired}>Preferred Time</span>
                    <input name='eventenddate'type='time' value={preferedTime} className={`${styles.fields}`}  onChange={(e) => {setPreferredTime(e.target.value)} }/>
                   </label>
                   </> : ''
                  }
                    <div className={`${styles.addBtnDiv} ${styles.div}`}>
                        <button type="submit" className={styles.addBtn} 
                        onClick={(e) => {handleSubmit(e)} }>Create Message</button>
                    </div>
         </form>
        </>
     );
}
 
export default AddMsg;