import { createContext, useContext, useState } from 'react';
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { BsList } from 'react-icons/bs';
import { MenuContext } from '../DashBoard';
import CreateForm from './CreateForm';
import ViewForms from './ViewForms';
import FormUsers from './FormUsers';

export const FormContext = createContext();

const Form = () => {
    const { showMenu, setShowMenu } = useContext(MenuContext);
    const [title, setTitle] = useState('');
    const [editData, setEditData] = useState({});
    const [editing, setEditing] = useState(false);
    const clearEdit = () => {
        setEditData({});
        setEditing(false);
    }
    return (
        <FormContext.Provider value={{ setTitle, editData, setEditData, editing, setEditing, clearEdit }}>
            <div className={`${styles.subView}`}>
                <div className={titleStyle.sectionTitle}>
                    <h3 className={titleStyle.h3}>
                        {title}
                    </h3>
                    <div className={`${titleStyle.menuDiv}`}>
                        <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                        {showMenu &&
                            <div className={`${titleStyle.subMenu}`} >
                                <ul>
                                    <li onClick={() => {clearEdit()}}><Link to='create-form'>Create Form</Link></li>
                                    <li onClick={() => clearEdit()}><Link to='view-forms'>View Form</Link></li>
                                    <li onClick={() => clearEdit()}><Link to='form-users'>Form Users</Link></li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <Routes>
                    <Route index element={<CreateForm />} />
                    <Route path='/create-form' element={<CreateForm />} />
                    <Route path='/view-forms' element={<ViewForms />} />
                    <Route path='/form-users' element={<FormUsers />} />
                    <Route path="/*" element={<PageNotFound />} />
                </Routes>
            </div>
        </FormContext.Provider>

    );
};

export default Form;