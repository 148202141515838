import React, { useState,useRef, useEffect } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
//import {MdMenu as MenuIcon} from 'react-icons/md';
import { BsList as MenuIcon } from 'react-icons/bs'


const Menu = (props) => {
    const options = props.options || [];
    const [showList,setShowList] = useState(false);
    const spanRef = useRef();
    const divRef = useRef();
    const handleClose = ()=>{
        setShowList(false);
    }

    useEffect(()=>{
        const handleOutsideClick = (e)=>{
            if(spanRef && spanRef.current && divRef && divRef.current){
                const spanDimensions = spanRef.current.getBoundingClientRect();
                const divDimensions = divRef.current.getBoundingClientRect();
                if( e.clientX < divDimensions.left ||
                    e.clientX > spanDimensions.right ||
                    e.clientY < spanDimensions.top ||
                    e.clientY > divDimensions.bottom){
                        handleClose();
                }
                
            }
        }
        window.addEventListener('click',handleOutsideClick);

        return ()=> window.removeEventListener('click',handleOutsideClick);
    },[])

  return (
    <Wrapper>
        {/* {showList ? <div onClick={()=>setShowList(false)} ></div> :''} */}
        <span onClick={()=>{setShowList(!showList)}} ref={spanRef}>
            <MenuIcon />
            <span>Menu</span>
            
        </span>
        {showList ? <div ref={divRef}>
            {options && options.length > 0 ?
            options.map(({label,path,onClick=()=>{}},index) => <Link to={path|| '#'} onClick={onClick} key={index}>{label}</Link>)
        :''}
        </div> : ''}


    </Wrapper>
  )
}

export default Menu;

const Container = (props) =>{
    const children = props.children;

    return <span {...props}>
        {children}
    </span>
}

const Wrapper = styled(Container)`
     /*border:1px solid var(--border-color); 
    box-shadow: 1px 0px 2px 1px rgba(0,0,0,0.1), 0px 1px 2px 1px rgba(0,0,0,0.1) ;*/
    width:fit-content;
    padding:5px 8px;
    border-radius: 5px;
    background-color: var(--white-color);
    position: relative;
    margin-bottom: 8px;
    & > span{
        display: flex;
        gap:3px;
        width:100%;
        align-items: center;
       /* font-size:18px;*/
        cursor:pointer;
    }
    & > span > span{
        font-size:14px;
        font-weight: 500;
    }

    & > div{
        position: absolute;
        top:32px;
        background-color: var(--white-color);
        display: flex;
        flex-direction: column;
        width: 200px;
        right:-10%;
        box-shadow: 1px 3px 3px 1px rgba(0,0,0,0.1), 0px 3px 3px 1px rgba(0,0,0,0.1) ;
        z-index: 1000;
        padding:10px 0;
        border-radius: 5px;

    }
    & > div > a{
        flex:1;
        padding:12px 15px;       
    }
    & > div > a:hover{
        background-color: #f2f2f2;
    }

`