import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import SideOption from "./SideOption";
import CategoriesCss from '../../../css/Categories.module.css';
import ResultCss from '../../../css/Result.module.css';
import modalcss from '../../../css/modal.module.css';
import { deleteTemplate } from "../../../redux/reducers/resultReducer";
import { useNavigate } from "react-router-dom";
import { ResultContext } from "../Result/ResultMgmt";
import { MenuContext } from "../DashBoard";
import { useContext } from "react";
import { FaEdit, FaRegTrashAlt, FaTimes } from "react-icons/fa";
import Select from "../../../components/Select";
import { setInstitutionTemplate } from "../../../redux/reducers/questionReducer";
import restApi from "../../../helperFunctions/Api2";
import parse from 'html-react-parser';


const ViewTemplate = () => {

    // const defaultTemplate = useSelector((state) => state.results.templates.default);
    const institutions = useSelector(state => state.questions.user_institutions);
    const templates = useSelector((state) => state.results.templates);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState('');
    const { setEditing, setEditData, setEditType,editType, clearEdit, setTitle } = useContext(ResultContext);
    const { selected, changeSelected, setShowLoading } = useContext(MenuContext);
    const [selInst, setSelInst] = useState({});
    const [errText, setErrText] = useState('')

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDelete = (element) => {
        if (element.is_default) {
            setModalText('Action failed. You cannot delete a default template');
            setModalToggle(true);
        } else {
            if(window.confirm('Are you sure you want to delete this template?')) {
            setShowLoading(true);
            restApi.PostApi('/result/delete-template', {id: element.id}, true)
            .then(result=>{
                console.log(result);
                setModalText(result.message);
                if(result.isSuccessful || result.isSuccesful){ 
                    dispatch(deleteTemplate(element.id))
                }
            })
            .catch(error=>{
                setModalText("Error: Connection error");
            })
            .finally(()=>{
                setShowLoading(false);
                setModalToggle(true);
            })
            }
        }
    }
    const handleEdit = (element) => {
        if (element.id === 'default') {
            setModalText('Cannot edit Default template');
            setModalToggle(true);
        } else {
            let temp = {...element}
            temp.template = JSON.parse(element.template);
            // console.log(temp);
            setEditData(temp);
            setEditType('result-template');
            setEditing(true);
            navigate('../add-result-template');
        }
    };

    const handleDefault = (element) => {
        setModalText(element);
        setModalToggle(true);
    }
    const setDefault = (payload) => {
        if (!payload.institution.id) {
            setErrText('Please select an institution');
        } else {
            console.log(payload.institution.id, '887', errText)
            dispatch(setInstitutionTemplate(payload))
        }
    }

    useEffect(() => {
        setTitle('View Result Template');
        editType === 'result-template' && clearEdit();
    }, []);

    return (
        <Fragment>
            {/* <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} /> */}
            <div className={CategoriesCss.form}>
                <div className={CategoriesCss.viewQuestionContainer} onClick={() => (selected !== null) && changeSelected(null)}>
                    {/* <EachSubjectTemplate key={-1} template={defaultTemplate} index={-1} selected={selected} changeSelected={changeSelected} handleDelete={handleDelete} handleEdit={handleEdit} handleDefault={handleDefault} /> */}
                    {templates? Object.values(templates).sort((a,b)=> b.id - a.id).map((template, index) => {
                        return <EachSubjectTemplate key={index} template={JSON.parse(template.template)} tem={template} index={index} selected={selected} changeSelected={changeSelected} handleDelete={handleDelete} handleEdit={handleEdit} handleDefault={handleDefault} />
                    }): 'No Template added'}
                </div>
            </div>
            {/* CUSTOM MODAL  \|/ */}
            {modalToggle && <div className={modalcss.modal} style={{ display: modalToggle ? "block" : "none" }}>
                <div className={modalcss.modal_content}>
                    <div className={modalcss.modal_header}>
                        <span className={modalcss.close} onClick={() => setModalToggle(false)}>&times;</span>
                        <h2></h2>
                    </div>
                    <div className={modalcss.modal_body}>
                        {modalText.id?<div className={CategoriesCss.form}>
                            <label className={CategoriesCss.label}>
                                <span>Select institution to set as default result for</span>
                                <Select onChange={setSelInst} options={institutions} className={CategoriesCss.fields} label='name' value='id' />
                            </label>
                            <div className={`${CategoriesCss.buttonContainer} ${CategoriesCss.div}`}>
                                <button onClick={() => setDefault({ template: modalText, institution: selInst })} className={CategoriesCss.viewBtn}>
                                    Set Default
                                </button>
                            </div>
                            <div className={ResultCss.errText}>{errText}</div>
                        </div>:modalText}
                    </div>
                </div>
            </div>}

        </Fragment>
    )

}

export default ViewTemplate;

const EachSubjectTemplate = ({ template, index, selected, changeSelected, handleEdit, handleDelete, handleDefault, tem }) => {
    const [showOption, setShowOption] = useState(false);
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {
        if (selected == index) {
            setShowOption(true);
        } else {
            setShowOption(false);
        }
    }, [selected])
    const SideOption = ({ element, index, changeSelected, handleDelete, handleEdit, showOption }) => {

        return <Fragment>
            <span onClick={() => { changeSelected(index); }}>
                <span></span>
                <span></span>
                <span></span>
            </span>
            {showOption &&
                <div style={{ display: 'flex' }} >
                    <span onClick={() => changeSelected(null)}><FaTimes /></span>
                    <button onClick={() => handleEdit(tem)}><FaEdit /> Edit</button>
                    <button onClick={() => handleDelete(tem)} ><FaRegTrashAlt /> Delete</button>
                </div>}
        </Fragment>
    }

    return (
        <div className={CategoriesCss.eachQuestion}>
            <div><span><b>Template Name: </b><span className="purpleText">{template.name}</span></span></div>
            <SideOption element={template} index={index} changeSelected={changeSelected} handleEdit={handleEdit} handleDelete={handleDelete} showOption={showOption} />
            <button onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'see less...' : 'see more...'} </button>
            <div style={{ display: seeMore ? 'flex' : 'none' }} className={ResultCss.eachTemplate}>{seeMore && template.sections &&
                Object.keys(template.sections).map((key, index) => <Section section={template.sections[key]} key={key} index={index} />)
            }</div>
        </div>
    )

}
const Section = ({ section, index }) => {

    return (
        <>
            <h3 className={ResultCss.secName}>{section.name}</h3>
            {/* <h5>{section.name}</h5> */}
            {section.content && section.type === 'table' ?
                <div className={ResultCss.viewTable}><table>
                    <thead>
                        <tr></tr>
                        <tr>
                            <th style={{ width: '10px' }}>S/N</th>
                            {Object.keys(section.content).map((key, index) => <th key={key}>{section.content[key].name}</th>)}
                            {/*section.content.total.name && <th colSpan={2} >{section.content.total.name}</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            {Object.keys(section.content).map((key, index) => <td key={key}>{section.content[key].name === '' ? section.content[key].name : section.content[key].type == 'num' ? Math.floor(Math.random() * 100) : section.content[key].name}</td>)}
                        </tr>
                    </tbody>
                </table></div>
                
                : <>
                { section.content && section.type === 'comment' ?
                    <div className={ResultCss.viewComment} >
                    
                        {/* <h6>{section.content.name}</h6> */}
                        {Object.keys(section.content).map((key, index) => 
                        <div key={key} >
                            <span>{section.content[key].name}:</span>
                            <input type="text" placeholder={`Enter ${section.content[key].name}`}/>
                        </div>)}
                    </div> :
                    <div className={ResultCss.viewCertificate}> 
                        {parse(section.content['0'].name)}
                    </div>
                }</>
            }
        </>
    )
}