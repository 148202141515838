import React from "react";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { BsList } from 'react-icons/bs';
import { useState, createContext } from "react";
import Modal from '../../../components/modal';
import Add from  './addContactGroup';
import View from './viewContactGroup';
import AddCon from './addContacts';
import ViewCon from './viewContacts'
export const staffContext = createContext();
export const eventModalContext = createContext();


const ContactSettings = () => {

    const [showMenu, setShowMenu] = useState(false);
    const [modalObject,setModalObject] = useState({});
    const [modalTogglee,setModalTogglee] = useState(false);
    const [view, setView] = useState('');
    const [title, setTitle] = useState('');
    const [editContactCon, setEditContactCon]  = useState({});
    const [allContact, setAllContact] = useState([]);
    

    return ( 
        <>
            <staffContext.Provider value={{ view, setView }}>
                <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <h1 className={`${styles.title}`}>Events</h1>
                    <div className={`${styles.subView}`}>
                        <div className={titleStyle.sectionTitle}>
                            <h3 className={titleStyle.h3}>
                                {title}
                            </h3>
                            <div className={`${titleStyle.menuDiv}`}>
                                <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                                {showMenu &&
                                    <div className={`${titleStyle.subMenu}`} >
                                        <ul>
                                            <li><Link to='add-contact-group'>Add Contact Group</Link></li>
                                            <li><Link to='add-contact'>Add Contacts</Link></li>
                                            <li><Link to='view-contact-group'>View Contacts </Link></li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        <eventModalContext.Provider value={{modalObject,setModalObject,modalTogglee,setModalTogglee,editContactCon,setEditContactCon, allContact, setAllContact}} >
                            <Routes>
                                <Route path='*' element={<Add seTTitle={setTitle} />} />
                                <Route path='/add-contact-group' element={<Add seTTitle={setTitle} />} />
                                <Route path='/view-contact-group' element={<View seTTitle={setTitle} />} />
                                <Route path='/add-contact' element={<AddCon seTTitle={setTitle} />} />
                                <Route path="/*" element={<PageNotFound />} /> 
                            </Routes> 
                            <Modal modalObject={modalObject} modalTogglee={modalTogglee} closeModal={()=>{setModalTogglee(false)}} />
                        </eventModalContext.Provider>
                        
                    </div>
                </div>
            </staffContext.Provider>
        </>
     );
}
 
export default ContactSettings;
