import React, { useRef, useState, useEffect } from 'react'
import css from './select.module.css';
import { BiChevronDown as DownIcon, BiChevronUp as UpIcon } from 'react-icons/bi';

const Select = ({ options, onChange, defaultValue, className, multi, label, value, reset, placeholder, disabled }) => {
    const [showList, setShowList] = useState(false);
    const [selectVal, setSelectVal] = useState('');
    const [click, setClick] = useState(false);
    const [inputVal, setInputVal] = useState('');
    const [selOptions, setSelOptions] = useState(options);
    const inputRef = useRef(null);
    const divRef = useRef(null);
    //const initializeArr = (arg) => Array.isArray(arg)?arg:[];
    const [selectArr, setSelectedArr] = useState([]);
    // const [count, setCount] = useState(0)

    const handleClick = () => {

        if (inputRef && inputRef.current) {
            if (showList) {
                inputRef.current.autofocus = false;
                setShowList(false)
                return false;
            }
            inputRef.current.focus();
            inputRef.current.autofocus = true;
            setShowList(true);
        }
        //console.log('clicked')
    }
    const handleBlur = () => {
        if (inputRef && inputRef.current) {
            if (!click) {
                inputRef.current.autofocus = false;
                setShowList(false);
                setInputVal('')
            }
        }
    }

    const handleFocus = () => {
        if (divRef && divRef.current) {
            setClick(true);
        }
    }

    const handleUnFocus = () => {
        if (divRef && divRef.current) {
            setClick(false);
            if (!inputRef.current.autofocus) { setShowList(false) }
        }
    }

    const handleSelect = (val) => {
        // onChange(val.value);
        // setSelectVal(val.label);
        setShowList(false);
        setInputVal('');
        if (selectArr && selectArr.some(element => element[value] == val[value])) {

        }
        else {
            setSelectedArr([...selectArr, val])
        }
        setSelOptions(options)
    }

    const handleInput = (e) => {
        const value = e.target.value;
        setInputVal(value);
    }
    const removeOptions = (val) => {
        const newSelectArr = selectArr.filter(element => element[value] != val)
        //console.log(value,newSelectArr)
        setSelectedArr(newSelectArr);
    }

    useEffect(() => {
        if (!inputVal && !inputRef.current.autofocus) {
            setShowList(false);
            setSelOptions(options);

        }
        else if (!inputVal && !click && !inputRef.current.autofocus) {
            setShowList(false);
            setSelOptions(options);
        }
        else if (inputVal) {
            const newOptions = options.filter(element => element[label].toLowerCase().includes(inputVal.toLowerCase()))
            if (newOptions.length) {
                setSelOptions(newOptions);
            }
        }
        else {
            setSelOptions(options);
        }
    }, [selectVal, inputVal, showList])

    useEffect(() => {
        setSelectVal('');
        setSelectedArr([]);
        setInputVal('');
        onChange([])
        //console.log('called reset')
    }, [reset])

    useEffect(() => {
        //console.log(options);
        // if (options && options.length>0 && count==0 && defaultValue.length>0) {
        //         setCount(count+1)
        // }
        if (options && options.length > 0) {
            setSelOptions(options);
            setSelectVal('');
            setSelectedArr([]);
            if (defaultValue) {
                if (Array.isArray(defaultValue)) {
                    let arr = [];
                    defaultValue.forEach(eachItem => {
                        let checkItem = options.find(item => item[value] == eachItem[value]);
                        //console.log(checkItem);
                        if (checkItem) {
                            arr.push(checkItem);
                        }

                    })
                    if (arr.length > 0) setSelectedArr(arr);
                }
                else {
                    handleSelect(defaultValue);
                }

            }
        }//change made here
        else if (options && options.length == 0) {
            setSelectedArr(false)
        }//end
    }, [options])

    useEffect(() => {
        //console.log(selectArr)
        onChange(selectArr);

    }, [selectArr])



    useEffect(() => {
        //console.log(defaultValue,options);
        if (defaultValue && options && options.length > 0) {
            if (Array.isArray(defaultValue)) {
                //setSelectedArr(defaultValue);
                let arr = [];
                defaultValue.forEach(eachItem => {
                    let checkItem = options.find(item => item[value] == eachItem[value]);
                    //console.log(checkItem);
                    if (checkItem) {
                        arr.push(checkItem);
                    }

                })
                if (arr.length > 0) setSelectedArr(arr);
            }
            else {
                handleSelect(defaultValue);
            }

        }
    }, [defaultValue])

    return (
        <div className={`${className ? className : ''} ${css.selectContainer}`} style={disabled?{backgroundColor:'#eee'}:{backgroundColor:'transparent'}}>
            <div>
                <div>
                    {selectArr && selectArr.length > 0 && selectArr.map((element, index) =>
                    (<div key={index}>
                        <div>{element[label] ? element[label].length > 15 ? `${element[label].slice(0, 12)}...` : element[label] : ""}
                        </div>
                        <span onClick={() => { removeOptions(element[value]) }}><i className='bi-x-lg'></i></span>
                    </div>))}
                </div>
                <input onChange={(e) => { handleInput(e) }} style={inputVal ? { width: 'fit-content' } : { width: '4px' }} ref={inputRef} onBlur={() => { handleBlur() }} disabled={disabled} />
                {<div onClick={() => { !disabled && handleClick() }} style={!selectVal && !inputVal ? { color: 'lightgrey' } : { color: '#222' }}>{selectVal && !inputVal ? selectVal : inputVal ? '' : (defaultValue && Array.isArray(defaultValue) && defaultValue.length > 0 ? defaultValue[label] : !inputVal && !showList && placeholder ? placeholder : 'Select ...')}</div>}
                <span title={disabled ? 'disabled' : ''} onClick={() => { !disabled && handleClick() }} className={`${css.icons} ${disabled ? `${css.disabled}` : ''}`}>{!disabled ? !showList ? <DownIcon /> : <UpIcon /> : <DownIcon />}</span>
            </div>
            {!disabled && showList && <div ref={divRef} onMouseOver={() => { handleFocus() }} onMouseOut={() => { handleUnFocus() }}>
                {!disabled && showList && selOptions && selOptions.length > 0 && selOptions.map((element, index) => (
                    <div key={index} onClick={() => handleSelect(element)}>{element[label]}</div>
                ))}
            </div>}
        </div>
    )
}

export default Select