import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    online_class:[],
    virtual_class:[], 
    assignments: [],
    notes: []  
}

export const resultSlice = createSlice({
    name: 'learning',
    initialState: initialState,
    reducers: {
        setOnline_class:(state, action)=>{
            return {...state,online_class:action.payload};
        }, 
        addOnline_class:(state,action)=>{
            const newOnlice_class= [action.payload, ...state.online_class,];
            return {...state,online_class:newOnlice_class}
        },
        editOnline_class:(state,action)=>{
            const newOnlice_class = state.online_class.map(eClass=> eClass.id === action.payload.id ? action.payload:eClass );
            return{...state,online_class:newOnlice_class}
        }
        ,
        deleteOnline_class:(state,action)=>{
            const newOnlice_class = state.online_class.filter(eClass => eClass.id !== action.payload.id);
            return {...state,online_class:newOnlice_class}
        },
        setVirtualClass: (state,action) =>{
            return {...state,virtual_class:action.payload}
        },
        addVirtualClass: (state,action)=>{
            let newClass = [action.payload,...state.virtual_class]
            return {...state,virtual_class:newClass}
        },
        editVirtualClass:(state,action)=>{
            return {
                ...state,
                virtual_class:state.virtual_class.map(eClass => eClass.id === action.payload.id?action.payload:eClass)
            }
        },
        deleteVirtualClass:(state,action) =>{
            return{ ...state,
                virtual_class:state.virtual_class.filter(eClass => eClass.id !== action.payload.id)
            }
        },
        setAssignments: (state,action)=>{
            state.assignments = action.payload
        },
        addAssignment:(state,action) => {
            // console.log(action.payload);
            state.assignments.unshift(action.payload)

        },
        editeachAss:(state,action) => {
            // console.log(action.payload);
            return {...state, assignments: state.assignments.map(eachAssignment => eachAssignment.id === action.payload.id ? {...eachAssignment ,...action.payload} :eachAssignment)}
        },
        deleteAssignment:(state,action) => {
            // console.log(action.payload);
            return {...state, assignments:state.assignments.filter(eachass => Number(eachass.id) !== Number(action.payload))}
        },
        addAssignmentComment: (state, action) => {
            state.assignments.forEach(eachAssignment =>{ 
                if (eachAssignment.id === action.payload.assignment_id) {
                    eachAssignment.users.forEach(user => {
                        if (user.id == action.payload.id) {
                            user.comment = action.payload.comment;
                        }
                    })
                }
            })
        },
        addAssignmentScore: (state, action) => {
            state.assignments.forEach(eachAssignment =>{ 
                if (eachAssignment.id === action.payload.assignment_id) {
                    eachAssignment.users.forEach(user => {
                        if (user.id == action.payload.id) {
                            user.score = action.payload.score;
                        }
                    })
                }
            })
        },
        setNotes: (state, action)=>{
            state.notes = action.payload;
        },
        editNotes:(state,action)=>{
            // console.log(action.payload)
            return{...state,
                notes:state.notes.map(eClass=> eClass.id === action.payload.id ? {...eClass, ...action.payload}:eClass )
            }
        },
        deleteNote:(state,action)=>{
            const newNotes = state.notes.filter(eClass => eClass.id !== action.payload);
            return {...state,notes:newNotes}
        },
        addNote:(state,action) => {
            state.notes.unshift(action.payload);
            //  console.log(action.payload)
        },
    }
})

export const {
    setOnline_class,
    addOnline_class,
    editOnline_class,
    deleteOnline_class,
    setVirtualClass,
    addVirtualClass,
    editVirtualClass,
    deleteVirtualClass,
    setAssignments, addAssignment, editeachAss, deleteAssignment, addAssignmentComment, addAssignmentScore,
    setNotes,editNotes, deleteNote, addNote
} = resultSlice.actions

export default resultSlice.reducer;