import React from 'react'

const EachPrice = ({name,image,pricing}) => {
  return (
    <div>
        <div>
            <img src={image} alt='alt' />
            <h3>{name}</h3>
        </div>
        <div>
            {pricing.length > 0 ?pricing.map((price,i)=><SubPrice key={i} price={price} />):''}
        </div>
        
    </div>
  )
}

const SubPrice = ({price}) =>{
    return (
        <div>
            <div>{price.name}</div>
            <div>
                {price.labels && price.labels.length > 0 ?price.labels.map((p,i)=><span key={i}>
                    {p}
                </span>):''}
            </div>

        </div>
    )
}

export default EachPrice