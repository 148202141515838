import {  useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './../css/Login.module.css';
import PrimaryFields from './PrimaryFields';
import Api from "../Api";
import Loading from './loading';
import Modal from "./modal";

const validating = {
  value: 'Validating...'
};
const SignUp = ({ switchMode }) => {
  const [modalToggle, setModalToggle] = useState(false);
  const [modalText, setModalText] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [tooltip, setTooltip] = useState('Password');
  const handleTooltip = (value) => {
    value.length >= 6 && (/\d+/.test(value)) && (/\W+/.test(value)) && (/[a-zA-Z]+/.test(value)) ? setStrong(true) : setStrong(false);
    setTooltip(`${strong ? 'Strong Password!' : `Password must..${value.length < 6 ? ', be of 6 or more characters' : ''}${!(/\d+/.test(value)) ? ', have at least one number' : ''}${!(/\W+/.test(value)) ? ', have at least one symbol' : ''}${!(/[a-zA-Z]+/.test(value)) ? ', have alphabetical characters' : ''}.`}`);
  };

  const [inputs, setInputs] = useState({});
  const [validUser, setValidUser] = useState(false);
  const [userSymbol, setUserSymbol] = useState(false);
  const [hasAlpha, setHasAlpha] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [properLength, setProperLength] = useState(false);
  const [strong, setStrong] = useState(false);
  const [match, setMatch] = useState(false);
  const [showInit, setShowInit] = useState(false);
  const [showZero, setShowZero] = useState(false);
  const [showFirst, setShowFirst] = useState(false);
  const [showLast, setShowLast] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [showCon, setShowCon] = useState(false);
  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs(inputs => ({ ...inputs, [name]: value }));

    if (name === 'user-name') {
      value.length >= 1 ?
        setShowInit(true) : setShowInit(false);

      value.length >= 3 ?
        setValidUser(true) : setValidUser(false);

      !(/\W+/.test(value)) ?
        setUserSymbol(true) : setUserSymbol(false);
    }

    if (name === 'user-pass') {
      value.length >= 1 ?
        setShowFirst(true) : setShowFirst(false);

      value.length >= 6 ?
        setProperLength(true) : setProperLength(false);

      /\d+/.test(value) ?
        setHasNumber(true) : setHasNumber(false);

      /\W+/.test(value) ?
        setHasSymbol(true) : setHasSymbol(false);

      /[a-zA-Z]+/.test(value) ?
        setHasAlpha(true) : setHasAlpha(false);

      if (value.length >= 6 && (/\d+/.test(value)) && (/\W+/.test(value)) && (/[a-zA-Z]+/.test(value)))
        inputs['con-pass'] === value ?
          setMatch(true) : setMatch(false);
      handleTooltip(value);
    }

    if (name === 'con-pass') {
      value.length >= 1 ?
        setShowLast(true) : setShowLast(false);

      value.length >= 6 && value === inputs['user-pass'] ?
        setMatch(true) : setMatch(false);
    }

    if (name === 'user-con') {
      value.length >= 1 ?
        setShowZero(true) : setShowZero(false);

      (/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/.test(value)) ?
        setEmail(true) : setEmail(false);


      (/^\+*\d{1,3}\d{9,10}$/.test(value)) ?
        setPhone(true) : setPhone(false);

      (/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/.test(value) || /^\+*\d{1,3}\d{9,10}$/.test(value)) ? setShowCon(true) : setShowCon(false);
    }
  };

  const [success, setSuccess] = useState(false);

  const [message, setMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    setShowLoading(true);

    Api.PostApi('/account/register', {
      login: inputs['user-con'],
      username: inputs['user-name'],
      password: inputs['user-pass'],
      isEmailUsed: email
    })
      .then(result => result.json())
      .then((result) => {
        setShowLoading(false);
        setModalText(result.message);
        setModalToggle(true);
        if(result.isSuccessful){      
          setSuccess(!success);
          setMessage(result.message);
          (() => {
            setValidUser(false);
            setUserSymbol(false);
            setHasAlpha(false);
            setHasNumber(false);
            setHasSymbol(false);
            setProperLength(false);
            setStrong(false);
            setMatch(false);
            setShowInit(false);
            setShowZero(false);
            setShowFirst(false);
            setShowLast(false);
            setEmail(false);
            setPhone(false);
            setShowCon(false);
          })();
          //console.log(result, 'result');
          setInputs({ 'user-name': '', 'user-con': '', 'user-pass': '', 'con-pass': '' });
        }
      })
      .catch((err) => {
        setShowLoading(false);
        setModalText("Action failed. Registration could not be completed. Please contact admin");
        setModalToggle(true)
        setSuccess(!success);
        setMessage("Action failed. Registration could not be completed. Please contact admin");
        console.log(err, 'error');
      });
  };

  return (
    <>
      <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
      <Loading shouldShow={showLoading} />
      <div className={`${styles.formParent}`}>
        <form className={`${styles.defaultLog}`} onSubmit={onSubmit}>
          <h1 className={`${styles.signIn}`}>
            Sign Up
          </h1>
          <span className={`${styles.notice}`}>You are registering as a Primary User.</span>
          <div className={`${styles.inputDiv}`}>
            <PrimaryFields toSwitch={false} onChange={onInputChange} tooltip={tooltip} validUser={validUser} userSymbol={userSymbol} hasAlpha={hasAlpha} hasNumber={hasNumber} hasSymbol={hasSymbol} properLength={properLength} match={match} showInit={showInit} showZero={showZero} showFirst={showFirst} showLast={showLast} email={email} phone={phone} showCon={showCon} validating={validating.value} />
          </div>
          <span className={`${styles.notice}`}>By signing up to Sight-EduPlus, you agree to the <Link to='/policies/terms-of-use'>Sight-EduPlus Terms of Use</Link> or other agreement for Sight-Innovation
            services, and the <Link to='/policies/privacy-policy'>Privacy Policy</Link>. This site uses essential cookies. For more information, see our
            <Link to='/policies/privacy-policy/cookie-notice'> Cookie Notice</Link>
            .</span>
          <div className={`${styles.btnDiv}`}>
            <button type='submit' className={`${styles.submitButton}`} disabled={!(validUser && userSymbol && hasAlpha && hasNumber && hasSymbol && properLength && match && (email || phone))} title={!(validUser && userSymbol && hasAlpha && hasNumber && hasSymbol && properLength && match && (email || phone)) ? 'Please correctly fill out all fields.' : 'Register'}>Register</button>
            <span className={`${styles.littleNotice}`}>Existing User? Click <Link to='/account/sign-in' className={`${styles.reverseButton}`} onClick={switchMode}>here</Link> to log in</span>
          </div>
        </form>
      </div>
    </>

  );
};

export default SignUp;