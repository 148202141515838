import React from "react";
import Payments from "./Payments";
import WithdrawalSettings from "./WithdrawalsSettings";
import Billing from './Billing';
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import styles from '../../../css/InstitutionMgmt.module.css';
import DashBoard from "./PaymentDashboard";

const PaymentMgt = () => {
    return ( 
        <>
         <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Routes>
                <Route path="dashboard" element={<DashBoard />}/>
                <Route path="payments/*" element={<Payments/>} />
                <Route path="withdrawals/*" element={<WithdrawalSettings />} />
                <Route path="billing/*" element={<Billing/>}/>
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </div>
        </>
     );
}


export default PaymentMgt;