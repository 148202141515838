import { useState } from 'react';
import styles from './../../../css/Categories.module.css';
import staffstyles from './../../../css/StaffMgmt.module.css';
import styled from 'styled-components';
import titleStyle from './../../../css/SectionTitle.module.css';
import { Routes, Route, useNavigate, } from "react-router-dom";
import Menu from '../../../components/Menu';
import PageNotFound from '../../404/PageNotFound';
import { createContext } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useEffect } from 'react';
import { useContext } from 'react';
import { MenuContext } from '../DashBoard';
import Select from '../../../components/Select';
import { useDispatch, useSelector } from 'react-redux';
import restApi from '../../../helperFunctions/Api2';
import { IoReloadCircleSharp as ReloadIcon } from 'react-icons/io5';
import { addTimetable, deleteTimetable, updateTimetable } from '../../../redux/reducers/questionReducer';
import PageNumber from '../../../components/PageNumber';
import SideOption from '../../../components/SideOption';

const NO_OF_ITEMS_PER_PAGE = 30;
const EditContext = createContext();

const Timetable = () => {
    const [title, setTitle] = useState('');
    const [editTimetable, setEditTimetable] = useState(null);

    return (
        <div className={`${staffstyles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h1 className={`${staffstyles.title}`}>Timetable</h1>
            <div className={`${staffstyles.subView}`}>
                <div className={titleStyle.sectionTitle}>
                    <h3 className={titleStyle.h3}>
                        {title}
                    </h3>
                    <Menu options={[
                        { label: 'Create Timetable', path: "create", onClick: () => setEditTimetable(null) },
                        { label: 'View Timetable', path: "view", onClick: () => setEditTimetable(null) }
                    ]} />
                </div>
                <EditContext.Provider value={{ editTimetable, setEditTimetable, }}>
                    <Routes>
                        <Route path='*' element={<CreateTimeTable setTitle={setTitle} />} />
                        <Route path='/create/*' element={<CreateTimeTable setTitle={setTitle} />} />
                        <Route path='/view/*' element={<ViewTimetable setTitle={setTitle} />} />
                        <Route path="/*" element={<PageNotFound />} />
                    </Routes>
                </EditContext.Provider>
            </div>
        </div>
    );
};

const cell = {
    value: '',
    selected: false,
    disabled: false
};
const defTimeTable = {
    name: '',
    head: [{ ...cell, value: ['Time', 'Date'], disabled: !true }, { ...cell }, { ...cell }, { ...cell }],
    body: [
        [{ ...cell }, { ...cell }, { ...cell }, { ...cell }],
        [{ ...cell }, { ...cell }, { ...cell }, { ...cell }],
    ]
};
function CreateTimeTable ({ setTitle }) {
    const institutions = useSelector(state => state.questions.user_institutions);
    const [selInst, setSelInst] = useState(null);
    const [currentUserCategoryArr, setCurrentUserCatergoryArr] = useState([]);
    const [selCategory, setSelCategory] = useState([]);
    const [defCategory, setDefCategory] = useState('');
    const { editTimetable, setEditTimetable } = useContext(EditContext);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tableData, settableData] = useState(JSON.parse(JSON.stringify(defTimeTable)));
    const handleInput = (event, index, rowIndex) => {
        const value = event.target.value;
        let data = { ...tableData };

        if (rowIndex === -1) {
            if (Array.isArray(index)) {
                data.head[index[0]].value[index[1]] = value;
            } else {
                data.head[index].value = value;
            }
        } else {
            data.body[rowIndex][index].value = value;
        }
        settableData(data);
    };

    const addRow = (row) => {
        let data = { ...tableData };
        let newArr = [];
        for (let i = 0; i < data.body[row].length; i++) {
            newArr[i] = { ...cell };
        }

        data.body = [
            ...data.body.slice(0, row + 1),
            newArr,
            ...data.body.slice(row + 1),
        ];
        settableData(data);
    };
    // const selectRow = (row) => {
    //     let data = { ...tableData };
    //     if (data.body[row].every(cell => cell.selected)) {
    //         data.body[row].forEach(cell => cell.selected = false);
    //     } else {
    //         data.body[row].forEach(cell => cell.selected = true);
    //     }
    //     settableData(data);
    // };
    const deleteRow = (row) => {
        let data = { ...tableData };
        if (data.body.length <= 1)
            return;
        data.body = data.body.filter((ro, index) => index !== row);
        settableData(data);
    };

    const addColumn = (col) => {
        let data = { ...tableData };

        data.head = [
            ...data.head.slice(0, col + 1), { ...cell }, ...data.head.slice(col + 1)
        ];
        data.body = data.body.map(row => [
            ...row.slice(0, col + 1), { ...cell }, ...row.slice(col + 1)
        ]);
        settableData(data);
    };

    // const selectColumn = (col) => {
    //     let data = { ...tableData };
    //     const bool = data.body.every(row => row[col].selected);

    //     data.body.forEach(row => {
    //         row[col].selected = !bool;
    //     });
    //     settableData(data);
    // };
    const deleteColumn = (col) => {
        let data = { ...tableData };
        if (data.head.length <= 2)
            return;
        data.head = data.head.filter((co, index) => index !== col);
        data.body = data.body.map(row => row.filter((co, index) => index !== col));
        settableData(data);
    };

    function allowDrop (ev) {
        ev.preventDefault();
    }

    function drag (row, ev) {
        ev.dataTransfer.setData("text", row);
    }

    function drop (row, ev, type) {
        ev.preventDefault();
        const from = parseInt(ev.dataTransfer.getData("text"));
        if (type === 'row') {
            let body = [...tableData.body].filter((r, ri) => ri !== from);
            body = [...body.slice(0, row), [...tableData.body[from]], ...body.slice(row)];
            settableData({ ...tableData, body });
        } else if (type === 'col') {
            let head = [...tableData.head].filter((c, ci) => ci !== from);
            head = [...head.slice(0, row), tableData.head[from], ...head.slice(row)];

            let body = [...tableData.body].map(r => {
                let rw = r.filter((c, ci) => ci !== from);
                return [...rw.slice(0, row), r[from], ...rw.slice(row)];
            });
            settableData({ ...tableData, head, body });
        }
    }
    function handleCancel () {
        setEditTimetable(null);
        navigate('../create');
    }
    function handleSubmit () {
        if (tableData.name.trim().length === 0) {
            setModalText('Please fill in the title of the timetable');
            setModalToggle(true);
            return;
        }
        if (!selInst || !selInst.id || selCategory.length < 1) {
            setModalText('Please select at least one group');
            setModalToggle(true);
            return;
        }
        setShowLoading(true);
        if (!editTimetable) {
            const payload = {
                timetable: { institution_id: selInst.id, timetable: JSON.stringify(tableData) },
                categories: selCategory.map(cat => cat.id),
                groups: selCategory.map(cat => ({ group_id: cat.id }))
            };
            restApi.PostApi('/institution/add-timetable', payload, true)
                .then(result => {
                    console.log(result);
                    if (result.isSuccessful) {
                        dispatch(addTimetable({ ...payload, ...result.data }));
                    }
                    setModalText(result.message);
                }).finally(() => {
                    setModalToggle(true);
                    setShowLoading(false);
                });
        } else {
            const oldc = editTimetable.groups.map(grp => grp.group_id), newc = selCategory.map(cat => cat.id);
            const payload = {
                timetable: {
                    id: editTimetable.id,
                    institution_id: selInst.id,
                    timetable: JSON.stringify(tableData),
                },
                removeGroup: oldc.filter(c => !newc.includes(c)),
                addGroup: newc.filter(c => !oldc.includes(c)),
            };
            restApi.PostApi('/institution/edit-timetable', payload, true)
                .then(result => {
                    payload.timetable = {
                        ...editTimetable,
                        institution_id: selInst.id,
                        timetable: JSON.stringify(tableData),
                        groups: selCategory.map(cat => ({ group_id: cat.id }))
                    };
                    payload.oldInstitutionId = editTimetable.institution_id !== selInst.id && editTimetable.institution_id
                    console.log(payload, result);
                    if (result.isSuccessful) {
                        setEditTimetable(null);
                        dispatch(updateTimetable(payload));
                        navigate('../view');
                    }
                    setModalText(result.message);
                }).finally(() => {
                    setModalToggle(true);
                    setShowLoading(false);
                });
        }
    }
    function clearFields () {
        setSelInst(null);
        setDefCategory('');
        settableData(JSON.parse(JSON.stringify(defTimeTable)));
    }

    useEffect(() => {
        setTitle('Create Timetable');
    }, [setTitle]);
    useEffect(() => {
        if (editTimetable) {
            const inst = institutions.find(ins => ins.id === editTimetable.institution_id);
            setSelInst(inst);
            setDefCategory(editTimetable.groups.map(cat => inst.categories[cat.group_id]));
            settableData(JSON.parse(editTimetable.timetable));
        } else {
            clearFields();
        }
    }, [editTimetable, institutions]);
    useEffect(() => {
        if (selInst) {
            const cat = [];
            for (let x in selInst.categories) cat.push(selInst.categories[x]);
            setCurrentUserCatergoryArr(Object.values(cat));
        } else setCurrentUserCatergoryArr([]);
        setSelCategory([]);

    }, [selInst]);

    return (
        <Container>
            <div className={styles.form}>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Name</span>
                    <input placeholder='Timetable' className={`${styles.input}`} type='text' onChange={e => settableData({ ...tableData, name: e.target.value })} value={tableData.name} />
                </label>
                <label className={styles.label}>
                    <span className={styles.isRequired}>Select Institution</span>
                    <Select name='institution' options={institutions} onChange={setSelInst} className={styles.fields} label='name' value='institution_id' defaultValue={selInst} reset={selInst === null} />
                </label>
                {/* currentUserCategoryArr.length > 0 &&  */<label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Select Group</span>
                    <Select name='instCategory' options={currentUserCategoryArr} multi onChange={setSelCategory} className={styles.selectFields} defaultValue={defCategory} label='name' value='id' />
                </label>}
            </div>
            <div className='container'><table>
                <thead>
                    <tr>
                        {tableData.head.map((cell, ci) =>
                            ci === 0 ? <th key={ci}>
                                <input value={cell.value[0]} onChange={(e) => handleInput(e, [ci, 0], -1)} disabled={cell.disabled} />
                                <input value={cell.value[1]} onChange={(e) => handleInput(e, [ci, 1], -1)} disabled={cell.disabled} />
                            </th> :
                                <th key={ci} onDrop={(ev) => drop(ci, ev, 'col')} onDragOver={allowDrop} selected={cell.selected}>
                                    {ci > 0 && <div className='clmbtn' draggable onDragStart={(ev) => drag(ci, ev)} >
                                        {/* <button title='Select column' className='button' onClick={() => selectColumn(ci)}>&#9783;</button> */}
                                        <button title='Add column' className='button' onClick={() => addColumn(ci)}>&#10010;</button>
                                        <button title='Delete column' className='button' onClick={() => deleteColumn(ci)}><FaTrashAlt /></button>
                                    </div>}
                                    <input value={cell.value} onChange={(e) => handleInput(e, ci, -1)} disabled={cell.disabled} /></th>)}
                    </tr>
                </thead>
                <tbody>{tableData.body.map((row, ri) =>
                    <tr key={ri} >
                        {row.map((cell, ci) => <td key={ci} onDrop={(ev) => drop(ri, ev, 'row')} onDragOver={allowDrop}>
                            {ci === 0 && <div className='optdiv' draggable onDragStart={(ev) => drag(ri, ev)} >
                                <div className='optbtn'>
                                    {/* <button title='select row' className='button' onClick={() => selectRow(ri)}>&#9783;</button> */}
                                    <button title='Add row' className='button' onClick={() => addRow(ri)}>&#10010;</button>
                                    <button title='Delete row' className='button' onClick={() => deleteRow(ri)}><FaTrashAlt /></button>
                                </div>
                                <div></div>
                            </div>}
                            <input value={cell.value} className={cell.selected ? 'selected' : ''} onChange={(e) => handleInput(e, ci, ri)} disabled={cell.disabled} />
                        </td>)}
                    </tr>
                )}</tbody>
            </table></div>
            <div className={` ${styles.div} ${styles.buttonContainer}`}>
                {editTimetable && <button className={`cancelEditButton`} onClick={() => handleCancel()}>Cancel Edit</button>}
                <button type='submit' className={styles.viewBtn} onClick={(e) => { handleSubmit(e); }}>
                    {editTimetable ? 'Save' : 'Add'} Timetable
                </button>
            </div>

        </Container>

    );
}

const ViewTimetable = ({ setTitle }) => {
    const institutions = useSelector(state => state.questions.user_institutions);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setEditTimetable } = useContext(EditContext);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const { selected, changeSelected } = useContext(MenuContext);

    const [currentUserCategoryArr, setCurrentUserCatergoryArr] = useState([]);
    const [selInst, setSelInst] = useState(null);
    const [timetables, setTimetables] = useState([]);
    const [selCat, setSelCat] = useState({});
    const [reset, setReset] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);

    function handleDelete (timetable) {
        console.log(timetable);
        //console.log(user, category);
        setShowLoading(true);
        if (window.confirm('Are you sure you want to remove this timetable?')) {
            if (!restApi.checkNetwork()) return;
            restApi.PostApi('/institution/delete-timetable', timetable, true)
                .then(result => {
                    if (result.isSuccessful) {
                        dispatch(deleteTimetable(timetable));
                        setModalText('Timetable removed successfully.');
                    }
                    else {
                        setModalText(result.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setModalText('Unsuccessful, Please try again, or Re-Login if problem persists');
                })
                .finally(() => {
                    setModalToggle(true);
                    setShowLoading(false);
                });
        }

    }
    function handleEdit (timetable) {
        setEditTimetable(timetable);
        navigate('../create');
    }

    useEffect(() => {
        setTitle('View Timetables');
        setEditTimetable(null);
    }, [setEditTimetable, setTitle]);
    useEffect(() => {
        setSelInst(institutions.find(inst => selInst && inst.id === selInst.id) || {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [institutions]);

    useEffect(() => {
        if (selInst && selInst.id) {
            const cat = [];
            for (let x in selInst.categories) cat.push(selInst.categories[x]);
            setCurrentUserCatergoryArr(Object.values(cat));
            setTimetables(selInst.timetables ? selInst.timetables : []);
            setSelCat({});
        }
    }, [selInst, reset]);
    useEffect(() => {
        if (!selInst)
            return;
        else if (selInst.id && selCat.id) {
            selInst.timetables && setTimetables(selInst.timetables.filter(timetable => timetable.groups && timetable.groups.map(gr => gr.group_id).includes(selCat.id)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selCat]);
    useEffect(() => {
        if (timetables.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((timetables.length / NO_OF_ITEMS_PER_PAGE) + ((timetables.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0));
        }
    }, [timetables.length]);
    return <div className={styles.form} >
        <div className={styles.filterDiv}>
            <label className={styles.label}>
                <span>Select institution</span>
                <Select onChange={setSelInst} options={institutions} className={styles.fields} label='name' value='id' defaultValue={selInst} />
            </label>
            <label className={styles.label}>
                <span>Filter by group</span>
                <Select onChange={setSelCat} options={currentUserCategoryArr} className={styles.fields} label='name' value='id' reset={reset} />
            </label>
            <button onClick={() => setReset(!reset)} title="Reload filter" style={{ alignSelf: "flex-end", marginBottom: '5px', cursor: "pointer", fontSize: "25px", color: "var(--secondary-color)", oultline: 'none', border: "none", backgroundColor: "transparent" }}><ReloadIcon /></button>
        </div>
        <div className={styles.viewQuestionContainer}>
            {timetables && timetables.length > 0 ? timetables.map((timetable, index) =>
                index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE)
                && <EachTimetable key={index} timetable={timetable} index={index} selected={selected} changeSelected={changeSelected} handleEdit={handleEdit} handleDelete={handleDelete} institution={selInst} />)
                : "No Timetable available!"}
        </div>
        {timetables.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
    </div>;
};
function EachTimetable ({ timetable, index, selected, changeSelected, handleEdit, handleDelete, institution }) {
    const tableData = JSON.parse(timetable.timetable);
    const [seeDetails, setSeeDetails] = useState(false);

    return <div className={styles.eachQuestion}>
        <div>
            <div>
                <span>Name: </span>
                <span className={styles.eachQuestionValue} >{tableData.name}</span>
            </div>
            <div><span>Groups: </span>
                <div style={{ display: 'inline-block', marginBottom: '10px' }}>
                    {timetable.groups.map((category, key) => institution && institution.categories[category.group_id] && <EachUserGroup key={key}><span className={styles.eachQuestionValue}>{institution.categories[category.group_id].name}</span></EachUserGroup>)}
                </div>
            </div>
        </div>
        <SideOption element={timetable} index={index} changeSelected={changeSelected} showOption={selected === index}
            handleDelete={() => handleDelete(timetable)} handleEdit={() => handleEdit(timetable)} />
        <button onClick={() => setSeeDetails(!seeDetails)}>{!seeDetails ? 'see more...' : 'see less...'}</button>
        {seeDetails && <ViewContainer>
            <div className='container' style={{ padding: 0 }}><table>
                <thead>
                    <tr>{tableData.head.map((cell, ci) =>
                        ci === 0 ? <th key={ci}>
                            <span>{cell.value[0]}</span>
                            <span>{cell.value[1]}</span>
                        </th>
                            : <th key={ci}>{cell.value}</th>)}
                    </tr>
                </thead>
                <tbody>{tableData.body.map((row, ri) =>
                    <tr key={ri} >{row.map((cell, ci) => <td key={ci}>{cell.value}</td>)}</tr>
                )}</tbody>
            </table></div>
        </ViewContainer>}
    </div>;
}

export default Timetable;

const ViewContainer = styled.div`
overflow-x: hidden;
padding-bottom: 20px;
display: flex !important;
align-items: center;

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow-x: auto;
	padding: 0px 30px 30px;
    max-width: 100%;

    table {
        border-collapse: collapse;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow-y: clip;
    }

    th,
    td {
        color: #333;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
    }

    th {
        text-align: left;
        // border: 1px solid;
    }

    tr {
        position: relative;
    }


    thead {
        tr{
            position: unset;
        }
        th {
            // background-color: var(--nav-color);
            min-width: 120px;
            position: relative;
            // color: #fff;
        }
        th:first-of-type{
            background: linear-gradient(24deg, rgba(85, 96, 143, 0.12) 50%, #a2a3b769 50%);
            display: flex;
            flex-direction: column;
            border: none;
            >span{
                padding: 0;
            }
            >span:first-of-type{
                align-self: flex-end;
                text-align: end;
            }
            >span:nth-of-type(2){
                color: #333
            }
        }
        th:not(:first-of-type) {
            position: relative;
        }
    }

    tbody {
        td:first-of-type {
            // background-color: rgba(85, 96, 143, 0.2);
            font-weight: 700;
            // border-color: #fff;
        }

        tr {
            border: 1px solid;

            ${''/* &:hover {
                background-color: rgba(200, 200, 200, 0.3);
            } */}
        }

        ${''/* td:not(:first-of-type) {
            position: relative;
            
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(200, 200, 200, 0.2);
                    z-index: -0;
                }
            }
        } */}
    }
}

`;
const EachUserGroup = styled.span`
    border:1px solid var(--primary-color);
    color: black;
    padding:3px 10px;
    border-radius: 15px;
    display: inline-block;
    margin:2px;
    cursor:pointer;
    position: relative;

    .icon {
        color: red;
    }
    .tooltip {
        position: relative;
        display: inline;

        .tooltiptext {
            visibility: hidden;
            width: max-content;
            background-color: #fff;
            color: #000;
            border-radius: 6px;
            font-size: 12px;
            box-shadow: rgb(116 178 182 / 50%) 0px 0px 8px, rgba(70, 70, 70, 0.12) 2px 2px 3px;
            padding: 4px;
        
            /* Position the tooltip */
            position: absolute;
            z-index: 1;
            right: 0%;
            top: -32px;

        }
        :hover .tooltiptext{
            visibility: visible;
        }
    }
`;
const Container = styled.div`
overflow-x: hidden;
padding-bottom: 20px;


h1 {
	font-size: 3.2em;
	line-height: 1.1;
}

.button {
	display: inline-flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	background-color: rgb(212, 212, 255);
	color: #36395A;
	border-radius: 4px;
	border-width: 0;
	box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
	padding: 10px, 16px;
	font-size: 18px;
	transition: box-shadow .15s, transform .15s;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	will-change: box-shadow, transform;
	min-width: fit-content;

	&:focus {
		box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
	}

	&:hover {
		box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
		transform: translateY(-2px);
	}

	&:active {
		box-shadow: #D6D6E7 0 3px 7px inset;
		transform: translateY(2px);
	}
}


.container {
	/* position: absolute;
	top: 50%;
	left: 50%;
	min-height: 100vh;
	transform: translate(-50%, -50%); */
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: center;
	overflow-x: auto;
	padding: 0px 30px 30px;

    table {
        border-collapse: collapse;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow-y: clip;

        input {
            display: flex;
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            background-color: transparent;
            color: inherit;
            margin: 0;
            font: inherit;
            padding: 10px;
            position: relative;
            z-index: 2;
        }
    }

    th,
    td {
        color: #333;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    th:hover .clmbtn,
    td:first-of-type:hover .optbtn {
        display: inline-flex;
        z-index: 3;
    }

    th {
        text-align: left;
        // border: 1px solid;
    }

    tr {
        position: relative;
    }


    thead {
        tr{
            position: unset;
        }
        th {
            // background-color: var(--nav-color);
            min-width: 120px;
            position: relative;
            // color: #fff;
            padding-top: 12px;
        }
        th:first-of-type{
            background: linear-gradient(24deg, rgba(85, 96, 143, 0.2) 50%, var(--nav-color) 50%);
            display: flex;
            flex-direction: column;
            border: none;
            padding-right: 10px;
            padding-left: 10px;
            >input{
                padding: 0;
            }
            >input:first-of-type{
                align-self: flex-end;
                text-align: end;
            }
            >input:nth-of-type(2){
                color: #333
            }
        }
        th:not(:first-of-type) {
            position: relative;
            
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(0, 200, 200, 0.1);
                    z-index: -0;
                }
            }
        }
    }

    tbody {
        td:first-of-type {
            // background-color: rgba(85, 96, 143, 0.12);
            font-weight: 700;
            // border-color: #fff;
        }

        tr {
            border: 1px solid;

            &:hover {
                background-color: rgba(200, 200, 200, 0.3);
            }
        }

        td:not(:first-of-type) {
            position: relative;
            
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(200, 200, 200, 0.2);
                    z-index: -0;
                }
            }
        }
    }

    .optdiv {
        width: calc(100% + 3.8rem);
        height: 100%;
        position: absolute;
        left: -3.8rem;
        display: flex;
        
        >div:last-child{
            background-color: rgba(0, 200, 200, 0.1);
            display: none;
            width: 100%;
        }
    }

    .optbtn {
        display: none;
        gap: 2px;
        z-index: 1;
        height: fit-content;

        &::before {
            display: none;
        }
        &:hover+div:last-child{
            display: block;
        }

    }

    .clmbtn {
        position: absolute;
        right: 0;
        top: -0px;
        display: none;
        z-index: -1;
    }

    .selected {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

`;
