import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from '../../../css/CreateInst.module.css';
import { eventModalContext } from './events';
import { useSelector, useDispatch } from 'react-redux';
import Api from '../../../helperFunctions/Api2';
import Select from '../../../components/Select';
import { addAppointment, editAppointment } from '../../../redux/reducers/eventReducer';
import { useNavigate } from 'react-router-dom';
import TextEditor from "../../../components/TextEditor";
// import Calendar, { RepeatCalender } from '../../../components/Calendar/calendar';
import { UploadedFileOnly } from '../../../components/UploadedFiles';
import { MenuContext } from '../DashBoard';

const audienceArr = [
   { value: "0", label: 'Public' },
   { value: "1", label: "Institution Group" },
];
const appointmentArr = [
   { value: '0', label: 'Custom' },
   { value: '1', label: 'Recurrent' }
];

const CreateAppointment = ({ seTTitle }) => {
   const navigate = useNavigate();

   useEffect(() => {
      seTTitle('Create appointment');
   }, [seTTitle]);

   const [appointmentTitle, setAppointmentTitle] = useState('');
   const [appointmentDes, setAppointmentDescription] = useState('');
   const [audienceType, setAudienceType] = useState(audienceArr[0]);
   // const [defaultAudience, setDefaultAudience] = useState(audienceArr[0]);
   // const [defaultInstitution, setDefaultInstitution] = useState("");
   // const [defaultCategory, setDefaultCategory] = useState("");
   const [selInstitution, setSelInstitution] = useState(null);
   // const [categoryArr, setCategoryArr] = useState([]);
   // const [sel_category, set_category] = useState([]);
   const [disableField, setDisableField] = useState(false);
   // const [bookings, setBookings] = useState({});
   const [resetField, setResetField] = useState(false);
   const [imageToggle, setImageToggle] = useState(false);
   const dispatch = useDispatch();
   const institutions = useSelector(state => state.questions.user_institutions);
   // const user = useSelector((state) => state.account.username);
   const { setModalObject, setModalToggle, editAppointmentCon, setEditAppointmentCon } = useContext(eventModalContext);
   const { setShowLoading } = useContext(MenuContext);
   const [appointmentImage, setAppointmentImage] = useState('');
   const [appointmentVideo, setAppointmentVideo] = useState('');
   const [appointmentType, setAppointmentType] = useState(appointmentArr[0]);
   const [appointmentId, setAppointmentId] = useState(0);
   // const [recurrency, setRecurrency] = useState({});
   const paymentLinks = useSelector(store => store.account.paymentLinks);
   const [notePaymentLinks, setNotePaymentLinks] = useState([]);
   const [addPaymentLinks, setAddPaymentLink] = useState(false);
   const paylinkId = useRef();


   const validateSingleValues = (vals, msgs) => {
      let i, len = vals.length;
      let status = true;
      let msg = "";
      for (i = 0; i < len; i++) {
         const val = vals[i];
         if (val === '' || typeof val === 'undefined' || val === null) {
            status = false;
            msg = msgs[i];
            break;
         }
      }
      if (!status) {
         setModalObject({ header: "", body: `${msg}`, footer: "" });
         setModalToggle(true);
      }
      return status;
   };

   // const formatObject = (obj) => {
   //    return {
   //       id: obj.id,
   //       subuser_id: obj.subuser_id ? obj.subuser_id : null,
   //       title: obj.appointment.title,
   //       description: obj.appointment.description,
   //       start_date: obj.appointment.start_date,
   //       end_date: obj.appointment.end_date,
   //       categories: obj.is_institution === 1 ? [...obj.categories.map(eC => ({
   //          is_institution: obj.is_institution,
   //          category_id: eC
   //       }))] :
   //          [...obj.email_group.map(eC => ({
   //             is_institution: obj.is_institution,
   //             category_id: eC
   //          }))]
   //    };
   // };

   const clearForm = () => {
      setAppointmentTitle('');
      setAppointmentDescription('');
      setResetField(!resetField);
      setDisableField(false);
      setAppointmentImage('');
      setAppointmentVideo('');
      setAddPaymentLink(false);
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      let check = validateSingleValues([
         appointmentTitle,
         appointmentDes,
      ], [
         "Please Enter Appointment Title",
         "Please Enter Appointment Description",

      ]);
      if (!check) return false;
      // if (Object.values(bookings).length < 1) {
      //    setModalObject({
      //       header: "",
      //       body: "Please book at least 1 session",
      //       footer: ""
      //    });
      //    setModalToggle(true);
      //    return false;
      // }
      // check = Object.values(bookings).some(value => value.some(obj => obj.isAvailable && !validateSingleValues([obj.start_time, obj.end_time], [
      //    "Please Check all selected slots and enter a valid start time",
      //    "Please Check all selected slots and enter a valid end time",
      // ])));
      // if (check) return false;
      if (audienceType.value === '1') {
         if (!selInstitution || !selInstitution.id) {
            console.log(selInstitution);
            setModalObject({
               header: "",
               body: "Select a valid Institution",
               footer: ""
            });
            setModalToggle(true);
            return false;
         }
      // if (!sel_category || sel_category.length === 0) {
      //    setModalObject({
      //       header: "",
      //       body: "Select a valid Institution Group",
      //       footer: ""
      //    });
      //    setModalToggle(true);
      //    return false;
      // }
      };

      const appointmentObject = {
         title: appointmentTitle,
         description: appointmentDes,
         type: parseInt(appointmentType.value),
         thumbnail: appointmentImage,
         preview_url: appointmentVideo,
         audience_type: audienceType.value === '1' ? 1 : 0,
         institution_id: audienceType.value === '1' ? selInstitution.id : null,
         payment_link_id: addPaymentLinks ?  parseInt(paylinkId.current.value) : null
      };
      if (!Api.checkNetwork()) return false;
      setShowLoading(true);

      if (appointmentId < 1) {
         Api.PostApi('/event/book-appointment', {appointment: appointmentObject}, true)
            .then(result => {
               console.log(appointmentObject, 'appointment');
               console.log(result, 'server result');
               setShowLoading(false);
               setModalObject({ header: '', body: result.message, footer: '' });
               setModalToggle(true);
               if (result.isSuccessful) {
                  dispatch(addAppointment({
                     ...result.data,
                  }));
                  clearForm();
   
               }
               else {
                  setModalObject({ header: '', body: 'Appointment could not be added', footer: '' });
                  setModalToggle(true);
               }
            })
            .catch(err => {
               setShowLoading(false);
               // alert('Action failed. please   re-log in');
            });
         
      } else {
         appointmentObject.id = appointmentId;
         Api.PostApi('/event/edit-appointment', appointmentObject, true)
            .then(result => {
               console.log(appointmentObject, 'appointment');
               setShowLoading(false);
               setModalObject({ header: '', body: result.message, footer: '' });
               setModalToggle(true);
               if (result.isSuccessful) {
                  console.log(result, 'server result');
                  dispatch(editAppointment(appointmentObject));
                  clearForm();
   
               }
               else {
                  setModalObject({ header: '', body: result.message, footer: '' });
                  setModalToggle(true);
               }
            })
            .catch(err => {
               setShowLoading(false);
               // alert('Action failed. please   re-log in');
            });
         
      }


   };

   const fillEditDetails = () => {
      setAppointmentId(editAppointmentCon.id)
      setAppointmentTitle(editAppointmentCon.title);
      setAppointmentDescription(editAppointmentCon.description);
      let is_institution = Boolean(editAppointmentCon.institution_id);
      setAppointmentType(appointmentArr[parseInt(editAppointmentCon.type)]);
      setAudienceType(audienceArr[is_institution ? 1 : 0]);
      setDisableField(true);
      if (is_institution) {
         let tempInst = institutions.find(eInst => eInst.id === editAppointmentCon.institution_id);
         setSelInstitution(tempInst);
      }
      // let bookings = {};
      // editAppointmentCon.sessions.forEach(session => bookings[session.session_date] = session.bookings.map(book => ({ start_time: book.start_time.slice(0, 5), end_time: book.end_time.slice(0, 5), slots: book.slots, isAvailable: Boolean(book.status) })));
      // console.log(bookings);
      // setBookings(bookings);
      setAppointmentImage(editAppointmentCon.thumbnail);
      setAppointmentVideo(editAppointmentCon.preview_url);
      if (paymentLinks[editAppointmentCon.payment_link_id]) {
         setAddPaymentLink(true);
      }
   };

   useEffect(() => {
      if (editAppointmentCon && editAppointmentCon.id) {
         fillEditDetails();
      }
      return setEditAppointmentCon(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [editAppointmentCon]);

   // useEffect(() => {
   //    if (!editAppointmentCon) {
   // setBookings({});
   // setRecurrency({});
   // }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [appointmentType]);

   useEffect(() => {
      if( audienceType.value === '0' && (!editAppointmentCon || !editAppointmentCon.id)) setSelInstitution(null);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [audienceType]);
   // useEffect(() => {
   //    // console.log(bookings);
   // }, [bookings]);

   useEffect(() => {
      //console.log(paymentLinks);
      let notePLinks = [];
      notePLinks = Object.values(paymentLinks);
      setNotePaymentLinks(notePLinks);
   }, [paymentLinks]);

   const style = {
      readonly: false,
      height: 400,
      width: 680
   };

   return (
      <>
         <div className={`${styles.form} ${styles.form2}`} >
            <label className={`${styles.label}`}>
               <span className={styles.isRequired}>Appointment Title</span>
               <input name='appointmentName' value={appointmentTitle ? appointmentTitle : ''} className={`${styles.fields}`} type='text' placeholder="Title" onChange={(e) => { setAppointmentTitle(e.target.value); }} />
            </label>
            {/*<label className={`${styles.label}`}>
                   <span>Description</span>
                   <textarea name='appointmentdescription' value={appointmentDes ? appointmentDes: ''} className={`${styles.fields}`} onChange={(e) => {setAppointmentDescription(e.target.value)}} placeholder="Descript the appointment"></textarea>
                </label>*/}
            <TextEditor
               text={'Appointment Description'}
               setShowLoading={setShowLoading}
               navigate={navigate}
               value={appointmentDes}
               style={style}
               handleChange={setAppointmentDescription}
            />
            <label className={styles.label}>
               <span className={styles.isRequired}>Appointment Type</span>
               <Select options={appointmentArr} onChange={setAppointmentType} placeholder="Select Appointment Type" disabled={disableField} defaultValue={appointmentType} reset={resetField} />
            </label>

            {/* {appointmentType.value === '0' && <>
               <Calendar bookings={bookings} setBookings={setBookings} />
            </>}
            {appointmentType.value === '1' && <>
               <RepeatCalender bookings={bookings} setBookings={setBookings} recurrency={recurrency} setRecurrency={setRecurrency} />
            </>} */}

            <label className={styles.label}>
            <span className={styles.isRequired}>Audience Type</span>
            <Select options={audienceArr} onChange={setAudienceType} defaultValue={audienceType} placeholder="Select Audience Type" disabled={disableField} reset={resetField} />
         </label>

            {
            audienceType && audienceType.value === '1' &&
            <>
               <label className={styles.label}>
                  <span className={styles.isRequired}>Select Institution</span>
                  <Select options={institutions} onChange={setSelInstitution} defaultValue={selInstitution} placeholder="Select Institution" value='id' disabled={disableField} label="name" reset={resetField} />
               </label>
            </>
         }

            <UploadedFileOnly type="image" title={'Copy logo url from "Upload Image"'} setShowLoading={setShowLoading} onCopy={setAppointmentImage} value={appointmentImage} />


            <UploadedFileOnly onCopy={setAppointmentVideo} value={appointmentVideo} type={'video'} setShowLoading={setShowLoading} />

            <div className={styles.divContainer}>
               <input style={{ maxHeight: 15 }} type="checkbox" checked={addPaymentLinks} name="require_payment" onChange={() => addPaymentLinks ? setAddPaymentLink(false) : setAddPaymentLink(true)} /><span>Add Payment Link</span>
            </div>

            {
               addPaymentLinks && (
                  <label className={`${styles.label}`}>
                     <span className={styles.isRequired}>Select Payment Link</span>
                     <select ref={paylinkId} className={styles.selectFields} defaultValue={editAppointmentCon && editAppointmentCon.payment_link_id}>
                        {notePaymentLinks.map((paymentLink, index) => (
                           <option key={paymentLink.id} value={paymentLink.id}>{paymentLink.title}</option>
                        ))}
                     </select>
                     {/*<Select options={paymentLinks} name='Payment Link' className={styles.selectFields} onChange={setPayLink} value="id" label="title"/>*/}
                  </label>
               )
            }



            {appointmentId < 1 ?
               <div className={`${styles.addBtnDiv} ${styles.div}`}>
                  <button type="submit" className={styles.addBtn}
                     onClick={(e) => { handleSubmit(e); }}>Add Appointment</button>
               </div>
               :
               <div className={`${styles.addBtnDiv} ${styles.div}`}>
                  <button onClick={() => { clearForm(); navigate('../view-appointment'); }} className='cancelEditButton'>Cancel Edit</button>
                  <button type="submit" className={styles.addBtn}
                     onClick={(e) => { handleSubmit(e, true); }}>Save Change(s)</button>
               </div>
            }
         </div>
      </>
   );
};

export default CreateAppointment;
