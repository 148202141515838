import { createContext, useContext, useState } from "react";
import { MenuContext } from "../DashBoard";
import { Link, Route, Routes } from "react-router-dom";
import titleStyle from '../../../css/SectionTitle.module.css';
import styles from '../../../css/StaffMgmt.module.css';
import { BsList } from "react-icons/bs";
import PageNotFound from "../../404/PageNotFound";
import MakeWithdrawal from "./MakeWithdrawal";

export const staffContext = createContext();
export const EditContext = createContext();

const WithdrawalSettings =()=>{

    const {showMenu, setShowMenu} = useContext(MenuContext);
    const [title, setTitle] = useState('');
    const [view, setView] = useState();
    const [editData, setEditData]= useState({});
    const [editing, setEditing]= useState(false);

    const clearEdit=()=>{
        setEditData({});
        setEditing(false)
    }

    return (
        <staffContext.Provider value={{ view, setView }}>
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Withdrawals</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`} >
                                    <ul onClick={clearEdit}>
                                        <li><Link to='make-withdrawal'>Make Withdrawal</Link></li>
                                        <li><Link to='view-withdrawals'>View Withdrawals</Link></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                        <EditContext.Provider value={{editData,setEditData,editing,setEditing,clearEdit}}>
                        <Routes>
                            <Route path='*' element={<MakeWithdrawal setTitle={setTitle} />} />
                            <Route path='/make-withdrawal/*' element={<MakeWithdrawal setTitle={setTitle} />} />
                            <Route path='/view-withdrawals/*' element={<MakeWithdrawal setTitle={setTitle} />} />
                            <Route path="/*" element={<PageNotFound />} />
                        </Routes>
                        </EditContext.Provider>
                    </div>
                </div>
            </div>
        </staffContext.Provider>
    )
}
export default WithdrawalSettings