import { useState } from 'react';
import { BsRecord, BsRecord2 } from "react-icons/bs";
import styles from './../css/AcctType.module.css';

const AcctType = ({ mode, handler, state, d }) => {
    const [toggler, setToggler] = useState(state);
    const toggle = () => {
        setToggler(!toggler);
    };
    return (
        <>
            <h1 className={`${styles.signIn}`}>
                {!d ? `Log ${mode} As`: `Log In`}
            </h1>
            {!d && <div className={`${styles.type}`}>
                <input type='radio' name='user-type' className={`${styles.radioBtn}`} id='pri-user' value='primary' checked={toggler} onChange={() => { handler(true); toggle(); }} />

                <label htmlFor='pri-user' id='pri-user-label' className={`${styles.typeLabel}`}>
                    Primary User <BsRecord className={`${styles.checkIcon1}`} /> <BsRecord2 className={`${styles.checkIcon}`} />
                    <span className={`${styles.typeLabelSpan}`}>
                        Users who registered directly with Sight-EduPlus.
                    </span>
                </label>
                <input type='radio' name='user-type' className={`${styles.radioBtn}`} id='sec-user' value='secondary' checked={!toggler} onChange={() => { handler(false); toggle();}} />

                <label htmlFor='sec-user' className={`${styles.typeLabel}`} id='sec-user-label'>
                    Secondary User <BsRecord className={`${styles.checkIcon1}`} /> <BsRecord2 className={`${styles.checkIcon}`} />
                    <span className={`${styles.typeLabelSpan}`}>
                        Users registered under an institution, examples are students, staff, employee.
                    </span>
                </label>
            </div>}
        </>
    );
};

export default AcctType;