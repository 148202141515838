import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoriesCss from '../../../css/Categories.module.css';
import ResultCss from '../../../css/Result.module.css';
import { deleteSet } from "../../../redux/reducers/resultReducer";
import { useNavigate } from "react-router-dom";
import { ResultContext } from "./ResultMgmt";
import { MenuContext } from "../DashBoard";
import { useContext } from "react";
import SideOption from "../../../components/SideOption";
import restApi from "../../../helperFunctions/Api2";


const ViewSet = () => {

    const templates = useSelector((state) => state.results.templates);
    const sets = useSelector((state) => state.results.sets);
    const { setEditing, setEditData, setEditType, setTitle } = useContext(ResultContext);
    const { selected, changeSelected, setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDelete = (element) => {
        if (element.id === 'default') {
            setModalText('Cannot delete Default set');
            setModalToggle(true);
        } else {
            if(window.confirm('Are you sure you want to delete this result set?')) {
                setShowLoading(true);
                restApi.PostApi('/result/delete-result-set', {id: element.id}, true)
                .then(result=>{
                    setModalText(result.message);
                    console.log(result);
                    if(result.isSuccessful || result.isSuccesful){
                        dispatch(deleteSet(element.id))
                    }
                })
                .catch(error=>{
                    setModalText(error.message);
                })
                .finally(()=>{
                    setShowLoading(false);
                    setModalToggle(true);
                })
            }
        }
    }
    const handleEdit = (element) => {
        if (element.id === 'default') {
            setModalText('Cannot edit Default set');
            setModalToggle(true);
        } else {
            setEditData(element);
            setEditType('result-set');
            setEditing(true);
            navigate('../add-result-set');
        }
    };


    useEffect(() => {
        setTitle('View Result Set');
        // console.log(sets)
    }, []);

    return (
        <Fragment>
            <div className={CategoriesCss.form}>
                <div className={CategoriesCss.viewQuestionContainer} onClick={() => (selected !== null) && changeSelected(null)}>
                    {Object.values(sets).length>0 ? Object.values(sets).sort((a,b)=> b.id - a.id).map((set, index) => {
                        return <EachSubjectSet key={index} set={set} index={index} selected={selected} changeSelected={changeSelected} handleDelete={handleDelete} handleEdit={handleEdit} templates={templates}/>
                    }) : 'No Set added'}
                </div>
            </div>
        </Fragment>
    )

}

export default ViewSet;

const EachSubjectSet = ({ set, index, selected, changeSelected, handleEdit, handleDelete, templates }) => {
    const institutions = useSelector(state => state.questions.user_institutions);
    const [showOption, setShowOption] = useState(false);
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {
        if (selected == index) {
            setShowOption(true);
        } else {
            setShowOption(false);
        }
    }, [selected])

    return (
        <div className={CategoriesCss.eachQuestion}>
            <div><span>Title: <span className="purpleText">{set.title}</span></span></div>
            <SideOption element={set} index={index} changeSelected={changeSelected} handleEdit={handleEdit} handleDelete={handleDelete} showOption={showOption} />
            <button onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'see less...' : 'see more...'} </button>
            <div style={{ display: seeMore ? 'flex' : 'none', alignItems: 'left', gap: '5px' }} className={ResultCss.eachTemplate}>
                <div >Description: <span className="purpleText">{set.description}</span></div>
                <div >Year/session: <span className="purpleText">{set.session}</span></div>
                {set.template && <div >Result Template: <span className="purpleText">{templates[`${set.template_id}`].title}</span></div>}
                <div >No. of Groups: <span className="purpleText">{set.groups.length}</span></div>
                <div >Requires payment: <span className="purpleText">{set.payment_link?'Yes':'No'}</span></div>
                {set.institution_id && institutions.find(inst => inst.id === set.institution_id) && <div >Institution: <span className="purpleText">{institutions.find(inst => inst.id === set.institution_id).name}</span></div>}
            </div> 
        </div>
    )

}
