import React, { useState, useEffect, useContext } from 'react';
import styles from '../../../css/CreateInst.module.css';
import { eventModalContext } from './events';
import { useSelector, useDispatch } from 'react-redux';
import Api from '../../../helperFunctions/Api2';
import Select from '../../../components/Select';
import { addAppointmentSession, deleteTimeSlot, editAppointmentSession } from '../../../redux/reducers/eventReducer';
import Calendar, { RepeatCalender } from '../../../components/Calendar/calendar';
import { MenuContext } from '../DashBoard';
import { useNavigate } from 'react-router-dom';

export default function AddAppointmentSession ({ seTTitle }) {

    useEffect(() => {
        seTTitle('Add appointment session');
    }, [seTTitle]);

    const navigate = useNavigate();
    const { setShowLoading } = useContext(MenuContext);
    const [resetField, setResetField] = useState(false);
    const appointments = useSelector(state => state.event.appointments);
    const dispatch = useDispatch();
    const { setModalObject, setModalToggle, editSessionCon, setEditSessionCon } = useContext(eventModalContext);
    const [appointment, setAppointment] = useState(null);
    const [bookings, setBookings] = useState({});
    const [recurrency, setRecurrency] = useState({});
    const [session, setSession] = useState({});


    function deleteTime (bookedDayKey, index) {
        if (bookings[bookedDayKey][index].id) {
            setShowLoading(true);
            const payload = {
                id: bookings[bookedDayKey][index].id,
                appointment_id: appointment.id,
                session_id: session.id
            };
            Api.PostApi('/event/delete-timeslot', payload, true)
                .then(result => {
                    // console.log(result, payload, 'server result');
                    if (result.isSuccessful) {
                        dispatch(deleteTimeSlot(payload))
                        let bk = bookings[bookedDayKey].filter((b, ind) => ind !== index);
                        setBookings({ ...bookings, [bookedDayKey]: bk });
                    }
                })
                .finally(() => {
                    setShowLoading(false);
                    // alert('Action failed. please   re-log in');
                });
        } else {
            let bk = bookings[bookedDayKey].filter((b, ind) => ind !== index);
            setBookings({ ...bookings, [bookedDayKey]: bk });
        }
    }
    const validateSingleValues = (vals, msgs) => {
        let i, len = vals.length;
        let status = true;
        let msg = "";
        for (i = 0; i < len; i++) {
            const val = vals[i];
            if (val === '' || typeof val === 'undefined' || val === null) {
                status = false;
                msg = msgs[i];
                break;
            }
        }
        if (!status) {
            setModalObject({ header: "", body: `${msg}`, footer: "" });
            setModalToggle(true);
        }
        return status;
    };

    // const formatObject = (obj) => {
    //    return {
    //       id: obj.id,
    //       subuser_id: obj.subuser_id ? obj.subuser_id : null,
    //       title: obj.appointment.title,
    //       description: obj.appointment.description,
    //       start_date: obj.appointment.start_date,
    //       end_date: obj.appointment.end_date,
    //       categories: obj.is_institution === 1 ? [...obj.categories.map(eC => ({
    //          is_institution: obj.is_institution,
    //          category_id: eC
    //       }))] :
    //          [...obj.email_group.map(eC => ({
    //             is_institution: obj.is_institution,
    //             category_id: eC
    //          }))]
    //    };
    // };

    const clearForm = () => {
        setResetField(!resetField);
        setEditSessionCon(null);
        setSession({});
        setBookings({});
        setRecurrency({});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let check = validateSingleValues([
            appointment.id,
        ], ["Please Select An Appointment"]);
        if (!check) return false;

        if (Object.values(bookings).length < 1 || Object.values(bookings).every(value => value.every(obj => !obj.isAvailable))) {
            setModalObject({
                header: "",
                body: "Please book at least 1 session",
                footer: ""
            });
            setModalToggle(true);
            return false;
        }
        check = Object.values(bookings).some(value => value.some(obj => obj.isAvailable && !validateSingleValues([obj.start_time, obj.end_time], [
            "Please Check all selected slots and enter a valid start time",
            "Please Check all selected slots and enter a valid end time",
        ])));
        if (check) return false;

        if (!Api.checkNetwork()) return false;

        setShowLoading(true);

        if (session.id) {
            const appointmentObject = {
                appointment_id: appointment.id,
                session_id: session.id,
                bookings: bookings[session.session_date].filter(b=> b.start_time && b.end_time).map(b => {
                    let ret = { start_time: b.start_time + ':00', end_time: b.end_time + ':00', slots: b.slots };
                    if (b.id) ret.id = b.id;
                    return ret;
                })
            };
            if (parseInt(appointment.type) === 1)
                appointmentObject.session = { recurrency_value: parseInt(recurrency[session.session_date]) };
            console.log(appointmentObject, 'appointment');
            Api.PostApi('/event/edit-session', appointmentObject, true)
                .then(result => {
                    setShowLoading(false);
                    // console.log(result, 'server result');
                    setModalObject({ header: '', body: result.message, footer: '' });
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        dispatch(editAppointmentSession({
                            ...appointmentObject
                        }));
                        clearForm();

                    }
                    else {
                        setModalObject({ header: '', body: 'Session could not be added', footer: '' });
                        setModalToggle(true);
                    }
                })
                .catch(err => {
                    setShowLoading(false);
                    // alert('Action failed. please   re-log in');
                });
        } else {

            const appointmentObject = {
                appointment_id: appointment.id,
                sessions: Object.keys(bookings).filter(key => bookings[key].some(b => b.isAvailable)).filter(b => b.start_time && b.end_time).map(key => (
                    {
                        session_date: key,
                        recurrency_value: parseInt(appointment.type) === 1 ? Number(recurrency[key]) : null,
                        bookings: bookings[key].map(b => ({ start_time: b.start_time + ':00', end_time: b.end_time + ':00', slots: b.slots }))
                    }
                )),
            };
            Api.PostApi('/event/add-multi-sessions', appointmentObject, true)
                .then(result => {
                    // console.log(appointmentObject, 'appointment');
                    // console.log(result, 'server result');
                    setShowLoading(false);
                    setModalObject({ header: '', body: result.message, footer: '' });
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        dispatch(addAppointmentSession({
                            ...result.data,
                        }));
                        clearForm();

                    }
                    else {
                        setModalObject({ header: '', body: 'Session could not be added', footer: '' });
                        setModalToggle(true);
                    }
                })
                .catch(err => {
                    setShowLoading(false);
                    // alert('Action failed. please   re-log in');
                });
        }


    };

    const fillEditDetails = () => {
        setAppointment(appointments.find(appointment => appointment.id === editSessionCon.consultation_id));
        setSession(editSessionCon);
        let bookings = {};
        bookings[editSessionCon.session_date] = editSessionCon.bookings.map(book => ({ ...book, start_time: book.start_time.slice(0, 5), end_time: book.end_time.slice(0, 5), slots: book.slots, isAvailable: Boolean(book.status) }));
        // console.log(bookings);
        setBookings(bookings);
        setEditSessionCon(null);
    };

    useEffect(() => {
        // console.log(editSessionCon);
        editSessionCon && editSessionCon.id && fillEditDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSessionCon]);

    useEffect(() => {
        appointment && setAppointment(appointments.find(app => appointment.id === app.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointments]);
    // useEffect(() => {
    //     console.log(appointment);
    //     let bookings = {}
    //     appointment && appointment.id && appointment.sessions.forEach(session => bookings[session.session_date] = session.bookings.map(book => ({ ...book, start_time: book.start_time.slice(0, 5), end_time: book.end_time.slice(0, 5), slots: book.slots, isAvailable: Boolean(book.status) })));
    //     setBookings(bookings);
    // }, [appointment]);
    // useEffect(() => {
    //     console.log(bookings);
    // }, [bookings]);


    return (
        <div className={`${styles.form} ${styles.form2}`} >
            <label className={styles.label}>
                <span className={styles.isRequired}>Appointment</span>
                <Select options={appointments} onChange={setAppointment} placeholder="Select Appointment" defaultValue={appointment} reset={resetField} label='title' value='id' disabled={Boolean(session.id)} />
            </label>

            {appointment && parseInt(appointment.type) === 0 && <>
                <Calendar bookings={bookings} setBookings={setBookings} editingDay={session.session_date} deleteTime={deleteTime} />
            </>}
            {appointment && parseInt(appointment.type) === 1 && <>
                <RepeatCalender bookings={bookings} setBookings={setBookings} recurrency={recurrency} setRecurrency={setRecurrency} editingDay={session.session_date} deleteTime={deleteTime} />
            </>}

            {!session.id ? <div className={`${styles.addBtnDiv} ${styles.div}`}>
                <button type="submit" className={styles.addBtn}
                    onClick={(e) => { handleSubmit(e); }}>Add Appointment Session</button>
            </div>
                :
                <div className={`${styles.addBtnDiv} ${styles.div}`}>
                    <button onClick={() => { clearForm(); navigate('../view-appointment'); }} className='cancelEditButton'>Cancel Edit</button>
                    <button type="submit" className={styles.addBtn}
                        onClick={(e) => { handleSubmit(e, true); }}>Save Change(s)</button>
                </div>}
        </div>
    );
};