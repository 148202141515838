import {memo, useEffect, useState} from "react";
import {Bar } from "react-chartjs-2";
import css from "./css/BarGraph.module.css";
import {Chart as ChartJS,BarElement, ArcElement, Tooltip,Legend,CategoryScale,LinearScale} from 'chart.js';

ChartJS.register({
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement
  })

const BarGraph = ({labels, data, labelText, backgroundColor, sideText, bottomText}) =>{
    const [graphData, setGraphData] = useState(
      {
        labels: ['Jan','Feb','Mar','April','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
        datasets: [
            {
              label: "Item List",
              data: [40,50,60,40,50,60,40,50,60,40,50,60],
              backgroundColor: [
                '#8884d8','#8884d8',
                '#8884d8','#8884d8',
                '#8884d8','#8884d8',
                '#8884d8','#8884d8',
                '#8884d8','#8884d8',
                '#8884d8','#8884d8'
              ],
              borderWidth: 1,
            }
        ]
      }
    );
    const [graphOption, setGraphOption] = useState({
        maintainAspectRation:false,
        scale:{
          y:{
            beginAtZero:true
          }
        },
        legend:{
          labels:{
            fontSize:18,
            usePointStyle:true,
            boxSize:10, 
            pointStyle:'circle',  
          } 
        }
      })

    useEffect(()=>{
        setGraphData({
          labels: labels,
          datasets: [
              {
                label: labelText,
                data: data,
                backgroundColor: backgroundColor ? backgroundColor : [
                  '#8884d8','#8884d8',
                  '#8884d8','#8884d8',
                  '#8884d8','#8884d8',
                  '#8884d8','#8884d8',
                  '#8884d8','#8884d8',
                  '#8884d8','#8884d8'
                ],
                borderWidth: 1,
              }
          ]
        })
    },[data])

    return(
        <>
            <div className={css.graphContainer}>
                <Bar data={graphData} options={graphOption} />

                <span className={css.sideText}>{sideText}</span>
                <span className={css.bottomText}>{bottomText}</span>
            </div>
        </>
    )
}

export default memo(BarGraph);