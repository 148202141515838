import { useEffect,createContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from '../../../../css/StaffMgmt.module.css';
import Modal from '../../../../components/modal';
import { Link, Route, Routes } from "react-router-dom";
import titleStyle from '../../../../css/SectionTitle.module.css';
import PageNotFound from "../../../404/PageNotFound";
import { BsList } from 'react-icons/bs';
import css from './chat.module.css';
import CategoryChat from "./CategoryChat";
import InstChat from './InstChat';


export const staffContext = createContext();
export const chatContext = createContext();

const Chat = () =>{
    const [chatMode,setChatMode] = useState(0); //0 means grounp, 1 means institution;
    const [modalObject, setModalObject] = useState({});
    const [modalToggle, setModalToggle] = useState(false);
    const [currentRoom,setCurrentRoom] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [view, setView] = useState('');
    const [title, setTitle] = useState('');
    const institutions = useSelector(state=> state.questions.user_institutions);

    const ChatContextValue = {
        chatMode,
        currentRoom,
        setCurrentRoom,
    }
    
    useEffect(()=>{

    },[])

    return (
        <staffContext.Provider value={{view, setView}} >
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
               <h1 className={`${styles.title}`}>COMMUNICATION</h1>
               <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`}>
                                    <ul>
                                        <li><Link to='category-chat'>Category Chat</Link></li>
                                        <li><Link to='institution-chat'>institution Chat</Link></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={css.container}>
                        <chatContext.Provider value={ChatContextValue}>
                            <Routes>
                                <Route path="*" element={<CategoryChat />} />
                                <Route path="category-chat" element={<CategoryChat />} />
                                <Route path="institution-chat" element={<InstChat />} />
                            </Routes>
                        </chatContext.Provider>
                    </div>
                 
               </div>
            </div>
        </staffContext.Provider>
    
    )

}

export default Chat;










