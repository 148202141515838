import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {dateObject} from '../../../helperFunctions/Api2';
import DualBarGraph from "../../../components/Graph/DualBarGraph";
import css from "../../../css/ServiceDashboard.module.css";
import { Link } from 'react-router-dom';
import VideoPlayer from "../../../components/VideoPlayer";

const Dashboard = ({setTitle}) =>{
    const tests = useSelector(store => store.test.tests);
    const [data,setData] = useState(null);
    const [takenTest, setTakenTest] = useState([]);
    const [takenTestCnt, setTakenTestCnt] = useState(0);
    const {datetime} = useSelector(store => store.account.username);
    const [showVideo, setShowVideo] = useState(false);

    const arrageData = (year) =>{
      let dataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
      let dataArr2 = [0,0,0,0,0,0,0,0,0,0,0,0];
      let cnt = 0;
     
       tests.forEach(test => {
           let d = new Date(test.created_at);
           let vYear = d.getFullYear();
           if(vYear == year){
               dataArr[d.getMonth()]++;

               if(test.analytic_id){
                 dataArr2[d.getMonth()]++;
                 cnt++;
               }
           }
       })
      
      setData(dataArr); setTakenTestCnt(cnt);
      setTakenTest(dataArr2);
   }

    useEffect(()=>{
      let d = new Date(datetime);
      let year = d.getFullYear();
      arrageData(year)
    }, [tests])

    return (
        <div className={css.container}>
          <div>Test Dashboard</div>
          <div>
              <div>
                  <div>
                    <DualBarGraph data={data} data2={takenTest} sideText={"No. of Test"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText={`Test Created`} labelText2={`Test Taken`}/>
                    <br/>
                    <div>
                      Total numbers of test: <span className={css.purpleText}>{tests.length}</span><br/>
                      Total test taken: <span className={css.purpleText}>{takenTestCnt}</span><br/>
                    </div>
                  </div>
                  <div>
                    <div>Sight-EduPlus Test</div>
                    <p>
                       Sight-EduPlus Test enables you to create custom test or simulate real exam scenario while also increasing retention of what you have learnt.
                    </p>
                    <div>
                       <Link to="../test" style={{backgroundColor:"var(--secondary-color)",color:"var(--white-color)",border:"1px solid var(--secondary-color)"}}>
                        Get Started
                       </Link>
                       <button onClick={()=>setShowVideo(!showVideo)} style={{border:"1px solid var(--secondary-color)",border:"1px solid var(--secondary-color)",backgroundColor:"var(--white-color)"}}>
                       Watch Video
                       </button>
                    </div>
                  </div>
              </div>
              {showVideo && <VideoPlayer serviceCode={'SET'}/>}
          </div>
        </div>
      )
}

export default Dashboard;