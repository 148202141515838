import styles from '../../../css/Categories.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useContext, useRef} from 'react';
import Select from "../../../components/Select";
import { ResultContext } from "./ResultMgmt"
import templateCss from "../../../css/ResultTemplate.module.css";
import Api from "../../../helperFunctions/Api2";
import Loading from '../../../components/loading';
import {AddToUserResult, setAllUserResult, AddTableRow,
     setUserResult, DeleteTableRow, addToUsersResults} from '../../../redux/reducers/resultReducer';
import ResultCss from '../../../css/Result.module.css';
import { read, utils, writeFile } from 'xlsx';
import { ModalContext } from '../../../App';

export const getCertificatesValues = (text) => {
    let allVar = text.split('{');
    let allVarLen = allVar.length;

    for (let i = 1; i < allVarLen; i++) {
        allVar[i - 1] = allVar[i].split('}')[0];
    }
    allVar.pop();
    //setCertificate(allVar);
    return allVar;
};
const AddUserResult = ()=>{
    const { setTitle, clearEdit, editing, editType, editData, } = useContext(ResultContext);
    const { popup } = useContext(ModalContext);
    //const resultGroups = useSelector(state => state.results.groups);
    const resultSets = useSelector(state => state.results.sets);
    const resultTemplates = useSelector(state => state.results.templates);
    const newUserResult = useSelector(state => state.results.userResult);
    const resCourses = useSelector(state => state.results.courses);

    const [selSet, setSelSet] = useState({});
    const [Sets, setSets] = useState(Object.values(resultSets))
    const [resultGroups, setResultGroups] = useState([])
    const [selResultGroup, setSelResultGroup] = useState({});
    const [template, setTemplate] = useState({});
    const [isIndResult, setIsIndResult] = useState(true);
    const [isCreated, setIsCreated] = useState(true);
    const institutions = useSelector(state => state.questions.user_institutions);
    const uploadResult = useRef();
    const [userRes, setUserRes] = useState({});
    const [autoGenPwd, setAutoGenPwd] = useState(false);
    const [sendUserPwd, setSendUserPwd] = useState(true);
    //const [allResults, setAllResults] = useState({});
    const allResults = useSelector(state => state.results.userResult);
    const [userName, setUserName] = useState(['']);
    const [userRegNo, setUserRegNo] = useState(['']);
    const [userEmail, setUserEmail] = useState(['']);
    const [showLoading, setShowLoading] = useState(false);
    const [courseIndex, setCourseIndex] = useState(-1);
    const [certificates, setCertificate] = useState([]);
    const [userPwd, setUserPwd] = useState('')
    const dispatch = useDispatch();


    useEffect(()=>{
        if (editing && editType === 'result') {
            dispatch(setUserResult({result: JSON.parse(editData.result), editing: true}))
        } else if(selResultGroup.id){
            const temp = JSON.parse(resultTemplates[`${resultSets[`${selResultGroup.set_id}`].template_id}`].template).sections;
            setTemplate(temp);
            dispatch(setUserResult({result: temp}));
        }else {
            setTemplate({})
            dispatch(setUserResult({}))
        }
    },[selResultGroup])

    useEffect(()=>{
        setSelResultGroup({});
        if(selSet.id){
            setResultGroups(selSet.groups);
            editing && editType === 'result' && setSelResultGroup(resultSets[editData.result_set_id].groups.find(group => group.id === editData.result_group_id || group.id === editData.resultGroup ));
        }else {
            setResultGroups([]);
        }
    }, [selSet])


    useEffect(() => {
        setSets(Object.values(resultSets));
        setSelSet({});
    },[resultSets])
    useEffect(() => {
        setTitle('Add Result');
    },[])

    useEffect(()=>{
        if(editing && editType === 'result' && editData.isIndividual ){
            setSelSet(resultSets[editData.result_set_id]);
            setUserEmail([editData.user.email]);
            setUserName([editData.user.user_details]);
            setUserRegNo([editData.user.user_id]);
            setAutoGenPwd(editData.autoGenPwd);
            setSendUserPwd(editData.sendUserPwd);
            setIsIndResult(editData.isIndividual);
            setTemplate(editData.template);
        }
        else{
            clearFields();
        }

    },[editType])
    const clearFields = () => {
        editing && editType === 'result' && clearEdit();
        setSets(Object.values(resultSets));
        setSelSet({});
    }
    const setIndexUserRegNo = (index, value)=>{
        let regArr = userRegNo;
        regArr[index] = value;
        setUserRegNo(regArr);
    }

    const setIndexUserName = (index, value)=>{
        let regArr = userName;
        regArr[index] = value;
        setUserName(regArr);
    }

    const setIndexUserEmail = (index, value)=>{
        let regArr = userEmail;
        regArr[index] = value;
        setUserEmail(regArr);
    }

    const HandleSubmit = () =>{
        //Add Validation here
        //if (!selSet.id || !selResultGroup.id || !userName[0] || !userRegNo[0] || (sendUserPwd && !userEmail[0])) {
        if (!selSet.id || !selResultGroup.id || (sendUserPwd && !userEmail[0])) {
            popup('Please Completely fill all required fields');
            return
        }
        const userResultOjb = {
            setId: selSet.id,
            isIndividual: isIndResult,
            //isCreated: isCreated,
            autoGenPwd: autoGenPwd,
            sendUserPwd: sendUserPwd,
            user: {},
            is_institution: selResultGroup.is_institution
        };

        if(isIndResult){
            
            userResultOjb.user.result_group_id = selResultGroup.id;
            userResultOjb.resultGroup = selResultGroup.id;
            userResultOjb.result_set_id = selSet.id;
            
            if(selResultGroup.is_institution) {
                userResultOjb.user.subuser_id = userRes.id
                userResultOjb.user.user_id = userRes.user_id;
                userResultOjb.user.user_details = `${userRes.firstName} ${userRes.middleName} ${userRes.lastName}`;
                //userResultOjb.user.email = userRes.email
                
            }
            else{
                userResultOjb.user.user_details = userName[0];
                //userResultOjb.user.user_id = userRegNo[0];  
                userResultOjb.user.email = userEmail[0];
                userResultOjb.user.password = userPwd
            }

            userResultOjb.result = allResults;

            if(sendUserPwd) userResultOjb.user.email = userEmail[0];
        }
        else{
            userResultOjb.resultGroup = selResultGroup.id;
            userResultOjb.result_set_id = selSet.id;
            userResultOjb.result = allResults;
            userResultOjb.user.email = userEmail;
            userResultOjb.user.user_id = userRegNo;
            userResultOjb.user.user_details = userName;
            userResultOjb.courseIndex = courseIndex
        }

        userResultOjb.isTranscript = selSet.result_type == 1 ? true : false;
        userResultOjb.autoGenPwd = autoGenPwd;
        userResultOjb.sendUserPwd = sendUserPwd;
        userResultOjb.template = template;

        //console.log(userResultOjb, 'user result')
        setShowLoading(true);
        
        Api.PostApi('/result/add-result',userResultOjb, true)
        .then(result=>{
            setShowLoading(false);
            console.log(result, 'result response');
            popup(result.message);
            if(result.isSuccesful){
                if(isIndResult){
                    dispatch(addToUsersResults(result.data));
                    if(result.data.result_group_id)
                        Api.PostApi('/result/save-group-result',{
                            result: result.groupRes,
                            resultGroup: result.data.result_group_id
                        }, false)
                        .then(response=>{
                            console.log(response) 
                        })
                }
                clearFields();
            }
            /* else {
                if(isIndResult)dispatch(addToUsersResults({...userResultOjb, id: 1}));
                clearFields();
            } */
        })
    }

    const HandleResultImport = (rows)=>{
        console.log("text")
        let defaultResult; let secId;

        for(let x in template){
            if(template[x].isDefault == Number(x)){
                 defaultResult = template[x].content;
                 secId = template[x].id
            }
        }

        const len = rows.length; 
        let email; let userId; let username;

        const allEmails = []; const allStudentIds = [];
        const allUsersNames = [];

        let rowIndex = -1;
        for(let x in rows[0]){
            rowIndex++;
            if(x.toLowerCase() == "email") email = x;
            if(x.toLowerCase() == "studentid") userId = x;
            if(x.toLowerCase() == "name" || x.toLowerCase() == "fullname") username = x;
            if(x.toLowerCase() == "course" || x.toLowerCase() == "subject") setCourseIndex(rowIndex);
        }

        for(let i = 0; i < len; i++){
            let cnt = -1;
            for(let x in defaultResult){
                cnt++; let isFound = false; let fileCol = ""
                for(let u in rows[i]){
                    if(u.toLowerCase() == defaultResult[x].name.toLowerCase()){
                        isFound = true;
                        fileCol = u;
                    } 
                }
                if(isFound)
                    dispatch(AddToUserResult({
                        sectionId: secId,
                        row: i,
                        column: x,
                        value: rows[i][fileCol]
                    }))
            }

            if(isIndResult){
                if(i==0){
                    if(email) allEmails.push(rows[i][`${email}`])

                    if(userId) allStudentIds.push(rows[i][`${userId}`])
        
                    if(username) allUsersNames.push(rows[i][`${username}`])
                }
            }
            else{
                if(email) allEmails.push(rows[i][`${email}`])

                if(userId) allStudentIds.push(rows[i][`${userId}`])
    
                if(username) allUsersNames.push(rows[i][`${username}`])
            }
        }
        
        setUserName(allUsersNames);
        setUserEmail(allEmails);
        setUserRegNo(allStudentIds);

        //Console log result here
    }

    const HandleFiles = ($event)=>{
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    console.log(rows, 'read rows')
                    HandleResultImport(rows)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const setUsersResultFormat = (group)=>{
        if(group.id){
            setSelResultGroup(group);
            const temp = JSON.parse(resultTemplates[`${resultSets[`${group.set_id}`].template_id}`].template).sections

            const uRes = {};
            for(let x in temp){
                uRes[`${x}`] = [{}]
            }
            // dispatch(setAllUserResult(uRes));
            //Set result format here
        }
    }

    return(
        <>
            <Loading shouldShow={showLoading} />
            <div style={{overflowX: 'scroll'}} className={`${styles.form} `}>
                <label htmlFor='set' className={`${styles.label}`}>
                    <span className={styles.isRequired}>Select Result Set</span>
                    <Select name="resultSet" options={Sets} onChange={setSelSet} label="title" value="id" defaultValue={selSet} />
                </label>
                <label htmlFor='group' className={`${styles.label}`}>
                    <span className={styles.isRequired}>Select Result Group</span>
                    <Select options={resultGroups} onChange={setUsersResultFormat} label="title" value="id" defaultValue={selResultGroup} />
                </label>
                {selResultGroup.id && (
                    <>
                        <label >
                            <input style={{ maxHeight: 15 }} type="radio" checked={isIndResult} name="groupResult" onChange={() => setIsIndResult(true)} /><span>Add Individual Result</span><br />
                            <input style={{ maxHeight: 15 }} type="radio" checked={isIndResult ? false : true} name="groupResult" onChange={() => setIsIndResult(false)} /><span>Add Group Results</span>
                        </label>
                        <label >
                            <input style={{ maxHeight: 15 }} type="radio" checked={isCreated} name="is_created" onChange={() => setIsCreated(true)} /><span>Create {isIndResult ? `Individual`:`Group`} Result</span><br />
                            <input style={{ maxHeight: 15 }} type="radio" checked={isCreated ? false : true} name="is_created" onChange={() => setIsCreated(false)} /><span>Upload {isIndResult ? `Individual`:`Group`} Results</span>
                        </label>
                        {
                            isIndResult ? 
                            (<>{isCreated ? (
                                (selResultGroup.is_institution ? (
                                    <>
                                        <label htmlFor='user' className={`${styles.label}`}>
                                            <p>Select user</p>
                                            <Select id="user" onChange={setUserRes} options={[]} label='name' value='id'/>
                                        </label>
                                    </>
                                ) : (
                                    <>
                                        {/*<label htmlFor='name' className={`${styles.label}`}>
                                            <span className={styles.isRequired}>User name</span>
                                            <input value={userName[0]} onChange={(e)=> setUserName([e.target.value])} type='text' id='title' className={`${styles.input}`} placeholder='Enter name of result owner' required />
                                        </label>*/}
                                        <label htmlFor='userId' className={`${styles.label}`}>
                                            <span className={styles.isRequired}>Email/UserId</span>
                                            <input value={userEmail[0]} onChange={(e)=> setUserEmail([e.target.value])} type='text' id='Enter student ID' className={`${styles.input}`} placeholder='Enter student Id' required />
                                        </label>
                                        
                                    </>
                                )
                                )
                            ):(
                                <label className={`${styles.label}`}>
                                    <span>Select File {`(only excel files allowed)`}</span><br/>
                                    <input ref={uploadResult} type="file" onChange={HandleFiles} />
                                </label>
                            )}
                           {!selResultGroup.is_institution && <>
                                <label className={styles.labelinp}>
                                    <input className={styles.reqpay} type='radio' name="resultPass" checked={autoGenPwd} onChange={()=> setAutoGenPwd(true)}/>
                                    <span>Auto generate password </span> <br/>
                                    <input className={styles.reqpay} type='radio' name="resultPass" checked={!autoGenPwd} onChange={()=> setAutoGenPwd(false)}/>
                                    <span>Input password </span>
                                </label>
                                {
                                    autoGenPwd ? (<>
                                        <label className={styles.label}>
                                            <input className={styles.reqpay} type='checkbox' checked={sendUserPwd} onChange={()=> sendUserPwd ? setSendUserPwd(false):setSendUserPwd(true)}/>
                                            <span>Send User his/her password </span> 
                                        </label>
                                        {
                                           /* sendUserPwd && !selResultGroup.is_institution && (
                                                <label htmlFor='userEmail' className={`${styles.label}`}>
                                                    <span className={styles.isRequired}>User Email</span>
                                                    <input value={userEmail[0]} onChange={(e)=>setUserEmail([e.target.value])} type='text' id='userEmail' className={`${styles.input}`} placeholder='Enter user email' required />
                                                </label>
                                            )*/
                                        }
                                    </>) : 
                                    <label className={styles.label}>
                                        <span className={styles.isRequired}>Enter password</span>
                                        <input placeholder='Enter user password' value={userPwd} onChange={e=>setUserPwd(e.target.value)} type='text' className={`${styles.input}`} />
                                    </label>
                                }
                            </>
                            }
                            
                            </>)
                             : (
                                <>{!isCreated && (
                                    <label className={`${styles.label}`}>
                                        <span>Select File {`(only excel files allowed)`}</span><br/>
                                        <input ref={uploadResult} type="file" onChange={HandleFiles} />
                                    </label>
                                )}
                                <>
                                    <label className={styles.label}>
                                        <input className={styles.reqpay} type='checkbox' checked={autoGenPwd} onChange={()=> autoGenPwd ? setAutoGenPwd(false):setAutoGenPwd(true)}/>
                                        <span>Auto generate password </span> 
                                    </label>
                                    {
                                        autoGenPwd && (
                                            <label className={styles.label}>
                                                <input className={styles.reqpay} type='checkbox' checked={sendUserPwd} onChange={()=> sendUserPwd ? setSendUserPwd(false):setSendUserPwd(true)}/>
                                                <span>Send Users their password </span> 
                                            </label>
                                        )
                                    }
                                </>
                                </>           
                            )
                        }

                        {
                            (isIndResult && template) && (
                                Object.values(template).map((section, index)=>(
                                <div key={index} className={ResultCss.resDiv}>
                                    <h2>{section.name}</h2>
                                    {
                                        section.type == 'table' ? (
                                            <div className={templateCss.ColDiv}>
                                                <table className={templateCss.table}>
                                                    <thead>
                                                        <tr>{
                                                                Object.values(section.content).map((column, index)=>(
                                                                    <th key={index}>
                                                                        {column.name}
                                                                    </th>
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {newUserResult[section.id].map((row, rowIndex)=>(
                                                            <tr key={rowIndex}>
                                                            {
                                                                Object.values(section.content).map((column, index)=>(
                                                                    <td key={index}>{
                                                                        column.type=='img'?
                                                                        <input type="file" onChange={(e)=>{

                                                                        }}/>
                                                                        :
                                                                        <input value={allResults[section.id][rowIndex][index] || ''} onChange={(e)=>dispatch(AddToUserResult({
                                                                            sectionId: section.id,
                                                                            row: rowIndex, //For row
                                                                            column: index, //For column
                                                                            value: e.target.value
                                                                        }))} type="text" placeholder={`Enter ${column.name}`} disabled={column.type.indexOf('fixed') > 0 ? true: false}/>
                                                                    }
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                        ))                         
                                                        }
                                                    </tbody>
                                                </table>
                                                {isCreated && (
                                                <div>
                                                    <button onClick={()=>dispatch(AddTableRow({
                                                    sectionId: section.id,
                                                    templateId: resultSets[`${selResultGroup.set_id}`].template_id
                                                    }))}>Add Row</button> &nbsp;
                                                    {newUserResult[section.id].length > 1 && (
                                                        <button onClick={()=>dispatch(DeleteTableRow({
                                                            sectionId: section.id
                                                        }))}>Remove Row</button>
                                                    )
                                                }
                                                </div>
                                                )}
                                            </div>
                                        ) : <>{section.type == 'comment' ?
                                            
                                            <div className={ResultCss.viewComment}>
                                                {
                                                    Object.values(section.content).map((row,index)=>(
                                                        <div key={index}>
                                                            <span>{row.name}:</span>
                                                            {row.type=='img' ?
                                                            <input type='file' onChange={(e)=>{

                                                            }} />
                                                            :<input value={allResults[section.id][0][row.id] || ''} placeholder={`Enter ${row.name}`} onChange={(e)=>dispatch(AddToUserResult({
                                                                            sectionId: section.id,
                                                                            row: 0,
                                                                            column: row.id,
                                                                            value: e.target.value
                                                                        }))} type="text" disabled={row.type.indexOf('fixed') > 0 ? true: false}/>
                                                                    }
                                                            <br/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        :
                                        <div className={ResultCss.viewComment}>
                                            {getCertificatesValues(section.content['0'].name).map((vals, index)=>(
                                                <div key={index}>
                                                    <span>{vals}:</span>
                                                    <input value={allResults[section.id][0][index] || ''} placeholder={`Enter ${vals}`} onChange={(e)=>dispatch(AddToUserResult({
                                                                    sectionId: section.id,
                                                                    row: 0,
                                                                    column: index,
                                                                    value: e.target.value
                                                                }))} type="text"/>
                                                            
                                                    <br/>
                                                </div>
                                            ))
                                            }
                                        </div>
                                        }</> 
                                    }
                                </div>
                            )))
                        }
                        {
                            /**
                             * Check for group result
                             */
                            (!isIndResult && template) && (
                                Object.values(template).map((section, index)=>(
                                    <div key={index}>
                                    {(section.type == 'table' && section.id == section.isDefault) && 
                                        <div className={templateCss.ColDiv}>
                                            <h2>{section.name}</h2>
                                                <table className={templateCss.table}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Student Id
                                                            </th>
                                                            {
                                                                sendUserPwd && (
                                                                    <th>Student Email</th>
                                                                )
                                                            }
                                                            {
                                                                Object.values(section.content).map((column, index)=>(
                                                                    <th key={index}>
                                                                        {column.name}
                                                                    </th>
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {newUserResult[section.id].map((row, rowIndex)=>(
                                                            <tr key={rowIndex}>
                                                              
                                                              <td>
                                                                <input value={userRegNo[rowIndex]} type="text" placeholder='Enter Student Id' onChange={(e)=>setIndexUserRegNo(rowIndex, e.target.value)}/>
                                                              </td>
                                                              {
                                                                sendUserPwd && (
                                                                    <td>
                                                                        <input type="text" value={userEmail[rowIndex]} placeholder='Enter Student Email' onChange={(e)=>setIndexUserEmail(rowIndex, e.target.value)}/>
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                Object.values(section.content).map((column, index)=>(
                                                                    <td key={index}>
                                                                        <input value={allResults[section.id][rowIndex][index]} onChange={(e)=>dispatch(AddToUserResult({
                                                                            sectionId: section.id,
                                                                            row: rowIndex, //For row
                                                                            column: index, //For column
                                                                            value: e.target.value
                                                                        }))} type="text" placeholder={`Enter ${column.name}`}/>
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                        ))                         
                                                        }
                                                    </tbody>
                                                </table>
                                                {isCreated && (
                                                <div>
                                                    <button onClick={()=>dispatch(AddTableRow({
                                                    sectionId: section.id,
                                                    templateId: resultSets[`${selResultGroup.set_id}`].template_id
                                                    }))}>Add Row</button> &nbsp;
                                                    {newUserResult[section.id].length > 1 && (
                                                        <button onClick={()=>dispatch(DeleteTableRow({
                                                            sectionId: section.id
                                                        }))}>Remove Row</button>
                                                    )
                                                }
                                                </div>
                                                )}
                                            </div>
                                    }
                                    </div> 
                                ))
                            )
                        }
                            </>
                        )
                }

                <div className={styles.buttonContainer}>
                    <button className={styles.viewBtn} onClick={HandleSubmit}>Save Result</button>
                    {/* {editing && editType==='result-group' && <button className={CategoriesCss.viewBtn} onClick={handleCancel} >Cancel</button>} */}
                </div>
            </div>
        </>
    )
}

export default AddUserResult