import styles from './css/Categories.module.css';
import { useEffect, useState,useContext } from 'react';
import Api from "../../../Api";
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory,editCategory } from '../../../redux/reducers/questionReducer';
import Select from '../../../components/Select';
import { CategoryContext } from './Categories';

const visibilty_arr = [
    {label:'Personal',value:0 },
    {label:'Institution',value:1 },
    {label:'Public',value:3 },
]

const CreateQuestionCategory = ({ setTitle }) => {
    const {editObject,setEditObject} = useContext(CategoryContext);
    const [categoryName,setCategoryName] = useState('');
    const [groupName,setGroupName] = useState('');
    const [categoryDescription,setCategoryDescription] = useState('');
    const [defaultVisibility,setDefaultVisibility] = useState('');
    const [defaultInstitution,setDefaultInstitution] = useState('');
    const [visibility,set_visibility] = useState({});
    const navigate = useNavigate();
    const [sel_institution,set_sel_institution] = useState(null);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [reset,setReset] = useState(false);
    const user_institutions = useSelector((store) => store.questions.user_institutions);

    const dispatch = useDispatch();

    const message1 = `Please completely fill out all required fields`;
    // const message2 = `Category information accepted!`;
    const message3 = `Process Failed. Please login again!`;

    const clearForm = ()=>{
        setCategoryName('');
        setGroupName('');
        setCategoryDescription('');
    }

    const handleAddCategory = (e) =>{
        //Call show modal
        e.preventDefault();
        const new_category = {
            name: categoryName,
            main_category: groupName,
            description: categoryDescription,
            audience_type: visibility.value,
            Institution_id:sel_institution?sel_institution.id:null,
        };
        //console.log(new_category,'payload')
        if (new_category.name === '' ||
            new_category.main_category === '' ||
            new_category.description === '' ||
            Object.keys(visibility).length <= 0)  {
            setModalText(message1);
            setModalToggle(true);
            return false;
        }
        if(visibility.value === 1 && (!sel_institution || !sel_institution.id)){
            setModalText("Please select an institution");
            setModalToggle(true);
            return false;
        }

        else return (() => {
            setShowLoading(true);
            Api.PostApi('/question/add-category', new_category, true)
                .then(response => response.json())
                .then(result => {
                    setShowLoading(false);
                    console.log(result);
                    if (result.isSuccessful) {
                        clearForm();
                        setModalText('Category added successfully');
                        setModalToggle(true);
                        new_category.id = result.data.id;
                        dispatch(addCategory(new_category));
                    }
                    else {
                        setShowLoading(false);
                        // alert('Action failed, please re-login');
                        setModalText(result.message);
                        setModalToggle(true);
                        //sessionStorage.removeItem('eduplus_tkn');
                        //navigate("/account/sign-in");
                    }

                })
                .catch(error => {
                    // alert('Action failed, please re-login');
                    setShowLoading(false);
                    setModalText(message3);
                    setModalToggle(true);
                    sessionStorage.removeItem('eduplus_tkn');
                    navigate("/account/sign-in");
                });

            return false;
        })();
    }
    const handleEditCategory = (e) =>{
        //Call show modal
        e.preventDefault();
        const new_category = {
            ...editObject,
            name: categoryName,
            main_category: groupName,
            description: categoryDescription,
            audience_type: visibility.value,
            institution_id:(sel_institution && sel_institution.id)?sel_institution.id:null,
        };
        if (new_category.name === '' ||
            new_category.main_category === '' ||
            new_category.description === '' /* || !new_category.is_visible */)  {
            setModalText(message1);
            setModalToggle(true);
            return false;
        }
        if(visibility.value === 1 && (!sel_institution || !sel_institution.id)){
            setModalText("Please select an institution");
            setModalToggle(true);
            return false;
        }
        else return (() => {
            setShowLoading(true);
            Api.PostApi('/question/edit-category', new_category, true)
                .then(response => response.json())
                .then(result => {
                    console.log(result, 'result')
                    setShowLoading(false);
                    alert(result.message);
                    //setModalToggle(true);
                    console.log(result);
                    if (result.isSuccessful) {                   
                        clearForm();
                        dispatch(editCategory(new_category));
                        setEditObject(null);
                        navigate('../view-categories')
                    }
                    else {
                        setShowLoading(false);
                        // alert('Action failed, please re-login');
                        setModalText(result.message);
                        setModalToggle(true);
                        //sessionStorage.removeItem('eduplus_tkn');
                        //navigate("/account/sign-in");
                    }

                })
                .catch(error => {
                    // alert('Action failed, please re-login');
                    setShowLoading(false);
                    setModalText(message3);
                    setModalToggle(true);
                    sessionStorage.removeItem('eduplus_tkn');
                    //navigate("/account/sign-in");
                });

            return false;
        })();
    }

    useEffect(() => {
        setTitle('Add category');
    }, [setTitle]);

    useEffect(()=>{
        if(editObject){
            console.log(editObject);
            setCategoryName(editObject.name);
            setGroupName(editObject.main_category || '')
            setCategoryDescription(editObject.description);
            setDefaultVisibility({value:editObject.audience_type})
            set_visibility({value:editObject.is_visible})
            if(editObject.institution_id){
                setDefaultInstitution({id:editObject.institution_id})
                set_sel_institution({id:editObject.institution_id})
            }
        }
        else{
            setDefaultInstitution('');
            setDefaultVisibility('');
            setReset(!reset);
            setCategoryDescription('')
            setCategoryName('');
            setGroupName('')
        }
    },[editObject])

    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <div className={`${styles.mainView}`}>
                <form className={`${styles.form}`}>
                    <label htmlFor='cat-name' className={`${styles.label}`}>
                        <span className={styles.isRequired}>Category Name</span>
                        <input  type='text' id='cat-name' className={`${styles.input}`} value={categoryName} placeholder='Enter the Category Name' onChange={(e)=>setCategoryName(e.target.value)} required />
                    </label>
                    <label htmlFor='grp-name' className={`${styles.label}`}>
                        <span className={styles.isRequired}>Group Name</span>
                        <input  type='text' id='grp-name' className={`${styles.input}`} value={groupName} placeholder='Enter name for grouping categories' onChange={(e)=>setGroupName(e.target.value)} required />
                    </label>

                    <label htmlFor='cat-des' className={`${styles.label}`}>
                        <span className={styles.isRequired}>Category Description</span>
                        <textarea  id='cat-des' className={`${styles.textarea}`} placeholder='Add an Explanatory Description' rows={5} required value={categoryDescription} onChange={(e)=>setCategoryDescription(e.target.value)} />
                    </label>

                    <label htmlFor='visibility' className={styles.label}>
                        <span className={styles.isRequired}>Category Visibility</span>
                       
                        <Select options={visibilty_arr} onChange={set_visibility}
                            defaultValue={defaultVisibility} reset={reset} label='label' value='value'/>
                    </label>
                    {visibility && visibility.value === 1 && <label className={styles.label}>
                        <span>Select Institution</span>
                        <Select options={user_institutions} value="id" label="name" onChange={set_sel_institution} defaultValue={defaultInstitution}
                          placeholder='Select Institution' reset={reset} />

                    </label>}
                    {editObject && Object.keys(editObject).length > 0 ?
                    <span style={{display:'flex', gap:'30px'}}>
                        <button onClick={()=>{clearForm();setEditObject(null); navigate('../view-question-category')}} className={`${styles.saveCatBtn} cancelEditButton`}>Cancel Edit</button>
                        <button className={`${styles.saveCatBtn}`}  onClick={(e) => handleEditCategory(e)} >Save Change(s)</button>
                        
                    </span>
                    :
                        <button className={`${styles.saveCatBtn}`}
                        onClick={(e) => handleAddCategory(e)}
                    >Save Category</button>
                    }
                </form>
            </div>
        </>
    );
};

export default CreateQuestionCategory;