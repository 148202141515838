import { MenuContext } from "../DashBoard";
import { Link, Route, Routes } from "react-router-dom";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import PageNotFound from "../../404/PageNotFound";
import { useState, createContext } from "react";
import Modal from '../../../components/modal';
import { useContext } from "react";
import { BsList } from 'react-icons/bs';
import Create from './Createassignment';
import View from "./viewAssignment";
import UsersAssignment from "./UsersAssignment";

export const staffContext = createContext();
export const LearningContext = createContext();

const AssignmentIndex = () => {
    const {showMenu, setShowMenu} = useContext(MenuContext);
    const [modalObject, setModalObject] = useState({});
    const [modalToggle, setModalToggle] = useState(false);
    const [view, setView] = useState('');
    const [title, setTitle] = useState('');
    const [editAssignment, setEditAssignment] = useState(false);
    const [editAssObj, setEditAssObj ] = useState({})

    const clearEdit =()=>{
        setEditAssignment(false)
        setEditAssObj({});
    }


    return (
        <>
        <staffContext.Provider value={{view, setView}} >
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }} >
               <h1 className={`${styles.title}`}>Assignment/Task</h1>
               <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`}>
                                    <ul onClick={()=>clearEdit()}>
                                        <li><Link to='create-assignment'>Create Assignment</Link></li>
                                        <li><Link to='view-assignment'>View Assignment</Link></li>
                                        <li><Link to='users-assignment'>Users Assignment</Link></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                 <LearningContext.Provider value={{modalObject,setModalObject,modalToggle,setModalToggle,editAssignment,setEditAssignment,editAssObj,setEditAssObj, clearEdit}}>
                      <Routes >
                         <Route path="*" element={<Create setTitle={setTitle}/>} />
                         <Route path="/create-assignment" element={<Create  setTitle={setTitle}/>} />
                         <Route path="/view-assignment" element={<View setTitle={setTitle} />} />
                         <Route path="/users-assignment" element={<UsersAssignment setTitle={setTitle} />} />
                         <Route path="/*" element={<PageNotFound setTitle={setTitle}/>}/>
                      </Routes>
                      <Modal modalObject={modalObject} modalTogglee={modalToggle} closeModal={()=>{setModalToggle(false)}} />
                </LearningContext.Provider>
               </div>
            </div>
        </staffContext.Provider>
      
        </>
      );
}
 
export default AssignmentIndex;