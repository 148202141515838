import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './../css/Login.module.css';
import PrimaryFields from './PrimaryFields';
import SecondaryFields from './SecondaryFields';
import AcctType from './AcctType';
import Api from "../helperFunctions/Api2";
import Loading from './loading';
import Modal from "./modal";
import { useNavigate } from 'react-router';
import { useParams } from "react-router-dom";
import { ModalContext } from '../App';

const Login = ({ switchMode }) => {
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const { d } = useParams();
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(true);
    const [priUser, setPriUser] = useState(d ? false : true);
    const [validUser, setValidUser] = useState(false);
    const [userPass, setUserPass] = useState(false);
    const [orgID, setOrgID] = useState(d ? true : false);
    const [userKey, setUserKey] = useState(false);
    const [secUserPass, setSecUserPass] = useState(false);
    const [defOrg, setDefOrg] = useState(d);
    const {customizeData, setCustomizeData, isCustReady, setIsCustReady } = useContext(ModalContext);
    const handleStatus = (status) => {
        setPriUser(status);
    };
    const [inputs, setInputs] = useState({});
    const onInputChange = (e = this) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(inputs => ({ ...inputs, [name]: value }));
        if (name === 'user-con') {
            (/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/.test(value) || /^\+*\d{1,3}\d{9,10}$/.test(value)) ?
                setValidUser(true) : setValidUser(false);
        }

        if (name === 'user-pass') {
            value.length >= 6 ?
                setUserPass(true) : setUserPass(false);
        }

        if (name === 'org-id') {
            setDefOrg(value)
            value.length >= 8 ?
                setOrgID(true) : setOrgID(false);
        }

        if (name === 'user-key') {
            value.length >= 6 ?
                setUserKey(true) : setUserKey(false);
        }

        if (name === 'sec-user-pass') {
            value.length >= 8 ?
                setSecUserPass(true) : setSecUserPass(false);
        }
    };

    const onSubmit = (e = this) => {
        e.preventDefault();

        if (!Api.checkNetwork()) return;

        setShowLoading(true);
        if (!priUser && !customizeData) { //alert('goo')
            Api.PostApi('/form/get-customization', { service_id: 4, institution_id: defOrg }, true).then(result => {
                setIsCustReady(true);
                if (result.isSuccessful) {
                    localStorage.setItem('customization', result.data.customization);
                    setCustomizeData(JSON.parse(result.data.customization));
                }else {
                    localStorage.removeItem('customization');
                    setCustomizeData(undefined);
                }
            })
        } 
        Api.PostApi('/account/login', {
            login: priUser ? inputs["user-con"] : inputs['user-key'],
            userRole: priUser ? 'root' : 'sub_user',
            password: priUser ? inputs["user-pass"] : inputs['sec-user-pass'],
            orgId: priUser ? '' : defOrg
        })
            .then((result) => {
                setShowLoading(false);
                if (result.isSuccessful) {
                    localStorage.setItem('eduplus_tkn', result.token);
                    if(d) localStorage.setItem('edu_inst_id', d);
                    else localStorage.removeItem('edu_inst_id')
                    //localStorage.setItem('eduplus_idnty', result.ids);
                    if (result.user.reset_password == 1) {
                        navigate(`/account/forgot-password/abcdefghijklmnopqrstuvwxyz/${result.user.reset_link}`)
                    }
                    else navigate("/account/services");
                }
                else {
                    setModalText(result.message)
                    setModalToggle(true);
                    console.table('priUser', priUser, 'validUser', validUser, 'userPass', userPass, 'secUserPass', secUserPass, 'userKey', userKey, 'orgID', orgID, 'validUser', validUser);
                }
            })
            .catch(err => {
                setShowLoading(false);
            });
    };

    useEffect(() => {
        if (!priUser && d) { //alert('goo')
            setShowLoading(true);
            Api.PostApi('/form/get-customization', { service_id: 4, institution_id: defOrg }, true).then(result => {
                console.log(result);
                setIsCustReady(true);
                if (result.isSuccessful) {
                    localStorage.setItem('customization', result.data.customization);
                    setCustomizeData(JSON.parse(result.data.customization));
                }
            }).finally(() => {
                setShowLoading(false)
            })
        } else {
            localStorage.removeItem('customization');
            setCustomizeData(undefined);
            setShowLoading(false);
        }
    }, [d]);
    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={`${styles.formParent}`} style={{display: !d || isCustReady ? 'flex': 'none'}}>

                <form className={`${styles.defaultLog}`} onSubmit={onSubmit}>
                    <AcctType mode='In' handler={handleStatus} state={priUser} d={d} />
                    <div className={`${styles.inputDiv}`}>
                        {priUser ?
                            (
                                <>
                                    <PrimaryFields toSwitch={true} onChange={onInputChange} />
                                    <Link to='/account/forgot-password' className={`${styles.forgot}`}>Forgot Password?</Link>
                                </>
                            ) :
                            <SecondaryFields toSwitch={true} onChange={onInputChange} initialValue={defOrg} d={d} />}
                    </div>
                    <div className={`${styles.btnDiv}`}>
                        <button type='submit' className={`${styles.submitButton}`} disabled={priUser ? !(validUser && userPass) : !(secUserPass && userKey && orgID)} title={priUser && !(validUser && userPass) ? 'Please fill out all fields' : !(secUserPass && userKey && orgID) ? 'Please fill out all fields' : 'Login'}>Log In</button>
                        {!d && (<span className={`${styles.littleNotice}`}>Don't have an account? Click <Link to='/account/sign-up' className={`${styles.reverseButton}`} onClick={switchMode}>here</Link> to register</span>)}
                    </div>
                </form>
            </div>
        </>
    );
};

export default Login;