import { createSlice } from "@reduxjs/toolkit";

const portalSlice = createSlice({
    name: 'portal',
    initialState: {
        forms: [], websites: [{
            title: "test website",
            id: 1
        }],
    },
    reducers: {
        addForm: (state, action) => {
            state.forms.unshift(action.payload);
        },
        deleteForm: (state, action) => {
            state.forms = state.forms.filter(form => form.id !== action.payload);
        },
        editForm: (state, action) => {
            state.forms = state.forms.map(form=> form.id === action.payload.id ? {...form, ...action.payload}:form );
        },
        setForms: (state, action) => {
            state.forms = action.payload;
        },
        addWebsite: (state, action) => {
            state.websites.unshift(action.payload);
        },
        deleteWebsite: (state, action) => {
            state.websites = state.websites.filter(website => website.id !== action.payload);
        },
        editWebsite: (state, action) => {
            state.websites = state.websites.map(web=> web.id === action.payload.id ? {...web, ...action.payload}:web );
        },
        setWebsites: (state, action) => {
            state.websites = action.payload;
        },
    }

});
export const {
    addForm, deleteForm, editForm, setForms, setCurrentForm, 
    addWebsite, deleteWebsite, editWebsite, setWebsites, 
    
}    = portalSlice.actions;
export default portalSlice.reducer;