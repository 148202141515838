import { createContext, useState } from 'react';
import styles from './../../../css/StaffMgmt.module.css';
import titleStyle from './../../../css/SectionTitle.module.css';
import { Routes, Route, } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import Menu from '../../../components/Menu';
import CreateGroupAttendance from './CreateGroupAttendance';
import ViewGroupAttendance from './ViewGroupAttendance';
import TakeAttendance from './TakeAttendance';

export const staffContext = createContext();
export const EditContext = createContext();

const Attendance = () => {
    const [title, setTitle] = useState('');
    const [view, setView] = useState();
    const [editing, setEditing] = useState(false);
    const [editData, setEditData] = useState({});

    const clearEdit = () => {
        setEditing(false);
        setEditData({});
    }

    return (
        <staffContext.Provider value={{ view, setView }}>
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Attendance Management</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <Menu options={[
                            { label: 'Create Attendance', path: "create-attendance", onClick: () => clearEdit({}) },
                            { label: 'View Attendance', path: "view-attendance", onClick: () => clearEdit({}) },
                            { label: 'Take Attendance', path: "take-attendance", onClick: () => clearEdit({}) }
                        ]} />
                    </div>
                    <EditContext.Provider value={{ editData, setEditData, editing, setEditing, clearEdit }}>
                        <Routes>
                            <Route path='*' element={<CreateGroupAttendance setTitle={setTitle} />} />
                            <Route path='/create-attendance' element={<CreateGroupAttendance setTitle={setTitle} />} />
                            <Route path='/take-attendance' element={<TakeAttendance setTitle={setTitle} />} />
                            <Route path='/view-attendance' element={<ViewGroupAttendance setTitle={setTitle} />} />
                            <Route path="/*" element={<PageNotFound />} />
                        </Routes>
                    </EditContext.Provider>
                </div>
            </div>
        </staffContext.Provider>
    );
};

export default Attendance;