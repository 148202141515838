import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {dateObject} from '../../../helperFunctions/Api2';
import css from "../../../css/ServiceDashboard.module.css";
import BarGraph from '../../../components/Graph/BarGraph';
import VideoPlayer from '../../../components/VideoPlayer';

const LearnDashboard = () => {
  const [data,setData] = useState(null);
  const virtuals = useSelector(store => store.learning.virtual_class);
  const notes = useSelector(store => store.learning.notes);
  const assignments = useSelector(store => store.learning.assignments);
  const {datetime} = useSelector(store => store.account.username);
  const [totalAttendances, setTotalAttendance] = useState(0);
  const [noteData, setNoteData] = useState(null);
  const [assignmentData, setAssignmentData] = useState(null);
  const [showVideo, setShowVideo] = useState(false);

  const arrageData = (virtuals,year) =>{
     let dataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
     let totalAttendance = 0;
    
      virtuals.forEach(eVirtual => {
          let d = new Date(eVirtual.start_time);
          let vYear = d.getFullYear();
          if(vYear == year){
              dataArr[d.getMonth()]++;
          }
          totalAttendance += eVirtual.attendance.length;
      })
     
     setData(dataArr);
     setTotalAttendance(totalAttendance);
  }
  useEffect(()=>{
    if(virtuals.length > 0){
      console.log(datetime);
      let d = new Date(datetime);
      let year = d.getFullYear();
      arrageData(virtuals,year)
    }
    
  },[virtuals])

  useEffect(()=>{
      const arrangeData = (year)=>{
        let dataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
       
         notes.forEach(note => {
             let d = new Date(note.created_at);
             let vYear = d.getFullYear();
             if(vYear == year){
                 dataArr[d.getMonth()]++;
             }
         })
        
        setNoteData(dataArr);   
      }

      if(notes.length > 0){
        let d = new Date(datetime);
        let year = d.getFullYear();
        arrangeData(year)
      }
  },[notes])

  useEffect(()=>{
    const arrangeData = (year)=>{
      let dataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
     
      assignments.forEach(assignment => {
           let d = new Date(assignment.created_at);
           let vYear = d.getFullYear();
           if(vYear == year){
               dataArr[d.getMonth()]++;
           }
       })
      
      setAssignmentData(dataArr);   
    }

    if(assignments.length > 0){
      let d = new Date(datetime);
      let year = d.getFullYear();
      arrangeData(year)
    }
},[assignments])

  return (
    <div className={css.container}>
    <div>Learning Dashboard</div>
    <div>
        <div>
            <div>
              <BarGraph data={data} sideText={"No. of Virtual Classes"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText={`Virtual Classes`}/>
              <BarGraph data={noteData} sideText={"No. of NotesEbooks"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText={`Notes/Ebooks`}/>
              <BarGraph data={assignmentData} sideText={"No. of Assignments"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText={`Assignments`}/>
              <br/>
              <div>
                Numbers of Virtual Classes: <span class={css.purpleText}>{virtuals.length}</span><br/>
                Total attendants: <span class={css.purpleText}>{totalAttendances}</span><br/>
                Total Notes: <span class={css.purpleText}>{notes.length}</span><br/>
                Total Assignments: <span class={css.purpleText}>{assignments.length}</span>
              </div>
            </div>
            <div>
              <div>EDUPLUS LEARNING </div>
              <p>
                Eduplus Learning Service enables you to be able to create & manage virtual classes, assignments and notes/ebooks which facilitate continous learning regardless of distance or other challenges.
              </p>
              <div>
                 <Link to="./online" style={{backgroundColor:"var(--secondary-color)",color:"var(--white-color)",border:"1px solid var(--secondary-color)"}}>
                  Get Started
                 </Link>
                 <button onClick={()=>setShowVideo(!showVideo)} style={{border:"1px solid var(--secondary-color)",border:"1px solid var(--secondary-color)",backgroundColor:"var(--white-color)"}}>
                  Watch Video
                 </button>
              </div>
            </div>
        </div>
        {showVideo && <VideoPlayer serviceCode={'LMS'}/>}
    </div>
    </div>
  )
}

export default LearnDashboard;



