import styles from './../css/Fields.module.css';
import { BsCheck2Circle, BsRecord, } from "react-icons/bs";
import InputField from './InputField';
import { useEffect } from 'react';

const SecondaryFields = ({ toSwitch, onChange, tooltip, hasAlpha, hasNumber, hasSymbol, properLength, match, showFirst, showLast, initialValue, d }) => {
  
 /* useEffect(()=>{
    inp.current.value = initialValue;
  },[initialValue]);*/
  return toSwitch ? (
    <>
      <InputField style={{display: d ? 'none':'inline-block'}} title="Institution ID" value={initialValue} inputID='org-id' placeholder="Enter Institution ID" type='text' onChange={e => { onChange(e); }} />
      <InputField title='User Email' inputID='user-key' placeholder='Enter your user email' type='text' onChange={e => { onChange(e); }} />
      <InputField title='Password' inputID='sec-user-pass' placeholder='Enter Password' type='password' onChange={e => { onChange(e); }} />
    </>
  ) : (
    <>
      <InputField title="Institution ID" inputID='org-id' placeholder="Enter institution ID" type='text' onChange={e => { onChange(e); }} />
      <InputField title='Username' inputID='user-name' placeholder='Enter your Username' type='text' onChange={e => { onChange(e); }} />
      <InputField title='Password' inputID='user-pass' placeholder='Enter Password' type='password' onChange={e => { onChange(e); }} tooltip={tooltip} />
      {showFirst && <div className={`${styles.conditionDiv}`}>
        <span className={`${styles.condition}`}>{hasAlpha ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Password must have alphabetical characters</span>
        <span className={`${styles.condition}`}>{hasNumber ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Password must have at least one number</span>
        <span className={`${styles.condition}`}>{hasSymbol ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Password must have at least one symbol</span>
        <span className={`${styles.condition}`}>{properLength ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Password must be of 6 or more characters</span>
      </div>}
      <InputField title='Confirm Password' inputID='con-pass' placeholder='Re-enter Password' type='password' onChange={e => { onChange(e); }} />
      {showLast && <span className={`${styles.condition}`}>{match ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}{match ? 'Password accepted' : `Passwords don't match`}</span>}
    </>
  );
};

export default SecondaryFields;