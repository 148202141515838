import React, { PureComponent, useEffect,useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {useSelector} from 'react-redux';


export const dateObject = {
  months:{
    1:"Jan",
    2:"Feb",
    3:"Mar",
    4:"Apr",
    5:"May",
    6:"Jun",
    7:"Jul",
    8:"Aug",
    9:"Sep",
    10:"Oct",
    11:"Nov",
    12:"Dec"
  }
}

export class Example extends PureComponent {
  constructor(props){
    super(props);
    this.state = {chartData:this.props.chartData};
    this.props = props;
  }
 
  render() {
    const data = this.state.chartData;
    return (
      <> 
        {}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  }
}

const CbtChart = () => {
  const cbtData = useSelector(store => store.cbt.cbts);
  const [chartData,setChartData] = useState([])
  useEffect(()=>{
    if(cbtData && cbtData.length > 0){
      const fillchartData = () =>{   
        let tempData = [];
        const tempChartData =  [...Object.values(dateObject.months).map(eDate => ({name:eDate,value:0}))]
        tempChartData.forEach(eData =>{
          let dName = eData.name;
          let dValue = eData.value;
          cbtData.forEach(eCbt => {
            let created_at = eCbt.created_at;
            const d = new Date(created_at);
            const cMonth = d.getMonth()+1;
            if(dName == dateObject.months[cMonth]){
                dValue++;
            }
          });
          tempData.push(({name:dName,value:dValue.toString()}))
        })
        setChartData(tempData);
      }
      fillchartData();
    }
  },[cbtData])
  return (
    <>
      {chartData && chartData.length > 0 ? <Example chartData={chartData} />: <></>}
    </>
    
    
  )
}

export default CbtChart