import JoditEditor from "jodit-react";
import { useState } from "react";
import styles from '../views/userAdmin/QuestionC/css/AddQuestions.module.css';
import UploadedFiles from "./UploadedFiles";

const TextEditor = ({ text, setShowLoading, value, handleChange, style, reff, txIndex, labelStyle, onCopy }) => {
	const [modalToggle, setModalToggle] = useState(false);
	const config = {
		height: 400,
		width: '100%',
		readonly: false,
		...style
	};
	const handleUpdate = (event) => {
		if (txIndex || txIndex >= 0) handleChange(txIndex, event);
		else handleChange(event);
	};
	return (<>
		<UploadedFiles type={'image'} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} setShowLoading={setShowLoading} onCopy={onCopy} />
		<label htmlFor='question' style={labelStyle ? labelStyle : { marginBottom: '-20px' }} className={`${styles.label}`}>
			<span className={styles.isRequired}>{text}</span>
			<span className={styles.styledlink} onClick={() => setModalToggle(true)}>upload image</span>
		</label>
		<div className={styles.editorDiv}>
			<JoditEditor
				value={value}
				ref={reff}
				config={config}
				onBlur={handleUpdate}
				// onChange={handleUpdate}
			/>
		</div>
	</>);
};
export default TextEditor;

// export function UploadImageOnly ({ text, setShowLoading, onCopy, labelStyle }) {
// 	const [modalToggle, setModalToggle] = useState(false);
// 	return <>
// 		<UploadedImages modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} setShowLoading={setShowLoading} onCopy={onCopy} />
// 		<label htmlFor='question' style={labelStyle ? labelStyle : { marginBottom: '-20px' }} className={`${styles.label}`}>
// 			<span className={styles.isRequired}>{text}</span>
// 			<span className={styles.styledlink} onClick={() => setModalToggle(true)}>upload image</span>
// 		</label>
// 	</>;
// }
