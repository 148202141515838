import { createContext, useContext, useState } from 'react';
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { BsList } from 'react-icons/bs';
import { MenuContext } from '../DashBoard';
import CreateWebsite from './CreateWebsite';
import DesignWebsite from './DesignWebsite';
// import WebsiteUsers from './WebsiteUsers';

export const WebsiteContext = createContext();

const Website = () => {
    const { showMenu, setShowMenu } = useContext(MenuContext);
    const [title, setTitle] = useState('');
    const [editData, setEditData] = useState({});
    const [editing, setEditing] = useState(false);
    const [fulsc, setFullsc] = useState('');
    const clearEdit = () => {
        setEditData({});
        setEditing(false);
    }
    return (
        <WebsiteContext.Provider value={{ setTitle, setFullsc, editData, setEditData, editing, setEditing, clearEdit }}>
            <div className={`${styles.baseView} ${fulsc}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Website Management</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`} >
                                    <ul>
                                        <li onClick={() => { clearEdit(); }}><Link to='create-website'>Create Website</Link></li>
                                        <li onClick={() => clearEdit()}><Link to='design-website'>Design Website</Link></li>
                                        {/* <li onClick={() => clearEdit()}><Link to='website-users'>Website Users</Link></li> */}
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    <Routes>
                        <Route index element={<CreateWebsite />} />
                        <Route path='/create-website' element={<CreateWebsite />} />
                        <Route path='/design-website' element={<DesignWebsite />} />
                    {/* <Route path='/website-users' element={<WebsiteUsers />} />*/}
                        <Route path="/*" element={<PageNotFound />} /> 
                    </Routes>
                </div>
            </div>
        </WebsiteContext.Provider>

    );
};

export default Website;