import React,{useState,useEffect} from 'react'
import { Doughnut, } from "react-chartjs-2";
import {Chart as ChartJS,BarElement, ArcElement, Tooltip,Legend,CategoryScale,LinearScale} from 'chart.js';

ChartJS.register({
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement
})

const colors = {
  passColor:'rgba(10,230,50,0.9)',
  failedColor:'rgba(220,10,50,0.9)',
  absendColor:'rgba(100,100,100,0.9)',
}


const initial_data = {
  labels: ['Passed Users', 'Failed Users','Absent Users'],
  datasets: [
      {
        label: 'Pass/Fail Chart',
        data: [40,25,5],
        backgroundColor: [
          `${colors.passColor}`,
          `${colors.failedColor}`,
          `${colors.absendColor}`
        ],
        borderWidth: 1,
        cutout:'50%',
      }
  ]
}

const DoughnutCharts = (props) => {
    const [doughnutOption,setDoughnutOption] = useState(props.doughtnut_option || {});
    const [data,setData] = useState(props.doughnut_data || initial_data);
    useEffect(()=>{
      if(props.doughnut_data){
        setData(props.doughnut_data)
      }
      if(props.doughtnut_option){
        setDoughnutOption(props.doughtnut_option);
      }
    },[props.doughnut_data,props.doughtnut_option])
  return (
    <>
      {data && Object.keys(data).length > 0 ?<div><Doughnut data={data} options={doughnutOption} /></div> :<>LOADING CHART</>} 
    </>
  )
}

export default DoughnutCharts