import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import styles from './css/QuestionBankView.module.css';
import {Questions,Categories,Test} from './index';
import QuestionsMarket from "./QuestionsMarket";


const QuestionBankView = () => {
    return (
        <div className={styles.view} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Routes>
                <Route path='/test/*' element={<Test/>}/>
                <Route path="/categories/*" element={<Categories />} />
                <Route path="/questions/*" element={<Questions />} />
                <Route path="/question-marketplace/*" element={<QuestionsMarket />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </div>
    );
};

export default QuestionBankView;