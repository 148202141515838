import React from "react";
import styles from '../../../css/CreateInst.module.css';
import styling from '../../../css/Categories.module.css';
import eventstyle from './Css/event.module.css';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Loading from '../../../components/loading';
import { eventModalContext } from "./msgSettings";
import { useSelector, useDispatch } from 'react-redux';
import EachMsg from "./eachMsg";
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;



const ViewMsg = ({ setTitle }) => {



  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();
  const Message = useSelector(state => state.questions.user_message);
  const { editMsgCon, setEditMsgCon, allMsg, setAllMsg } = useContext(eventModalContext);
  const [currentInd, setCurrentInd] = useState(null);
  const [currentOpened, setCurrentOpened] = useState(null);
  const [sent, setSent] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(0);




  const handleEditMsg = (Msg) => {
    setEditMsgCon(Msg);
    setAllMsg(Message);
    navigate("/services/event-management/events/create-event");
  }


  const handleDeleteMsg = (Msg) => {
    const id = Msg.id;
    if (id) {

      setAllMsg(Message.filter(env => (env.id !== id)))
    }
  }

  useEffect(() => {
    setTitle('View Events')
  }, [setTitle])

  useEffect(() => {
    if (Message.length > NO_OF_ITEMS_PER_PAGE) {
      setPageNum((Message.length / NO_OF_ITEMS_PER_PAGE) + ((Message.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
    }
  }, [Message.length]);

  return (
    <>
      <Loading shouldShow={showLoading} />
      <div className={styling.form}>
        <div className={styling.viewQuestionContainer}>
          {Message.length > 0 ? Message.map((Msg, ind) =>
            ind < (currentPage * NO_OF_ITEMS_PER_PAGE) && ind >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
            <EachMsg message={Msg} currentInd={currentInd} currentOpened={currentOpened} changeCurrentOpened={setCurrentOpened} changeInd={setCurrentInd} viewEventStyle={eventstyle.eventCon} ind={ind} key={Msg.id} handleEditMsg={handleEditMsg} handleDeleteMsg={handleDeleteMsg} />
          ) : 'No Message Added yet'}
        </div>
        <p>EVENTS</p>
        {Message.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
      </div>
    </>
  );
}

export default ViewMsg;