import { useState, useEffect, useRef, useReducer, useContext } from 'react';
import styles from './css/AddQuestions.module.css';
import Option from './Option';
import Api from "../../../Api";
import JoditEditor from "jodit-react";
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import { useDispatch, useSelector } from 'react-redux';
import { addQuestion, editSelectedQuestion } from "../../../redux/reducers/questionReducer";
import { EditContext } from './Questions';
import Select from '../../../components/Select';
import UploadedFiles from '../../../components/UploadedFiles';

const questionTypes = [
    { name: 'Question With options', id: 0 },
    { name: 'Question Without options', id: 1 },
]


const genArray = (len) => {
    let array = [];
    for (let index = 0; index < len; index++) {
        array[index] = index;
    }
    return array;
};

const AddQuestions = ({ setTitle }) => {

    const [number, setNumber] = useState(3);
    const [array, setArray] = useState(genArray(number));
    const [inputs, setInputs] = useState({});
    const [shouldShow, setShouldShow] = useState(true);
    const [answers, setAnswers] = useState({});
    const [score, setScore] = useState('');
    const editorRef = useRef(null);
    const [submitted, setSubmitted] = useState(false);
    // const [questionCategories,setQuestionCategories] = useState([])

    const [content, setContent] = useState("Start writing");
    const [modalToggle, setModalToggle] = useState(false);
    const [uploadedImageToggle, setUploadedImageToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [isInformed, setIsInformed] = useState(true);
    const [arrOptions, setArrOptions] = useState([]);
    const [arrAnswers, setArrAnswers] = useState([]);
    const [selCategories, setSelCategories] = useState([]);
    const [defaultCategory, setDefaultCategory] = useState('');

    const [questionType, setQuestionType] = useState(questionTypes[0]);
    const imgView = useRef();
    const imgInput = useRef();
    const availOptions = useRef();
    const currentQuestion = useRef();
    const category = useRef();
    const is_visible = useRef();
    const maxScore = useRef();
    const modal2 = useRef();
    const editor = useRef(null);
    //const categories = useSelector((state) => state.questions.categories);
    const questionCategories = useSelector(state => state.questions.categories);
    const [categories, setQCategories] = useState([])
    const dispatch2 = useDispatch();
    const navigate = useNavigate();
    const { editQuestion, setEditQuestion } = useContext(EditContext);
    const allQuestions = useSelector((state) => state.questions.value);
    // const dispatch1 = useDispatch();
    const [answer, setAnswer] = useState('');

    const config = {
        readonly: false,
        height: 400
    };

    const scoreRef = {
        num: score
    };

    const numRef = {
        num: number
    };

    const log = () => {
        if (editorRef.current) {
            //console.log(editorRef.current.getContent());
        }
    };

    const [maxOption] = useState({
        max: Number(numRef.num) === 2 ? 1 : Number(numRef.num) > 3 ? 3 : 2,
        selected: 0
    });

    const onAnswerChange = (name, value) => {
        setAnswers({ ...answers, [name]: value });
    };

    // const fetchItems = async () => {
    //     Api.getApi('/question/get-category', true)
    //         .then(response => response.json())
    //         .then(result => {
    //             //console.log(result)
    //             if (shouldShow) {
    //                 dispatch2(setCategory(result.data));
    //                 setQuestionCategories(result.data);
    //                 setLoadingState("ready");                  
    //             }
    //         })
    //         .catch(err => {
    //             if (shouldShow) setLoadingState("error");
    //         });
    // };

    const onInputChange = (e = this) => {
        const name = e.target.name;
        let value = e.target.value;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    };

    const onNumberInputChange = (e = this) => {
        let value = e.target.value;
        while (/[a-zA-Z]+/.test(value)) {
            value = String(value).slice(0, -1);
        }

        if (value.length > 1)
            value = String(value).slice(1);
        //console.log('value', value);
        if (value > 7) {
            value = 7;
        } else if (value < 2) {
            value = 2;
        }
        numRef.num = Number(value);
        setNumber(Number(numRef.num));
        availOptions.current.textContent = Number(numRef.num);

        setInputs({});
        setAnswers({});
    };

    const onScoreInputChange = (e = this) => {
        let value = e.target.value;
        while (/[a-zA-Z]+/.test(value)) {
            value = String(value).slice(0, -1);
        }
        while (/\.\./.test(value)) {
            value = String(value).slice(0, -1);
        }
        while (/[0-9]+[.]+[0-9]+[.]+/.test(value)) {
            value = String(value).slice(0, -1);
        }
        if (String(value[value.length - 1]) === '.') {
            value = String(value).slice(0, -1);
            while (/\W$/.test(value)) {
                value = String(value).slice(0, -1);
            }
            //console.log('reaches', value);
            value = value + '.';
        } else {
            while (/\W$/.test(value)) {
                value = String(value).slice(0, -1);
            }
        }
        if (value <= -1)
            value = 1;
        if (value === 0)
            value = '';
        if (value.length > 1 && Number(value[0]) === 0) {
            value = String(value).slice(1, value.length);
        }
        scoreRef.num = value;
        setScore(scoreRef.num);
        maxScore.current.textContent = Number(scoreRef.num);
    };

    const reducer = (state, action) => {
        //console.log(state)
        switch (action.type) {
            case 'add':
                return {
                    ...state,
                    max: (number === 2 ? 1 : number > 3 ? 3 : 2),
                    selected: Number(state.selected) + 1
                };
            case 'reduce':
                return {
                    ...state,
                    max: (number === 2 ? 1 : number > 3 ? 3 : 2),
                    selected: Number(state.selected) - 1
                };
            case 'reset':
                return {
                    ...state,
                    max: (number === 2 ? 1 : number > 3 ? 3 : 2),
                    selected: 0
                };
            default:
                return {
                    max: number === 2 ? 1 : number > 3 ? 3 : 2,
                    selected: 0
                };
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        max: maxOption.max,
        selected: maxOption.selected
    });

    const fillEditableData = () => {
        editor.current.value = editQuestion.question;
        setSelCategories(categories);
        let selInd = 0;
        let i;
        let len = categories.length;
        for (i = 0; i < len; i++) {
            if (Number(categories[i].id) === Number(editQuestion.category_id)) {
                selInd = i;
                break;
            }
        }
        setDefaultCategory(categories[selInd]);
        const myOptions = editQuestion.options_num;
        setNumber(Number(myOptions));
        setArray(genArray(myOptions));
        setScore(Number(editQuestion.score));
        if (editQuestion.type == 1) {
            setAnswer(editQuestion.answers)
        } else {
            let tempOptions = editQuestion.options.slice(2, -2);
            //console.log(tempOptions);
            tempOptions = tempOptions.split('","')
            // tempOptions.join('[~]')
            // tempOptions = tempOptions.map(temp => temp.slice(1,-1))
            let tempAnswers = editQuestion.answers.slice(1, -1).split(',');
            // tempAnswers.forEach((answer)=>{
            //     dispatch({ type: 'add' });
            // })
            if (tempAnswers.length > 1) {
                setIsInformed(true);
            }
            setArrOptions(tempOptions);
            setArrAnswers(tempAnswers);
        }
        maxScore.current.value = '';
        setQuestionType(editQuestion.type ? questionTypes[editQuestion.type] : questionTypes[0]);
    }

    const removeEditableData = () => {
        setSelCategories(categories);
        setDefaultCategory(categories[0]);
        setArrAnswers([]);
        setArrOptions([]);
        setNumber(3)
        setArray(genArray(3))
        setScore('');
        setQuestionType(questionTypes[0]);
        setAnswer('');
        // editor.current.value = '';
        editor.current.value = "Start Writing";
        // console.log('test')
    }

    const handleSaveEditQuestion = (e) => {
        e.preventDefault();
        const oldQuestions = allQuestions;
        const options = [];
        for (const index in inputs) {
            options.push(inputs[index]);
        }

        const indices = [];
        let i = 1;
        for (const index in inputs) {
            if (answers[index]) {
                indices.push(i);
            }
            i++;
        }
        const question = {
            id: editQuestion.id,
            score: maxScore.current.value,
            question: editor.current.value,
            category_id: Number(defaultCategory.id),
            options_num: options.length,
            options: JSON.stringify(options),
            answered_num: indices.length,
            answers: questionType.id == 1 ? answer : JSON.stringify(indices),
            type: questionType.id,
            is_public: is_visible.current.value === 'public' ? true : false
        };

        if (
            // question.score === '' ||
            question.question.length <= 7 ||
            question.type == 0 && (options.length < Number(question.options_num) || Number(question.answered_num) === 0)
        ) {
            setModalText(message2);
            setModalToggle(true);
            return;
        } else {
            setShowLoading(true);
            Api.PostApi('/question/edit-question', question, true)
                .then(response => response.json())
                .then(result => {
                    setShowLoading(false);
                    //console.log(result);
                    if (result.isSuccessful) {
                        // alert("Question added successfully");
                        dispatch2(editSelectedQuestion({ ...question }))
                        const newQuestions = oldQuestions.map(quest => quest.id == question.id ? { ...question } : quest)
                        //setQuestions(newQuestions)
                        setEditQuestion({});
                        navigate('../view-questions')
                        setShowLoading(false);
                        editor.current.value = '';
                        setSubmitted(!submitted);
                        setModalText(result.message);
                        setModalToggle(true);
                    }
                    else {
                        // alert("Action failed. Please re-login");
                        //console.log('else');
                        setModalText(result.message);
                        setModalToggle(true);
                    }
                })
                .catch(err => {
                    //console.log(err,'something else');
                    setModalText(message3);
                    setModalToggle(true);
                    // alert("Action failed. Please re-login");
                    sessionStorage.removeItem('eduplus_tkn');
                    navigate("/account/sign-in");
                });
        }
    }

    useEffect(() => {
        setNumber(number);
        setArray(genArray(number));
    }, [number, numRef.num]);

    const handleUpdate = (event) => {
        const editorContent = event.innerHTML;
        setContent(editorContent);
    };

    useEffect(() => {
        dispatch({});
    }, [numRef.num]);

    useEffect(() => {
        setNumber(number);
        setArray(genArray(number));
    }, [number, numRef.num]);

    useEffect(() => {
        setShouldShow(true);
        //console.log(categories)
        // if (categories.length === 0) {
        //     fetchItems();

        // }
        // else {
        //     if (shouldShow) setLoadingState("ready");
        // }

        return () => {
            setShouldShow(false);
        };
    }, []);

    useEffect(() => {
        if (isInformed && state.selected > 1) {
            setModalText(message1);
            setModalToggle(true);
            setIsInformed(false);
        }
    }, [isInformed, state.selected]);

    useEffect(() => {
        setIsInformed(true);
        //array.forEach((v, i) => setAnswers({ ...answers, [`option-${Number(i + 1)}`]: false }));
    }, [number]);
    //useEffect to help update the selected question category

    useEffect(() => {
        setTitle('Add question');
    }, []);

    useEffect(()=>{
        if(questionCategories.length > 0){
            let newCat = questionCategories.filter(x=>x.isOwned)
            setQCategories(newCat);
        }
    },[questionCategories])

    useEffect(() => {
        setTitle('Add Question');
        if (Object.keys(editQuestion).length > 0) {
            fillEditableData()

        }
        else if (categories.length > 0) {
            removeEditableData()
        }
    }, [editQuestion]);

    useEffect(() => {
        if (categories.length > 0 && Object.keys(editQuestion).length === 0) {
            setSelCategories(categories);
            setDefaultCategory(categories[0]);

        }
        //console.log(categories)
    }, [categories])
    useEffect(() => {
        if (editQuestion && Object.keys(editQuestion).length > 0) {
            console.log(Number(editQuestion.category_id), Number(defaultCategory.id))
            if (!isNaN(defaultCategory.id)) {
                if (Number(editQuestion.category_id) === Number(defaultCategory.id)) {
                    fillEditableData()
                }
                else {
                    setEditQuestion({});
                    removeEditableData()
                }
            }

        }
    }, [defaultCategory])

    const message1 = `Please note, choosing multiple answers for a question makes it a multiple choice question. Therefore, the score for this question would be divided by the number of correct answers and distributed equally to each correct option. Hence, the student's score would be a sum of the points for each correct answer chosen.`;
    const message2 = `Please completely fill out all required fields and make sure at least one correct option is provided.`;
    const message3 = `Action failed, please re-login.`;
    const message4 = `Question added successfully.`;
    const noCategoryMessage = "Please try again";


    return (
        <>
            <UploadedFiles type='image' modalTogglee={uploadedImageToggle} closeModal={() => setUploadedImageToggle(false)} setShowLoading={setShowLoading} navigate={navigate} />
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <form className={styles.form}>
                <div className={styles.questionDesDiv}>
                    <label htmlFor='category' className={`${styles.questionDesLabel} ${styles.label}`}>
                        <span className={styles.isRequired}>Question Category</span>
                        <Select label='name' value='id' onChange={setDefaultCategory} options={selCategories} defaultValue={defaultCategory} />
                    </label>

                    <label className={`${styles.questionDesLabel} ${styles.label}`}>
                        <span className={styles.isRequired}>Question Type</span>
                        <Select label='name' value='id' onChange={setQuestionType} options={questionTypes} defaultValue={questionType} />
                    </label>
                    {/* <label htmlFor='visibility' className={`${styles.questionDesLabel} ${styles.label}`}>
                        <span className={styles.isRequired}>Question Visibility</span>
                        <select ref={is_visible} id='visibility' name='visibility' className={`${styles.select}`} required>
                            <option value='personal'>
                                -Personal by default-
                            </option>
                            <option value='personal'>
                                Personal
                            </option>
                            <option value='public'>
                                Public
                            </option>
                        </select>
                    </label> */}
                </div>

                <label htmlFor='question' className={`${styles.questionLabel} ${styles.label}`}>
                    <span className={styles.isRequired}>Enter your question</span>
                    <span className={styles.styledlink} onClick={() => setUploadedImageToggle(true)}>upload question image</span>
                </label>

                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    onBlur={handleUpdate}
                    onChange={(e) => handleUpdate}
                />
                {questionType.id == 0 && <><label htmlFor='options' className={`${styles.optionAmount} ${styles.label}`} >
                    Number of Options
                    <input type='number' id='options' className={styles.input} ref={availOptions} value={number} onChange={onNumberInputChange} required />
                </label>
                    <div className={`${styles.choices} ${styles.div}`}>
                        {
                            array.map((x, index) => {
                                return (
                                    <Option number={number} num={x} onInputChange={onInputChange} key={index} onChk={dispatch} states={state} handleAnswers={onAnswerChange} submitted={submitted} arrAnswers={arrAnswers} arrOptions={arrOptions} setInputs={setInputs} setAnswers={setAnswers} answers={answers} />
                                );
                            })
                        }
                    </div></>}
                {questionType.id == 1 && <label className={` ${styles.label} `}>
                    <br /> <span >Answer</span>
                    <JoditEditor
                        config={config} onBlur={e => setAnswer(e.innerHTML)} onChange={(e) => setAnswer(e.innerHTML)} value={answer} />
                </label>}
                <label htmlFor='score' className={`${styles.score} ${styles.label} `}>
                    <span>Question Score</span>
                    <input type='text' id='score' className={styles.input} ref={maxScore} onChange={onScoreInputChange} value={score} required />
                </label>
                <div className={`${styles.addBtnDiv} ${styles.div}`}>
                    {Object.keys(editQuestion).length === 0 ?

                        <button type='submit' className={styles.addBtn} onClick={(event) => {
                            event.preventDefault();
                            if (!defaultCategory.id) {
                                setModalText(message2);
                                setModalToggle(true);
                                return false;
                            }
                            const options = [];
                            for (const index in inputs) {
                                options.push(inputs[index]);
                            }

                            const indices = [];
                            let i = 1;
                            for (const index in inputs) {
                                if (answers[index]) {
                                    indices.push(i);
                                }
                                i++;
                            }
                            const question = {
                                score: maxScore.current.value,
                                question: editor.current.value,
                                category_id: Number(defaultCategory.id),
                                options_num: options.length,
                                options: JSON.stringify(options),
                                answered_num: indices.length,
                                answers: questionType.id == 1 ? answer : JSON.stringify(indices),
                                type: questionType.id,
                                // is_public: is_visible.current.value === 'public' ? true : false
                            };

                            //console.table(question);

                            if (
                                // question.score === '' ||
                                question.question.length <= 7 ||
                                question.type == 0 && (options.length < Number(question.options_num) || Number(question.answered_num) === 0)
                            ) {
                                setModalText(message2);
                                setModalToggle(true);
                                return;
                            } else {
                                setShowLoading(true);
                                Api.PostApi('/question/add-question', question, true)
                                    .then(response => response.json())
                                    .then(result => {
                                        setShowLoading(false);
                                        console.log(result);
                                        if (result.isSuccessful) {
                                            // alert("Question added successfully");
                                            dispatch2(addQuestion({ ...question, id: result.data.id }));
                                            setShowLoading(false);
                                            editor.current.value = '';
                                            setSubmitted(!submitted);
                                            maxScore.current.value = '';
                                            setModalText(message4);
                                            setModalToggle(true);
                                        }
                                        else {
                                            // alert("Action failed. Please re-login");
                                            setModalText(result.message);
                                            setModalToggle(true);
                                            //sessionStorage.removeItem('eduplus_tkn');
                                            //navigate("/account/sign-in");
                                        }
                                    })
                                    .catch(err => {
                                        setModalText(message3);
                                        setModalToggle(true);
                                        // alert("Action failed. Please re-login");
                                        sessionStorage.removeItem('eduplus_tkn');
                                        navigate("/account/sign-in");
                                    });
                            }
                            return false;
                        }}>
                            Add Question
                        </button> :
                        <span style={{ display: 'flex', gap: '30px' }}>
                            <button onClick={() => { setEditQuestion({}); navigate('../view-email-group') }} className='cancelEditButton'>Cancel Edit</button>
                            <button className={styles.saveBtn} onClick={(e) => { handleSaveEditQuestion(e) }}>
                                Save Change(s)
                            </button>
                        </span>
                    }
                </div>
            </form>
        </>
    );
};

export default AddQuestions;