import React,{useEffect,useState} from "react";
import Style from "./css/BillPayment.module.css";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../../helperFunctions/Api2";

const BillPayment = ({setShowLoading, setModalTogglee}) => {
    const [total,setTotal] = useState(0);
    const values = useSelector (store => store.billing.values);
    const [list,setList] = useState([]);

    useEffect(()=>{
      if(values.length > 0){
         setList(values);
      }
    },[values])

    useEffect(()=>{
      //console.log(list)
      if(list.length > 0){
          let tempTotal = 0;
          list.forEach(eachService =>{
             tempTotal += Number(eachService.amount)
          })
          setTotal(tempTotal.toFixed(2));
      }else{
        setTotal(0)
      }
      
    },[list])

    const makeFinalPayment =()=>{
      setShowLoading(true);
      setModalTogglee(false);
      Api.PostApi('/payment/make-account-bill-payment',{
        amount: total,
        bill_ids: list
      }, true)
      .then(result=>{
        setShowLoading(false);
        console.log(result)
        window.open(result.data, "_blank");
      })
    }

  return (
    <>
      <div className={Style.bill}>
        <div  className={Style.bill1}>
            <button onClick={makeFinalPayment} disabled={total == 0?true:false}>PAY NOW</button>
        </div>
        <div className={Style.bill2} >
            <div>
              Total Amount due:
            </div>
            <p><strong>{total} USD</strong></p>
            
        </div>
        {values && values.length > 0 && 
          values.map((value, index) => 
          <EachServervice key={index}  className={Style.bill3} service={value} list={list} onChange={setList} />
          )}
      </div>
    </>
  )
}

export default BillPayment


const EachServervice = ({service,className,list,onChange}) =>{
  const [checked,setChecked] = useState(true);
  const handleChecked = () =>{

     if(checked){
        onChange(list.filter(eachService => eachService.service_name != service.service_name));
     }
     else{
       onChange([...list,service])
     }
     setChecked(!checked)
  }

  useEffect(()=>{
    setChecked(true)
  },[])

  return(
    <div className={className}>
       <div> 
          <div> 
            <input type="checkbox" checked={checked} onChange={()=>handleChecked()}/> 
            <span>{service.service_name} : </span>
          </div> 
          <span> {service.amount} USD</span>
        </div>

    </div>
  )
}