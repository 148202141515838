import { createSlice } from "@reduxjs/toolkit";

export const TestReducer = createSlice({
    name:"test",
    initialState:{tests:[]},
    reducers:{
       setTest:(state, action) => {
            //state.cbts = action.payload;
            return {
                tests:action.payload
            };
       },
       addTest:(state, action) => {
            return {
            tests:[action.payload,...state.cbts]
            };
      },
      deleteTest:(state,action)=>{
        return{
            tests:state.cbts.filter(eachTest => eachTest.id != action.payload.id)
        };
      },
      editTest:(state,action)=>{
        const editedTest = state.cbts.map(eachTest => eachTest.id == action.payload.id?action.payload:eachTest)
        return{
            cbts:editedTest,
        };
      }
    }
})
export const {setTest,addTest,deleteTest,editTest} = TestReducer.actions;
export default TestReducer.reducer;