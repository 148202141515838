import { useState } from 'react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { serviceObject } from './SideNav';
import { Link } from "react-router-dom";
import styles from './../../css/Services.module.css';
import './../../css/bootstrapIcons/bootstrap-icons.css';

const Services = () => {
    const [recent, setRecent] = useState(false);
    const [all, setAll] = useState(true);
    const collapseRecent = () => {
        setRecent(!recent);
    };
    const collapseAll = () => {
        setAll(!all);
    };
    const sortedServices = [...serviceObject.services.map(el => el.serviceName + " " + serviceObject.services.indexOf(el)
    )];
    return (
        <>
            <div className={`${styles.servicesDiv}`}>
                <h1 className={`${styles.title}`}>Sight-EduPlus Dashboard</h1>
                <div className={`${styles.wrapper}`}>
                    <div className={`${styles.serviceMain}`}>
                        <h3 className={`${styles.serviceTitle}`} >Sight-EduPlus Services</h3>
                        <div className={`${styles.serviceSection}`}>
                            <span className={`${styles.sectionTitle}`} style={{display: 'none'}} onClick={collapseRecent}>{recent ? <BsChevronDown className={`${styles.dropper}`} /> : <BsChevronRight className={`${styles.dropper}`} />}Recently viewed services</span>
                            {recent &&
                                <div className={`${styles.linksDiv}`}>
                                    {
                                        serviceObject.services.filter(service => serviceObject.services.indexOf(service) % 3 === 0).map((service, index) => { return <Link to={service.serviceLink ? service.serviceLink : service.subServices[0].subServiceLink} className={`${styles.service}`} key={index}><em className={`${service.serviceIcon} ${styles.serviceIcon}`}></em>{service.serviceName}</Link>; })
                                    }
                                </div>}
                        </div>
                        <div className={`${styles.serviceSection}`}>
                            <span className={`${styles.sectionTitle}`} onClick={collapseAll}>{all ? <BsChevronDown className={`${styles.dropper}`} /> : <BsChevronRight className={`${styles.dropper}`} />}All Services</span>
                            {all &&
                                <div className={`${styles.linksDiv}`}>
                                    {
                                        sortedServices.sort().map((service, index) => {
                                            const serviceIndex = parseInt(service.slice(service.length - 2, service.length));

                                            return <Link to={serviceObject.services[serviceIndex].serviceLink ? serviceObject.services[serviceIndex].serviceLink : serviceObject.services[serviceIndex].subServices[0].subServiceLink} className={`${styles.service}`} key={index}><em className={`${serviceObject.services[serviceIndex].serviceIcon} ${styles.serviceIcon}`}></em>{serviceObject.services[serviceIndex].serviceName}</Link>;
                                        })
                                    }
                                </div>}
                        </div>
                    </div>
                    <div className={`${styles.sideAttractions}`}>
                        <div className={`${styles.attractions}`}>
                            <h3 className={`${styles.attractText}`}>
                                Sight-EduPlus Blog
                            </h3>
                            <span className={`${styles.attractDes}`}>
                                Get valuable insight on learning, tutoring, and institution management activities on Sight-EduPlus blog.
                            </span>
                            <a href="https://blog.sight-eduplus.com" className={`${styles.switch}`} target='_blank'>Visit Blog</a>
                        </div>
                        <div className={`${styles.attractions}`}>
                            <h3 className={`${styles.attractText}`}>
                                Stay connected to your Sight-EduPlus resources on-the-go
                            </h3>
                            <div className={`${styles.attractContent}`} >
                                <div className={`${styles.app}`}></div>
                                <span className={`${styles.attractDes}`}>
                                    Enjoy learning, tutoring across all platforms with your Sight-EduPlus. Sight-EduPlus mobile app is coming soon.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Services;