import React,{useEffect,useState} from 'react'
import {NO_OF_QUESTION_PER_PAGE} from './ViewQuestions'



const QuestionPageNumber = ({click,pageStartNum,element}) => {
  const [highLight,setHighLight] = useState(false);
  useEffect(()=>{
    if(pageStartNum === element*NO_OF_QUESTION_PER_PAGE){
        setHighLight(true);
    }
    else{
        setHighLight(false)
    }
  },[pageStartNum])
  return (
    <span style={highLight?{backgroundColor:'#fff',color:'var(--primary-color)'}:{backgroundColor:'var(--secondary-color)',color:'var(--white-color)'}} onClick={()=>{click(element)}}>{element+1}</span>
  )
}

export default QuestionPageNumber