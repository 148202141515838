import React, { PureComponent, useEffect,useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, } from 'recharts';
import {useSelector} from 'react-redux';



export class Example extends PureComponent {
  constructor(props){
    super(props);
    this.state = {chartData:this.props.chartData};
    this.props = props;
  }
 
  render() {
    const data = this.state.chartData;
    return (
      <> 
        {}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip/>
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  }
}

export const modifyString = (str) =>{
    const limit = 3;
    str = str.trim();
    let tempStrArr = str.split(' ');
    let len = tempStrArr.length;
    let i = 0;
    let newStr = '';
    let temp = Math.ceil(limit/len);
    let count = 0;
    while(i < limit){
        newStr+= `${tempStrArr[count].slice(0,temp)}.`;
        count++;
        i+=temp;
        temp = Math.ceil((limit-i)/(len - 1));
    }
    return newStr.toUpperCase().slice(0,-1);   
}

const QuestionChart = () => {
  const {value ,categories} = useSelector(store => store.questions);
  
  const [chartData,setChartData] = useState([])
  useEffect(()=>{
    if(value && value.length > 0 && categories && categories.length > 0){
        const questions = value;
        let tempData = [];
        categories.forEach(eCategory => {
            let val = 0;
            questions.forEach(eQuestion => {
                let id = eCategory.id;
                let cId = eQuestion.category_id;
                if(id === cId) val++;
                
            })
            tempData.push({
                id: eCategory.id,
                name: modifyString(eCategory.name),
                value:val,
                tName:eCategory
            })
        })

        tempData.sort((a,b) => b.value - a.value);
        //console.log(tempData.slice(0,7))
        setChartData(tempData.slice(0,7));
       

     }
  },[value,categories])
  return (
    <>
      {chartData && chartData.length > 0 ? <Example chartData={chartData} />: <></>}
    </>
    
    
  )
}

export default QuestionChart