import { createContext, useState } from 'react';
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { BsList } from 'react-icons/bs';
import Create from './createCbt';
import View from './viewCbt';
import ViewResult from './ViewResult';
import Modal from '../../../components/modal';
import CbtDashboard from './CbtDashboard';
import Menu from '../../../components/Menu';

export const staffContext = createContext();
export const CbtModalContext = createContext();

const Cbt = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [editObject,setEditObject] = useState(null);
    const [title, setTitle] = useState('');
    const [modalObject,setModalObject] = useState({});
    const [modalTogglee,setModalTogglee] = useState(false);
    
    return (
        <Routes>
            <Route path='/dashboard' element={<CbtDashboard/>}/>
            <Route path="/*" element={
                <staffContext.Provider value={{ title, setTitle }}>
                    <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <h1 className={`${styles.title}`}>CBT</h1>
                        <div className={`${styles.subView}`}>
                            <div className={titleStyle.sectionTitle}>
                                <h3 className={titleStyle.h3}>
                                    {title}
                                </h3>
                                <Menu options={[
                                    {label:'Create CBT',path:'create-cbt'},
                                    {label:'View CBT',path:'view-cbt'},
                                    {label:'View Result',path:'view-result'}
                                ]} />

                            </div>
                            <CbtModalContext.Provider value={{modalObject,setModalObject,modalTogglee,setModalTogglee,editObject,setEditObject}} >
                                <Routes>
                                    {/* <Route path='*' element={<Create seTTitle={setTitle} />} /> */}
                                    <Route path='/create-cbt' element={<Create setTitle={setTitle} />} />
                                    <Route path='/view-cbt' element={<View setTitle={setTitle} />} />
                                    {/* <Route index element={<CbtDashboard />} /> */}
                                    <Route path="/view-result" element={<ViewResult setTitle={setTitle} />} />
                                
                                </Routes> 
                                <Modal modalObject={modalObject} modalTogglee={modalTogglee} closeModal={()=>{setModalTogglee(false)}} />
                            </CbtModalContext.Provider>
                            
                        </div>
                    </div>
                </staffContext.Provider>
            }/>
         
        </Routes>
        
    );
};

export default Cbt;