import { createSlice } from "@reduxjs/toolkit";

export const CbtReducer = createSlice({
    name:"cbt",
    initialState:{cbts:[]},
    reducers:{
       setCbts:(state, action) => {
            //state.cbts = action.payload;
            return {
                cbts:action.payload
            };
       },
       addCbt:(state, action) => {
            return {
            cbts:[action.payload, ...state.cbts]
            };
      },
      deleteCbt:(state,action)=>{
        return{
            cbts:state.cbts.filter(eachCbt => eachCbt.id != action.payload.id)
        };
      },
      editCbt:(state,action)=>{
        const editedCbt = state.cbts.map(eachCbt => eachCbt.id == action.payload.id?action.payload:eachCbt)
        return{
            cbts:editedCbt,
        };
      }
    }
})
export const {setCbts,addCbt,deleteCbt,editCbt} = CbtReducer.actions;
export default CbtReducer.reducer;