import modalcss from "../css/modal.module.css";
import { memo } from "react";

const Modal = ({ modalObject, modalTogglee, closeModal, children, className }) => {


    return (
        <div className={`${modalcss.modal}`} style={{ display: modalTogglee ? "block" : "none" }}>
            <div className={modalcss.modal_content}>
                <div className={modalcss.modal_header}>
                    <span className={modalcss.close} onClick={closeModal}>&times;</span>
                    <h2>{modalObject.header}</h2>
                </div>
                <div className={modalcss.modal_body}>
                    {modalObject.body}
                </div>
                {children && <div className={modalcss.modal_body}>
                    {children}
                </div>}
                <div className={modalcss.modal_footer}>
                    {modalObject.footer}
                </div>
            </div>
            <div className={modalcss.bgdiv} onDoubleClick={closeModal}></div>
        </div>
    );

};

export default memo(Modal);