import {createSlice} from "@reduxjs/toolkit";



export const billingSlice = createSlice({
    name:'billing', 
  initialState: { values: [
    {
    service_id:1,
    service_name:'CBT',
    amount:2.24,
  },
  {
    service_id:2,
    service_name:'Learning',
    amount:4.78,
  },
  {
    service_id:3,
    service_name:'Test',
    amount:4.02,
},], },
  reducers:{
    addBilling: (state,action)=>{
      state.values = [
        action.payload, ...state.values
      ];
    },
    setBiling: (state,action)=>{
      state.values = action.payload
    }
  },

})
export const { addBilling, setBiling}= billingSlice.actions
export default billingSlice.reducer