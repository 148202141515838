import React, { useState } from 'react'
import css from './css/dashboard.module.css';
import {Link} from 'react-router-dom';
import {QuestionChart} from './index';
import VideoPlayer from '../../../components/VideoPlayer';

const Dashboard = () => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <div className={css.container}>
        <div>
          <div>QUESTION BANK DASHBOARD</div>
          <div>
            <div className={css.graphContainer}>
              <QuestionChart />
            </div>
            <div className={css.infoContainer}>
            <div className={css.head}>EDUPLUS QUESTION BANK</div>
              <p className={css.body}>
                Eduplus Question Bank provides users with fast and easy to use user-firendly interface to store and access question to prepare for future Academic Challenges, Test and Examination. 

              </p>
              <div className={css.foot}>
                <Link to="../questions/add-question">Get Started</Link>
                <Link to="" onClick={()=>setShowVideo(!showVideo)}>Watch Video</Link>
              </div>
            </div>
          </div>
          {showVideo && <VideoPlayer serviceCode={'EQB'}/>}
        </div>
    </div>
  )
}

export default Dashboard