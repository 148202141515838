import styles from './../css/Fields.module.css';
import { BsCheck2Circle, BsRecord } from "react-icons/bs";
import InputField from './InputField';

const PrimaryFields = ({ toSwitch, onChange, validUser, userSymbol, hasAlpha, hasNumber, hasSymbol, properLength, match, showInit, showZero, showFirst, showLast, email, phone, showCon, validating }) => {

    return toSwitch ? (
        <>
            <InputField title='Email' inputID='user-con' placeholder='Enter your Email' type='text' onChange={onChange} />
            <InputField title='Password' inputID='user-pass' placeholder='Enter Password' type='password' onChange={onChange} />
        </>
    ) : (
        <>
            <InputField title='Username' inputID='user-name' placeholder='Enter your Username' type='text' onChange={onChange} />
            {showInit &&
                <div className={`${styles.conditionDiv}`}>
                    <span className={`${styles.condition}`}>{userSymbol ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Username cannot have spaces or symbols</span>
                    <span className={`${styles.condition}`}>{validUser ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Username must be of 3 or more characters</span>
                </div>}
            <InputField title='Email' inputID='user-con' placeholder='Enter your Email' type='text' onChange={onChange} />
            {showZero && <span className={`${styles.condition}`}>{showCon ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}<span>{email ? 'Email Detected' : phone ? `Mobile Number Detected` : validating}</span></span>}
            <InputField title='Password' inputID='user-pass' placeholder='Enter Password' type='password' onChange={onChange} />
            {showFirst &&
                <div className={`${styles.conditionDiv}`}>
                    <span className={`${styles.condition}`}>{hasAlpha ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Password must have alphabetical characters</span>
                    <span className={`${styles.condition}`}>{hasNumber ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Password must have at least one number</span>
                    <span className={`${styles.condition}`}>{hasSymbol ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Password must have at least one symbol</span>
                    <span className={`${styles.condition}`}>{properLength ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}Password must be of 6 or more characters</span>
                </div>}
            <InputField title='Confirm Password' inputID='con-pass' placeholder='Re-enter Password' type='password' onChange={onChange} />
            {showLast && <span className={`${styles.condition}`}>{match ? <BsCheck2Circle className={`${styles.checked}`} /> : <BsRecord className={`${styles.unchecked}`} />}{match ? 'Password accepted' : `Passwords don't match`}</span>}
        </>
    );
};

export default PrimaryFields;