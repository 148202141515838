import React,{useEffect,useState, useContext, Fragment} from 'react';
import HTMLReactParser from 'html-react-parser';
import Api from "../../../Api";
import { deleteQuestion } from '../../../redux/reducers/questionReducer';
import { useDispatch } from 'react-redux';
//import { useEffect } from 'react/cjs/react.production.min';
import { useNavigate } from 'react-router-dom';
import { EditContext } from './Questions';
import { FaEdit, FaRegTrashAlt, FaTimes } from 'react-icons/fa';


const EachQuestion = ({style,eQuestion,changeInd,ind,currentOpened,changeCurrentOpened,setQuestions,questions,questionIndex,pageStartNum, setShowLoading}) => {
  const [hamstyle,setHamStyle] = useState('none');
  const [openStyle,setOpenStyle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {setEditQuestion,setAllQuestions} = useContext(EditContext);
  const [questionOptions,setQuestionOptions] = useState([])
  const [showQuestion,setShowQuestion] = useState(false);

  const handleChange = (ind)=>{
    if(ind){
      changeInd(ind)
    }
    else{
      changeInd(null);
    } 
  }

  const handleOpened = (index)=>{
      if(index){
        changeCurrentOpened(index);
      }
      else{
        changeCurrentOpened(null);
      }
      
  }

  const handleDeleteQuestion =async () =>{
    const id = eQuestion.id;
    const table = 'questions'
    // dispatch(deleteQuestion({id}));
    //setAllQuestions(questions.filter(question => question.id !== id ));
    // setQuestions(questions.filter(question => question.id !== id ));
    setShowLoading(true);
    Api.PostApi(`/question/delete-item`,{id,table},true)
      .then(response => response.json())
      .then(result => {
         //console.log(result)
         if(result.isSuccessful){
           dispatch(deleteQuestion({id}))
           setAllQuestions(questions.filter(question => question.id !== id ));
           setQuestions(questions.filter(question => question.id !== id ));

         }
      })
      .catch(err =>{
        //console.log(err)
    }).finally(()=>{
      setShowLoading(false);
    })
  }

  const handleEditQuestion = () =>{
      setEditQuestion(eQuestion);
      setAllQuestions(questions);
      navigate('../add-question')
  }
 

  useEffect(()=>{
      if(eQuestion.id == ind){
          setHamStyle('flex')
          return false;
      }
      setHamStyle('none');
  },[ind])

  useEffect(()=>{
    // console.log(eQuestion)
    if(eQuestion.id == currentOpened){
      setOpenStyle(true);
      //console.log(eQuestion.options)
    } 
    else{
      setOpenStyle(false)
      // setOpenStyle(false)
    }  
},[currentOpened])
  useEffect(()=>{
    let newOptions = eQuestion.options.slice(2,-2);
    newOptions = newOptions.split('","');
    //console.log(newOptions)
    setQuestionOptions(newOptions);
  },[])

  useEffect(()=>{
    if(questionIndex >= pageStartNum && questionIndex < pageStartNum+10){
      setShowQuestion(true)
    }
    else{
      setShowQuestion(false)
    }
  },[pageStartNum])
  
  return (
    <Fragment>
      {showQuestion &&
      <div className={style} style={{paddingBottom:'45px'}} >
        <div style={openStyle?{height:'auto'}:{height:'60px'}}>{HTMLReactParser(eQuestion.question)}
            
        </div>
        <span onClick={()=>{handleChange(eQuestion.id)}}>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div style={{display:hamstyle}}>
          <span onClick={()=>{handleChange(null)}}><FaTimes /></span>
          <button onClick={()=>{handleEditQuestion()}}><FaEdit /> Edit</button>
          <button onClick={() => { handleDeleteQuestion(); }}><FaRegTrashAlt /> Delete</button>
        </div>
        {!openStyle?<button onClick={()=>{handleOpened(eQuestion.id)}}>see more...</button>:<button onClick={()=>{handleOpened()}}>see less...</button>}
        <div style={openStyle?{display:'flex'}:{display:'none'}}>
          <h3>Options</h3>
          {questionOptions.length?questionOptions.map((option,index) =>(
            <div key={index}>{index==0&&'(A) '||index==1&&'(B) '||index==2&&'(C) '||index==3&&'(D) '||index==4&&'(E) '||index==5&&'(F) '}{option }</div>
          )):''}
        </div>
      </div>
      }
    </Fragment>
    
  )
}

export default EachQuestion