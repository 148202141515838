import { useState, useRef, useEffect, useContext } from 'react';
import styles from '../../../css/TakeTest.module.css';
import Api from "../../../helperFunctions/Api2";
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux";
import { MenuContext } from '../DashBoard';
import { Context } from './Test';

const TakeTest = ({ setTitle }) => {

    const [isTimed, setIsTimed] = useState(false);
    const navigate = useNavigate();

    const categories = useSelector((state) => state.questions.categories);

    const toggleIsTimed = () => {
        setIsTimed(!isTimed);
    };

    const questCat = useRef();
    const questAmt = useRef();
    const isTimedRef = useRef();
    const questDuration = useRef();
    const purpose = useRef();

    const {editData, setEditData } = useContext(Context);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const message1 = `Please completely fill out all required fields`;

    const validate = () => {
        if (Number(questAmt.current.value) === 0 ||
            (isTimed && Number(questDuration.current.value) === 0) ||
            purpose.current.value === ''
        ) {
            // Show a popup Modal
            setModalText(message1);
            setModalToggle(true);
        } else {
            // Show a popup Modal
            if(!Api.checkNetwork()) return;
             setShowLoading(true);
            const testParam = {
                category_id: Number(questCat.current.value),
                question_num: Number(questAmt.current.value),
                is_timed: isTimed ? 1 : 0,
                duration: isTimed ? Number(questDuration.current.value) : isTimed,
                purpose: purpose.current.value
            };
            Api.PostApi('/test/add-test', testParam, true)
                .then(result => {
                     setShowLoading(false);
                    //console.log(result)
                    if(result.isSuccessful){
                        window.location.href="https://cbt.sight-eduplus.com/" + result.data
                    }
                    else{
                        setModalText(result.message);
                        setModalToggle(true);
                    }
                })
                .catch(error => {
                    setShowLoading(false);
                    setModalText("Action failed. Please re-login");
                    setModalToggle(true);
                    // alert("Action failed. Please re-login");
                    sessionStorage.removeItem('eduplus_tkn');
                    navigate("/account/sign-in");
                });
            // Post these Values
            //console.table(testParam);
        }
    };

    useEffect(() => {
        setTitle('Create personal test');
    }, []);
    useEffect(() => {
        if (editData && editData.id) {
            console.log(editData);
            questCat.current.value = editData.category_id;
            questAmt.current.value = editData.question_num;
            setIsTimed(Boolean(editData.is_timed));
            purpose.current.value = editData.purpose;
        }
    }, [editData]);
    useEffect(() => {
        if (isTimed && editData && questDuration.current) {
            questDuration.current.value = editData.duration;
        }
    }, [editData, isTimed]);
    useEffect(() => {
    }, []);

    return (
        <>
            <div className={`${styles.mainView}`}>
                <div className={`${styles.mainChoices}`}>
                    <div className={`${styles.choices}`}>
                        <label className={`${styles.label}`} htmlFor={'questCat'}>
                            Questions' Category
                            <select id='questCat' name='questCat' className={`${styles.select}`} required ref={questCat}>
                                 {categories.map((category) => (
                                        <option value={category.id}key={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                            </select>
                        </label>

                        <label className={`${styles.label}`} htmlFor={'questAmt'}>
                            <span className={styles.isRequired}>Number of Questions (max: 50)</span>
                            <input cal="true" className={`${styles.input}`} type='number' min='1' max="50" id='questAmt' name='questAmt' required ref={questAmt} />
                        </label>
                        <label className={`${styles.label} ${styles.isTimedLabel}`} htmlFor={'isTimed'}>
                            Timed Exam?
                            <input className={`${styles.input}`} type='checkbox' id='isTimed' name='isTimed' checked={isTimed} onChange={toggleIsTimed} ref={isTimedRef} />
                        </label>

                        {isTimed &&
                            <label className={`${styles.label} ${styles.questDurationLabel}`} htmlFor={'questDuration'}>
                                <span className={styles.isRequired}>Duration (in minutes)</span>
                                <input className={`${styles.input}`} type='number' id='questDuration' min='1' name='questDuration' required ref={questDuration} />
                            </label>
                        }
                        <label className={`${styles.purposeTitle} ${styles.label}`} htmlFor='purpose'>
                            <span className={styles.isRequired}>Purpose</span>
                            <textarea className={`${styles.textarea}`} name='purpose' placeholder='Could you please tell us why you are about to take this test?' rows={6} required ref={purpose} />
                        </label>
                    </div><br/><br/>
                    <span className={styles.purposeDiv}>{editData && <button className={`${styles.startBtn} cancelEditButton`} onClick={()=>navigate('../analysis')}>
                        Cancel
                    </button>}
                    <button className={`${styles.startBtn}`} onClick={validate} disabled={!!editData}>
                        Start
                    </button></span>
                </div>
            </div>
        </>
    );
};

export default TakeTest;