import selcss from './../../../components/Select/select.module.css';
import css from './../../../css/viewStyle.module.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import Api from '../../../helperFunctions/Api2';
import Select from '../../../components/Select';
import PageNumber from '../../../components/PageNumber';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { EditContext } from './AttendanceManagement';
const NO_OF_ITEMS_PER_PAGE = 30


export default function TakeAttendance({ setTitle }) {

    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const user_institutions = useSelector(state => state.questions.user_institutions);
    const [selInstitution, setSelInstitution] = useState({});
    const [activeCat, setActiveCat] = useState([]);
    const [selCategory, setSelCategory] = useState({});
    const [selAttendance, setSelAttendance] = useState({});
    const [activeUsers, setActiveUsers] = useState({});
    const [activeAttendance, setActiveAttendance] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);
    const { editing, editData, clearEdit, setEditing } = useContext(EditContext);
    const [defaultInst, setDefaultInst] = useState('');
    const [defaultCategory, setDefaultCategory] = useState('');
    const [defaultAtt, setDefaultAtt] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        const attendance = {
            id: selAttendance.id,
            date: new Date().toISOString().replace('T', ' ').slice(0, -5),
            record: {}
        };
        activeUsers.forEach(element => {
            attendance.record[element.id] = element.present ? 1 : 0
        });

        setShowLoading(true);
        Api.PostApi('/institution/take-attendance', attendance, true)
            .then(result => {
                setShowLoading(false);
                if (result.isSuccessful) {
                    console.log(attendance, result);
                    setModalText(result.message);
                    setModalToggle(true);
                }
                else {
                    setModalText(result.message);
                    setModalToggle(true);
                }
            })
            .catch(error => {
                setShowLoading(false);
                setModalText('Action failed, please check your internet connection, or relogin');
                setModalToggle(true);
            })
    }

    useEffect(() => {
        setTitle('Take Attendance');
    }, [setTitle]);
    // console.log(editData, editing)


    useEffect(() => {
        if (editing && editData.type == 'takeAttendance' && editData.id) {
            const a = user_institutions.find(inst => inst.id === editData.institution_id);
            setDefaultInst(a);
        }
    }, [editData, editing, user_institutions])



    useEffect(() => {
        if (selInstitution.id) {
            setActiveCat(Object.values(selInstitution.categories));
            setSelCategory({});
            if (editing && editData.type == 'takeAttendance' && editData.id) {
                const b = selInstitution.categories[editData.institution_category];
                setDefaultCategory(b);
            }
        }
    }, [selInstitution])


    useEffect(() => {
        // setSelAttendance({})
    }, [selInstitution, selCategory]);


    useEffect(() => {
        if (selCategory.id) {
            const arr = Object.keys(selCategory.attendance);
            setActiveAttendance(arr.length > 0 ? arr.map((id, ind) => { return { id, value: selCategory.attendance[id] } }) : []);
            setActiveUsers(Object.values(selInstitution.users).filter(eachUser => eachUser.categories && eachUser.categories.includes(selCategory.id)));
        }
        else {
            setActiveAttendance([]);
            setActiveUsers([]);
        }
    }, [selCategory, selInstitution,])

    useEffect(()=>{
        if (editing && editData.type == 'takeAttendance' && editData.id) {
            const d = activeAttendance.find(d => d.id == editData.id)
            setDefaultAtt(d);
            setTimeout(()=>clearEdit(), 5000)
        }
    }, [activeAttendance])


    useEffect(() => {
        if (activeUsers.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((activeUsers.length / NO_OF_ITEMS_PER_PAGE) + ((activeUsers.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [activeUsers.length]);
    // console.log(selAttendance);

    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <div className={css.container} style={{ paddingBottom: '10px' }}>
                <label>
                    <span>Select Institution</span>
                    <Select onChange={setSelInstitution} options={user_institutions} defaultValue={defaultInst} value="id" label="name" />
                </label>
                <label>
                    <span>Select User Group</span>
                    <Select onChange={setSelCategory} options={activeCat} defaultValue={defaultCategory} value="id" label="name" />
                </label>
                <label>
                    <span>Select Attendance</span>
                    <ModSelect onChange={setSelAttendance} options={activeAttendance} defaultValue={defaultAtt} value="value" label="id" />
                </label>
                {
                    selAttendance.id && <table className={css.attendanceTable}>
                        <thead><tr>
                            <th>S/N</th>
                            <th>Name</th>
                            <th>Present</th>
                        </tr></thead>
                        <tbody>{
                            activeUsers.length ? activeUsers.map((userr, index) => {
                                const user = { ...userr }
                                // console.log(user)
                                user.present = user.present ? user.present : false;
                                return (
                                    index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                                    <tr key={index} >
                                        <td>{index + 1}</td>
                                        <td>{user.firstName + ' ' + user.lastName}</td>
                                        <td><input type='checkbox' onChange={() => setActiveUsers(activeUsers.map(e => user.id === e.id ? { ...user, present: !user.present } : e))} checked={user.present} />{user.present}</td>
                                    </tr>)
                            }) :
                                <tr>'No User Available'</tr>
                        }</tbody>
                        {activeUsers.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
                    </table>
                }
                <div className={css.addBtnDiv}>
                    <button className={`${css.addBtn}`} onClick={(e) => handleSubmit(e)}>Save Attendance</button>
                </div>
            </div>
        </>
    );
};




/*********************************************************************** */
const ModSelect = ({ options, onChange, defaultValue, className, label, value, disabled, reset, placeholder }) => {
    const [showList, setShowList] = useState(false);
    const [selectVal, setSelectVal] = useState('');
    const [click, setClick] = useState(false);
    const [inputVal, setInputVal] = useState('');
    const [selOptions, setSelOptions] = useState(options);
    const inputRef = useRef(null);
    const divRef = useRef(null);
    const handleClick = () => {

        if (inputRef && inputRef.current) {
            // console.log(showList);
            if (showList) {
                inputRef.current.autofocus = false;
                setShowList(false)
                return false;
            }
            inputRef.current.focus();
            inputRef.current.autofocus = true;
            setShowList(true);
        }
        //console.log('clicked')
    }
    const handleBlur = () => {
        if (inputRef && inputRef.current) {
            if (!click) {
                inputRef.current.autofocus = false;
                setShowList(false);
                setInputVal('')
            }
        }
    }

    const handleFocus = () => {
        if (divRef && divRef.current) {
            setClick(true);
        }
    }

    const handleUnFocus = () => {
        if (divRef && divRef.current) {
            setClick(false);
            if (!inputRef.current.autofocus) { setShowList(false) }
        }
    }

    const handleSelect = (val) => {
        onChange(val);
        setSelectVal(val[label]);
        setShowList(false);
        setInputVal('');
        setSelOptions(options)
    }

    const handleInput = (e) => {
        const value = e.target.value;
        setInputVal(value);

    }


    useEffect(() => {
        if (!inputVal && !inputRef.current.autofocus) {
            setShowList(false);
            setSelOptions(options);

        }
        else if (!inputVal && !click && !inputRef.current.autofocus) {
            setShowList(false);
            setSelOptions(options);
        }
        else if (inputVal) {
            const newOptions = options.filter(element => element[label].toLowerCase().includes(inputVal.toLowerCase()))
            if (newOptions.length > 0) {
                setSelOptions(newOptions);
            }
        }
        else {
            setSelOptions(options);
        }
    }, [selectVal, inputVal, showList])

    useEffect(() => {
        if (options && options.length > 0) {
            setSelOptions(options);
            setSelectVal('');
        }
        //changes made here
        else if (options && options.length == 0) {
            setSelectVal(undefined)
        }
        //end
    }, [options])

    useEffect(() => {
        setSelectVal('');
        setInputVal('');
        onChange({})
    }, [reset])
    useEffect(() => {
        if (defaultValue && label && value) {
            let tempVal = options.find(opt => opt[value] == defaultValue[value]);
            if (tempVal) handleSelect(tempVal);
            // console.log(defaultValue,defaultValue[label])
        }
    }, [defaultValue, label, value])

    // useEffect(()=>{
    //     console.log(showList)
    // },[showList])

    const computeStyle = () => {
        let inpVal = inputVal ? { width: 'fit-content' } : { width: '4px' };
        let disVal = disabled ? { backgroundColor: '#eee' } : { backgroundColor: 'transparent' };
        return { width: inpVal.width, backgroundColor: disVal.backgroundColor }
    }

    return (
        <div className={`${className ? className : ''} ${selcss.selectContainer}`} style={disabled ? { backgroundColor: '#eee' } : { backgroundColor: 'transparent' }}>
            <div>
                <div></div>
                <input onChange={(e) => { handleInput(e) }} style={computeStyle()} ref={inputRef} onBlur={() => { handleBlur() }} disabled={disabled} />
                {<div onClick={!disabled ? () => handleClick() : () => { }} style={!selectVal && !inputVal ? { color: 'lightgrey' } : { color: '#222' }}>{selectVal && !inputVal ? selectVal.split('~')[1] : inputVal ? '' : (defaultValue ? defaultValue[label].split('~')[1] : !inputVal && !showList && placeholder ? placeholder : 'Select ...')}</div>}
                <span title={disabled ? 'disabled' : ''} onClick={() => { handleClick() }} className={`${selcss.icons} ${disabled ? `${selcss.disabled}` : ''}`}>{!disabled ? !showList ? <BiChevronDown /> : <BiChevronUp /> : <BiChevronDown />}</span>
            </div>
            {!disabled && showList && <div ref={divRef} onMouseOver={() => { handleFocus() }} onMouseOut={() => { handleUnFocus() }}>
                {showList && selOptions && selOptions.length > 0 && selOptions.map((element, index) => (
                    <div key={index} onClick={() => handleSelect(element)}>{element[label].split('~')[1]}</div>
                ))}
            </div>}
        </div>
    )
}