import css from './css/servicesCss.module.css';
import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Button } from './components'
import { ScreenContext } from './Home';
import text from './FAQ.json';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const Help = () => {
  const { smallScreen } = useContext(ScreenContext);
  const [serviceList, setServiceList] = useState([]);
  const [activeHash, setActiveHash] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const serviceArr = text;
    let hash = null;
    if (location.hash) {
      const obj = {
        institution: 1,
        tutor: 2,
        student: 3
      }
      setActiveHash(location.hash.slice(1));
      hash = obj[location.hash.slice(1)];
    }
    else {
      setActiveHash(null);
    }

    setServiceList(!hash ? serviceArr : serviceArr.filter(service => service.categories.some(e => e === hash)))

  }, [location])

  return (
    <>
      <section >
        <div className={css.bannerDiv}>
          <div></div>
          <div></div>
          <div>
            <div>
              <h1>Start Using Eduplus Today!</h1>
              <p>
                Whether you are a student, a tutor, a school owner or an educator (&nbsp;Coach&nbsp;/&nbsp;Trainer&nbsp;), Eduplus have services to cater for your needs.
              </p>
              <div>
                <Button text='Get Started' />
              </div>

            </div>
            <div>
              <img alt='background' src='./images/edu1.png' />
            </div>
          </div>

        </div>
        <div className={css.servicesDiv}>
          <div>
            {smallScreen ? <div className={css.mobileToggle}>

            </div> :
              <div className={css.desktopToggle}>
                <Link to='#' className={activeHash === null ? css.activeCategory : ''}>FAQ</Link>
                <Link to='#institution' className={activeHash === 'institution' ? css.activeCategory : ''}>Videos</Link>
                <Link to="#tutor" className={activeHash === 'tutor' ? css.activeCategory : ''}>Articles</Link>
              </div>}
            <div className={css.supportContainer}>
              {serviceList && serviceList.length > 0 && serviceList.map(({ question, categories, answer }, index) => <EachService key={index} question={question} categories={categories} answer={answer} index={index} />)}
            </div>
          </div>

        </div>

      </section>
    </>
  )
}

export default Help;


const EachService = ({ index, question, categories, answer }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <div className={css.supportContent}>
        <div onClick={() => setShow(!show)} >
          <h3 className={css.question}>
            <span className={css.index}>Question {index + 1}:</span> {question}?
          </h3>
          <b>{show ? <BsChevronLeft /> : <BsChevronRight />}</b>
        </div>
        {show && <div className={css.answer}>{answer}</div>}
      </div>

    </>
  )
}
