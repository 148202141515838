import React, { useState } from "react";
import { useEffect } from "react";
import Api from '../../../helperFunctions/Api2';
import css from './Css/viewEmail.module.css';
import { useDispatch, useSelector } from "react-redux";
import Loading from '../../../components/loading';
import { eventModalContext } from "./mailSettings";
import { deleteEmailGroup } from "../../../redux/reducers/eventReducer";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { EditComponent } from "../../../components/EditComponent";
import { BiEditAlt as EditIcon } from 'react-icons/bi';
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;

let count = 0;

const ViewEmails = ({ setTitle }) => {
    const [see, setSee] = useState(false);
    const [seeIndex, setSeeIndex] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const emailGroups = useSelector(state => state.event.email_group);
    // const emailList  = useSelector(state => state.questions.user_emailList);
    const { editEmailObj, setEditEmailObj, setAllContact, setModalObject, setModalToggle } = useContext(eventModalContext);
    const [menuIndex, setMenuIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSee = (ind) => {

        if (ind == seeIndex) {
            setSee(!see);
        }
        setSeeIndex(ind);
    }

    const handleEditBtn = (obj) => {
        setEditEmailObj(obj);
        navigate('../add-email-group');
    }

    const handleEditEmailList = (obj) => {
        setEditEmailObj(obj);
        navigate('../add-emails');
    }

    const handleDeleteBtn = (email) => {
        setModalObject({
            header: <>Confirmation Message</>,
            body: <>
                <p>Are you sure you want to delete this Email Group. This action will delete the records associated with this Group too.</p>

            </>,
            footer:
                <div>
                    <button onClick={() => setModalToggle(false)}>Cancel</button>
                    <button onClick={() => { handleDelete(email.id) }}>Delete Email Group</button>
                </div>
        });
        setModalToggle(true);
    }

    const handleDelete = (id) => {
        if (!Api.checkNetwork()) return false;
        setShowLoading(true)

        Api.PostApi('/event/delete-email-group', { id }, true)
            .then(result => {
                setShowLoading(false);

                console.log(result, "Server result");
                if (result.isSuccessful) {
                    setMenuIndex(null);
                    setModalObject({ header: "", body: "Email group Deleted successfully", footer: "" });
                    setModalToggle(true);
                    dispatch(deleteEmailGroup({ id }))
                }
            })
            .catch(error => {
                setShowLoading(false);
                console.log(error, "Server Error")
            })
    }


    const moreOption = (obj) => [
        <div key={1} onClick={() => handleEditEmailList(obj)}>
            <EditIcon /> Edit Email List
        </div>,
    ]

    useEffect(() => {
        setTitle('View Emails')
    }, [setTitle])

    useEffect(() => {
        setSee(true);
    }, [seeIndex])

    useEffect(() => {
        if (emailGroups.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((emailGroups.length / NO_OF_ITEMS_PER_PAGE) + ((emailGroups.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [emailGroups.length]);


    return (
        <>
            <Loading shouldShow={showLoading} />
            <div className={css.viewContainer}>
                {emailGroups.length > 0 ? emailGroups.map((email, ind) =>
                    ind < (currentPage * NO_OF_ITEMS_PER_PAGE) && ind >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                    <div key={ind}>

                        <EditComponent more={moreOption(email)} index={ind} setMenuIndex={setMenuIndex} menuIndex={menuIndex} clickEdit={() => handleEditBtn(email)} clickDelete={() => handleDeleteBtn(email)} />
                        <div>
                            <span className={css.title}>Group Name: </span>
                            <span>{email.title}</span>
                        </div>
                        <div>
                            <span className={css.title}>Group Description: </span>
                            <span>{email.description ? email.description : ""}</span>
                        </div>
                        <div>
                            <span className={css.title}>Email List:</span>
                            <div className={css.emailGroups}>
                                {email.emails && email.emails.length > 0 ? email.emails.map(((eEmail, ind) => ind <= 8 ? <span key={ind}>{(typeof eEmail == 'string' ? eEmail : eEmail.email)}</span> : '')) : 'No Email Added'}

                            </div>
                            {email.emails.length > 8 ?
                                <span className={css.see} onClick={() => handleSee(ind)}>{ind === seeIndex && see == true ? 'see less ...' : 'see more ...'}</span> : ''}
                        </div>
                    </div>
                ) : 'No email List added to any email Group'}

                {emailGroups.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
            </div>
        </>
    );
}

export default ViewEmails;

