import React,{useState,useEffect} from 'react'
import Select from '../Select';
import css from './style.module.css';


export const timeList = [
    // { name: "Select Time Zone...", value: "-1" },
    { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12.00" },
    { name: "(GMT -11:00) Midway Island, Samoa", value: "-11.00" },
    { name: "(GMT -10:00) Hawaii", value: "-10.00" },
    { name: "(GMT -9:30) Taiohae", value: "-09.833" },
    { name: "(GMT -9:00) Alaska", value: "-09.00" },
    { name: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08.00" },
    { name: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07.00" },
    { name: "(GMT -6:00) Central Time (US & Canada), Mexico City", value: "-06.00" },
    { name: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima", value: "-05.00" },
    { name: "(GMT -4:30) Caracas", value: "-04.833" },
    { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04.00" },
    { name: "(GMT -3:30) Newfoundland", value: "-03.833" },
    { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03.00" },
    { name: "(GMT -2:00) Mid-Atlantic", value: "-02.00" },
    { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01.00" },
    { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "0.00" },
    { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "1.00" },
    { name: "(GMT +2:00) Kaliningrad, South Africa", value: "2.00" },
    { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "3.00" },
    { name: "(GMT +3:30) Tehran", value: "3.833" },
    { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "4.00" },
    { name: "(GMT +4:30) Kabul", value: "4.833" },
    { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "5.00" },
    { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "5:833" },
    { name: "(GMT +5:45) Kathmandu, Pokhara", value: "5.75" },
    { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "6.00" },
    { name: "(GMT +6:30) Yangon, Mandalay", value: "6.833" },
    { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "7.00" },
    { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "8.00" },
    { name: "(GMT +8:45) Eucla", value: "8.75" },
    { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "9.00" },
    { name: "(GMT +9:30) Adelaide, Darwin", value: "9.833" },
    { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "10.00" },
    { name: "(GMT +10:30) Lord Howe Island", value: "10.833" },
    { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "11.00" },
    { name: "(GMT +11:30) Norfolk Island", value: "11.833" },
    { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "12.00" },
    { name: "(GMT +12:45) Chatham Islands", value: "12.75" },
    { name: "(GMT +13:00) Apia, Nukualofa", value: "13.00" },
    { name: "(GMT +14:00) Line Islands, Tokelau", value: "14.00" }
];

const Timezone = ({onChange,reset,defaultValue,list,placeholder}) => {
  const [timeArr,setTimeArr] = useState([]);
  const [timeReset,setTimeReset] = useState(false);
  const [defaultTime,setDefaultTime] = useState('');

  useEffect(()=>{
    if(list && list.length > 0) setTimeArr(list);
    else setTimeArr(timeList)
  },[])


  useEffect(()=>{
    if(typeof defaultValue == 'object' && !Array.isArray(defaultValue) ){
        setDefaultTime(defaultValue);
    }
    else if(typeof defaultValue == 'number' || typeof defaultValue == 'string'){
      let dValue = timeList.find(eTime => eTime.value == defaultValue);
        if(dValue)setDefaultTime(dValue);
    }
    
  },[defaultValue])


  return (
    <div className={css.container}>
        <Select defaultValue={defaultTime?defaultTime:''} options={timeArr} onChange={onChange?onChange:()=>{}} label='name' placeholder={placeholder?placeholder:'Select Timezone'} reset={reset} />
    </div>
  )
}

export default Timezone