import css from './../../../css/viewStyle.module.css';
import css2 from './../../../css/view-category.module.css';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserCategory, deleteUserPrivileges } from '../../../redux/reducers/questionReducer';
// import { MenuContext } from '../DashBoard';
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import { useNavigate } from 'react-router-dom';
import Select from '../../../components/Select';
import { EditComponent } from '../../../components/EditComponent';
import styled from 'styled-components';
import PageNumber from '../../../components/PageNumber';
import restApi from '../../../helperFunctions/Api2';
import { EditContext } from './GroupMgmt';
import { BiXCircle } from 'react-icons/bi';
// import { Tooltip } from 'chart.js';
const NO_OF_ITEMS_PER_PAGE = 30


const ViewUserCategory = ({ setTitle }) => {

    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const user_institutions = useSelector(state => state.questions.user_institutions);
    const [selInstitution, setSelInstitution] = useState({});
    const [activeCategories, setActiveCategories] = useState([]);
    const [menuIndex, setMenuIndex] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);
    const { setEditCategory } = useContext(EditContext);


    const deleteCat = (cat) => {
        // console.log(cat);
        if (window.confirm('Are you sure you want to delete this group?')) {
            if (!restApi.checkNetwork()) return;
            setShowLoading(true);
            restApi.PostApi('/institution/delete-group', { id: cat.id }, true)
                .then(result => {
                    if (result.isSuccessful) {
                        dispatch(deleteUserCategory(cat));
                        setModalText('Group deleted successfully.');
                    }
                    else {
                        setModalText(result.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setModalText('Unsuccesful, Please try again, or Re-Login if problem persists')
                })
                .finally(() => {
                    setModalToggle(true);
                    setShowLoading(false);
                });
        }
    }

    const handleEdit = (category) => {
        setEditCategory(category);
        navigate("../create-category")
    }


    useEffect(() => {
        setTitle('View User Groups');
    }, [setTitle]);



    useEffect(() => {
        if (user_institutions && user_institutions.length > 0 && selInstitution.id) {
            let tempCate = Object.values(user_institutions.find(inst => inst.id === selInstitution.id).categories).sort((a, b)=> b.id - a.id);
            setActiveCategories(tempCate);
        }
    }, [selInstitution, user_institutions])

    useEffect(() => {
        if (activeCategories.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((activeCategories.length / NO_OF_ITEMS_PER_PAGE) + ((activeCategories.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [activeCategories.length]);


    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <div className={css.container}>
                <label>
                    <span>Select Institution</span>
                    <Select onChange={setSelInstitution} options={user_institutions} defaultValue="" value="id" label="name" />
                </label>
                <div className={css2.container}>
                    {
                        activeCategories && activeCategories.length > 0 ? activeCategories.map((eCategory, index) =>
                            index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE)
                            && <EachCategory eCategory={eCategory} index={index} handleEdit={handleEdit} deleteCat={deleteCat} menuIndex={menuIndex} setMenuIndex={setMenuIndex} 
                            key={index} setShowLoading={setShowLoading} setModalText={setModalText} setModalToggle={setModalToggle} dispatch={dispatch}/>)
                            : 'No Group in this Institution'

                    }
                </div>
                {activeCategories.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
            </div>
        </>
    );
};

export default ViewUserCategory;
const EachCategory = ({ eCategory, index, handleEdit, deleteCat, menuIndex, setMenuIndex, setShowLoading, setModalText, setModalToggle, dispatch }) => {
    const [seeDetails, setSeeDetails] = useState(false);

    const deletePriv = (privilege) => {
        const payload = {
            institution_id: eCategory.institution_id,
            group_id: eCategory.id,
            privilege_id: privilege.id
        }
        console.log(payload);
        if (window.confirm('Are you sure you want to delete this privilege?')) {
            if (!restApi.checkNetwork()) return;
            setShowLoading(true);
            restApi.PostApi('/privileges/remove-group-privileges', payload, true)
                .then(result => {
                    if (result.isSuccessful) {
                        dispatch(deleteUserPrivileges(payload));
                        setModalText('Privilege deleted successfully.');
                    }
                    else {
                        setModalText(result.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setModalText('Unsuccesful, Please try again, or Re-Login if problem persists')
                })
                .finally(() => {
                    setModalToggle(true);
                    setShowLoading(false);
                });
        }
    }

    return (<div key={eCategory.id}>
        <EditComponent menuIndex={menuIndex}
            index={index} setMenuIndex={setMenuIndex}
            clickDelete={() => deleteCat(eCategory)}
            clickEdit={() => handleEdit(eCategory)}
        />
        <div>
            <span>Name: </span>
            <span>{eCategory.name}</span>
        </div>
        <div>
            <span>No of Users: </span>
            <span>{eCategory.users.length}</span>
        </div>
        {seeDetails &&
            <>
                <div><span>Is Group Joinable: </span><span>{eCategory.is_joinable == 1 ? 'true' : 'false'}</span> </div>
                <div><span>Description: </span><span>{eCategory.description}</span> </div>
                <div>
                    <span>Priviledges: </span>
                    {eCategory.privileges.length === 0 ? <span>No priviledge assigned yet</span> :
                        eCategory.privileges.map((ePrivileges, index) =>
                            <EachPrivileges key={index}><span >{ePrivileges.name}</span> <span className='tooltip'><BiXCircle className='icon tooltip' onClick={() => deletePriv(ePrivileges)} /><i className='tooltiptext'>Remove Privilege</i></span> </EachPrivileges>)
                    }
                </div>

            </>}
        <Button onClick={() => setSeeDetails(!seeDetails)}>{!seeDetails ? 'see more...' : 'see less...'}</Button>

    </div>)

}

const EachPrivileges = styled.span`
    border:1px solid var(--primary-color);
    color: var(--primary-color);
    padding:3px 10px;
    border-radius: 15px;
    display: inline-block;
    margin:2px;
    cursor:pointer;
    position: relative;
    
    .icon {
        color: red;
    }
    .tooltip {
        position: relative;
        display: inline;
    
        .tooltiptext {
            visibility: hidden;
            width: max-content;
            background-color: #fff;
            color: #000;
            border-radius: 6px;
            font-size: 12px;
            box-shadow: rgb(116 178 182 / 50%) 0px 0px 8px, rgba(70, 70, 70, 0.12) 2px 2px 3px;
            padding: 4px;
          
            /* Position the tooltip */
            position: absolute;
            z-index: 1;
            right: 0%;
            top: -32px;

        }
        :hover .tooltiptext{
          visibility: visible;
        }
        }

      }
`
const Button = styled.button`
    background-color:var(--white-color);
    color:var(--primary-color);
    border:none;
    outline:none;
    cursor: pointer;
    z-index: 0;
    height: 20px;
    padding: 0px;
    width: max-content;
`


/*********************************************************************** */