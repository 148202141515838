import { createContext, useState,useContext } from 'react';
import styles from './css/StaffMgmt.module.css';
import titleStyle from './css/SectionTitle.module.css';
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { BsList } from 'react-icons/bs';
import {AddQuestions,ViewQuestions} from './index'; 
import { MenuContext } from '../DashBoard';
import Menu from '../../../components/Menu';

export const staffContext = createContext();

export const EditContext = createContext(); 

const Questions = () => {
    const {showMenu, setShowMenu} = useContext(MenuContext);
    const [title, setTitle] = useState('');
    const [view, setView] = useState();
    const [editQuestion,setEditQuestion] = useState({});
    const [allQuestions,setAllQuestions] = useState([]);

    const clearEditQuestion = () =>{
        setEditQuestion({});
    }
    return (
        <staffContext.Provider value={{ view, setView }}>
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Questions</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                       
                        <Menu options={[
                            {label:'Add Questions',path:'add-question',onClick:clearEditQuestion},
                            {label:'View Questions',path:'view-questions',onClick:clearEditQuestion}
                        ]} />
                    </div>
                    <div style={{width:'100%'}} onClick={()=>setShowMenu(false)}>
                    <Routes>
                        <Route path='*' element={
                            <EditContext.Provider value={{editQuestion,setEditQuestion,allQuestions,setAllQuestions}}>
                                <AddQuestions setTitle={setTitle} />
                            </EditContext.Provider>
                        } />
                        <Route path='/add-question' element={
                            <EditContext.Provider value={{editQuestion,setEditQuestion,allQuestions,setAllQuestions}}>
                                <AddQuestions setTitle={setTitle} />
                            </EditContext.Provider>
                        } />
                        <Route path='/view-questions' element={
                            <EditContext.Provider value={{editQuestion,setEditQuestion,allQuestions,setAllQuestions}}>
                                <ViewQuestions setTitle={setTitle} />
                            </EditContext.Provider>
                            } />
                        <Route path="/*" element={<PageNotFound />} />
                    </Routes>
                    </div>
                </div>
            </div>
        </staffContext.Provider>
    );
};

export default Questions;