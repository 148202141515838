import styles from '../../../../css/Categories.module.css';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {IoReloadCircleSharp as ReloadIcon} from 'react-icons/io5';
import PageNumber from '../../../../components/PageNumber';
import Select from '../../../../components/Select';
const NO_OF_ITEMS_PER_PAGE = 30;

const EachUserPrivilege = ({ element, index, className }) => {

    return (
        <div className={className}>
            <h4 style={{ color: 'var(--text-color-2)' }}>{element.name.replace(/_/g, ' ')}</h4>
            <p style={{ marginTop: '-30px' }}>{element.description}</p>
        </div>
    )
}

const ViewUserPrivileges = ({ setTitle }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum,setPageNum] = useState(0);
    const all_privileges = useSelector(state => state.questions.user_privileges);
    const services = useSelector(state=>state.account.service);
    const [privileges, setPrivileges] = useState([])
    const [selService, setSelService] = useState({});
    const [reset,setReset] = useState(false);

    useEffect(() => {
        setTitle('View Users Privileges');
    }, [setTitle,privileges]);
    useEffect(()=>{
        if (selService.id) {
            setPrivileges(all_privileges.filter(priv=> priv.service_id === selService.id))
        }else{
            setPrivileges(all_privileges)
        }
    },[selService])

    useEffect(() => {
        if (privileges.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((privileges.length / NO_OF_ITEMS_PER_PAGE) + ((privileges.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0));
        }
    }, [privileges.length]);


    return (
        <div className={styles.form}>
        <label className={styles.label} style={{position: 'relative'}}>
            <p>Filter by Service</p>
            <Select onChange={setSelService} options={services} className={styles.fields} label='name' value='id' reset={reset} />
            <button onClick={()=>setReset(!reset)} title="Reload filter" style={{alignSelf:"flex-end",marginBottom:'5px',cursor:"pointer",fontSize:"25px",color:"var(--secondary-color)",oultline:'none',border:"none",backgroundColor:"transparent", position: 'absolute', top: '20px', right: '0'}}><ReloadIcon/></button>
        </label>
            <div className={styles.viewQuestionContainer}>{privileges.length ?
                privileges.map((element, index) => index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1)* NO_OF_ITEMS_PER_PAGE) &&  <EachUserPrivilege key={index} element={element} index={index} className={styles.eachQuestion} />) : 'No Available Privileges!'}
            </div>
            {privileges.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
        </div>
    )
}

export default ViewUserPrivileges;