import styled from "styled-components";

const PageNumber = ({ setCurrentPage, pageNum, currentPage, }) => {
    return (
        <Container>
            {[...Array(parseInt(pageNum))].map((each, index)=>
                <span key={index} style={index+1 === currentPage ? { backgroundColor: '#fff', color: 'var(--primary-color)' } : { backgroundColor: 'var(--secondary-color)', color: 'var(--white-color)' }} onClick={() =>setCurrentPage(index + 1) }>{index + 1}</span>
            )}
        </Container>
    )
}

export default PageNumber

const Container = styled.div`
    display: flex;
    width:100%;
    overflow-x:auto;
    gap:5px;
    & > span{
     display: flex;
     width: 30px;
     height: 30px;
     border:1px solid var(--primary-color);
     font-size:14px;
     font-weight:normal;
     justify-content: center;
     align-items: center;
     border-radius: 3px;
     font-weight: bold;
     cursor: pointer;
  }
  `