import { useEffect, useRef } from "react";
// import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

export default function VideoPlayer({url, serviceCode}) {
    // const videoRef = useRef();
    const service = useSelector(store => store.account.service.find(serv => serv.code === serviceCode));

    if (service && !url) {
        url = service.url
    }
    useEffect(() => {
        // videoRef.current.scrollIntoView();
        url && window.open(url, "_blank")
    },[url])
    // },[videoRef])

    return <></>
    {/* <div ref={videoRef} style={{display:"flex", width:'100%', backgroundColor: 'var(--white-color)',
    padding: '20px', alignSelf:'center', justifySelf: 'center', alignItems:'center', justifyContent: 'center', borderRadius: '10px',
    boxShadow: '0 0 2px 0.3px rgb(0 0 0 / 8%)'}}>
        <ReactPlayer url={url} playing controls />
    </div> */}
}