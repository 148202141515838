import css from './../../../css/viewStyle.module.css';
import css2 from './../../../css/view-category.module.css';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import { EditContext } from './AttendanceManagement';
import Api from '../../../helperFunctions/Api2';
import { useNavigate } from 'react-router-dom';
import Select from '../../../components/Select';
import { EditComponent } from '../../../components/EditComponent';
import PageNumber from '../../../components/PageNumber';
import { deleteGroupAttendance } from '../../../redux/reducers/questionReducer';
import { FaRegClipboard } from 'react-icons/fa';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
const NO_OF_ITEMS_PER_PAGE = 30


const ViewGroupAttendance = ({ setTitle }) => {

    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const user_institutions = useSelector(state => state.questions.user_institutions);
    const [selInstitution, setSelInstitution] = useState({});
    const [selCategory, setSelCategory] = useState({});
    const [activeUsers, setActiveUsers] = useState({});
    const [activeCat, setActiveCat] = useState([]);
    const [activeAttendance, setActiveAttendance] = useState([]);
    const [menuIndex, setMenuIndex] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setEditing, setEditData } = useContext(EditContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);
    
    const EachAttendance = ({ each, title}) => {
        const [show, setShow] = useState(false);
        const dates = Object.keys(each.value);

        const ModalJsx = ({date}) => {
            const [showM, setShowM] = useState(false);
            
            return (
            <div className={css.supportContent}>
                <div onClick={() => setShowM(!showM)} >
                    <h3 className={css.question}><span className={css.index}>Date:</span> {new Date(date).toDateString()}</h3>
                    <b>{showM ? <BsChevronLeft /> : <BsChevronRight />}</b>
                </div>
                    <h3 className={css.question}><span className={css.index}>Time:</span> {new Date(date).toLocaleTimeString()}</h3>
                    {
                        showM && <table className={css.attendanceTable}>
                            <thead><tr>
                                <th>S/N</th>
                                <th>Name</th>
                                <th>Present</th>
                            </tr></thead>
                            <tbody>{
                                activeUsers.length ? activeUsers.map((userr, index) => {
                                    const user = { ...userr }
                                    // console.log(user)
                                    user.present = user.present ? user.present : false;
                                    return (
                                       <tr key={index} style={{backgroundColor: each.value[date][user.id] ?'var(--secondary-color)29':'#ba1e6f29'}}>
                                            <td>{index + 1}</td>
                                            <td>{user.firstName + ' ' + user.lastName}</td>
                                            <td>{each.value[date][user.id] ?'present':'absent'}</td>
                                        </tr>)
                                }) :
                                    <tr>'No User Available'</tr>
                            }</tbody>
                        </table>
                    }
                {showM && <div className={css.answer}>{each[date]}</div>}
            </div>)
        }
        return <>
            <div>
                <span>Name: </span>
                <span>{title}</span>
            </div>
        {show && (dates.length > 0 ? dates.map((date, index) => <ModalJsx date={date} key={index} />) : 'No attendance taken yet')}
            <span style={{ color: 'var(--primary-color)', cursor: 'pointer', width: 'max-content' }} onClick={() => setShow(!show)}>{!show ? 'see' : 'hide'} attendance taken...</span>
        </>
    }

    const deleteAttendance = (attendance) => {
        if (window.confirm('Sure to Delete?')) {
            setShowLoading(true)
            console.log(attendance)
            Api.PostApi('/institution/delete-attendance-record', { id: attendance.id })
                .then(result => {
                    if (result.isSuccessful) {
                        dispatch(deleteGroupAttendance(attendance))
                        setModalText('Deleted Attendance Succesfully');
                    } else setModalText('Action Failed')
                })
                .catch(err => {
                    setModalText(err.message);
                }).finally(() => {
                    setShowLoading(false);
                    setModalToggle(true);
                })
        }
    }
    const editAttendance = (attendance) => {
        const data = { id: attendance.id, title: attendance.title, institution_id: selInstitution.id, institution_category: selCategory.id }

        setEditData({ ...data, type: 'groupAttendance' });
        setEditing(true);
        navigate('../create-attendance');
    }

    const More = (attendance) => {
        const takeAttendance = () => {
            const data = { id: attendance.id + '~' + attendance.title, institution_id: selInstitution.id, institution_category: selCategory.id }
            // console.log(data);
            setEditData({ ...data, type: 'takeAttendance' });
            setEditing(true);
            navigate('../take-attendance');
        }
        return (
            <div onClick={takeAttendance}><FaRegClipboard />Take Attendance </div>
        )
    }

    useEffect(() => {
        setTitle('View Attendace');
    }, [setTitle]);



    useEffect(() => {
        if (selInstitution.id) {
            setActiveCat(Object.values(selInstitution.categories))
            setSelCategory({});
        }
    }, [selInstitution.categories, selInstitution.id])

    useEffect(() => {
        if (selInstitution.id && selCategory.institution_id === selInstitution.id) {
            setActiveAttendance(Object.keys(selCategory.attendance).map(key => ({ value: selCategory.attendance[key], title: key.split('~')[1], id: key.split('~')[0] })));
        } else {
            setActiveAttendance([]);
            setSelCategory({})
        }
    }, [selCategory.attendance, selCategory.institution_id, selInstitution.id])
    // console.log(activeAttendance);

    useEffect(() => {
        if (selCategory.id) {
            const arr = Object.keys(selCategory.attendance).sort((a, b)=> Number(b.split('~')[0]) - Number(a.split('~')[0]));
            setActiveAttendance(arr.length > 0 ? arr.map((id, ind) => { return { id, value: selCategory.attendance[id] } }) : []);
            setActiveUsers(Object.values(selInstitution.users).filter(eachUser => eachUser.categories && eachUser.categories.includes(selCategory.id)));
        }
        else {
            setActiveAttendance([]);
            setActiveUsers([]);
        }
    }, [selCategory, selInstitution,])

    useEffect(() => {
        if (activeAttendance.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((activeAttendance.length / NO_OF_ITEMS_PER_PAGE) + ((activeAttendance.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [activeAttendance.length]);

    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <div className={css.container}>
                <label style={{ width: "100%" }}>
                    <span>Select Institution</span>
                    <Select onChange={setSelInstitution} options={user_institutions} defaultValue="" value="id" label="name" />
                </label>
                <label style={{ width: "100%" }}>
                    <span>Select Group</span>
                    <Select onChange={setSelCategory} options={activeCat} value="id" label="name" />
                </label>
                <div className={css2.container}>
                    {
                        activeAttendance && selInstitution.categories && activeAttendance.length > 0 ? activeAttendance.map((each, index) =>
                            index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                            <div key={index}>
                                <EditComponent menuIndex={menuIndex} clickEdit={() => editAttendance(each)} clickDelete={() => deleteAttendance(each)} index={index} setMenuIndex={setMenuIndex} More={() => More(each)} />
                                <EachAttendance each={each} title={each.id.split('~')[1]} index={index} setModalText={setModalText} setModalToggle={setModalToggle} modalToggle={modalToggle} />
                            </div>) : selCategory.id ? 'No Attendance in this Institution category' : selInstitution.id ? 'Please Select a Category' : 'Please select an institution'

                    }
                </div>
                {activeAttendance.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
            </div>
        </>
    );
};

export default ViewGroupAttendance;


/*********************************************************************** */