import styles from './../css/CustomerAgreement.module.css';
import Nav from './Nav';


const CustomerAgreement = () => {
    return (
        <>
            <div className={`${styles.myDiv1}`}>
                <div className={`${styles.myDiv12}`}>
                    <Nav />
                    <h1 className={`${styles.heading26}`}>Terms of Use</h1>
                </div>
            </div>
            <div className={`${styles.myDiv2}`}>
                <div className={`${styles.myDiv21}`}>
                    <div className={`${styles.myDiv22}`}>
                        <h4 className={`${styles.heading27}`}> The use of any product, service or feature (“the materials”) available
                            through the internet websites accessible at www.sight-eduplus.com (the website) by any, shall be
                            governed by the following terms of use: <br /><br /> You agree to make no such promotions or upload
                            that involve unlicensed use of materials protected by copyright or trademark law. <br /><br /> You
                            agree that all such promotions will be fully compliant with federal and state laws, including
                            federal and state laws regarding network abuse, unsolicited messaging and other rules that
                            pertain to advertising and disclosure. <br /><br /> You agree that you will not engage in activities
                            that interfere with our information tracking or flow of information.<br /><br /> You agree to only
                            promote your items within the guidelines of the payment integration.<br /><br /> You agree not to
                            promote any banned, outlawed, or unfit product with our site or its logo.<br /><br /> You agree not
                            to include any nude, indecent, pornographic content in your advertisement.<br /><br /> You agree not
                            to SPAM any URL that have our links or to sites with our buttons even using our redirect link.
                            You agree never to email to anyone in a broadcast unless they are double option and IP, name,
                            date, and time have been recorded and can be produced. You understand that if you disregard
                            this, we may terminate your account without warning. We will do our best to work with you but if
                            our host or backbone server asks us to remove any member for SPAM, it will be done if we agree
                            with the complaint.<br /><br /> Emailing your staff, students and parent about matters that are not
                            directly or inversely connected with this software service is forbidden.<br /><br /> You agree not
                            to deceive or mislead a prospective candidate as to the facility (tangible or otherwise)
                            available at your institution, or use puffs or any other misleading graphics or statement of
                            fact. Such will amount to fraud.<br /><br /> You warrant that every information supplied shall be
                            true, correct, and not misleading in any way since doing otherwise may attract sanction.<br /><br />
                            Every institution subscribing to this software service is required to:<br /><br /> Comply with the
                            technical requirements for subscribing to this service, mainly; internet connectivity, provision
                            of hardware, and sheets to transcribe texts to digital format.<br /><br /> Undertake the training of
                            designated staff member(s) in the use of the software.<br /><br /> Upload the required computed data
                            upon subscription.<br /><br /> Allow a financial inclusion of the software service for the
                            subscriber.<br /><br /> Maintain a prompt and high sense of fiduciary duties among parties.<br /><br />‍
                            <strong className={`${styles.strong} ${styles.boldText6}`}>COPYRIGHT NOTICES<br />‍</strong> The entire content of this app
                            are protected by national and international copyright and trademark laws. The ownership of
                            copyrights and trademarks in this software belongs to Sight-EduPlus.com, her affiliates or licensor(s)
                            (with the exception of open source graphics). You may not modify, copy, reproduce, republish,
                            upload, post, transmit, or distribute, in a manner, the material on the app including texts,
                            graphics, code, or imitate the software. You may print and download any material solely for the
                            purpose for which the app was created (which has been earlier spelt out), but not for any other
                            extraneous use.<br /><br />‍ <strong className={`${styles.strong} ${styles.boldText6}`}>TRADEMARK<br />‍</strong> Publications,
                            products, logo, content, or services herein referenced or on the site are the exclusive
                            trademarks or service marks of Sight-EduPlus.com. Other logos associated with particular institutions’
                            names mentioned in the site may be the trademarks of their respective owners, with the exception
                            of those that are open source.<br /><br /> ‍<strong className={`${styles.strong} ${styles.boldText6}`}>LIMITATION OF
                                LIABILITY<br />‍</strong> IN NO EVENT will Sight-EduPlus.com be liable for<br />‍<br />I. Any
                            incidental, consequential, or indirect damages for loss of profit, business interruption, loss
                            of programs or information and the like arising out of the use of or inability to use the
                            service, or any information or transactions provided on this platform, or any delay of such
                            information or service. Even if Sight-EduPlus.com or its agents or channel partners have been notified
                            or advised as to the possibility of such damages.<br /> II. Any claim attributable to errors,
                            omissions, or other inaccuracies in the service and/or material or information obtained through
                            the service. It’s a garbage in and out system.<br /> III. Any claim as to loss or damages for data
                            loss or any other technical failure arising from circumstances reasonably unforeseeable or
                            beyond human control that can create a force majeure or such other intervening factor.<br /> IV.
                            Any claim as to loss and damages resulting from any technical failure in the payment integration
                            which is a direct result of malfunction from the institution’s financial institution of choice
                            or the e-payment platform or any of such arising from a technical failure that is reasonably
                            unforeseeable or beyond immediate human control Sight-EduPlus.com makes no representation about any
                            other website which you may access through this one or which may link to this site.<br /><br />
                            ‍<strong className={`${styles.strong} ${styles.boldText6}`}>INDEMNIFICATION<br />‍</strong> It is mutually agreed that
                            parties to this service shall indemnify the other from and against all losses, expenses, damages
                            and costs including reasonable attorneys’ fee, resulting from any violation of this agreement
                            (including negligent and wrongful conduct) by any person accessing the service.<br /><br />‍ <strong
                                className={`${styles.strong} ${styles.boldText6}`}>THIRD PARTY RIGHTS<br />‍</strong> The provisions relating to terms
                            of use and indemnification are for the benefit of Sight-EduPlus.com and its officers, directors,
                            employees, agents, licensors, channel partners, and any third party information provider to the
                            service. Each of these individuals or entities shall have the right to assert and enforce those
                            provisions directly against you on its own behalf.<br /><br /> ‍<strong
                                className={`${styles.strong} ${styles.boldText6}`}>TERM &amp; TERMINATION<br />‍</strong> This Agreement may be
                            terminated by either party without notice at any time for any reason. The provisions relating to
                            copyright, licenses, indemnification, third party rights, and miscellaneous shall survive any
                            termination of this agreement.<br /><br /> ‍<strong
                                className={`${styles.strong} ${styles.boldText6}`}>DISCLAIMER<br />‍</strong> Take notice that we at Sight-EduPlus.com do not
                            endorse or guarantee any vendor or the standard or quality of any product displayed on this
                            platform. Buyers are, however advised to verify all necessary details and information through
                            the medium provided for doing so on this platform. </h4>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerAgreement;