import { createSlice } from "@reduxjs/toolkit";

/*let lastId = 0;
const initialValue = [
    

]*/

export const questionSlice = createSlice({
    name: "question",
    initialState: { value: [], user_categories: [], categories: [], public_categories: [], images: [], user_institutions: [], user_privileges: [], user_message: [], user_learning: [], date: null, questions: {}, timetables: [] },
    reducers: {
        addCategory: (state, action) => {
            state.categories = [
                action.payload,
                ...state.categories,
            ];
        },
        setCategory: (state, action) => {
            state.categories = action.payload //.filter(c=>c.isOwned);
        },
        setPublicCategory: (state, action) => {
            state.public_categories = action.payload;
        },
        editCategory: (state, action) => {
            return {
                ...state, categories: state.categories.map(eCate => eCate.id == action.payload.id ? { ...action.payload } : eCate)
            }
        },
        deleteCategory: (state, action) => {
            return {
                ...state, categories: state.categories.filter(eCate => eCate.id != action.payload.id)
            }
        },
        addImage: (state, action) => {
            state.images.unshift(action.payload)
        },
        setImages: (state, action) => {
            state.images = action.payload;
        },
        removeImage: (state, action)=>{
            state.images = state.images.filter(x=>x.id != action.payload.id);
        },
        addQuestion: (state, action) => {
            state.value = [action.payload, ...state.value];
        },
        setQuestions: (state, action) => {
            state.value = action.payload;
        },
        addUserCategory: (state, action) => {
            state.user_institutions.forEach((inst) => {
                if (inst.id === action.payload.institution_id) {
                    inst.categories[action.payload.id] = {...action.payload, attendance:{}, users:[], privileges:[]}
                }
                return(inst)
            });
        },
        deleteUserCategory: (state, action) => {
            state.user_institutions = state.user_institutions.map(institution => {
                if (institution.id === action.payload.institution_id) {
                    let i = {...institution}
                    delete i.categories[action.payload.id]
                    return i
                }else
                    return institution;
            })
        },
        editUserCategory: (state, action) => {
            const id = action.payload.id;
            state.user_institutions = state.user_institutions.map(institution =>
                institution.id === action.payload.institution_id ?
                    institution = {
                        ...institution,
                        categories: {
                            ...institution['categories'],
                            [`${id}`]: action.payload
                        }
                    } : institution

            )
            //console.log(state.user_institutions)
        },
        setUserCategory: (state, action) => {
            state.user_categories = action.payload;
        },
        deleteQuestion: (state, action) => {
            state.value = state.value.filter(question => (question.id !== action.payload.id));
        },
        editSelectedQuestion: (state, action) => {
            state.value = state.value.map(question => (Number(question.id) === Number(action.payload.id) ? { ...question, ...action.payload } : question));

        },


        setUserInstitution: (state, action) => {
            state.user_institutions = action.payload;
        },
        addUserInstitution: (state, action) => {
            state.user_institutions = [ action.payload, ...state.user_institutions,]
        },
        deleteUserInstitution: (state, action) => {
            let result = state.user_institutions.filter((inst) => (Number(inst.id) !== Number(action.payload.id)));
            state.user_institutions = result;
        },
        editUserInstitution: (state, action) => {
            state.user_institutions = state.user_institutions.map(inst => inst.id === action.payload.id ? action.payload : inst);
        },
        addGroupAttendance: (state, action) => {
            // console.log(action.payload);
            state.user_institutions.forEach(institution => {
                if (institution.institution_id === action.payload.institution_id || institution.id === action.payload.institution_id) {
                    institution.categories[action.payload.institution_category].attendance[action.payload.id+'~'+action.payload.title] = {};
                }
            })
        },
        editGroupAttendance: (state, action) => {
            // state.user_institutions.forEach(inst=>{
            //     if(inst.institution_id===action.payload.institution_id || inst.id===action.payload.institution_id ){
            //         inst.groupAttendance = inst.groupAttendance.map(e => e.id === action.payload.id?action.payload:e);
            //     }
            // })
        },
        deleteGroupAttendance: (state, action) => {
            // state.user_institutions.forEach(inst=>{
            //     if(inst.institution_id===action.payload.institution_id || inst.id===action.payload.institution_id){
            //         inst.groupAttendance = inst.groupAttendance.filter(e => e.id !== action.payload.id);
            //     }
            // })
        },
        addUser: (state, action) => {
            state.user_institutions.forEach(institution => {
                if (institution.institution_id === action.payload.institution_id || institution.id === action.payload.institution_id) {
                    institution.users[action.payload.id] = action.payload;
                }
            })
        },
        addMultipleUsers: (state, action) => {
            state.user_institutions.forEach(institution => {
                if (institution.institution_id === action.payload.institution_id || institution.id === action.payload.institution_id) {
                    action.payload.users.forEach(user => {
                        user.categories = action.payload.categories;
                        institution.users[user.id] = user;
                    })
                }
            })
        },
        deleteUser: (state, action) => {
            state.user_institutions.forEach(inst => {
                if (inst.institution_id === action.payload.institution_id || inst.id === action.payload.institution_id) {
                    delete inst.users[action.payload.id];
                }
            })
        },
        editUser: (state, action) => {
            state.user_institutions.forEach(inst => {
                if (inst.institution_id === action.payload.institution_id || inst.id === action.payload.institution_id) {
                    inst.users[action.payload.id] = action.payload;
                    // inst.users = {
                    //     ...inst.users,
                    //     [`${action.payload.id}`]: action.payload
                    // }
                    inst.users[action.payload.id].categories.forEach(category=>{
                        if(!inst.categories[category].users.includes(action.payload.id))
                            inst.categories[category].users.push(action.payload.id);
                    })
                }
            })
            
        },
        addUserToGroup: (state, action) => {
            state.user_institutions.forEach(inst => {
                if (inst.institution_id === action.payload.institution_id || inst.id === action.payload.institution_id) {
                    if(!inst.users[action.payload.subuser_id].categories.includes(action.payload.group_id))
                        inst.users[action.payload.subuser_id].categories.push(action.payload.group_id);

                    inst.users[action.payload.subuser_id].categories.forEach(category=>{
                        if(!inst.categories[category].users.includes(action.payload.subuser_id))
                            inst.categories[category].users.push(action.payload.subuser_id);
                    })
                }
            })
            
        },
        deleteUserGroup: (state, action)=>{
            state.user_institutions.forEach(inst => {
                if (inst.institution_id === action.payload.institution_id || inst.id === action.payload.institution_id) {
                    inst.users[action.payload.subuser_id].categories = inst.users[action.payload.subuser_id].categories.filter(category => category != action.payload.group_id)
                    inst.categories[action.payload.group_id].users = inst.categories[action.payload.group_id].users.filter(user => user != action.payload.subuser_id)
                }
            })
        },
        disableUser: (state, action)=>{
            state.user_institutions.forEach(inst => {
                if (inst.institution_id === action.payload.institution_id || inst.id === action.payload.institution_id) {
                    inst.users[action.payload.id].isEnabled = action.payload.isEnabled;
                }
            })
        },
        addUserPrivileges: (state, action) => {
            state.user_institutions.forEach(inst => {
                if (inst.id === action.payload.instId) {
                    inst.categories[action.payload.category_id].privileges = action.payload.privileges;
                }
            })
        },
        deleteUserPrivileges: (state, action) => {
            state.user_institutions.forEach(inst => {
                if (inst.id === action.payload.institution_id) {
                    inst.categories[action.payload.group_id].privileges = inst.categories[action.payload.group_id].privileges.filter(priv => priv.id !== action.payload.privilege_id);
                }
            })
        },
        setUserPrivileges: (state, action) => {
            state.user_privileges = action.payload
        },
        setUserLearning: (state, action) => {
            state.user_learning = action.payload
        },
        addClass: (state, action) => {
            state.user_learning = [
                action.payload,
                ...state.user_learning];
            // console.log(action.payload, 'payload');
        },
        editClass: (state, action) => {
            state.user_learning = state.user_learning.map(eachClass => eachClass.id == action.payload.id ? action.payload : eachClass)
        },
        deleteClass: (state, action) => {
            state.user_learning = state.user_learning.filter(eachClass => eachClass.id != action.payload.id)
        },
        setInstitutionTemplate: (state, action) => {
            state.user_institutions = state.user_institutions.map(inst => {
                if (inst.id === action.payload.institution.id) {
                    inst.template = action.payload.template;
                }
                return inst
            });
        },
        saveQuestion: (state, action) => {
            state.questions[action.payload.id] = action.payload.data
        },
        addTimetable: (state, action) => {
            state.user_institutions.forEach(institution => {
                if (institution.id === action.payload.institution_id) {
                    institution.timetables = institution.timetables ?
                        [action.payload, ...institution.timetables]: [action.payload];
                }
            })
        },
        deleteTimetable: (state, action) => {
            state.user_institutions.forEach(institution => {
                if (institution.id === action.payload.institution_id) {
                    institution.timetables = institution.timetables.filter(
                        timetable => timetable.id !== action.payload.id);
                }
            });
        },
        updateTimetable: (state, action) => {
            state.user_institutions.forEach(institution => {
                if (action.payload.oldInstitutionId && institution.id === action.payload.oldInstitutionId) {
                    institution.timetables = institution.timetables.filter(
                        timetable => timetable.id !== action.payload.timetable.id);
                }
                else if (action.payload.oldInstitutionId && institution.id === action.payload.timetable.institution_id) {
                    institution.timetables =  institution.timetables ?
                        [action.payload.timetable, ...institution.timetables]: [action.payload.timetable];
                }
                else if (institution.id === action.payload.timetable.institution_id) {
                    institution.timetables = institution.timetables.map(
                        timetable => timetable.id === action.payload.timetable.id ? action.payload.timetable : timetable);
                }
            });
        },
        addInvites: (state, action) => {
            console.log(action.payload);

        },
        deleteInvites: (state, action) => {
            state.user_institutions.forEach(institution => {
                if (institution.id === action.payload.inst_id) {
                    institution.invitations = institution.invitations.filter(
                        invite => invite.id !== action.payload.id);
                }
            });
        },

    }
})

export const {
    addCategory, setCategory, setPublicCategory, editCategory, deleteCategory,
    addImage, setImages, addQuestion, deleteQuestion, editSelectedQuestion, setQuestions,
    addUserCategory, deleteUserCategory, editUserCategory, setUserCategory,
    addGroupAttendance, editGroupAttendance, deleteGroupAttendance,
    addUserInstitution, deleteUserInstitution, editUserInstitution, setUserInstitution,
    addUser, addMultipleUsers, deleteUser, editUser,deleteUserGroup, addUserToGroup, disableUser,
    addUserPrivileges, deleteUserPrivileges, setUserPrivileges, setUserLearning,
    addClass, setInstitutionTemplate, removeImage,
    saveQuestion,
    addTimetable, deleteTimetable, updateTimetable,
    addInvites, deleteInvites
} = questionSlice.actions;

export default questionSlice.reducer;