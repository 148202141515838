import { Link } from 'react-router-dom';
import css from './css/dashboard.module.css'
import {FaBuilding, FaUsers, FaUsersCog} from "react-icons/fa";
import {MdCategory} from "react-icons/md";
import {useSelector } from 'react-redux';
import VideoPlayer from '../../../components/VideoPlayer';
import { useState } from 'react';

const Dashboard = () =>{
  const institution = useSelector((state)=>state.questions.user_institutions);
  const [showVideo, setShowVideo] = useState(false);

  const getCatSum = (institution)=>{
    let catSum = 0;
    institution.forEach(value=>{
      catSum += Object.keys(value.categories).length;
    })
    return catSum;
  }

  const getUsersSum = (institution)=>{
    let usersSum = 0;
    institution.forEach(value=>{
      usersSum += Object.keys(value.users).length;
    })
    return usersSum;
  }
    return (
        <div className={css.container}>
          <div>INSTITUTION DASHBOARD</div>
          <div>
            <div className={`${css.graphContainer} ${css.content}`}>
              <div className={css.infodiv}>
                  <div className={`${css.iconBac} ${css.iconBlue}`}>
                    <FaBuilding />
                  </div>
                  <div>
                    Numbers of Institutions: <span className={css.value}>{institution.length}</span>
                  </div>
              </div>
              <div className={css.infodiv}>
                  <div className={`${css.iconBac} ${css.iconGreen}`}>
                    <span><MdCategory /></span>
                  </div>
                  <div>
                    Numbers of Groups: <span className={css.value}>{getCatSum(institution)}</span>
                  </div>
              </div>
              <div className={css.infodiv}>
                  <div className={`${css.iconBac} ${css.iconPink}`}>
                    <span><FaUsers /></span>
                  </div>
                  <div>
                    Numbers of Users: <span className={css.value}>{getUsersSum(institution)}</span>
                  </div>
              </div>
              {/*<div className={css.infodiv}>
                  <div className={`${css.iconBac} ${css.iconYellow}`}>
                    <span><FaUsersCog /></span>
                  </div>
                  <div>
                    Numbers of Admin Users: <span className={css.value}>1000</span>
                  </div>
              </div>*/}
            </div>
            <div className={`${css.infoContainer} ${css.content}`}>
              <div className={css.head}>EDUPLUS INSTITUTION</div>
              <p className={css.body}>
                Eduplus Institution mgmt. enables you to manage everything as regards your institution in one place in an efficient way.
              </p>
              <div className={css.foot}>
                <Link to="../institution">Get Started</Link>
                <Link to="" onClick={()=>setShowVideo(!showVideo)}>Watch Video</Link>           
              </div>
            </div>
          </div>      
          {showVideo && <VideoPlayer serviceCode={'IUM'}/>}
        </div>
      )
}

export default Dashboard