import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./reducers/accountReducer";
import questionReducer from "./reducers/questionReducer";
import ChatReducer from "./reducers/ChatReducer"
import resultReducer from './reducers/resultReducer';
import portalReducer from "./reducers/portalReducer";
import billingReducer from "./reducers/billingReducer";
import learningReducer from "./reducers/learningReducer";
import cbtReducer from "./reducers/cbtReducer";
import testReducer from "./reducers/testReducer";
import eventReducer from "./reducers/eventReducer";
import serviceReducer from "./reducers/serviceReducer";

export default configureStore({
    reducer: {
        account: accountReducer,
        questions: questionReducer,
        results: resultReducer,
        portal: portalReducer,
        chat: ChatReducer,
        billing: billingReducer,
        learning:learningReducer,
        cbt:cbtReducer,
        test:testReducer,
        event: eventReducer,
        services: serviceReducer, 
    }
})