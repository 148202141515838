import css from "../../../css/ServiceDashboard.module.css";
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import BarGraph from "../../../components/Graph/BarGraph";
import DualBarGraph from "../../../components/Graph/DualBarGraph";
import { useSelector } from 'react-redux';
import {dateObject} from '../../../helperFunctions/Api2';
import VideoPlayer from "../../../components/VideoPlayer";

const PaymentDashboard = () =>{
  const [data,setData] = useState(null);
  const payments = useSelector(store => store.account.paymentLinks);
  const {datetime} = useSelector(store => store.account.username);
  const [totalAmount, setTotalAmount] = useState(0);
  const [allTransactions, setAllTransactions] = useState([]);
  const [allBills, setAllBills] = useState([]);
  const totalBills = useSelector(store=>store.account.total_bills);
  const transactions = useSelector(store=>store.account.transactions)
  const [showVideo, setShowVideo] = useState(false);

  const arrageData = (year) =>{
     const allPayments = [0,0,0,0,0,0,0,0,0,0,0,0];
     let amount = 0; const allPaymentLinks = [];

     for(let i = 0; i < payments.length; i++){
      allPaymentLinks.push(payments[i].payments);
     }
     
     for(let i = 0; i < allPaymentLinks.length; i++){
        amount += allPaymentLinks[i].total_amount;
        const d = new Date(allPaymentLinks[i].created_at);  
        const vYear = d.getFullYear();    
        if(vYear == year){
          allPayments[d.getMonth()]++;
        }
     } 

     setTotalAmount(amount);
     setData(allPayments);

     let paidTransaction = [0,0,0,0,0,0,0,0,0,0,0,0];
     let bills = [0,0,0,0,0,0,0,0,0,0,0,0];

     transactions.forEach(transaction=>{
        const d = new Date(transaction.created_at);  
        const vYear = d.getFullYear();      
        if(vYear == year){
          paidTransaction[d.getMonth()] += transaction.amount;
        }
     })

     totalBills.forEach(bill=>{
        const d = new Date(bill.created_at);   
        const vYear = d.getFullYear();     
        if(vYear == year){
          bills[d.getMonth()] += bill.total_amount;
        }
     })

     setAllTransactions(paidTransaction);
     setAllBills(bills);
  }
  useEffect(()=>{
      let d = new Date(datetime);
      let year = d.getFullYear();
      arrageData(year)
    
  },[payments, totalBills,transactions])

    return (
        <div className={css.container}>
          <div>BILLS & PAYMENTS</div>
          <div>
              <div>
                  <div>
                    <DualBarGraph data={allBills} data2={allTransactions} sideText={"Total Bill | Payments"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText="Total Bills" labelText2="Total Bills Payment"/>
                    <BarGraph data={data} sideText={"No. of Payments"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText={`Payment History`}/>
                    <br/>
                    <div>
                      Numbers of Payment Links: <span className={css.purpleText}>{Object.keys(payments).length}</span><br/>
                      Total amount received: <span className={css.purpleText}>{totalAmount} USD</span><br/>
                      Total bill payment made: <span className={css.purpleText}>{transactions.length}</span><br/>
                      Total bills: <span className={css.purpleText}>{totalBills.length}</span>
                    </div>
                  </div>
                  <div>
                    <div>BILL & PAYMENT SERVICE</div>
                    <p>
                      Eduplus bills & payment service enables users to be manage eduplus bills payments, create and manage payment links.
                    </p>
                    <div>
                       <Link to="../payments" style={{backgroundColor:"var(--secondary-color)",color:"var(--white-color)",border:"1px solid var(--secondary-color)"}}>
                        Get Started
                       </Link>
                       <button onClick={()=>setShowVideo(!showVideo)} style={{border:"1px solid var(--secondary-color)",border:"1px solid var(--secondary-color)",backgroundColor:"var(--white-color)"}}>
                       Watch Video
                       </button>
                    </div>
                  </div>
              </div>
              {showVideo && <VideoPlayer serviceCode={'PMS'}/>}
          </div>
        </div>
      )
}

export default PaymentDashboard;