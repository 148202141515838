import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from '../../../helperFunctions/Api2';
import Modal from "../../../components/modal";
import styles from './../../../css/CreateInst.module.css';
import Loading from "../../../components/loading";
import Select from "../../../components/Select";
import { EditContext } from './AttendanceManagement';
import { useNavigate } from "react-router-dom";
import { addGroupAttendance, editGroupAttendance } from "../../../redux/reducers/questionReducer";

export default function CreateGroupAttendance({ setTitle }) {
    const institutions = useSelector(state => state.questions.user_institutions);
    const [currentUserCategoryArr, setCurrentUserCatergoryArr] = useState([]);
    const [modalToggle, setModalToggle] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [modalText, setModalText] = useState("");
    const [selInst, setSelInst] = useState({});
    const [selCategory, setSelCategory] = useState({});
    const [defaultInst, setDefaultInst] = useState('');
    const [defaultCategory, setDefaultCategory] = useState('');
    const [title, setName] = useState('');
    const { editing, editData, clearEdit, setEditing } = useContext(EditContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const clearFields=()=>{
        setCurrentUserCatergoryArr([]);
        setSelInst({});
        setDefaultInst('');
        setSelCategory({});
        setDefaultCategory('');
        setName('');
        clearEdit();
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const attendance = {
            institution_id: selInst.id,
            institution_category: selCategory.id,
            title
        };

        if (!attendance.institution_id || !attendance.institution_category ||
            attendance.title === '' ) {
            setModalText('Please completely fill all fields');
            setModalToggle(true);
            return false;
        }

        if (editing) {
            let editedObject = {
                institution_id: editData.institution_id,
                id: editData.id,
                institution_category: selCategory.id,
                title
            }
            setShowLoading(true);
            Api.PostApi('/institution/edit-attendance', editedObject, true)
                .then(result => {
                    setShowLoading(false);
                    setModalText(result.message);
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        dispatch(editGroupAttendance(result.data));
                        clearFields();
                    }
                })
                .catch(error => {
                    setShowLoading(false);
                    setModalText('Action failed, please check your internet connection, or relogin');
                    setModalToggle(true); clearFields();
                })

        }
        else {
            setShowLoading(true);
            Api.PostApi('/institution/create-attendance', attendance, true)
                .then(result => {
                    setShowLoading(false); 
                    setModalText(result.message);
                    setModalToggle(true);
                    if (result.isSuccessful) {                
                        dispatch(addGroupAttendance(result.data));
                        clearFields();
                    }

                })
                .catch(error => {
                    // alert('Action failed, please re-login');
                    setShowLoading(false);console.log(attendance, error);
                    setModalText(`Action Failed. Please login again!`);
                    setModalToggle(true);
                });

            return false;
        };
    };
    const handleCancel = () => {
        clearFields();
        navigate('../view-attendance');
    }


    useEffect(() => {
        setTitle('Create Attendance');
    }, [setTitle]);

    useEffect(() => {
        if (editing && editData.id) {
            setName(editData.title);
            let a = institutions.find(inst => inst.id === editData.institution_id);
            setSelInst(a);
            setDefaultInst(a);

        } else {
            setName('');
            setSelInst({});
            setDefaultInst('');
        }
    }, [editData, editing, institutions])

    useEffect(() => {
        if (editing && selInst.categories ) {
            let b = selInst.categories[editData.institution_category];
            setSelCategory(b);
            setDefaultCategory(b);
        } else {
            setSelCategory({});
            setDefaultCategory('');
        }
    }, [editData, editing, selInst])

    useEffect(() => {
        if (selInst) {
            const cat = [];
            for (let x in selInst.categories) cat.push(selInst.categories[x]);
            setCurrentUserCatergoryArr(Object.values(cat))
        }
    }, [selInst])

    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <form className={`${styles.form}`} >
                <label className={styles.label}>
                    <span className={styles.isRequired}>User institution</span>
                    <Select name='institution' options={institutions} onChange={setSelInst} defaultValue={defaultInst} className={styles.fields} label='name' value='id' disabled={editing}/>
                </label>
                {selInst && <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>User Group</span>
                    <Select name='Category' options={currentUserCategoryArr} onChange={setSelCategory} defaultValue={defaultCategory} className={styles.selectFields} label='name' value='id' />
                </label>}
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Attendance Title</span>
                    <input placeholder='' className={`${styles.fields}`} type='text' name='lastName' value={title} onChange={(e) => setName(e.target.value)} />
                </label>
                <div className={styles.addBtnDiv}>
                    {editing && <button className={`cancelEditButton`} onClick={()=>handleCancel()}>Cancel Edit</button>}
                    <button className={`${styles.addBtn}`} onClick={(e)=>handleSubmit(e)}>Save {editing?'Edited ':''}Attendance</button>
                </div>
            </form>
        </>
    )

}