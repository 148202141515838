import React, { useEffect } from 'react'
import Single from './Single';
import Multi from './Multi'

const Select = ({options,onChange=()=>{},defaultValue,className,multi,value='value',label='label' ,disabled,reset,placeholder}) => {
   
  return (
    <>
    {!multi ?
        <Single options={options} onChange={onChange} defaultValue={defaultValue} className={className} value={value} label={label}  disabled={disabled} reset={reset} placeholder={placeholder}/>:
        <Multi options={options} onChange={onChange} defaultValue={defaultValue} className={className} multi={multi} value={value} label={label} reset={reset} placeholder={placeholder} disabled={disabled}/>}    
    </>
  )
}


export default Select;