import React, { useContext, useEffect, useState } from "react";
import { LearningContext } from './AssignmentIndex';
import Loading from "../../../components/loading";
import { useDispatch, useSelector } from "react-redux";
import styles from '../../../css/CreateInst.module.css';
import Select from '../../../components/Select';
import { useRef } from "react";
import { addAssignment, editeachAss } from "../../../redux/reducers/learningReducer";
import { useNavigate } from "react-router-dom";
import Api from "../../../helperFunctions/Api2";
import Timezone, { timeList } from "../../../components/Timezone";
import TextEditor from "../../../components/TextEditor";

const CreateAssignment = ({ setTitle }) => {
    const [assval, setAssval] = useState('');
    const [score, setScore] = useState(100);
    const [showLoading, setShowLoading] = useState(false);
    const [instOption, selInstitution] = useState('');
    const [instCategory, setInstCategory] = useState([]);
    const [category, selCategory] = useState([]);
    const [defcategory, setdefCategory] = useState('');
    const { modalObject, setModalObject, modalToggle, setModalToggle, editAssignment, setEditAssignment, editAssObj, setEditAssObj, clearEdit } = useContext(LearningContext);
    const [content, setContent] = useState('start writing');
    const [subDate, setSubDate] = useState('');
    const [subTime, setSubTime] = useState('');
    const [timezone, setTimezone] = useState(null);
    const editor = useRef('editor');
    const dispatchFunc = useDispatch();
    const Navigate = useNavigate();
    const Institution = useSelector(state => state.questions.user_institutions);

    const validateInput = (value, body) => {
        if (value === null || value === '' || value === 'undefined') {
            setModalObject({ header: '', body: `Please set ${body}`, footer: '' });
            setModalToggle(true);
            return false;
        }
        return true;
    };
    const validateArrayselection = (value, body) => {
        if (value.length <= 0) {
            setModalObject({ header: '', body: `Please select/insert ${body}`, footer: '' });
            setModalToggle(true);
            return false;
        }
        return true;

    };
    const style = {
        readonly: false,
        height: 400,
        width: 680
    };

    const clearInputs = () => {
        setAssval('');
        setScore(100);
        setContent('start writing');
        setTimezone(0);
        setSubDate('');
        setSubTime('');
        selInstitution([]);
        selCategory([]);
        setInstCategory([]);
        setdefCategory('');
        if (editAssignment) {
            clearEdit();
        }
    };

    const AssignmentCreation = (event) => {
        event.preventDefault();
        if (!validateInput(assval, 'Assignment Title')) {
            return false;
        };
        if (!validateInput(subDate, 'Submission Date')) {
            return false;
        };
        if (!validateInput(subTime, 'Submission Time')) {
            return false;
        }
        if (!validateArrayselection(instOption, 'Institution')) {
            return false;
        };
        if (!validateArrayselection(category, 'institution group')) {
            return false;
        }
        if (editAssignment) {
            let assignment = {};
            assignment.id = editAssObj.id;
            assignment.title = assval;
            assignment.score = score;
            assignment.institution_id = instOption.id;
            assignment.institution_category = category;
            assignment.assignment = content;
            assignment.timezone = Number(timezone.value);
            assignment.end_date = `${subDate}T${subTime}Z`;
            // assignment.submission_date$time = `${subDate} ${subTime}`;
            // const submission = assignment.submission_date$time.split(` `);
            // assignment.submission_date = submission[0];
            // assignment.submission_time = submission[1];

            setShowLoading(true);

            Api.PostApi('/learning/edit-assignment', assignment, true)
                .then(result => {
                    console.log(result);
                    setShowLoading(false);
                    setModalObject({ header: '', body: result.message, footer: '' });
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        // console.log(assignment)
                        dispatchFunc(editeachAss(assignment));
                        clearInputs();
                    }
                })
                .catch(err => {
                    setShowLoading(false);
                    setModalObject({ header: '', body: err.message, footer: '' });
                    // console.log(assignment);
                    setModalToggle(true);

                });
        }
        else {
            var allCategory = [];
            for (let i = 0; i < category.length; i++) allCategory.push(category[i].id);

            let AssignmentObject = {
                assignment: {
                    title: assval,
                    end_date: `${subDate} ${subTime}`,
                    assignment: content,
                    institution_id: instOption.id,
                    score: score,
                    timezone: Number(timezone.value)
                },
                categories: allCategory
            };
            /*assignment.selected_Institutions = instOption;
            assignment.categories = category;
            assignment.editorText = editor.current.value;
            const submission = assignment.submission_date$time.split(` `);
            assignment.submission_date = submission[0];
            assignment.submission_time = submission[1];*/

            setShowLoading(true);
            console.log(AssignmentObject);

            Api.PostApi('/learning/add-assignment', AssignmentObject, true)
                .then(result => {
                    console.log(result, "Assignment result");
                    setShowLoading(false);
                    setModalObject({ header: '', body: result.message, footer: '' });
                    setModalToggle(true);
                    clearInputs();
                    if (result.isSuccessful) {
                        AssignmentObject.assignment.id = result.data;
                        AssignmentObject.assignment.institution_category = allCategory;
                        AssignmentObject.assignment.end_date = `${subDate}T${subTime}Z`;
                        dispatchFunc(addAssignment({ ...AssignmentObject.assignment }));
                    }
                });
        }
    };

    useEffect(() => {
        if (instOption && instOption.id) {
            setInstCategory(Object.values(instOption.categories));
        }
        else {
            setInstCategory([]);
        }
    }, [instOption]);

    useEffect(() => {
        setTitle('Create Assignment');
    }, []);

    useEffect(() => {
        if (editAssignment && editAssObj.type === 'edit') {
            // console.log(editAssObj)
            setAssval(editAssObj.title);
            setScore(editAssObj.score);
            setContent(editAssObj.assignment);
            setTimezone(timeList.find(obj => Number(obj.value) === editAssObj.timezone));
            setSubDate(editAssObj.end_date.split('T')[0]);
            setSubTime(editAssObj.end_date.split('T')[1].split('Z')[0]);
            selInstitution(Institution.find(obj => obj.id === editAssObj.institution_id));
            selCategory(instCategory.filter(obj => editAssObj.institution_category.includes(obj.id)));
            setdefCategory(instCategory.filter(obj => editAssObj.institution_category.includes(obj.id)));

        }
    }, [Institution, editAssObj, editAssignment, instCategory]);


    return (
        <>
            <Loading shouldShow={showLoading} />
            <form className={`${styles.form} ${styles.form2}`}>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Title</span>
                    <input type='text' className={`${styles.fields}`} placeholder='Enter Assignment title ' onChange={(e) => { setAssval(e.target.value); }} value={assval ? assval : ''} />
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Score</span>
                    <input type='number' className={`${styles.fields}`} placeholder='Enter Assignment score ' onChange={(e) => { setScore(e.target.value); }} value={score ? score : ''} />
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Select Institution</span>
                    {<Select options={Institution} name='learning Insti' className={styles.selectFields} disabled={editAssignment}
                        onChange={selInstitution} defaultValue={instOption} value="id" label="name" />}
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Select User Group</span>
                    {<Select options={instCategory} name='learning ' className={styles.selectFields} disabled={editAssignment}
                        onChange={selCategory} defaultValue={defcategory} multi value="id" label="name" />}
                </label>
                <TextEditor
                    text={'Enter your Assignment'}
                    setShowLoading={setShowLoading}
                    navigate={Navigate}
                    value={content}
                    reff={editor}
                    style={style}
                    handleChange={setContent}
                />
                <label className={`${styles.label}`} title='Select time Zone'>
                    <span className={styles.isRequired}>Select Timezone</span>
                    <Timezone onChange={setTimezone} reset={false} defaultValue={0} />
                </label>
                <label className={`${styles.label2}`}>
                    <span className={styles.isRequired}>Submission Date</span>
                    <input name='Submission date' value={subDate ? subDate : ''} className={`${styles.fields}`} type='date' onChange={(e) => { setSubDate(e.target.value); }} />
                </label>
                <label className={`${styles.label2}`}>
                    <span className={styles.isRequired}>Submission Time</span>
                    <input name='submission Time' value={subTime ? subTime : ''} className={`${styles.fields}`} type='time' onChange={(e) => { setSubTime(e.target.value); }} />
                </label>
                <div className={`${styles.addBtnDiv} ${styles.div}`}>
                    {editAssignment ?
                        <span style={{ display: 'flex', gap: '30px' }}>
                            <button onClick={() => { clearInputs(); Navigate('../view-assignment'); }} className='cancelEditButton'>Cancel Edit</button>
                            <button type="submit" className={styles.addBtn}
                                onClick={(event) => { AssignmentCreation(event); }}>Save Change(s)
                            </button>
                        </span>
                        :
                        <button type="submit" className={styles.addBtn}
                            onClick={(event) => { AssignmentCreation(event); }}>Create Assignment
                        </button>
                    }

                </div>
            </form>
        </>
    );
};

export default CreateAssignment;