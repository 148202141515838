import { Doughnut,Bar } from "react-chartjs-2";
import {Chart as ChartJS,BarElement, ArcElement, Tooltip,Legend,CategoryScale,LinearScale} from 'chart.js';


ChartJS.register({
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement
})

const barOption = {
  maintainAspectRation:false,
  scale:{
    y:{
      beginAtZero:true
    }
  },
  legend:{
    labels:{
      fontSize:18,
      usePointStyle:true,
      boxSize:10, 
      pointStyle:'circle',
    }
    
  }

}

const data1 = {
  labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
  datasets: [
      {
        label: 'Score Chart',
        data: [40,50,60,40,50,60,40,50,60,40,50,60],
        backgroundColor: [
          '#8884d8','#8884d8',
          '#8884d8','#8884d8',
          '#8884d8','#8884d8',
          '#8884d8','#8884d8',
          '#8884d8','#8884d8',
          '#8884d8','#8884d8'
        ],
        borderWidth: 1,
      }
  ]
}

export const BarChart = (props) =>{
    const options = props.option || barOption;
    const data = props.data || data1;

    return (
        <Bar data={data} options={options} />
    )
}