import { useState, useEffect, useRef,useContext } from 'react';
import styles from './css/AddQuestions.module.css';
import { EditContext } from './Questions';

const Option = ({ number, onChk, states, num, onInputChange, handleAnswers, submitted, arrOptions,arrAnswers, setInputs, setAnswers,answers }) => {
    // handleAnswers();
    const [chk, setChk] = useState(false);
    const textArea = useRef(null);
    const {editQuestion} = useContext(EditContext)
    const handleChk = () => {
        if (chk) {
            setChk(false);
            onChk({ type: 'reduce' });
            handleAnswers(`option-${Number(num + 1)}`, false);
        } else {
            if (Number(states.selected) < Number(states.max)) {
                setChk(true);
                onChk({ type: 'add' });
                handleAnswers(`option-${Number(num + 1)}`, true);
            }
        }

        //console.log(states);
    };

    useEffect(() => {
        setChk(false);
        textArea.current.value = '';
        onChk({ type: 'reset' });
    }, [submitted]);
    useEffect(()=>{
        if(arrOptions.length){
            //console.log(num)
            textArea.current.value = arrOptions[num];
            if(!arrOptions[num]){
                textArea.current.value = '';
            }
            
            if(arrAnswers.some(arr => Number(arr) === Number(num+1))){
                setChk(true);
                onChk({ type: 'add' });
                setAnswers({...answers,[`option-${Number(num + 1)}`]:true});
                //console.log(arrAnswers,"hello",num)
            }
            //console.log('answer',answers);
            const name = `option-${Number(num + 1)}`;
            const value = arrOptions[num];
            setInputs(inputs => ({ ...inputs, [name]: value }));

        }
        else{
            textArea.current.value = '';
            setChk(false);
            //handleAnswers(`option-${Number(num + 1)}`, false);
            setAnswers({});
        }
    },[editQuestion,arrAnswers,arrOptions])
    return (
        <>
            <label htmlFor={`option-${Number(num + 1)}`} className={`${styles.label}`}>
                <span className={styles.isRequired}> Option {Number(num + 1)}</span>
                <textarea type='text' id={`option-${Number(num + 1)}`} onInput={onInputChange} name={`option-${Number(num + 1)}`} required rows='3' className={styles.textarea} ref={textArea} />
                <label htmlFor={`answer-${Number(num + 1)}`} className={`${styles.label} ${styles.answerContainer}`}>
                    <span className={styles.span}>Answer?</span>
                    <input type='checkbox' id={`answer-${Number(num + 1)}`} name='option' onChange={handleChk} checked={chk} className={styles.inputs22} />
                </label>
            </label>
        </>
    );
};

export default Option;