import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {dateObject} from '../../../helperFunctions/Api2';
import { Link } from 'react-router-dom';
import css from "../../../css/ServiceDashboard.module.css";
import BarGraph from "../../../components/Graph/BarGraph";
import VideoPlayer from "../../../components/VideoPlayer";

const Dashboard = () =>{
    const forms = useSelector(store => store.portal.forms);
    const {datetime} = useSelector(store => store.account.username);
    const [activeForms, setActiveForms] = useState(0);
    const [data,setData] = useState(null);
    const [showVideo, setShowVideo] = useState(false);

    const arrageData = (year) =>{
      let dataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
     
       forms.forEach(form => {
           let d = new Date(form.created_at);
           let vYear = d.getFullYear();
           if(vYear == year){
               dataArr[d.getMonth()]++;
           }
       })
      
      setData(dataArr);
   }

    useEffect(()=>{
      let activeFormLen = 0; let len = forms.length;
      for(let i = 0; i < len; i++){
        if(!forms[i].is_closed) activeFormLen++;
      }
      setActiveForms(activeFormLen);

      let d = new Date(datetime);
      let year = d.getFullYear();
      arrageData(year)

    }, [forms])

    return (
        <div className={css.container}>
          <div>Forms Dashboard</div>
          <div>
              <div>
                  <div>
                    <BarGraph data={data} sideText={"No. of Forms"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText={`Created Forms`}/>
                    <br/>
                    <div>
                      Total forms created: <span className={css.purpleText}>{forms.length}</span><br/>
                      Total active forms: <span className={css.purpleText}>{activeForms}</span>
                    </div>
                  </div>
                  <div>
                    <div>EduPlus Form Service</div>
                    <p>
                       Sight-EduPlus Form service enables you to create rich and dynamic forms of different kinds such as admission/application form, transcript/result form application, question form, review forms etc.
                    </p>
                    <div>
                       <Link to="../forms" style={{backgroundColor:"var(--secondary-color)",color:"var(--white-color)",border:"1px solid var(--secondary-color)"}}>
                        Get Started
                       </Link>
                       <button onClick={()=>setShowVideo(!showVideo)} style={{border:"1px solid var(--secondary-color)",border:"1px solid var(--secondary-color)",backgroundColor:"var(--white-color)"}}>
                       Watch Video
                       </button>
                    </div>
                  </div>
              </div>
              {showVideo && <VideoPlayer serviceCode={'PWS'}/>}
          </div>
        </div>
      )
}

export default Dashboard;