import styles from './css/Categories.module.css';
import css from './css/eCategory.module.css';
import { useEffect, useState, useContext } from 'react';
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import { setUserCategory, deleteCategory } from "../../../redux/reducers/questionReducer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Api, { convertDatetoString } from '../../../helperFunctions/Api2';

import { EditComponent } from '../../../components/EditComponent';
import { CategoryContext } from './Categories';
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;

const user_id = Number(localStorage.getItem('eduplus_idnty'));

const quesAudCat = {
    '0':'Personal',
    '1':'For institution',
    '2':'Public & approved (available to others)',
    '3':'Public but not approved (not available to others)'
}

const ViewQuestionCategories = ({ setTitle }) => {

    // const categories = useSelector((state) => state.questions.user_categories);
    const [loadingState, setLoadingState] = useState("loading");
    const [shouldShow, setShouldShow] = useState(true);
    const categories = useSelector(store => store.questions.categories);
    const [menuIndex, setMenuIndex] = useState(null);

    const [modalToggle, setModalToggle] = useState(false);
    const [modalObject, setModalObject] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [activeCategories, setActiveCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);


    useEffect(() => {
        setShouldShow(true);

        return () => setShouldShow(false);
    }, []);

    useEffect(() => {
        setTitle('Question categories');
        // setActiveCategories(categories)
    }, [setTitle]);

    useEffect(() => {
        // setActiveCategories(categories);
        if (categories && categories.length > 0) {
            let tempCate = Array.from(categories);
            tempCate = tempCate.sort((a, b) => b.name - a.name);
            let temp_categories = tempCate.filter(eCate => eCate.added_by == user_id);
            let temp_categories2 = tempCate.filter(eCate => eCate.added_by != user_id);
            setActiveCategories([...temp_categories, ...temp_categories2]);
        }
    }, [categories])

    useEffect(() => {
        if (activeCategories.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((activeCategories.length / NO_OF_ITEMS_PER_PAGE) + ((activeCategories.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [activeCategories.length]);



    return (
        <>
            <Modal modalObject={modalObject} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <div className={styles.form} style={{
                padding: '30px 20px'
            }}>
                {activeCategories && activeCategories.length > 0 ? activeCategories.map((eCategory, index) =>
                    index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                    <EachCategory key={eCategory.id} menuIndex={menuIndex} index={index} eCategory={eCategory} setMenuIndex={setMenuIndex} modal={{ setModalToggle, setModalObject }} loading={{ setShowLoading }} />
                ) :
                    <div>

                        No Questions Category
                    </div>}

                {activeCategories.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

            </div>
        </>
    );
};

export default ViewQuestionCategories;


const EachCategory = ({ index, menuIndex, setMenuIndex, eCategory, loading, modal }) => {
    const { editObject, setEditObject } = useContext(CategoryContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const setShowLoading = loading.setShowLoading;
    const setModalToggle = modal.setModalToggle;
    const setModalObject = modal.setModalObject;
    const _style = {
        color: 'var(--primary-color)',
    }
    const handleDeleteBtn = () => {
        setModalObject({
            header: <>Confirmation Message</>,
            body: <>
                <p>Are you sure you want to delete this Question Category. This action will delete all questions associated with this Category too.</p>

            </>,
            footer:
                <div>
                    <button onClick={() => setModalToggle(false)}>Cancel</button>
                    <button onClick={() => { handleDelete() }}>Delete</button>
                </div>
        });
        setModalToggle(true);
    }
    const handleEdit = () => {
        setEditObject(eCategory);
        navigate('../add-category');
    }

    const handleDelete = () => {
        if (!Api.checkNetwork()) return false;

        setShowLoading(true)
        console.log({ id: eCategory.id })
        Api.PostApi('/question/delete-category', { id: eCategory.id }, true)
            .then(result => {
                setShowLoading(false);
                setModalObject({ body: result.message, footer: '' });
                setModalToggle(true);
                console.log(result, "Server result")
                if (result.isSuccessful) {
                    dispatch(deleteCategory({ id: eCategory.id }))
                }
            })
            .catch(error => {
                setShowLoading(false);
                console.log(error, "Server Error")
            })
    }

    return (
        <div key={eCategory.id} className={css.container}>
            {user_id == eCategory.added_by || true ? <EditComponent clickEdit={handleEdit} clickDelete={handleDeleteBtn} menuIndex={menuIndex} setMenuIndex={setMenuIndex} index={index} /> : <span className={css.drop}></span>}
            <div>
                <span>Name: </span>
                <span style={_style}>{eCategory.name}</span>
            </div>
            <div>
                <span>Group: </span>
                <span style={_style}>{eCategory.main_category || 'None'}</span>
            </div>
            <div>
                <span>Description: </span>
                <span style={_style}>{eCategory.description}</span>
            </div>
            <div>
                <span>No of Questions: </span>
                <span style={_style}>{eCategory.question_num}</span>
            </div>
            <div>
                <span>Visibility: </span>
                <span style={_style}>{quesAudCat[`${eCategory.audience_type}`]}</span>
            </div>
            <div>
                <span>Date Created: </span>
                <span style={_style}>{convertDatetoString(eCategory.created_at)}</span>
            </div>
        </div>
    )
}




/*********************************************************************** */