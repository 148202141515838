import React,{useRef,useEffect,useContext} from 'react';
import { CbtQuestionId } from './createCbt';

const CbtQuestionTimeInput = ({type,placeholder, onChange, disabled,timeVal,timeType,timeArrVal,id,index,q_id}) => {
   const inputRef = useRef();
   const {allQuestionId,setAllQuestionId} = useContext(CbtQuestionId);


   useEffect(()=>{
       if(timeVal !== null && timeType.value === '2'){
           if(inputRef){
               if(!inputRef.current.disabled)inputRef.current.value = timeVal;
               else{inputRef.current.value = ''}
           }
       }
      
   },[timeType,timeVal])

   useEffect(()=>{
       if(timeVal !== null){
           if(inputRef){
               if(!inputRef.current.disabled){
                   inputRef.current.value = timeVal;
                }
               if(inputRef.current.disabled)inputRef.current.value = '';
           }
       }
   },[disabled])


//    useEffect(()=>{
//        if(timeArrVal.length > 0){
//            const durationArr = timeArrVal.filter(element => Number(element.id) === Number(id));
//            let duration = null;
//            if(durationArr.length > 0){
//                 duration = durationArr[0].duration;
//            }
//            console.log(duration)
//            if(allQuestionId.length > 0)setAllQuestionId([...allQuestionId.map((element,num) => Number(num) === Number(index)?{questions:[...element.questions.map(elem => Number(elem.id) === Number(q_id)?{id:q_id,duration}:elem)]}:element)]);
//        }
//    },[timeArrVal])

  return (
    <input type={type} placeholder={placeholder} onChange={onChange} disabled={disabled} ref={inputRef} min={0}/>
  )
}

export default CbtQuestionTimeInput