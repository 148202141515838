import React,{useState,useEffect,useContext} from 'react';
import styles from '../../../css/CreateInst.module.css';
import eventstyle from './Css/event.module.css'
import { eventModalContext } from './mailSettings';
import { useSelector,useDispatch } from 'react-redux';
import Loading from '../../../components/loading';
import { editEmailGroup } from '../../../redux/reducers/eventReducer';
import Select from '../../../components/Select';
import { useNavigate } from "react-router-dom";
import EmailList from '../../../components/EmailList';
import {MdOutlineClose as CancelIcon} from 'react-icons/md';
import Api from '../../../helperFunctions/Api2';



const AddEmails = ({setTitle}) => {
    const [selEmail, setSelEmail] =  useState(null);
    const {setModalObject,setModalToggle,editEmailObj,setEditEmailObj} = useContext(eventModalContext);
    const [showLoading, setShowLoading] = useState(false);
    const emailGroup = useSelector(state => state.event.email_group);
    const [selDefault,setSelDefault] = useState('');
    const [emailDefault,setEmailDefault] = useState('');
    const [emailArrayList,setEmailArrayList] = useState([]);
    const [resetEmail,setResetEmail] = useState(false);
    const [disabled,setDisabled] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()

   
      

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!selEmail || !selEmail.id){
            setModalObject({
                header:"",
                body:"Select An Email Group",
                footer:""
            })
            setModalToggle(true);
            return false;
        }
        if(!emailArrayList || emailArrayList.length == 0){
            setModalObject({
                header:"",
                body:"Add valid Emails separeted by comma(,) or space",
                footer:""
            })
            setModalToggle(true);
            return false;
        }
        
        const emailObject = {
            group_id:selEmail.id,
            emails:emailArrayList
        }
        //console.log(emailObject);
       

        if(!Api.checkNetwork()) return false;
        setShowLoading(true)

        Api.PostApi('/event/add-emails-to-group',emailObject, true)
        .then(result=>{
            setShowLoading(false);
            console.log(result, "Server result");
            if(result.isSuccessful){
                setModalObject({header:"",body:result.message,footer:""});
                setModalToggle(true);
                dispatch(editEmailGroup({id:selEmail.id,emails:emailObject.email}));
                setResetEmail(!resetEmail);
              
            }
        })
        .catch(error=>{
            setShowLoading(false);
            console.log(error, "Server Error")
        })

    }
    
    const handleEdit = (e) => {
        e.preventDefault();
        if(!selEmail || !selEmail.id){
            setModalObject({
                header:"",
                body:"Select An Email Group",
                footer:""
            })
            setModalToggle(true);
            return false;
        }
        if(!emailArrayList || emailArrayList.length == 0){
            setModalObject({
                header:"",
                body:"Add valid Emails separeted by comma(,) or space",
                footer:""
            })
            setModalToggle(true);
            return false;
        }
        
        const emailObject = {
            group_id:selEmail.id,
            event:{
                title:editEmailObj.title,
                description:editEmailObj.description
            }
        }
        let add = [];
        let remove = [];
       
        editEmailObj.emails.forEach(eEmail => {
            let aEmail = eEmail.email;
            let check = emailArrayList.find(em => em.toLowerCase() == aEmail.toLowerCase());
            if(!check){
                remove.push(eEmail.id)
            }            
        })
        emailArrayList.forEach(eEmail => {
            let check = editEmailObj.emails.find(em => em.email.toLowerCase() == eEmail.toLowerCase());
            if(!check){
                add.push(eEmail);
            }

        })
        emailObject.add = add;
        emailObject.remove = remove;

        const newObject = {
            id:editEmailObj.id,
            emails:emailArrayList,
            title:editEmailObj.title,
            description:editEmailObj.description,
        }

        console.log(emailObject);
        if(!Api.checkNetwork()) return false;
        setShowLoading(true)

        Api.PostApi('/event/edit-email-group',emailObject, true)
        .then(result=>{
            setShowLoading(false);
            setModalObject({header:"",body:result.message,footer:""});
            setModalToggle(true);
            if(result.isSuccessful){
                dispatch(editEmailGroup(newObject));
                handleCancelEdit();            
            }
        })
        .catch(error=>{
            setShowLoading(false);
            console.log(error, "Server Error")
        })
      
    }

    const handleCancelEdit = () =>{
        setEditEmailObj({});
        setResetEmail(!resetEmail);
        setEmailDefault('');
        setSelDefault('');
        setDisabled(false);
    }

    useEffect(()=>{
        setTitle('Add Emails')
    },[])

    useEffect(()=>{
        if(editEmailObj && editEmailObj.id){
            console.log(editEmailObj);
            setSelDefault({id:editEmailObj.id});
            setEmailDefault(editEmailObj.emails.map(e => (`${e.email}`)));
            setDisabled(true);
        }
    },[editEmailObj])

      

    return ( 
        <>
          <Loading  shouldShow={showLoading}/>
         <form className={`${styles.form} ${styles.form2}`} >
           <label className={`${styles.label}`}>
                {/* {editEmailObj && editEmailObj.id ?<span onClick={handleCancelEdit} className={eventstyle.editStatus}><CancelIcon/> Cancel Edit</span>:<></>} */}
                <span className={styles.isRequired}>Select Email Group to add List</span>
                {<Select name='EmailSelect' options={emailGroup}  onChange={setSelEmail} className={styles.selectFields}  style={{width:'100%'}}defaultValue={selDefault} value='id' label='title' reset={resetEmail} disabled={disabled} />}
            </label>
            <label className={`${styles.label}`} >
                <span>Add List to Selected Email Group</span>
                <EmailList className={styles.fileds} onChange={setEmailArrayList} defaultValue={emailDefault} reset={resetEmail}/>

            </label>
            <small>NOTICE: Emails should be separated by a comma(<b>,</b>) or space</small>
            <div className={`${styles.addBtnDiv} ${styles.div}`}>
            {!editEmailObj || !editEmailObj.id ?
                <button type="submit" className={styles.addBtn} onClick={handleSubmit}>Add Email List</button>
            :
            <span style={{display:'flex', gap:'30px'}}>
                    <button onClick={()=>{handleCancelEdit(); navigate('../view-email-group')}} className='cancelEditButton'>Cancel Edit</button>
                    <button type="submit" className={styles.addBtn} onClick={handleEdit}>Save Change(s)</button>
            </span> 
            }
            </div>
           
         </form>
           
        </>
     );
}
 
export default AddEmails;