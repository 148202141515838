import { useContext, useEffect, useState } from 'react';
import styles from '../../../css/AddQuestions.module.css';
import ResultCss from '../../../css/Result.module.css';
import Staffstyles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { BsList } from 'react-icons/bs';
import CategoriesCss from '../../../css/Categories.module.css';
import { MenuContext } from '../DashBoard';
import { useSelector } from 'react-redux';
import Select from '../../../components/Select';
import restApi from '../../../helperFunctions/Api2';
import { Button } from '../../../components/Button';
import UploadedFiles from '../../../components/UploadedFiles';


export default function Customize() {
    const { showMenu, setShowMenu } = useContext(MenuContext);
    const [title, setTitle] = useState('');
    return <>
        <div className={`${Staffstyles.baseView} `} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h1 className={`${Staffstyles.title}`}>Customize Services</h1>
            <div className={`${Staffstyles.subView}`}>
                <div className={titleStyle.sectionTitle}>
                    <h3 className={titleStyle.h3}>
                        {title}
                    </h3>
                    <div className={`${titleStyle.menuDiv}`}>
                        <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                        {showMenu &&
                            <div className={`${titleStyle.subMenu}`} >
                                <ul>
                                    <li><Link to='customise'>Customize EduPlus</Link></li>
                                    <li><Link to='embed'>Embed EduPlus</Link></li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <Routes>
                    <Route index element={<Signin setTitle={setTitle} />} />
                    <Route path='/customise' element={<Signin setTitle={setTitle} />} />
                    <Route path='/embed' element={<Embeded setTitle={setTitle} />} />
                    <Route path="/*" element={<PageNotFound />} />
                </Routes>
            </div>
        </div>
    </>
}

function Signin({ setTitle }) {

    const Institutions = useSelector((state) => state.questions.user_institutions);
    const [selInst, setSelInst] = useState('');
    const [displayName, setName] = useState('');
    const [primaryColor, setPriColor] = useState('');
    const [secondaryColor, setSecColor] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [bgUrl, setBGUrl] = useState('');
    const [copyType, setCopyType] = useState('');
    // const [bgUrl, setBGUrl] = useState(0);
    const [openImage, setUploadedImageToggle] = useState(false);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);

    const handleCopy = (url) => {
        if (copyType === 'bg') {
            setBGUrl(url);
        }
        else if (copyType === 'logo') {
            setLogoUrl(url);
        }
    }
    function handleSubmit() {
        if (!selInst || !selInst.institution_id) {
            setModalText('Please Select an Institution');
            setModalToggle(true);
            return;
        }
        
        setShowLoading(true);
        let style = {}
        if (primaryColor) 
            style['--primary-color'] = primaryColor;
        if (secondaryColor) 
            style['--secondary-color'] = secondaryColor;
        const payload = {
            service_id: 4,
            institution_id: selInst.institution_id,
            customization: JSON.stringify({
                logoUrl, bgUrl, displayName, institution_id: selInst.institution_id,
                style
            })
        }
        restApi.PostApi('/form/add-customization', payload, true).then(result => {
            console.log(payload, result);
            setModalText(result.message);
            if (result.isSuccessful) {
                setName(''); setPriColor(''); setSecColor('');
                setBGUrl(''); setLogoUrl('');
            }
        }).catch(error => {
            setModalText('Error, Please try Again')
        }).finally(()=>{
            setShowLoading(false);
            setModalToggle(true);
        })
    }

    useEffect(() => {
        setTitle('Customize EduPlus');
    }, [setTitle])
    return <>
        <div className={ResultCss.mainView}>
            <label className={`${styles.label}`}><span className={styles.isRequired}>Select Institution</span>
                <Select options={Institutions} className={styles.selectFields}
                    onChange={setSelInst} value="id" label="name" />
            </label>
            <label className={CategoriesCss.label}>
                <span >Display Name</span>
                <input className={CategoriesCss.input} value={displayName} onChange={(e) => setName(e.target.value)} placeholder='Eg. Name of Institution' title='Name of Institution displayed under the logo' />
            </label>
            <label className={CategoriesCss.label}>
                <span >Primary Color</span>
                <input type='color' className={CategoriesCss.input} value={primaryColor} onChange={(e) => setPriColor(e.target.value)} />
            </label>
            <label className={CategoriesCss.label}>
                <span >Secondary Color</span>
                <input type='color' className={CategoriesCss.input} value={secondaryColor} onChange={(e) => setSecColor(e.target.value)} />
            </label>
            <UploadedFiles type={'image'} modalTogglee={openImage} closeModal={() => setUploadedImageToggle(false)} setShowLoading={setShowLoading} onCopy={handleCopy}/>
            <label className={`${styles.label} ${styles.label}`}>
                <span >Enter Logo Url</span>
                <span className={styles.styledlink} onClick={() =>{ setUploadedImageToggle(true); setCopyType('logo')}}>Select image</span>
                <input className={CategoriesCss.input} value={logoUrl} onChange={(e) => setLogoUrl(e.target.value)} placeholder='http://' title='URL of the displayed logo image' />
            </label>
            <label className={`${styles.label} ${styles.label}`}>
                <span >Background Image Url</span>
                <span className={styles.styledlink} onClick={() =>{ setUploadedImageToggle(true); setCopyType('bg')}}>Select image</span>
                <input className={CategoriesCss.input} value={bgUrl} onChange={(e) => setBGUrl(e.target.value)} placeholder='http://' title='URL of the displayed background image' />
            </label>
            <div className={CategoriesCss.buttonContainer}>
                <button className={CategoriesCss.viewBtn} onClick={handleSubmit} >Save Customization</button>
            </div>
        </div></>
}

const Options = [
    { name: 'Institution'},
    // { name: 'Sign In'},
    { name: 'Result'},
    { name: 'Forms'},
]
function Embeded ({ setTitle }) {

    const Institutions = useSelector((state) => state.questions.user_institutions);
    const forms = useSelector(store => store.portal.forms);
    const [selOption, setSelOpt] = useState(null);
    const [selInst, setSelInst] = useState(null);
    const [selForm, setSelForm] = useState(null);
    const [reset, setReset] = useState(false);
    const [link, setLink] = useState(null);
    const [copyText, setCopyText] = useState(false);


    useEffect(() => {
        setTitle('Embed EduPlus');
    }, [setTitle])
    useEffect(() => {
        selOption && setReset(!reset);
        setSelInst(null);
        setSelForm(null);
        setLink(null)
    }, [selOption])
    useEffect(() => {
        if (selForm && selForm.id) {
            setLink(`https://portal.sight-eduplus.com/${selForm.link}?d=0`)
        }
    }, [selForm])
    useEffect(() => {
        if (selInst && selInst.id) {
            selOption.name === "Result"
            ? setLink(`https://result.sight-eduplus.com/${selInst.institution_id}`)
            : setLink(`https://sight-eduplus.com/account/sign-in/${selInst.institution_id}`)
        } else {
            setLink(null)
        }
    }, [selInst, selOption])
    useEffect(() => {
        console.log(link);
    }, [link])


    function handleSubmit (link) {
        let text = `<iframe src="${link}" title="${selOption.name}" width="100%"></iframe>`
        navigator.clipboard.writeText(text);
        setCopyText(true); 

        setTimeout(() => {
            setCopyText(false);
        }, 2000);
    }
    
    return <div className={ResultCss.mainView}>
        <label className={`${styles.label}`}><span className={styles.isRequired}>Select Service</span>
            <Select options={Options} className={styles.selectFields}
                onChange={setSelOpt} value="name" label="name" />
        </label>
        {selOption && ( selOption.name === 'Institution' || selOption.name === 'Result') &&
            <label className={`${styles.label}`}><span className={styles.isRequired}>Select Institution</span>
                <Select options={Institutions} className={styles.selectFields}
                    onChange={setSelInst} value="id" label="name" reset={reset} />
            </label>
        }
        {selOption && selOption.name === 'Forms' &&
            <label className={`${styles.label}`}><span className={styles.isRequired}>Select Form</span>
                <Select options={forms} className={styles.selectFields}
                    onChange={setSelForm} value="id" label="title" reset={reset} />
            </label>
        }
        <div className={CategoriesCss.buttonContainer}>
            {link && <Button style={!copyText ? { backgroundColor: 'var(--secondary-color)', color: 'white' } : { backgroundColor: 'white', color: 'var(--secondary-color)' }} onClick={() => handleSubmit(link)} disabled={copyText}>{copyText ? 'Link Copied' : 'Copy Link'}</Button>}
        </div>
    </div>
    
}