import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {dateObject} from '../../../helperFunctions/Api2';
import css from "../../../css/ServiceDashboard.module.css";
import { Link } from 'react-router-dom';
import BarGraph from "../../../components/Graph/BarGraph";
import VideoPlayer from "../../../components/VideoPlayer";

const Dashboard = () =>{
    const [data,setData] = useState(null);
    const results = useSelector(store => store.results.userResults);
    const templates = useSelector(store => store.results.templates);
    const groups = useSelector(store => store.results.groups); 
    const {datetime} = useSelector(store => store.account.username);
    const [showVideo, setShowVideo] = useState(false);

    useEffect(()=>{

      const arrageData = (year) =>{
        let dataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
       
         results.forEach(result => {
             let d = new Date(result.created_at);
             let vYear = d.getFullYear();
             if(vYear == year){
                 dataArr[d.getMonth()]++;
             }
         })
        
        setData(dataArr);
     }

      let d = new Date(datetime);
      let year = d.getFullYear();
      arrageData(year)
    }, [results])

    return (
        <div className={css.container}>
          <div>Result Dashboard</div>
          <div>
              <div>
                  <div>
                    <BarGraph data={data} sideText={"No. of Results"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText={`Results Created`}/>
                    <br/>
                    <div>
                      Total numbers of results: <span className={css.purpleText}>{results.length}</span><br/>
                      Total result groups: <span className={css.purpleText}>{groups.length}</span><br/>
                      Total templates: <span className={css.purpleText}>{Object.values(templates).length}</span><br/>
                    </div>
                  </div>
                  <div>
                    <div>Result Mgmt. System</div>
                    <p>
                       EduPlus result management system gives you the ability to create & manage results/transcript of all kinds efficiently.
                    </p>
                    <div>
                       <Link to="../events/create-event" style={{backgroundColor:"var(--secondary-color)",color:"var(--white-color)",border:"1px solid var(--secondary-color)"}}>
                        Get Started
                       </Link>
                       <button onClick={()=>setShowVideo(!showVideo)} style={{border:"1px solid var(--secondary-color)",border:"1px solid var(--secondary-color)",backgroundColor:"var(--white-color)"}}>
                       Watch Video
                       </button>
                    </div>
                  </div>
              </div>
              {showVideo && <VideoPlayer serviceCode={'SET'}/>}
          </div>
        </div>
      )
}

export default Dashboard;