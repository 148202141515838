import styles from './css/Categories.module.css';
import { useContext, useEffect, useState } from 'react';
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import css from './css/analysis-page.module.css';
import {EditComponent} from '../../../components/EditComponent';
import {useSelector} from 'react-redux';
import { convertDatetoString } from '../../../helperFunctions/Api2';
import PageNumber from '../../../components/PageNumber';
import { Context } from './Test';
import { MenuContext } from '../DashBoard';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
const NO_OF_ITEMS_PER_PAGE = 30;


const Analysis = ({ setTitle }) => {

    const [menuIndex,setMenuIndex] = useState(null);
    const tests = useSelector(store => store.test.tests);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);
    const { setEditData } = useContext(Context);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const navigate = useNavigate();


    const handleResumeTest = (test_link) =>{
        window.open(`https://cbt.sight-eduplus.com/${test_link}`, '_blank');
    }
    const handleDelete = (data) => {
        console.log(data);
    };
    const handleEdit = (data) => {
        setEditData(data);
        navigate('../take-test');
    };


    useEffect(() => {
        setTitle('Analysis');
        setEditData(null);
    }, [setEditData, setTitle]);

    useEffect(() => {
        if (tests.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((tests.length / NO_OF_ITEMS_PER_PAGE) + ((tests.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [tests.length]);

    return (
        <>
            <div className={styles.form} style={{
                padding: '10px 0'
            }}>
                <div className={css.container}>
                    {tests && tests.length > 0 ? 
                    tests.map((data,index) =>
                    index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                    <EachTest key={data.id} index={index} data={data} menuIndex={menuIndex} setMenuIndex={setMenuIndex} handleResumeTest={handleResumeTest} handleDelete={handleDelete} handleEdit={handleEdit} /> ):
                    <></>
                        
                    }

                </div>
                {tests.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
            </div>
        </>
    );
};

export default Analysis;

export const EachTest = ({index,setMenuIndex,menuIndex,data, handleResumeTest, handleEdit, handleDelete})=>{
    const _style={
        color:'var(--primary-color)',
    }
    return (
        <div className={css.container2}>
            <EditComponent index={index} menuIndex={menuIndex} setMenuIndex={setMenuIndex} clickDelete={()=>handleDelete(data)} clickEdit={()=>handleEdit(data)} />
            <div>
                <span>Test Purpose: </span>
                <span style={_style}>{data.purpose}</span>
            </div>
            <div>
                <span>Created: </span>
                <span style={_style}>{convertDatetoString(data.created_at)}</span>
            </div>
            {data.test_link && 
                <div title="Click link to start Test">
                    <span>Test Link: </span>
                    <span style={_style} onClick={() => handleResumeTest(data.test_link)}>Click here to start</span>
                </div>
            }
            
        </div>
    )
}


/*********************************************************************** */