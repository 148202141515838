import React from "react";
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import styles from '../../../css/InstitutionMgmt.module.css';
import DashBoard from './Dashboard';
import Portal from "./Portal"
import Website from "./Website";
import Customize from "./Customize";

const FormMgt = () => {
    return ( 
        <>
         <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Routes>
                <Route path="dashboard" element={<DashBoard />}/>
                <Route path="forms/*" element={<Portal/>} />
                <Route path="website/*" element={<Website/>} />
                <Route path="customize/*" element={<Customize />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </div>
        </>
     );
}


export default FormMgt;