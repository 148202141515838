import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import Result from "./Result";
import styles from './../../../css/Result.module.css';
import { createContext, useState } from "react";
import ResultTemplate from "./ResultTemplate";
import ResultSet from "./ResultSet";
import ResultGroup from "./ResultGroup";
import DashBoard from "./Dashboard";

export const ResultContext = createContext();

const ResultMgmt = () => {
    const [title, setTitle] = useState('');
    const [editing,setEditing] = useState(false);
    const [editType, setEditType] = useState(null);
    const [editData, setEditData] = useState({});
    const clearEdit =()=>{
        setEditing(false)
        setEditType(null)
        setEditData({});
    }
    return (
        <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <ResultContext.Provider value={{clearEdit, editing, setEditing, editType, setEditType, editData, setEditData,  title, setTitle }}>
                <Routes>
                    <Route path="dashboard" element={<DashBoard />} />
                    <Route path="result/*" element={<Result />} />
                    <Route path="result-template/*" element={<ResultTemplate />} />
                    <Route path="result-set/*" element={<ResultSet />} />
                    <Route path="result-group/*" element={<ResultGroup />} />
                    <Route path="/*" element={<PageNotFound />} />
                </Routes>
            </ResultContext.Provider>
        </div>
    );
}
export default ResultMgmt
