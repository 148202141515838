import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ResultContext } from "./ResultMgmt"
import ResultCss from '../../../css/Result.module.css';
import CategoriesCss from '../../../css/Categories.module.css';
import { addResultGroup, editResultGroup } from "../../../redux/reducers/resultReducer";
import Select from "../../../components/Select";
import Api from "../../../helperFunctions/Api2";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import { useNavigate } from "react-router-dom";

const AddResultGroup = () => {
    const [instCategories, setInstCategories] = useState([]);
    const { clearEdit, editing, editType, editData, setTitle } = useContext(ResultContext);
    //const group = useSelector(state => state.results.currentGroup);
    const Sets = useSelector((state) => state.results.sets);
    const [sets, setSets] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [defaultSet, setDefaultSet] = useState('')
    const institutions = useSelector(state => state.questions.user_institutions);
    const [groupTitle, setGroupTitle] = useState('');
    const [description, setDescription] = useState('');
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [defaultGroup, setDefaultGroup] = useState('');

    const handleSubmit = () => {
        if (!defaultSet.id || !groupTitle || !description
            || (defaultSet.institution_id && !defaultGroup.id)) {
            setModalText("Please fill all required fields");
            setModalToggle(true);
            return
        }
        const obj = {
            title: groupTitle,
            description: description,
            result_set_id: defaultSet.id
        }
        if (defaultSet.institution_id) {
            obj.institution_id = defaultSet.institution_id;
            obj.institution_categories = defaultGroup.id;
        }
        if (editing && editType =='result-group') {
            obj.id = editData.id;
        }
        setShowLoading(true);
        Api.PostApi(editing && editType == 'result-group' ? '/result/edit-result-group' :'/result/add-result-group', obj, true)
        .then(result => {
            setShowLoading(false);
            setModalText(result.message);
            setModalToggle(true);
            if (result.isSuccessful || result.isSuccesful) {
                console.log(result);
                dispatch(editing && editType == 'result-group'? editResultGroup({ ...obj,  ...result.data}) :addResultGroup(result.data))
                clearFields();
            }
            })
    }
    function clearFields(){
        setGroupTitle(''); setDescription('');
        setDefaultGroup(''); handleSet('');
        editing && editType == 'result-group' && clearEdit();
        // editing && editType == 'result-group' && navigate('../view-result-group');
    }

    const handleSet = (set) => {
        setDefaultSet(set)
        const inst = institutions.find(inst => inst.id === set.institution_id);
        const categoriesObj = inst? inst.categories:{};
        let categoriesArray = [];
        if (inst && inst.categories && Object.keys(categoriesObj).length > 0) {
            categoriesArray = Object.values(categoriesObj);
        }
        setInstCategories(categoriesArray);
        if (set == '') {
            setSets([...sets])
        }
    }

    useEffect(() => {
        if (Object.values(Sets).length > 0) {
            setSets(Object.values(Sets))
        }
        else setSets([]);
    }, [Sets])

    useEffect(() => {
        setTitle('Add Result Group');
    }, [])
    useEffect(() => {
        if (editing && editType == 'result-group') {
            setGroupTitle(editData.title);
            setDescription(editData.description);
            handleSet(Sets[editData.set_id]);
            if (editData.institution_categories) {
                setDefaultGroup(JSON.parse(editData.institution_categories));
            }
        }
    }, [editType])


    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={ResultCss.mainView}>
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Select Result Sets</span>
                    <Select options={sets} onChange={handleSet} label="title" value="id" defaultValue={defaultSet}/>
                </label>
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Title</span>
                    <input value={groupTitle} onChange={(e) => setGroupTitle(e.target.value)} placeholder='title of the group' className={CategoriesCss.input} />
                </label>
                {defaultSet.institution_id ?
                    <label className={CategoriesCss.label}>
                        <span className={CategoriesCss.isRequired}>Select institution Group</span>
                        <Select onChange={setDefaultGroup} options={instCategories} className={CategoriesCss.fields} label='name' value='id' defaultValue={defaultGroup}/>
                    </label> : 
                    <label className={CategoriesCss.label}>
                        <span className={CategoriesCss.isRequired}>Description</span>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} style={{ outline: 'none', height: 70 }} placeholder='Brief description of the group' className={CategoriesCss.input} />
                    </label>
                }

                <div className={CategoriesCss.buttonContainer}>
                {editing && editType==='result-group' && <button className={`cancelEditButton`} onClick={clearFields} >Cancel Edit</button>}
                    <button className={CategoriesCss.viewBtn} onClick={handleSubmit} >{editing && editType==='result-group' ? 'Save Result Group': 'Add Result Group'}</button>
                </div>
            </div>
        </>
    )
}
export default AddResultGroup;