import { createContext, useContext, useState } from 'react';
import styles from './css/StaffMgmt.module.css';
import titleStyle from './css/SectionTitle.module.css';
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { BsList } from 'react-icons/bs';
import {CreateQuestionCategory,ViewQuestionCategories} from './index';

import { MenuContext } from '../DashBoard';
import Menu from '../../../components/Menu';

export const staffContext = createContext();
export const CategoryContext = createContext();




const Categories = () => {
    const {showMenu, setShowMenu} = useContext(MenuContext);
    const [title, setTitle] = useState('');
    const [view, setView] = useState();
    const [editObject,setEditObject] = useState(null);
    const CategoryContextProvider = {
        editObject,
        setEditObject,
    }
    return (
        <staffContext.Provider value={{ view, setView }}>
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Question Categories</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                       
                        <Menu options={[
                            {label:'Add category',path:'add-category',onClick:()=>{setEditObject(null)}},
                            {label:'View categories',path:'view-categories'}
                        ]} />
                    </div>
                    <div style={{width:'100%'}} onClick={()=>setShowMenu(false)}>
                    <CategoryContext.Provider value={CategoryContextProvider}>
                            <Routes>
                            <Route path='*' element={<CreateQuestionCategory setTitle={setTitle} />} />
                            <Route path='/add-category' element={<CreateQuestionCategory setTitle={setTitle} />} />
                            <Route path='/view-categories' element={<ViewQuestionCategories setTitle={setTitle} />} />
                            <Route path="/*" element={<PageNotFound />} />
                        </Routes>
                    </CategoryContext.Provider>
                    
                    </div>
                </div>
            </div>
        </staffContext.Provider>
    );
};

export default Categories;