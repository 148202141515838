import { createContext, useContext, useEffect, useState } from "react";
import { MenuContext } from "../DashBoard";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { BsList } from "react-icons/bs";

import titleStyle from '../../../css/SectionTitle.module.css';
import styles from '../../../css/StaffMgmt.module.css';
import AddBilling from "./AddBilling";
import ViewBilling from  "./ViewBilling";
import PageNotFound from "../../404/PageNotFound";



export const staffContext = createContext();
const Billing =()=>{
    const {showMenu, setShowMenu} = useContext(MenuContext);
    const [title,setTitle] = useState('');
    const [view, setView] = useState('');
    const location = useLocation()

    useEffect(()=>{
        let path = location.pathname;
        let name = path.split('/')[path.split('/').length -1];
        //console.log(name,path);
        if(name.toLowerCase() == 'billing')setTitle('Billing');
        else if(name.toLowerCase() == 'view')setTitle('View Billing')
        else setTitle('Billing')

    },[location])
    
    return (
        <staffContext.Provider value={{ view, setView }}>
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Billing</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`} >
                                    <ul>
                                        <li><Link to='view-billing'>View Billing</Link></li>
                                        <li><Link to='view-payments'>View Payments</Link></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                        <Routes>
                            <Route path='*' element={<AddBilling />} />
                            <Route path='/view-billing/*' element={<AddBilling />} />
                            <Route path='/view-payments/*' element={<ViewBilling />} />
                            <Route path="/*" element={<PageNotFound />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </staffContext.Provider>
    )
}
export default Billing