import React, { useContext } from 'react';
import logo from '../../images/edu-logo.png';
import logoCss from '../../css/logo.module.css';
import { ModalContext } from '../../App';

const Logo_img = ({ width, smallScreen }) => {
  const { customizeData } = useContext(ModalContext);
  const alt = "logo";
  return (
    <React.Fragment>
      <span className={logoCss.logo_container}>
        <img src={customizeData && customizeData.logoUrl ? customizeData.logoUrl : logo} alt={alt} width={width} />
        {smallScreen ? "" : customizeData && customizeData.displayName ? <span>{customizeData.displayName}</span> : <span>EDUPLUS</span>}

      </span>
    </React.Fragment>
  );
};

Logo_img.defaultProps = {
  width: 35,
};

export default Logo_img;