import styles from './../../../css/Categories.module.css';
import { useContext, useEffect, useRef, useState } from 'react';
import Api from '../../../helperFunctions/Api2';
import Loading from '../../../components/loading';
import Modal from "../../../components/modal";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addUserCategory, editUserCategory } from '../../../redux/reducers/questionReducer';
import { EditContext } from './GroupMgmt';
import Select from '../../../components/Select/index';
import styled from 'styled-components';

// const institutionArr = [
//     {
//         id:1,
//         name:'Golden Academy'
//     },
//     {
//         id:2,
//         name:'Pure Talent',
//     },
//     {
//         id:3,
//         name:'Gorilla Brain'
//     }
// ]

const CreateUserCategory = ({ setTitle }) => {

    const institutionList = useSelector(state => state.questions.user_institutions);
    const paymentLinks = useSelector(store=> store.account.paymentLinks);
    const categoryName = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const message1 = `Please completely fill out all required fields`;
    const message3 = `Action Failed. Please login again!`;

    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [institutionArr, setInstitutoinArr] = useState(institutionList);
    const [paymentArr, setPaymentArr] = useState(Object.values(paymentLinks));
    const [selInstitution, setSelInstitution] = useState('');
    const { editCategory, setEditCategory } = useContext(EditContext);
    const [defaultValue, setDefaultValue] = useState('');
    const [description, setDescription] = useState('');
    const [joinable, setJoinable] = useState(false);
    const [selPayment, setSelPayment] = useState({});
    const [defaultPayment, setDefaultPayment] = useState('');

    useEffect(() => {
        setTitle('Create User Group');
    }, [setTitle]);

    const clearFields = ()=>{
        categoryName.current.value = '';
        setSelInstitution('')
        setEditCategory({});
        setDefaultValue('');
        setDescription('');
        setJoinable(false);
        setSelPayment({});
        setDefaultPayment('')
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        //Call show modal
        const new_category = {
            institution_id: selInstitution.id,
            name: categoryName.current.value,
            description,
            is_joinable: joinable?1:0,
            paymentLink: selPayment.id
        };

        if (!new_category.institution_id || new_category.name === '' || new_category.description === '') {
            setModalText(message1);
            setModalToggle(true);
        }
        else {
            if (editCategory.id) {
                let editedObject = {
                    id: editCategory.id,
                    ...new_category
                }

                if (!Api.checkNetwork()) return;

                setShowLoading(true);
                Api.PostApi('/institution/edit-category', editedObject, true)
                    .then(result => {
                        setShowLoading(false);
                        setModalText(result.message);
                        setModalToggle(true);
                        if (result.isSuccessful) {
                            dispatch(editUserCategory({ ...editCategory, ...editedObject }));
                            clearFields()
                            setTimeout(() => navigate('../view-categories'), 5000)
                        }
                    })
                    .catch(error => {
                        setShowLoading(false);
                        setModalText(message3);
                        setModalToggle(true);
                        sessionStorage.removeItem('eduplus_tkn');
                        navigate("/account/sign-in");
                    })

            }
            else {
                if (!Api.checkNetwork()) return;
                setShowLoading(true);
                Api.PostApi('/institution/add-category', new_category, true)
                    .then(result => {
                        setShowLoading(false);
                        setModalText(result.message);
                         setModalToggle(true);
                        if (result.isSuccessful) {
                            dispatch(addUserCategory(result.data));
                            clearFields()
                        }
                    })
                    .catch(error => {
                        // alert('Action failed, please re-login');
                        setShowLoading(false);
                        setModalText(message3);
                        setModalToggle(true);
                        sessionStorage.removeItem('eduplus_tkn');
                        navigate("/account/sign-in");
                    });

                return false;
            };
        }
    };
    const handleCancel = () => {
        clearFields()
        navigate('../view-categories');
    }

    useEffect(() => {
        if (editCategory.id) {
            let a = institutionArr.find(inst => inst.id === editCategory.institution_id);
            setSelInstitution(a);
            setDefaultValue(a);
            categoryName.current.value = editCategory.name;
            setDescription(editCategory.description);
            setJoinable(editCategory.is_joinable == 1? true: false)
            setDefaultPayment(paymentLinks[editCategory.paymentLink])
            
        } else {
            categoryName.current.value = ''
            setSelInstitution('');
            setDefaultValue('');
        }
    }, [editCategory, institutionArr])

    useEffect(() => {
        setInstitutoinArr(institutionList);
    }, [institutionList])
    useEffect(() => {
        setPaymentArr(Object.values(paymentLinks));
    }, [paymentLinks])

    // useEffect(() => {
    //     console.log(selInstitution)
    // }, [selInstitution])

    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <form className={`${styles.form}`}>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Institution</span>
                    <Select options={institutionArr} onChange={setSelInstitution} defaultValue={defaultValue} value={'id'} label={'name'} disabled={editCategory.id ? true : false} />
                </label>

                <label htmlFor='cat-name' className={`${styles.label}`}>
                    <span className={styles.isRequired}>Group Name</span>
                    <input ref={categoryName} type='text' id='cat-name' className={`${styles.input}`} placeholder='Enter the User Group Name' required />
                </label>
                <label htmlFor='description' className={`${styles.label}`}>
                    <span className={styles.isRequired}>Description</span>
                    <textarea type='text' id='cat-name' className={`${styles.input}`} placeholder='Enter Group description' value={description} onChange={(e) => setDescription(e.target.value)} required />
                </label>
                <CheckLabel>
                    <input type='checkbox' checked={joinable} onChange={() => setJoinable(!joinable)} />
                    <span>Make group joinable</span>
                </CheckLabel>
                {joinable && <label className={`${styles.label}`}>
                    <span >Select Payment Link</span>
                    <Select options={paymentArr} onChange={setSelPayment} defaultValue={defaultPayment} value={'id'} label={'title'} />
                </label>}

                <div className={styles.buttonContainer}>
                    {editCategory.id && <button className={`cancelEditButton`} onClick={() => handleCancel()}>Cancel Edit</button>}
                    <button className={`${styles.saveCatBtn}`} style={{margin:0}} onClick={(e) => handleSubmit(e)}>{editCategory.id ? 'Save Change(s)' : 'Create User Group'}</button>
                </div>
            </form>
        </>
    );
};
const CheckLabel = styled.label`
    display:flex;
    flex:1;
    cursor: pointer;
    @media screen and (max-width:850px){
        &.second{
            flex-direction: row;
        }
        
    }
    
`
export default CreateUserCategory;


/*********************************************************************** */