import { useState, useEffect, useRef, useContext } from "react";
import Loading from "../../../components/loading";
import styles from '../../../css/CreateInst.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from "../../../components/Select";
import { addNote, editNotes } from "../../../redux/reducers/learningReducer";
import { LearningContext } from "./NoteIndex";
import { useNavigate } from "react-router-dom";
import Api from "../../../helperFunctions/Api2";
import TextEditor from "../../../components/TextEditor";

export const userCategory = [
    { id: 0, name: 'Personal' },
    { id: 1, name: 'Everybody with note link' },
    { id: 2, name: 'For Institution' }
];
const AddNote = ({ setTitle }) => {
    const [title, NoteTitle] = useState('');
    const [instCategory, setInstCategory] = useState([]);
    const [institution, selInstitution] = useState({});
    const [category, selCategory] = useState([]);
    const [defCategory, setdefCategory] = useState([]);
    const [showInst, setShowInst] = useState(false);
    const [setUser, setUserCategory] = useState('');
    const Institution = useSelector(state => state.questions.user_institutions);
    const editor = useRef(null);
    const uploadnote = useRef();
    const [content, Content] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const { setModalObject, setModalToggle, editNote, editObj, setEditNote, setEditObj, clearEdit } = useContext(LearningContext);
    const [isCreated, setIsCreated] = useState(true);
    const Navigate = useNavigate();
    const dispatchFunc = useDispatch();
    const paymentLinks = useSelector(store => store.account.paymentLinks);
    const forms = useSelector(store => store.portal.forms);
    const [notePaymentLinks, setNotePaymentLinks] = useState([]);
    const [addPaymentLinks, setAddPaymentLink] = useState(false);
    const [addForm, setAddForm] = useState(false);
    const paylinkId = useRef();
    const formId = useRef();

    const clearInputs = () => {
        NoteTitle('');
        Content('Start writing');
        setEditObj({});
        setEditNote(false);
        setInstCategory([]);
        selInstitution({});
        selCategory([]);
        setShowInst(false);
        setUserCategory('');
        setdefCategory([]);
        setAddForm(false);
        setAddPaymentLink(false);
    };

    const style = {
        height: 400,
        width: '100%',
        readonly: false
    };
    //this function was created to validate the value of the inputs before
    //going to the datebase.
    const validateInput = (value, body) => {
        if (value === null || value === '' || value === 'undefined') {
            setModalObject({ header: '', body: `Please Set ${body}`, footer: '' });
            setModalToggle(true);
            return false;
        }
        return true;
    };

    //this function validates select before going to the database.
    const validateSelect = (value, body) => {
        if (Object.keys(value).length <= 0) {
            setModalObject({ header: '', body: `Please Set ${body}`, footer: '' });
            setModalToggle(true);
            return false;
        }
        else if (value.length <= 0) {
            setModalObject({ header: '', body: `Please Set ${body}`, footer: '' });
            setModalToggle(true);
            return false;
        }
        return true;
    };

    //passing the value of the input inside the func()
    //was done here
    const NoteCreation = (e) => {
        e.preventDefault();
        if (!validateInput(title, 'Note Title')) {
            return false;
        }
        if (!validateSelect(setUser, 'The Note Category')) {
            return false;
        }
        if (showInst && (!validateSelect(category, 'the group for institution selected'))) {
            return false;
        }
        if (showInst && (!validateSelect(institution, 'the Institution'))) {
            return false;
        }
        if (editNote) {
            if (!isCreated)
                if (uploadnote.current && uploadnote.current.files.length > 0) {
                    if (uploadnote.current.files[0].type !== 'application/pdf') {
                        alert("Only pdf files are allowed"); return;
                    }
                }

            const allCategories = [];
            for (let i = 0; i < category.length; i++) allCategories.push(category[i].id);
            const formObject = new FormData();
            formObject.append('id', editObj.id);
            formObject.append('title', title);
            formObject.append('audience', Number(setUser.id));
            formObject.append('note', isCreated ? editor.current.value : '');
            if (!isCreated) formObject.append('file', uploadnote.current.files[0]);
            formObject.append('institution_categories', JSON.stringify(allCategories));
            formObject.append('is_created', isCreated ? 1 : 0);

            if (institution.id) formObject.append('institution_id', institution.id);

            if (addPaymentLinks) formObject.append('payment_link_id', paylinkId.current.value);

            if (addForm) formObject.append('form_id', formId.current.value);
            console.log(formObject, "obje");
            setShowLoading(true);
            Api.PostFormData('/learning/edit-note', formObject, true)
                .then(result => {
                    console.log(result);
                    setShowLoading(false);
                    setModalObject({ header: '', body: result.message, footer: '' });
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        dispatchFunc(editNotes(result.data));
                        clearInputs();
                        //clearEdit();
                        // Navigate('../view-notes');
                    };
                })
                .catch(error => {
                    setShowLoading(false);
                    setModalObject({ header: '', body: error.message, footer: '' });
                    setModalToggle(true);
                });
        }
        else {
            if (!isCreated)
                if (uploadnote.current.files[0].type !== 'application/pdf') {
                    alert("Only pdf files are allowed"); return;
                }

            const allCategories = [];
            for (let i = 0; i < category.length; i++) allCategories.push(category[i].id);
            const formObject = new FormData();
            formObject.append('title', title);
            formObject.append('audience', Number(setUser.id));
            formObject.append('note', isCreated ? editor.current.value : '');
            if (!isCreated) formObject.append('file', uploadnote.current.files[0]);
            formObject.append('institution_categories', JSON.stringify(allCategories));
            formObject.append('is_created', isCreated ? 1 : 0);

            if (institution.id) formObject.append('institution_id', institution.id);

            if (addPaymentLinks) formObject.append('payment_link_id', paylinkId.current.value);

            if (addForm) formObject.append('form_id', formId.current.value);

            setShowLoading(true);
            Api.PostFormData('/learning/add-note', formObject, true)
                .then(result => {
                    setShowLoading(false);
                    setModalObject({ header: '', body: result.message, footer: '' });
                    setModalToggle(true);
                    clearInputs();

                    if (result.isSuccessful) {
                        // console.log(result.data)
                        dispatchFunc(addNote(result.data));
                    }
                });

        }
    };


    useEffect(() => {
        setTitle('Add Notes/Ebooks');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (setUser && setUser.id === 2) {
            setShowInst(true);
        }
        else setShowInst(false);
    }, [setUser]);

    useEffect(() => {

        if (institution && institution.id) {
            // console.log(Object.values(institution.categories));
            setInstCategory(Object.values(institution.categories));
        }
        else {
            setInstCategory([]);
        }
    }, [institution]);

    useEffect(() => {
        if (editNote) {
            // console.log(editObj)
            NoteTitle(editObj.title);
            setUserCategory(userCategory.find(obj => obj.id === editObj.audience));
            setIsCreated(editObj.is_created == 1 ? true : false);
            if (editObj.is_created) Content(editObj.note);
            if (editObj.audience === 2) {
                const inst = Institution.find(obj => obj.id === editObj.institution_id);
                let temp_categories = Object.values(inst.categories);
                selInstitution(inst);
                setdefCategory(temp_categories.filter(obj => JSON.parse(editObj.institution_categories).includes(obj.id)));
            }
            if (paymentLinks[editObj.payment_link_id]) {
                setAddPaymentLink(true);
            }
            if (forms.find(obj => editObj.form_id === obj.id)) {
                setAddForm(true);
            }
        }
        else clearInputs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editNote]);

    const cancelEdit = (e) => {
        e.preventDefault();
        clearInputs();
        Navigate('view-notes');
    };

    useEffect(() => {
        //console.log(paymentLinks);
        let notePLinks = [];
        notePLinks = Object.values(paymentLinks);
        setNotePaymentLinks(notePLinks);
    }, [paymentLinks]);

    return (
        <>
            <Loading shouldShow={showLoading} />
            <div className={`${styles.form} ${styles.form2}`}>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Title</span>
                    <input type='text' className={`${styles.fields}`} placeholder='Enter note title ' onChange={(e) => { NoteTitle(e.target.value); }} value={title ? title : ''} />
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Audience Category</span>
                    {<Select options={userCategory} name='learning ' className={styles.selectFields} onChange={setUserCategory} value="id" label="name"
                        defaultValue={setUser} disabled={editNote} reset={editNote} />}
                </label>
                {showInst && //!editNote &&
                    <>
                        <label className={`${styles.label}`}>
                            <span className={styles.isRequired}>Select Institution</span>
                            <Select options={Institution} name='learning Insti' className={styles.selectFields} onChange={selInstitution} value="id" label="name"
                                defaultValue={institution} disabled={editNote}
                            />
                        </label>
                        <label className={`${styles.label}`}>
                            <span className={styles.isRequired}>Add a Group</span>
                            <Select options={instCategory} name='learning ' className={styles.selectFields} onChange={selCategory} multi value="id" label="name"
                                defaultValue={defCategory}
                            />
                        </label>
                    </>
                }
                <label >
                    <input style={{ maxHeight: 15 }} type="radio" checked={isCreated} name="is_created" onChange={() => setIsCreated(true)} /><span>Create Note</span><br />
                    <input style={{ maxHeight: 15 }} type="radio" checked={isCreated ? false : true} name="is_created" onChange={() => setIsCreated(false)} /><span>Upload Ebook</span>
                </label>
                {
                    isCreated ? (
                        <TextEditor
                            text={'Enter your Note'}
                            setShowLoading={setShowLoading}
                            navigate={Navigate}
                            value={content}
                            reff={editor}
                            style={style}
                            handleChange={Content}
                        />
                    ) : (
                        <label className={`${styles.label}`}>
                            <span>Select File {`(only pdf files allowed)`}</span>
                            <input ref={uploadnote} type="file" />
                        </label>
                    )
                }

                <div className={styles.divContainer}>
                    <input style={{ maxHeight: 15 }} type="checkbox" checked={addPaymentLinks} name="require_payment" onChange={() => addPaymentLinks ? setAddPaymentLink(false) : setAddPaymentLink(true)} /><span>Add Payment Link</span>
                </div>

                {
                    addPaymentLinks && (
                        <label className={`${styles.label}`}>
                            <span className={styles.isRequired}>Select Payment Link</span>
                            <select ref={paylinkId} className={styles.selectFields} defaultValue={editNote && editObj.payment_link_id}>
                                {notePaymentLinks.map((paymentLink, index) => (
                                    <option key={paymentLink.id} value={paymentLink.id}>{paymentLink.title}</option>
                                ))}
                            </select>
                            {/*<Select options={paymentLinks} name='Payment Link' className={styles.selectFields} onChange={setPayLink} value="id" label="title"/>*/}
                        </label>
                    )
                }

                <div className={styles.divContainer}>
                    <input style={{ maxHeight: 15 }} type="checkbox" checked={addForm} name="require_form" onChange={() => addForm ? setAddForm(false) : setAddForm(true)} /><span>Add Form</span>
                </div>

                {
                    addForm && (
                        <label className={`${styles.label}`}>
                            <span className={styles.isRequired}>Select Form</span>
                            <select ref={formId} className={styles.selectFields} defaultValue={editNote && editObj.form_id}>
                                {
                                    forms.map((form, index) => (
                                        <option value={form.id} key={index}>{form.title}</option>
                                    ))
                                }
                            </select>
                        </label>
                    )
                }

                <div className={`${styles.addBtnDiv} ${styles.div}`}>
                    {editNote ?
                        <>
                            <button type="submit" className='cancelEditButton'
                                onClick={(event) => { cancelEdit(event); }}>Cancel Edit
                            </button>
                            <button type="submit" className={styles.addBtn}
                                onClick={(event) => { NoteCreation(event); }}>Save Changes
                            </button>
                        </>
                        :
                        <button type="submit" className={styles.addBtn}
                            onClick={(event) => { NoteCreation(event); }}>Add Note
                        </button>
                    }
                </div>
            </div>
        </>
    );
};

export default AddNote;