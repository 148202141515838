import { useContext, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styling from '../../../css/Categories.module.css';
import HTMLReactParser from 'html-react-parser';
import style from '../Calender/Css/event.module.css';
import styles from '../../../css/CreateInst.module.css';
import { BsXLg } from 'react-icons/bs';
import { FaEdit, FaPaperPlane, FaRegTrashAlt } from 'react-icons/fa';
import { MenuContext } from '../DashBoard';
import TextEditor from "../../../components/TextEditor";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Api from "../../../helperFunctions/Api2";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import PageNumber from '../../../components/PageNumber';
import { LearningContext } from './AssignmentIndex';
import { BiCommentAdd } from 'react-icons/bi';
import { addAssignmentComment, addAssignmentScore } from '../../../redux/reducers/learningReducer';
const NO_OF_ITEMS_PER_PAGE = 30;

const UsersAssignment = ({ setTitle }) => {
    const Assignments = useSelector(state => state.learning.assignments);
    const institutions = useSelector(state => state.questions.user_institutions);
    const [assindex, setAssIndex] = useState(0);
    const [usersAssignment, setUsersAssignment] = useState([]);
    const [institutionUsers, setInstitutionUsers] = useState(null);
    const { selected, changeSelected } = useContext(MenuContext);
    const [indx, chgInd] = useState(-1);
    const [indx2, chgInd2] = useState(-1);
    const Navigate = useNavigate();
    const [content, setContent] = useState([]);
    const editor = useRef('editor');
    const [showLoading, setShowLoading] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);
    const { editAssignment, editAssObj, clearEdit } = useContext(LearningContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (assindex > 0) {
            const newAss = Assignments.filter(x => x.id == assindex);
            setUsersAssignment(newAss[0].users);
            //console.log(newAss[0].users, newAss);

            const newInst = institutions.filter(x => x.id == newAss[0].institution_id);
            setInstitutionUsers(newInst[0].users);
            //console.log(newInst,'institution users')
        }
    }, [assindex, Assignments]);

    useEffect(() => {
        setTitle('Users Assignments/Tasks');
    }, []);
    useEffect(() => {
        if (editAssignment && editAssObj.type == 'score') {
            setAssIndex(editAssObj.id);
            setTimeout(() => clearEdit(), 5000);
        }
    }, [editAssignment, editAssObj]);
    useEffect(() => {
        if (usersAssignment.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((usersAssignment.length / NO_OF_ITEMS_PER_PAGE) + ((usersAssignment.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0));
        }
    }, [usersAssignment.length]);

    const handleChange = (userassignment) => {
        if (userassignment) {
            changeSelected(userassignment.id);
        }
        else {
            changeSelected(null);
        }
    };

    const showScoreModal = (userAssignment, assignment_id) => {
        // console.log(userAssignment, assignment_id);
        const Jsx = () => {
            const [score, setScore] = useState(userAssignment.score || 0);
            const handleScore = (event) => {
                const payload = {
                    id: userAssignment.id,
                    assignment_id,
                    score
                };
                event.preventDefault();
                setShowLoading(true);
                Api.PostApi('/learning/score-assignment', payload, true)
                    .then(result => {
                        setModalText(result.message);
                        setModalToggle(true);
                        // console.log(result,'response')
                        if (result.isSuccessful) {
                            dispatch(addAssignmentScore(payload));
                        }
                    })
                    .finally(() => {
                        setShowLoading(false);
                    });
            };

            return <>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Score</span>
                    <input type='number' className={`${styles.fields}`} onChange={(e) => { setScore(e.target.value); }} value={score} />
                </label>
                <button type="submit" className={styles.addBtn} onClick={(event) => { handleScore(event); }}>Score Assignment</button>

            </>;
        };
        setModalText(<Jsx />);
        setModalToggle(true);
    };

    const showCommentModal = (userAssignment, assignment_id) => {
        // console.log(userAssignment, assignment_id);
        const Jsx = () => {
            const [comment, setComment] = useState(userAssignment.comment || '');
            const handleComment = (event) => {
                const payload = {
                    id: userAssignment.id,
                    assignment_id,
                    comment
                };
                event.preventDefault();
                setShowLoading(true);
                Api.PostApi('/learning/add-assignment-comment', payload, true)
                    .then(result => {
                        setModalText(result.message);
                        setModalToggle(true);
                        // console.log(result,'response')
                        if (result.isSuccessful) {
                            dispatch(addAssignmentComment(payload));
                        }
                    })
                    .finally(() => {
                        setShowLoading(false);
                    });
            };

            return <>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Comment</span>
                    <textarea type='text' className={`${styles.fields}`} onChange={(e) => { setComment(e.target.value); }} value={comment} />
                </label>
                <button type="submit" className={styles.addBtn} onClick={(event) => { handleComment(event); }}>Add Comment</button>

            </>;
        };
        setModalText(<Jsx />);
        setModalToggle(true);
    };

    const handleDelete = (userAssignment) => {

    };

    const handleOpen = (index) => {
        if (index >= 0) {
            chgInd(index);
            chgInd2(-1);
        }
        else {
            chgInd(-1);
        }
    };

    const handleOpen2 = (index) => {
        if (index >= 0) {
            chgInd2(index);
            chgInd(-1);
        }
        else {
            chgInd2(-1);
        }
    };

    const AssignmentSubmission = (event) => {
        event.preventDefault();

    };

    const setArrayContent = (index, text) => {
        let allContent = content;
        allContent[index] = text;
        setContent(allContent);
    };

    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={styling.form}>
                <span>Select Assignment</span>
                <select className={styling.selinp} onChange={(e) => setAssIndex(Number(e.target.value))} value={assindex}>
                    <option value={0}>Select Assignment</option>
                    {
                        Assignments.map((assignment, index) => (
                            <option key={index} value={assignment.id}>{assignment.title}</option>
                        ))
                    }
                </select><br />
                <div className={styling.viewQuestionContainer}>
                    {
                        usersAssignment.length > 0 && (
                            usersAssignment.map((userAssign, index) =>
                                index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                                (institutionUsers[`${userAssign.subuser_id}`] &&
                                    <div style={{ minHeight: '140px' }} className={styling.eachQuestion} key={userAssign.id}>
                                        <div>
                                            <div>
                                                <span>User name: </span><span className={styling.eachQuestionValue}>{institutionUsers ?
                                                    institutionUsers[`${userAssign.subuser_id}`].firstName + ' ' + institutionUsers[`${userAssign.subuser_id}`].lastName
                                                    : ''}</span>
                                            </div>
                                            {
                                                userAssign.score !== 0 ? (
                                                    <div>
                                                        <span>Score: </span>
                                                        <span className={styling.eachQuestionValue}>{userAssign.score}</span>
                                                    </div>
                                                ) : (<div></div>)
                                            }
                                            {
                                                userAssign.should_redo == 0 ? (
                                                    <div>
                                                        <span>Can Redo Assignment: </span>
                                                        <span className={styling.eachQuestionValue}>{userAssign.should_redo ? 'True' : 'False'}</span>
                                                    </div>
                                                ) : (<div></div>)
                                            }
                                        </div>

                                        <span className={style.eventSpan} onClick={() => { handleChange(userAssign); }}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                        <div style={userAssign.id === selected ? { display: 'flex' } : { disyplay: 'none' }}>
                                            <span onClick={() => { handleChange(); }}><BsXLg /></span>
                                            {/* <button onClick={() => { handleDelete(userAssign) }}><FaRegTrashAlt /> Delete</button> */}
                                            <button onClick={() => { showCommentModal(userAssign, assindex); }}><BiCommentAdd /> Comment </button>
                                            <button onClick={() => { showScoreModal(userAssign, assindex); }}><FaEdit /> Score</button>
                                            {/*<button onClick={() => { handleScore(eachassignment) }}> Score</button> */}
                                        </div>

                                        {indx != index ? <button className={style.eventBtn} onClick={() => { handleOpen(index); }}>Show Submission ...</button> : <button className={style.eventBtn} onClick={() => { handleOpen(); }}>Show less ...</button>}

                                        {indx2 != index ? <button className={style.eventBtn2} onClick={() => { handleOpen2(index); }}>Take Assignment ...</button> : <button className={style.eventBtn2} onClick={() => { handleOpen2(); }}>Show less ...</button>}
                                        <div style={indx == index ? { display: 'flex', gap: '10px' } : { display: 'none' }} className={style.moreDetails}>
                                            <div>
                                                <span>Comment: </span>
                                                <span className={styling.eachQuestionValue}>{userAssign.comment}</span>
                                            </div>
                                            {
                                                userAssign.submission ? (
                                                    <div>
                                                        <span>Submitted Assignment: </span>
                                                        <div>
                                                            {HTMLReactParser(userAssign.submission)}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <span>Submitted Assignment: </span>
                                                        <span className={styling.eachQuestionValue}>No submission available.</span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div style={indx2 == index ? { display: 'flex', width: '100%' } : { display: 'none' }} className={style.moreDetails}>
                                            <div>
                                                <TextEditor
                                                    text={'Enter Submission'}
                                                    setShowLoading={setShowLoading}
                                                    navigate={Navigate}
                                                    value={content[index] ? content[index] : 'start writing your assignment submission'}
                                                    reff={editor}
                                                    style={style}
                                                    handleChange={setArrayContent}
                                                    txIndex={index}
                                                />
                                            </div>

                                            <div className={`${styles.addBtnDiv} ${styles.div}`}>
                                                <button type="submit" className={styles.addBtn}
                                                    onClick={(event) => {
                                                        if (window.confirm('Are you sure you want to submit this assignment?')) {
                                                            setShowLoading(true);
                                                            const obj = {
                                                                id: userAssign.id,
                                                                submission: content[index] ? content[index] : ''
                                                            };
                                                            console.log(obj, index);
                                                            Api.PostApi('/learning/submit-assignment', obj, true)
                                                                .then(result => {
                                                                    setShowLoading(false);
                                                                    setModalText(result.message);
                                                                    setModalToggle(true);
                                                                    if (result.isSuccessful) {

                                                                    }
                                                                });
                                                        }
                                                    }}>Submit Assignment
                                                </button><br />
                                            </div>
                                            <br />
                                        </div>
                                    </div>

                                ))
                        )
                    }
                </div>
                {usersAssignment.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

            </div>
        </>
    );
};

export default UsersAssignment;