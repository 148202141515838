import React, { useRef } from "react";
import Loading from "../../../components/loading";
import { LearningContext } from "./OnlineClassindex";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import styles from '../../../css/CreateInst.module.css';
import Select from '../../../components/Select';
import { editOnline_class } from '../../../redux/reducers/learningReducer';
import{addVirtualClass} from '../../../redux/reducers/learningReducer'
import Api from "../../../helperFunctions/Api2";
import TextEditor from "../../../components/TextEditor";
import { UploadedFileOnly } from "../../../components/UploadedFiles";

const userCategory = [
    { id: 0, name: 'Everybody with link' },
    { id: 1, name: 'For Institution' },
    { id: 2, name: 'Everybody with link(public)' }
]

const CreateClass = () => {
    const { setModalObject,setModalToggle, editClass, setEditClass, editClassObj, setView } = useContext(LearningContext);
    const [showLoading, setShowLoading] = useState(false);
    const [title, classTitle] = useState('');
    const [institutionArr, setInstitutionArr] = useState([]);
    const [institution, selInstitution] = useState([]);
    const [description, setDescription] = useState('');
    const [instCategory, setInstCategory] = useState([]);
    const [category, selCategory] = useState([]);
    const [startTime, setStartTime] = useState('');
    //const [endTime, setEndTime] = useState('');
    //const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [setUser, setUserCategory] = useState('');
    const [udel, setUdel] = useState('');
    const [del, setDel] = useState('');
    const [cdel, setCdel] = useState([]);
    const [time, timeset] = useState('');
    const [showInst, setShowInst] = useState(false);
    const [result, setResult] = useState([]);
    const [thumbnail, setThumbnail] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Institution = useSelector(state => state.questions.user_institutions);
    const paymentLinks = useSelector(store => store.account.paymentLinks);
    const [addPaymentLinks, setAddPaymentLink] = useState(false);
    const forms = useSelector(store => store.portal.forms);
    const [assignForm, setAssignForm] = useState(false);
    const [selForm, setSelForm] = useState(null);
    const paylinkId = useRef();

    const validateInput = (value, body) => {
        if (value === null || value === '' || value === 'undefined') {
            setModalObject({ header: '', body: `Please set ${body}`, footer: '' });
            setModalToggle(true);
            return false;
        }
        return true;
    }


    const validateArrayselection = (value, body) => {
        if (Array.isArray(value) && value.length <= 0) {
            setModalObject({ header: '', body: `Please select the  ${body} detail`, footer: '' })
            setModalToggle(true);
            return false;
        }
        else {
            if (Object.keys(value).length <= 0) {
                setModalObject({ header: '', body: `Please select the  ${body} detail`, footer: '' })
                setModalToggle(true);
                return false;
            }
        }
        return true;

    }
    const clearInputs = () => {
        classTitle('');
        setDescription('');
        setStartTime('');
        //setEndTime('');
        setStartDate('');
        //setEndDate('');
        setDel('');
        timeset('');
        setAddPaymentLink(false);
        setThumbnail('');
    }

    const handleClass = (e) => {
        e.preventDefault();
        if (!validateInput(title, 'Title')) {
            return false;
        }
        if (!validateInput(description, 'Class description')) {
            return false;
        }
        if (!validateInput(startTime, 'Class Start Time')) {
            return false;
        }
        // if(!validateInput(endTime, 'Class End Time')) {
        //     return false;
        // }
        if (!validateInput(startDate, 'Class Start Date')) {
            return false;
        }
        // if(!validateInput(endDate, 'Class End Date')) {
        //     return false;
        // }
        if (!validateInput(time, 'Timer')) {
            return false;
        }
        if (showInst) {
            if (!validateArrayselection(institution, 'Institution')) {
                return false;
            }
            if (!validateArrayselection(category, 'User Group')) {
                return false;
            }
        }

        if (editClass) {
            //console.log('object working');
            // classObj = {};
            // classObj.id = editClassObj.id;
            // classObj.title = title;
            // classObj.description = description;
            // classObj.institutions = institution;
            // classObj.categories = category;
            // classObj.start_date =`${startDate} ${startTime}`;
            // const dateSplit = classObj.start_date.split(` `);
            // classObj.startDate = dateSplit[0];
            // classObj.startTime = dateSplit[1];

            const meetingObject = {};
            meetingObject.meeting = {};
            meetingObject.meeting.id = editClassObj.id;
            meetingObject.meeting.title = title;
            meetingObject.meeting.description = description;
            meetingObject.meeting.start_time = `${startDate} ${startTime}`;
            meetingObject.meeting.duration = Number(time);
            let allCategoryId = [];
            meetingObject.categories = [];
            let is_institution = false;
            if (setUser && setUser.id === 3) {
                is_institution = true;
                category.forEach(eCategory => {
                    allCategoryId.push(eCategory.id);
                })
            }
            if (addPaymentLinks) meetingObject.payment_link_id = paylinkId.current.value;
            if (assignForm) meetingObject.form_id = selForm.id;
            meetingObject.categories = allCategoryId;
            meetingObject.meeting.is_institution = is_institution;
            meetingObject.id = editClassObj.id;
            meetingObject.thumbnail = thumbnail;
            console.log(meetingObject);
            setShowLoading(true);
            //console.log(classObj);
            setShowLoading(false);
            setModalObject({ header: '', body: 'class edited successfully', footer: '' });
            setModalToggle(true);
            //dispatch(addClass(classObj));
            dispatch(editOnline_class(meetingObject));
            clearInputs();
        }
        else {
            //console.log('object working');
            let classObj = {};
            classObj.meeting = {}
            //classObj.meeting.id = testId;
            classObj.meeting.title = title;
            classObj.meeting.description = description;
            classObj.meeting.duration = Number(time);
            classObj.meeting.start_time = `${startDate} ${startTime}`;
            classObj.meeting.is_institution = showInst;
            classObj.is_institution = showInst;
            classObj.categories = category.map(cat => cat.id);
            classObj.thumbnail = thumbnail;
            if (showInst) classObj.meeting.institution_id = institution.id;
            if (addPaymentLinks) classObj.payment_link_id = paylinkId.current.value;
            if (assignForm) classObj.form_id = selForm.id;
            //classObj.meeting.end_time =`${endDate} ${endTime}`;
            //const dateSplit = classObj.start_date.split(` `);
            //const timeSplit = classObj.end_time.split(` `);
            //classObj.startDate = dateSplit[0];
            //classObj.startTime = dateSplit[1];


            setShowLoading(true);

            Api.PostApi('/learning/add-class-meeting', classObj, true)
                .then(result => {
                    setShowLoading(false);
                    setModalObject({ header: '', body: result.message, footer: '' });
                    setModalToggle(true);
                    console.log(result,'response')
                    //if(result.link2) classObj.link = result.link2;
                    console.log(classObj, 'class object')
                    if (result.isSuccessful) {
                        clearInputs();
                        dispatch(addVirtualClass({
                            title: classObj.meeting.title,
                            description: classObj.meeting.description,
                            status: 0,
                            id: result.data.id,
                            link: result.data.link,
                            is_institution: classObj.meeting.is_institution ? 1:0,
                            start_time: classObj.meeting.start_time,
                            duration: classObj.meeting.duration,
                            attendance: [],
                            payment_link_id: addPaymentLinks ? paylinkId.current.value : null,
                            form_id: assignForm ? selForm.id : null,
                            category: JSON.stringify(classObj.categories),
                            thumbnail,
                        }));
                    }
                })
            //console.log(classObj);
            //navigate("../view-class")
        }

    }

    /***** COMPONENTDIDMOUNT && COMPONENTDIDUPDATE FUNCTIONS */
    useEffect(() => {
        setView('Create Live Class');
    }, [setView])
    useEffect(() => {
        if (Institution) {
            setInstitutionArr(Institution);
            //console.log(Institution);
        }
        else {
            setInstitutionArr([{ id: 0, name: 'No institution added yet' }])
        }
    }, [Institution])

    useEffect(() => {
        if (institution && institution.id) {
            let resultArr = [];
            let arr;
            console.log(institution);

            arr = Object.keys(institution.categories);
            if (arr.length > 0) {
                for (const category in institution.categories) {
                    resultArr.push(institution.categories[category]);
                    //console.log(inst.categories[category])
                }
            }
            //    resarr = Object.values(inst.categories)

            setResult(resultArr);
        }
    }, [institution])

    useEffect(() => {
        if (result && result.length > 0) {

            setInstCategory(result);

        }
    }, [result])

    useEffect(() => {
        if (editClassObj.id) {
            //console.log(editClassObj);
            setEditClass(true);
            classTitle(editClassObj.title);
            setDescription(editClassObj.description);
            setStartDate(editClassObj.startDate);
            setStartTime(editClassObj.startTime);
            timeset(editClassObj.duration);
            setCdel(editClassObj.categories);
            // console.log(editClassObj.institutions);
            setDel(editClassObj.institutions);
            setCdel(editClassObj.categories);
            // console.log(editClassObj.categories);
            setThumbnail(editClassObj.thumbnail)
            if (paymentLinks[editClassObj.payment_link_id]) {
                setAddPaymentLink(true);
            }
            if (editClassObj.form_id) {
                setAssignForm(true);
                setSelForm(forms.find(form => form.id === editClassObj.form_id));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editClassObj])

    useEffect(() => {
        //console.log(del);
        //  console.log(instCategory,cdel);
    }, [del, cdel])

    useEffect(() => {
        //console.log(instCategory,cdel);

    }, [instCategory])

    useEffect(() => {
        //console.log(setUser,"users");

        if (setUser.id === 1) {
            setShowInst(true);
        }
        else setShowInst(false)

    }, [setUser])
    /*** ENDED HERE */
    return (
        <>
            <Loading shouldShow={showLoading} />
            <form className={`${styles.form} ${styles.form2}`}>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Title</span>
                    <input type='text' className={`${styles.fields}`} placeholder='Enter Class Name ' onChange={(e) => { classTitle(e.target.value) }} value={title ? title : ''} />
                </label>
                <TextEditor
                    text={'Class Description'}
                    setShowLoading={setShowLoading}
                    navigate={navigate}
                    value={description}
                    handleChange={setDescription}
                />
                <label className={`${styles.label2}`}>
                    <span className={styles.isRequired}>Start Date</span>
                    <input name='eventstartdate' value={startDate ? startDate : ''} className={`${styles.fields}`} type='date' onChange={(e) => { setStartDate(e.target.value) }} />
                </label>
                <label className={`${styles.label2}`}>
                    <span className={styles.isRequired}>Start Time</span>
                    <input name='eventstarttime' value={startTime ? startTime : ''} className={`${styles.fields}`} type='time' onChange={(e) => { setStartTime(e.target.value) }} />
                </label>
                <label className={`${styles.label2}`}>
                    <span className={styles.isRequired}>Duration</span>
                    <input className={`${styles.fields}`} type='number' value={time ? time : ''} placeholder="time in minutes" min='0' onChange={(e) => { timeset(e.target.value) }} />
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Audience Type</span>
                    {<Select options={userCategory} name='learning ' className={styles.selectFields} onChange={setUserCategory} defaultValue={udel} value="id" label="name" />}
                </label>
                {showInst &&
                    <>
                        <label className={`${styles.label}`}>
                            <span>Select Institution</span>
                            {<Select options={institutionArr} name='learning Insti' className={styles.selectFields} onChange={selInstitution} defaultValue={del} value="id" label="name" />}
                        </label>
                        <label className={`${styles.label}`}>
                            <span>Select User Group</span>
                            {<Select options={instCategory} name='learning ' className={styles.selectFields} onChange={selCategory} defaultValue={cdel} multi value="id" label="name" />}
                        </label>
                    </>

                }
                <label className={`${styles.label}`}>
                    <UploadedFileOnly type="image" title='Video thumbnail' setShowLoading={setShowLoading} onCopy={setThumbnail} value={thumbnail} labelStyle={{ marginBottom: 'auto', paddingRight: '25px' }} />
                    <input className={`${styles.fields}`} type='text' placeholder="Enter image link" value={thumbnail} onChange={(e) => setThumbnail(e.target.value)} />
                </label>
                <div className={styles.divContainer}>
                    <input style={{ maxHeight: 15 }} type="checkbox" checked={addPaymentLinks} name="require_payment" onChange={() => addPaymentLinks ? setAddPaymentLink(false) : setAddPaymentLink(true)} /><span>Add Payment Link</span>
                </div>
                {
                    addPaymentLinks && (
                        <label className={`${styles.label}`}>
                            <span className={styles.isRequired}>Select Payment Link</span>
                            <select ref={paylinkId} className={styles.selectFields} defaultValue={editClass && editClassObj.payment_link_id}>
                                {Object.values(paymentLinks).map((paymentLink) => (
                                    <option key={paymentLink.id} value={paymentLink.id}>{paymentLink.title}</option>
                                ))}
                            </select>
                            {/*<Select options={paymentLinks} name='Payment Link' className={styles.selectFields} onChange={setPayLink} value="id" label="title"/>*/}
                        </label>
                    )
                }
                <div className={styles.divContainer}>
                    <input style={{ maxHeight: 15 }}  type='checkbox' checked={assignForm} name="attach_form" onChange={() => setAssignForm(!assignForm)} />
                    <span>Attach Form</span>
                </div>
                {
                    assignForm &&
                    <label className={styles.label}>
                        <span className={styles.isRequired}>Form</span>
                        <Select name='form' options={forms} onChange={setSelForm} defaultValue={selForm} className={styles.fields} label='title' value='id' />
                    </label>

                }
                <div className={`${styles.addBtnDiv} ${styles.div}`}>
                    {editClass ?
                        <span style={{ display: 'flex', gap: '30px' }}>
                            <button onClick={() => { clearInputs(); navigate('../view-class') }} className='cancelEditButton'>Cancel Edit</button>
                            <button type="submit" className={styles.addBtn}
                                onClick={(event) => { handleClass(event) }}>Edit Class
                            </button>
                        </span>
                        :
                        <button type="submit" className={styles.addBtn}
                            onClick={(event) => { handleClass(event) }}>Create Class
                        </button>
                    }

                </div>
            </form>

        </>
    );
}

export default CreateClass;