import React,{useState,useEffect,createContext,useContext} from 'react';
import styles from '../../../css/CreateInst.module.css';
import eventstyle from './Css/event.module.css'
import { eventModalContext } from './smsSettings';
import { useSelector,useDispatch } from 'react-redux';
import Loading from '../../../components/loading';

const AddContact = ({seTTitle}) => {
    const [showLoading, setShowLoading] = useState(false);
    const [smsTitle, setSmsTitle] = useState('');
    const {setModalObject,setModalTogglee} = useContext(eventModalContext);
    const dispatch = useDispatch(); 

    let testId = Math.floor(Math.random()*1000 + 1 );


    const validateValue = (value, body) => {
        if(value === null || value === '' || value === 'undefined' ){
            setModalObject({header:'SMS Group  List could not be created',body:`PLEASE SET ${body}`,footer:''});
            setModalTogglee(true);
            return false;
       }
       return true;
    }
    useEffect(()=> {
        seTTitle('Add Contact')
    })

    const handleSubmit = (event) => {
        event.preventDefault()
        if(!validateValue(smsTitle, 'Group Title')) {
            return false
        }
        else{
            const contact = {};
            contact.groupName = smsTitle;
            contact.id = testId;

            setShowLoading(true);
            setShowLoading(false);
            setModalObject({header:'SMS Group', body:'SMS GROUP CREATED SUCCESSFULLY', footer:''});
            //console.log(contact);
            setSmsTitle('');
        }
         


    }

    
    return ( 
        <>
            <Loading  shouldShow={showLoading}/>
            <form className={`${styles.form} ${styles.form2}`} >
            <label className={`${styles.label}`}>
                <span className={styles.isRequired}>SMS Group Name</span>
                <input name='groupName' value={smsTitle ?smsTitle:'' } className={`${styles.fields}`} type='text' placeholder="Group Name" onChange={(e)=>{setSmsTitle(e.target.value)}}/>
            </label>
            <div className={`${styles.addBtnDiv} ${styles.div}`}>
                <button type="submit" className={styles.addBtn} 
                onClick={(event) => {handleSubmit(event)} }>Add Sms Group</button>
            </div>
            </form>
        </>
    );
}
 
export default AddContact;