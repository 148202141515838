import React from 'react'
import styled from 'styled-components'

export const Btn = (props) => {
    const children = props.children || props.label || 'Button';


  return (
    <button {...props} type="submit">{children}</button >
  )
}


export const Button = styled(Btn)`
    width:fit-content;
    padding:12px 25px;
    border:1px solid ${props => props.border || 'var(--secondary-color)' };
    color:${props => props.color || "var(--white-color)"};
    background-color: ${props => props.bg || 'var(--secondary-color)'};
    outline:none;
    border-radius: 8px;
    &:hover{
      background-color: ${props => props.hBg || 'var(--white-color)'};
      color : ${props => props.hColor || 'var(--secondary-color)'};
    }

`
