import { useEffect, useRef, useState,useContext} from 'react';
import styles from './../../../css/CreateInst.module.css';
import Modal from '../../../components/modal';
import Loading from '../../../components/loading';
import { useNavigate } from 'react-router-dom';
import { EditContext } from './Institution';
import { addUserInstitution,editUserInstitution } from '../../../redux/reducers/questionReducer';

import Api from "../../../helperFunctions/Api2";
import { useDispatch } from 'react-redux';

export const timeZones = [
    { name: "Select Time Zone...", value: "-1" },
    { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12.00" },
    { name: "(GMT -11:00) Midway Island, Samoa", value: "-11.00" },
    { name: "(GMT -10:00) Hawaii", value: "-10.00" },
    { name: "(GMT -9:30) Taiohae", value: "-09.833" },
    { name: "(GMT -9:00) Alaska", value: "-09.00" },
    { name: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08.00" },
    { name: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07.00" },
    { name: "(GMT -6:00) Central Time (US & Canada), Mexico City", value: "-06.00" },
    { name: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima", value: "-05.00" },
    { name: "(GMT -4:30) Caracas", value: "-04.833" },
    { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04.00" },
    { name: "(GMT -3:30) Newfoundland", value: "-03.833" },
    { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03.00" },
    { name: "(GMT -2:00) Mid-Atlantic", value: "-02.00" },
    { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01.00" },
    { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "0.00" },
    { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "1.00" },
    { name: "(GMT +2:00) Kaliningrad, South Africa", value: "2.00" },
    { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "3.00" },
    { name: "(GMT +3:30) Tehran", value: "3.833" },
    { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "4.00" },
    { name: "(GMT +4:30) Kabul", value: "4.833" },
    { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "5.00" },
    { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "5:833" },
    { name: "(GMT +5:45) Kathmandu, Pokhara", value: "5.75" },
    { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "6.00" },
    { name: "(GMT +6:30) Yangon, Mandalay", value: "6.833" },
    { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "7.00" },
    { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "8.00" },
    { name: "(GMT +8:45) Eucla", value: "8.75" },
    { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "9.00" },
    { name: "(GMT +9:30) Adelaide, Darwin", value: "9.833" },
    { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "10.00" },
    { name: "(GMT +10:30) Lord Howe Island", value: "10.833" },
    { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "11.00" },
    { name: "(GMT +11:30) Norfolk Island", value: "11.833" },
    { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "12.00" },
    { name: "(GMT +12:45) Chatham Islands", value: "12.75" },
    { name: "(GMT +13:00) Apia, Nukualofa", value: "13.00" },
    { name: "(GMT +14:00) Line Islands, Tokelau", value: "14.00" }
];

const CreateInst = ({ seTTitle }) => {
    
    const [eduSystem, setEduSystem] = useState(false);
    const [instValue, setInstValue] = useState(0);
    const [showLoading, setShowLoading] = useState(false);
    const [applySetting, setApplySetting] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [editing, setEditing] = useState(false);

    const imgView = useRef();
    const imgInput = useRef();
    const instName = useRef();
    const address = useRef();
    const timezone = useRef();
    const motto = useRef();
    const instType = useRef();
    const schType = useRef();
    const addDSettings = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const {editInst,setEditInst} = useContext(EditContext);
    const message2 = `Please completely fill out all required fields`;
    const addImage = () => {
        imgView.current.style.backgroundImage = `url(${URL.createObjectURL(imgInput.current.files[0])})`;
    };
   
    const handleInstitutionChange = () => {
        setInstValue(Number(instType.current.value));
    };

    const handleSubmit = (e) =>{
        e.preventDefault();
        const institution = {
            instName: instName.current.value,
            address: address.current.value,
            timezone: timezone.current.value,
            motto: motto.current.value,
            instType: instType.current.value,
        };
        // console.table(institution);

        if (institution.instName === '' ||
            institution.address === '' ||
            institution.timezone === '-1' ||
            Number(institution.instType) === 1
        ) {
            setModalText(message2);
            setModalToggle(true);
            return false;
        }
        else {
            let form = new FormData();
            form.append('file', imgInput.current.files[0]);
            form.append('name', institution.instName);
            form.append('address', institution.address);
            form.append('timezone', Number(institution.timezone));
            form.append('motto', institution.motto);
            form.append('is_school', institution.instType === 2 ? 1 : 0);
            form.append('school_type', !eduSystem ? 0 : instType.current.value);
            form.append('assign_settings', applySetting ? 1 : 0);

            let editedObject={}
            editedObject.id= 0;
            editedObject.name= institution.instName;
            editedObject.address= institution.address;
            editedObject.timezone= Number(institution.timezone);
            editedObject.motto= institution.motto;
            editedObject.is_school= institution.instType ===2 ? 1 : 0;
            editedObject.school_type= !eduSystem ? 0 : instType.current.value;
            editedObject.assign_settings= applySetting ? 1 : 0;

            setShowLoading(true)
            Api.PostFormData('/institution/add-institution', form, true)
            .then(result=>{
                setShowLoading(false);
                setModalText(result.message);
                setModalToggle(true);
                //console.log(result, 'good')
                if(result.isSuccessful){
                    editedObject.id=result.data.id;
                    editedObject.image = result.data.image;
                    editedObject.users = {}
                    editedObject.category_num = result.data.category_num;
                    editedObject.categories = {}
                    editedObject.created_at = result.data.created_at;
                    
                    dispatch(addUserInstitution(editedObject));
                    clearFields();
                }
            })
            .catch(err => {
                setShowLoading(false);
                //alert("Action failed. Please re-login");
            });
        }
                    
    };
    
    const handleEditSubmit=(e)=>{
        e.preventDefault();
        const institution = {
            
            instName: instName.current.value,
            address: address.current.value,
            timezone: timezone.current.value,
            motto: motto.current.value,
            instType: instType.current.value,
        };
        // console.table(institution);

        if (institution.instName === '' ||
            institution.address === '' ||
            Number(institution.timezone) === -1 ||
            Number(institution.instType) === 1
        ) {
            setModalText(message2);
            setModalToggle(true);
            return false;
        }
        else {
            let form = new FormData();
            form.append('file', imgInput.current.files[0]);
            form.append('name', institution.instName);
            form.append('address', institution.address);
            form.append('timezone', Number(institution.timezone));
            form.append('motto', institution.motto);
            form.append('is_school', institution.instType===2 ? 1 : 0);
            form.append('school_type', !eduSystem ? 0 : schType.current.value);
            form.append('assign_settings', applySetting ? 1 : 0);
            form.append('id', editInst.id);
            
            let editedObject= {};
            editedObject.categories = editInst.categories;
            editedObject.users = editInst.users;
            editedObject.id= editInst.id;
            editedObject.name= institution.instName;
            editedObject.address= institution.address;
            editedObject.timezone= Number(institution.timezone);
            editedObject.motto= institution.motto;
            editedObject.is_school= institution.instType===2 ? 1 : 0;
            editedObject.school_type= !eduSystem ? 0 : schType.current.value;
            editedObject.assign_settings= applySetting ? 1 : 0;
            
            
            setShowLoading(true)
            Api.PostFormData('/institution/edit-institution', form, true)
            .then(result=>{
                setShowLoading(false);
                setModalText(result.message);
                alert(result.message)
                setModalToggle(true);
                //console.log(result, 'good')
                if(result.isSuccessful){ 
                    if(result.data.image)
                      editedObject.image = result.data.image;
                    else editedObject.image = editInst.image; 
                    setEditInst(editedObject);
                    dispatch(editUserInstitution(editedObject));
                    clearFields();
                    navigate('../view-institution');
                    setEditInst({});
                }
            })
            .catch(err => {
                setShowLoading(false);
                setModalText('Action failed. Please check your network connectivity and try again');
                setModalToggle(true);
                //alert("Action failed. Please try again or re-login");
                //sessionStorage.removeItem('eduplus_tkn');
                //navigate("/account/sign-in");
            });
        };
    }

    const clearFields = (()=>{
        if (editing) {
            setEditInst({});
            setEditing(false);
        }
        instName.current.value='';
        address.current.value='';
        timezone.current.value='';
        motto.current.value='';
        instType.current.value='1';
        setInstValue(0);
        setApplySetting(false);
        imgView.current.style.backgroundImage = ``;
        imgInput.current.files[0] = undefined;
    })

    useEffect(() => {
        seTTitle('Create Institution');
    }, [seTTitle]);

    useEffect(() => {
        if (instValue === 2) {
            setEduSystem(true);
        } else {
            setEduSystem(false);
        }
         
    }, [instValue]);

    useEffect(()=>{
        if (editInst.id) {
            console.log(editInst)
            setEditing(true);
            instName.current.value=editInst.name;
            address.current.value=editInst.address;
            timezone.current.value=editInst.timezone.toFixed(2);
            motto.current.value=editInst.motto;
            if (editInst.is_school) {
                setInstValue(2);
                setEduSystem(true);
                instType.current.value='2';
                
            }
            else {instType.current.value='3'};
            editInst.assign_settings?setApplySetting(true):setApplySetting(false);
            imgView.current.style.backgroundImage = editInst.image? `url(http://api.sight-eduplus.com/upload/institution/${editInst.image})`:``
       
        }
         else {
            setEditing(false);
            instName.current.value='';
            address.current.value='';
            timezone.current.value='';
            motto.current.value='';
            if (editInst.is_school) {
                setInstValue(2);
                setEduSystem(true);
                instType.current.value='2';
                
            }
            else {instType.current.value='3'};
            editInst.assign_settings?setApplySetting(true):setApplySetting(false);
        }
    },[editInst])


    useEffect(()=>{
        if (eduSystem && editing) {
            schType.current.value=editInst.school_type.toString();
            addDSettings.current.checked=applySetting?true:false;
        }
    },[applySetting, editInst.school_type, editing, eduSystem])

    
    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <form className={`${styles.form}`} >
                <div className={`${styles.label}`}>
                    Logo
                    <div className={`${styles.preview}`} ref={imgView} />
                    <label className={`${styles.label}`}>
                        <input placeholder='' name='uploadBtn' className={`${styles.uploadBtn}`} type='file' ref={imgInput} onChange={addImage} accept="image/*" />
                    </label>
                </div>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Name</span>
                    <input placeholder='' name='instName' className={`${styles.fields}`} type='text' ref={instName} />
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Address</span>
                    <textarea name='address' className={`${styles.fields}`} rows='3' ref={address} />
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Time Zone</span>
                    <select name='timezone' className={`${styles.fields}`} ref={timezone} >
                        {
                            timeZones.map((zone, index) =>
                                <option key={index} value={zone.value}>{`${zone.name}`}</option>
                            )
                        }
                    </select>
                </label>
                <label className={`${styles.label}`}>
                    Motto
                    <input placeholder='' name='motto' className={`${styles.fields}`} type='text' ref={motto} />
                </label>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Institution Type</span>
                    <select name='instType' className={`${styles.fields}`} ref={instType} onChange={handleInstitutionChange} >
                        <option value='1'>
                            Select Type...
                        </option>
                        <option value='2'>
                            Educational Institution
                        </option>
                        <option value='3'>
                            Other
                        </option>
                    </select>
                </label>
                {/*eduSystem &&
                    <>
                        <label className={`${styles.label}`}>
                            <span className={styles.isRequired}>School Type</span>
                            <select name='instType' className={`${styles.fields}`} ref={schType} >
                                <option value='0'>
                                    Select Type...
                                </option>
                                <option value='1'>
                                    K12
                                </option>
                                <option value='2'>
                                    Primary/Secondary School
                                </option>
                                <option value='3'>
                                    College
                                </option>
                                <option value='4'>
                                    Polytechnic
                                </option>
                                <option value='5'>
                                    University
                                </option>
                            </select>
                        </label>
                        <label className={`${styles.label} ${styles.addDSettings}`} htmlFor={'addDSettings'}>
                        <span>Add Default School Settings?</span>
                            <input placeholder='' className={`${styles.input}`} onClick={(e)=>{
                                if(e.target.checked) setApplySetting(true);
                                else setApplySetting(false);
                            }} type='checkbox' id='addDSettings' ref={addDSettings} name='addDSettings' />
                        </label>
                    </>
                */}
                <div className={`${styles.addBtnDiv} ${styles.div}`}>{
                !editing?
                    <button type='submit' className={styles.addBtn} onClick={(e) => {
                        handleSubmit(e)}}>
                        Create Institution
                    </button>
                :
                <span style={{display:'flex', gap:'30px'}}>
                    <button onClick={()=>{setEditInst({}); navigate('../view-institution')}} className='cancelEditButton'>Cancel Edit</button>
                    <button type='submit' className={styles.addBtn} onClick={(e) => {
                        handleEditSubmit(e)}}>
                        Save Institution Edit
                    </button>
                </span>
                    }
                </div>
            </form>
        </>
    );
};

export default CreateInst;