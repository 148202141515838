import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './../../css/PageNotFound.module.css';


const PageNotFound = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/404');
    }, []);

    return (
        <div className={`${styles.parent}`}>
            <section className={`${styles.banSec}`}>
                <div></div>
                <div>
                    <h1>Oops!!!</h1><br />
                </div>
                <div>
                    <h2>The page you are trying to reach does not exist.
                        Click the button below to return to the Home page
                    </h2>
                </div>
                <div>
                    <Link to="/">Go Home</Link>
                </div>
            </section>
        </div>
    );
};

export default PageNotFound;