import { daysOfWeek, monthOfTheYear, getCalendarDays, formatDate, getWeekDays } from '../../helperFunctions/calendar';
import styled from 'styled-components';
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../views/userAdmin/DashBoard';
import styles from '../../css/CreateInst.module.css';
import { BiTrashAlt } from 'react-icons/bi';


const Calendar = ({ bookings, setBookings, editingDay, deleteTime }) => {
    const date = new Date();
    const [currentDate, setCurrentDate] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() });
    const { setModalText, setModalToggle, modalToggle } = useContext(MenuContext);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [selDay, setSelDay] = useState('');

    function handleCurrentDate (object) {
        if (object.month === -1) {
            object.month = 11;
            object.year = currentDate.year - 1;
        } else if (object.month === 12) {
            object.month = 1;
            object.year = currentDate.year + 1;
        }
        setCurrentDate({ ...currentDate, ...object });
    }
    function handleBookings (day) {
        // console.log(day, currentDate, date.getDate());
        setSelDay(`${currentDate.year}-${currentDate.month}-${day.dayNum}`);
        if (!bookings[`${currentDate.year}-${currentDate.month}-${day.dayNum}`]) {
            addTime(`${currentDate.year}-${currentDate.month}-${day.dayNum}`);
        }
        setModalToggle(true);
    }

    function addTime (bookedDayKey) {
        let bk = bookings[bookedDayKey] ? bookings[bookedDayKey] : [];
        bk.push({ start_time: '', end_time: '', slots: 0 });
        setBookings({ ...bookings, [bookedDayKey]: bk });
    }
    function handleValue (bookedDayKey, index, key, value) {
        let bk = bookings[bookedDayKey];
        bk[index][key] = value;
        setBookings({ ...bookings, [bookedDayKey]: bk });
    }

    useEffect(() => {
        const handleScreenSize = () => {
            setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', handleScreenSize);
        handleScreenSize();
        return () => window.removeEventListener('resize', handleScreenSize);
    }, []);
    useEffect(() => {
        modalToggle && setModalText(
            <ShowTimes bookedDayKey={selDay} bookings={bookings} addTime={addTime} deleteTime={deleteTime} handleValue={handleValue} />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalToggle, bookings]);

    return (
        <Wrapper>
            <div className='navbar'>
                <span onClick={() => handleCurrentDate({ month: currentDate.month - 1 })}><MdArrowBackIos /></span>
                <span>{monthOfTheYear[`${currentDate.month}`].name} {currentDate.year}</span>
                <span style={{ textAlign: 'end' }} onClick={() => handleCurrentDate({ month: currentDate.month + 1 })}><MdArrowForwardIos /></span>
            </div>
            <div className='dates'>
                <div>
                    {
                        Object.values(daysOfWeek).map((day, index) =>
                            <span key={index}>{screenSize <= 400 ? day[0] : day}</span>
                        )
                    }
                </div>
                <div>
                    {
                        getCalendarDays(currentDate.year, currentDate.month, bookings, editingDay).map((eachday, index) => (
                            <i key={index}>
                                <span style={eachday.disabled ?{color: 'grey'}:{}} className={`${date.getDate() === eachday.dayNum && date.getMonth() === currentDate.month && date.getFullYear() === currentDate.year ? 'today' : ''}${eachday.isAvailable ? ' booked' : ''}`} onClick={() => !eachday.disabled && handleBookings(eachday)}>
                                    {eachday.dayNum ? `${eachday.dayNum}` : ``}
                                </span>
                                {
                                    ((index + 1) % 7 === 0 && index !== 0) && <br />
                                }
                            </i>

                        ))
                    }
                </div>
            </div>
        </Wrapper>
    );
};

export default Calendar;

export function RepeatCalender ({ bookings, setBookings, recurrency, setRecurrency, editingDay, deleteTime }) {
    const { setModalText, setModalToggle, modalToggle } = useContext(MenuContext);
    const [selDay, setSelDay] = useState('');
    const [screenSize, setScreenSize] = useState(window.innerWidth);


    function handleBookings (day) {
        // console.log(day, currentDate, date.getDate());
        setSelDay(`${day.dayNum}-${day.dayName}`);
        if (!bookings[`${day.dayNum}-${day.dayName}`]) {
            addTime(`${day.dayNum}-${day.dayName}`);
        }
        if (!recurrency[`${day.dayNum}-${day.dayName}`]) {
            setRecurrency({ ...recurrency, [`${day.dayNum}-${day.dayName}`]: 1 });
        }
        setModalToggle(true);
    }

    function addTime (bookedDayKey) {
        let bk = bookings[bookedDayKey] ? bookings[bookedDayKey] : [];
        bk.push({ start_time: '', end_time: '', slots: 0 });
        setBookings({ ...bookings, [bookedDayKey]: bk });
    }
    function handleValue (bookedDayKey, index, key, value) {
        let bk = bookings[bookedDayKey];
        bk[index][key] = value;
        setBookings({ ...bookings, [bookedDayKey]: bk });
    }
    useEffect(() => {
        const handleScreenSize = () => {
            setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', handleScreenSize);
        handleScreenSize();
        return () => window.removeEventListener('resize', handleScreenSize);
    }, []);
    useEffect(() => {
        modalToggle && setModalText(
            <ShowTimes bookedDayKey={selDay} bookings={bookings} addTime={addTime} deleteTime={deleteTime} handleValue={handleValue} recurrency={recurrency} setRecurrency={setRecurrency} />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalToggle, bookings, recurrency]);
    return <Wrapper className='repeat'>
        <div className='dates'>
            <div>
                {
                    getWeekDays(bookings, editingDay).map((day, index) =>
                        <span style={day.disabled ? { color: 'grey' } : {}} className={`${day.isAvailable ? 'booked' : ''}`} key={index} onClick={() => !day.disabled && handleBookings(day)}>{screenSize <= 400 ? day.dayName.slice(0, 2) : day.dayName}</span>
                    )
                }
            </div>
        </div>
    </Wrapper>;
}
export function ShowTimes ({ bookedDayKey, bookings, addTime, deleteTime, handleValue, recurrency, setRecurrency }) {

    useEffect(() => {
        // console.log(bookings);
    }, [bookings]);

    return <Times>
        <label className={`${styles.label}`}>{formatDate(bookedDayKey)}</label>
        <div className={`time`}>
            <label className={`${styles.label2}`}>
                <span className={styles.isRequired}>Start Time</span>
            </label>
            <label className={`${styles.label2}`}>
                <span className={styles.isRequired}>End Time</span>
            </label>
            <label className={`${styles.label2}`}>
                <span className={styles.isRequired}>Slots</span>
            </label>
        </div>
        {bookings[bookedDayKey] && bookings[bookedDayKey].map((time, index) => <div key={index} className='time'>
            <label className={`${styles.label2}`}>
                {/* <span className={styles.isRequired}>Start Time</span> */}
                <input className={`${styles.fields}`} type='time' value={time.start_time} onChange={(e) => handleValue(bookedDayKey, index, 'start_time', e.target.value)} />
            </label>
            <label className={`${styles.label2}`}>
                {/* <span className={styles.isRequired}>End Time</span> */}
                <input className={`${styles.fields}`} type='time' value={time.end_time} onChange={(e) => handleValue(bookedDayKey, index, 'end_time', e.target.value)} />
            </label>
            <label className={`${styles.label2}`}>
                {/* <span className={styles.isRequired}>End Time</span> */}
                <input className={`${styles.fields}`} type='number' value={time.slots} min={0} onChange={(e) => handleValue(bookedDayKey, index, 'slots', e.target.value)} />
            </label>
            <BiTrashAlt className='delete' title='delete' onClick={() => deleteTime(bookedDayKey, index)} />
        </div>)}
        {recurrency && bookings[bookedDayKey].length > 0 && <label className={styles.label} title='No of weeks interval between recurrent appointment'
            style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            Repeat Every
            <input type='number' value={recurrency[bookedDayKey]} onChange={(e) => setRecurrency({ ...recurrency, [bookedDayKey]: e.target.value })}
                style={{ width: '40px', height: 'auto', fontSize: '16px', textAlign: 'center' }} min={1} max={52} /> Weeks
        </label>}
        <button className='add' onClick={() => addTime(bookedDayKey)}>Add Time Duration</button>
    </Times>;
}

const Wrapper = styled.div`
    border: 1px solid #d9d9d9;
    // height: 400px; width: 400px;
    border-radius: 5px;
    .navbar{
        display: flex; flex-direction: row;
        padding: 5px; justify-content: space-between;
    }
    .navbar span{
        display: inline-block;
        &:first-of-type, &:last-of-type{
            cursor: pointer;
        }
    }
    .dates > div{
        padding: 5px;
    }
    .dates > div:first-child{
        border-bottom: 1px solid #d9d9d9;
    }
    .dates span{
        display: inline-block;
        min-width: 40px; cursor: pointer;
        text-align: center;
    }
    .today{
        color: var(--secondary-color);
        background-color: #fff;
    }
    .booked{
        border-radius: 50px;
        font-style: normal;
        background-color: var(--secondary-color);
        color: #fff;
    }
    &.repeat{
        width: 100%;
        .dates > div:first-child{
            display: flex;
            justify-content: space-between;
            padding: 0;
        }
        .dates span{
            width: 13%;
            padding: 5px;
        }
    }
    @media screen and (max-width: 400px){
        width: 100%;
        .dates span{
            min-width: 14%;
            >e{ min-width: 100%;}
        }
        &.repeat .dates span{
            font-size: 15px;
            width: unset;
        }
    }
`;
const Times = styled.div`
    padding-top: 15px;
    position: relative;

    .time{
        display: flex;
        align-items: center;
        >label{width: 38%}
        >label:last-of-type{width: 20%}
    }
    .delete{
        font-size: 20px;
        cursor: pointer;
        color: #4c4c4c;
        min-width: 15px;
        :hover{ color: var(--secondary-color) }
    }
    .add{
        background-color: #fafafa;
        font-size: 15px;
        border: 1px solid var(--border-color);
        padding: 5px;
        border-radius: 5px;
        color: #4c4c4c;
        :hover{
            border: 1px solid var(--secondary-color);
            color: var(--secondary-color);
        }
    }
    >label:first-child{ 
        font-size: 20px;
        top: -20px;
        position: absolute;
        max-width: 90%;
    }
    @media screen and (max-width: 400px){
        .delete{
            position: absolute;
            right: -15px;
            font-size: unset;
        }
        input{
            font-size: 12px;
        }
        .time > label:last-of-type{
            width: 25%;
        }
    }
`;