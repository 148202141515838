import React,{createContext,useState,useEffect} from 'react';
import {Routes,Route,useLocation} from 'react-router-dom';
import PageNotFound from '../404/PageNotFound';
import Modal from '../../components/modal';
import{Header,HomeContent,Footer,Billing,Services,Pricing,Support} from './index';


export const ModalContext = createContext();
export const ScreenContext = createContext();
let counter = 0;


const Home = () => {
  const [modalObject,setModalObject] = useState({});
  const [modalToggle,setModalToggle] = useState(false);
  const location = useLocation();
  const [screenSize,setScreenSize] = useState(window.innerWidth);
  const [smallScreen,setSmallScreen] = useState(false);

  const setCookiesNotice = () =>{
      setModalObject({...modalObject,header:"This website uses cookies",
      body:<><span>This website uses cookies to improve user experience. </span><br/><span>By using our website you consent to all cookies in accordance with our Cookie Policy.</span></>})
  }
  const showCookiesNotice = ()=>{
       setModalToggle(true);
  }
  const closeModal =() =>{
    setModalToggle(false);    
  }

  useEffect(()=>{
    const handleScreenSize = ()=>{
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize',handleScreenSize);
    handleScreenSize();
    return ()=> window.removeEventListener('resize',handleScreenSize);
  },[])

  useEffect(()=>{
    if(screenSize <= 767){
      setSmallScreen(true);
    }
    else{
      setSmallScreen(false);
    }
  },[screenSize])

  useEffect(()=>{
      if(location.pathname.length <= 2 && counter === 0) {
        setCookiesNotice();
        showCookiesNotice();
        counter++;
      }
  },[])
  return (
    <React.Fragment>
      <ModalContext.Provider value={{modalObject,setModalObject,modalToggle,setModalToggle}}>
        <ScreenContext.Provider value={{smallScreen,setSmallScreen,screenSize,setScreenSize}}>
          <Header/>
          <Routes>
              <Route path="/" element={ <HomeContent/>}/>
              <Route path="/pricing" element={<Pricing/>}/>
              <Route path="/services" element={<Services />}/>
              <Route path="/support" element={<Support />}/>
              <Route path="/*" element={<PageNotFound />} />
          </Routes>
          <Footer/>
          <Modal modalObject={modalObject} modalTogglee={modalToggle} closeModal={()=>{closeModal()}}/>
        </ScreenContext.Provider>
      </ModalContext.Provider>  
    </React.Fragment>  
  
  )
}

export default Home