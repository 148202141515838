import { useEffect } from "react";
import { useSelector } from "react-redux";

const ViewPayments = ({ setTitle }) => {
    const paymentlinks = Object.values(useSelector(state => state.account.paymentLinks));

    useEffect(() => {
        setTitle('View Payments')
    },);

    return (
        <>
            <h3>COMING SOON</h3>
        </>
    );
}

export default ViewPayments;