import './css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./views/account/MainLogin";
import Home from './views/user/Home';
import MainForgotPassword from './views/account/MainForgotPassword';
import Policies from './views/account/Policies';
import DashBoard from './views/userAdmin/DashBoard';
import PageNotFound from './views/404/PageNotFound';  
import PaymentStatus from './views/userAdmin/Payments/PaymentStatus';
import Modal from './components/modal';
import { createContext, useEffect, useState } from 'react';

export const ModalContext = createContext();
function App () {

  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [customizeData, setCustomizeData] = useState({});
  const [isCustReady, setIsCustReady] = useState(false);

  const popup = (text)=> {
    setModalText(text);
    setShowModal(true);
  }
  useEffect(()=>{
    if (localStorage.customization && !customizeData.institution_id) {
      setCustomizeData(JSON.parse(localStorage.customization))
      
    }
  },[localStorage.customization, customizeData])

  return (
    <div style={customizeData && customizeData.style? customizeData.style : {}}>
      <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={showModal} closeModal={() => setShowModal(false)} />
      <ModalContext.Provider value={{popup, customizeData, setCustomizeData, isCustReady, setIsCustReady}}>
      <Router>
        <Routes>
          <Route path="account/sign-in" element={<Login defaultMode={true} />} />
          <Route path="account/sign-in/:d" element={<Login defaultMode={true} />} />
          <Route path="account/sign-up" element={<Login defaultMode={false} />} />
          <Route path="account/forgot-password/*" element={<MainForgotPassword />} />
          <Route path="account/change-password/*" element={<MainForgotPassword />} />
          <Route path="policies/*" element={<Policies />} />
          <Route path="account/services/*" element={<DashBoard />} />
          <Route path="account/*" element={<Login defaultMode={true} />} />
          <Route path="/index.html" element={<Home />} />
          <Route path="/payment-status" element={<PaymentStatus />} />
          <Route path="/*" element={<Home />} />
          <Route path="/404" element={<PageNotFound />} />
          
        </Routes>
      </Router>
      </ModalContext.Provider>
    </div>
  );
}

export default App;
