import {useState,useEffect, useContext} from 'react'
import css from './Css/dashboard.module.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {dateObject} from '../../../helperFunctions/Api2';
import BarGraph from '../../../components/Graph/BarGraph';
import VideoPlayer from '../../../components/VideoPlayer';

const Dashboard = () => {
  const events = useSelector(store => store.event.events);
  const {datetime} = useSelector(store => store.account.username);
  const [barData,setData] = useState([]);
  const [showVideo, setShowVideo] = useState(false);

  const arrangeData = (year) =>{
    if(events?.length > 0){
      //const evts = Array.from(events);
      //const arrEvents = evts.sort((a,b) => a.start_date-b.start_date);

      let dataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
    
      events.forEach(event => {
          let d = new Date(event.start_date);
          let vYear = d.getFullYear();
          if(vYear == year){
              dataArr[d.getMonth()]++;
          }
      })
     
     setData(dataArr);
    }  
  }

  useEffect(()=>{
      let d = new Date(datetime);
      let year = d.getFullYear();
      arrangeData(year)
  },[events])

  return (
    <div className={css.container}>
      <div>Calender | Event Dashboard</div>
      <div>
          <div>
              <div>
                 <BarGraph data={barData} sideText={"No. of Events"} bottomText={`Months`} labels={Object.values(dateObject.month)} labelText={`Events | Calendar`}/>
              </div>
              <div>
                <div>EVENT | CALENDER</div>
                <p>
                  Eduplus events & calendar enables users to be able to create & manage events for groups {`(both inside & outside eduplus)`} and institutions created in Sight-eduplus.
                </p>
                <div>
                   <Link to="../events/create-event" style={{backgroundColor:"var(--secondary-color)",color:"var(--white-color)",border:"1px solid var(--secondary-color)"}}>
                    Get Started
                   </Link>
                   <button onClick={()=>setShowVideo(!showVideo)} style={{border:"1px solid var(--secondary-color)",border:"1px solid var(--secondary-color)",backgroundColor:"var(--white-color)"}}>
                    Watch Video
                   </button>
                </div>
              </div>
          </div>
          {showVideo && <VideoPlayer serviceCode={'CEM'}/>}
      </div>
    </div>
  )
}

export default Dashboard