import React,{useEffect, useState,useContext} from 'react'
import css from './chat.module.css';
import Select from '../../../../components/Select';
import { useSelector,useDispatch } from 'react-redux';
import { chatContext } from '.';


// const sampleCategory = [
//     {id:356,name:'Physic Jamb'},
//     {id:484,name:'Mathematics'},
//     {id:53,name:'Html and Css'}
// ]

const username = Number(localStorage.getItem('eduplus_idnty'));
//const username = 27;
//const username = Math.floor(Math.random() * 100000)+1;

// const sampleInstitution = [
//     {id:2,name:"Omolabake"},
//     {id:49,name:"Saint Matthew"}
// ]

const CategoryChat = () => {
  const categories = useSelector(state => state.questions.categories);
  const [rooms,setRooms] = useState([]);
  const [selRoom,setSelRoom] = useState(null);
  const [defaultRoom,setDefaultRoom] = useState(null);
  const {setCurrentRoom} = useContext(chatContext); 
 
  useEffect(()=>{
    const sampleCategory = categories.filter(cate => cate.added_by == username);
    //console.log(sampleCategory);
    setRooms(sampleCategory);
    setDefaultRoom(sampleCategory[0]);
  },[categories])

  useEffect(()=>{
    if(selRoom && selRoom.id){
        setCurrentRoom(selRoom.id)
    }
  },[selRoom])

  // useEffect(()=>{
  //   console.log(categories);
  // },[categories])


  return (
    <div className={css.contentDiv}>
        <label>
            <span>Select Chat Category</span>
            <Select placeholder='Select Chat Category' defaultValue={defaultRoom} options={rooms} onChange={setSelRoom} label='name' value='id' />
        </label>
        {/* <ChatView /> */}
    </div>
  )
}

export default CategoryChat