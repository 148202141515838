
import { Link, Route, Routes } from "react-router-dom";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import PageNotFound from "../../404/PageNotFound";
import { useState, createContext } from "react";
import Modal from '../../../components/modal';
import { useContext } from "react";
import { BsList } from 'react-icons/bs';
import Create from './CreateClass';
import View from "./viewClass"
import Menu from "../../../components/Menu";

export const staffContext = createContext();
export const LearningContext = createContext();

const OnlineClassIndex = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [modalObject, setModalObject] = useState({});
    const [modalToggle, setModalToggle] = useState(false);
    const [view, setView] = useState('');
    const [title, setTitle] = useState('');
    const [editClass, setEditClass] = useState(false);
    const [editClassObj, setEditClassObj ] = useState({})


    return (
        <>
        <staffContext.Provider value={{view, setView}} >
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
               <h1 className={`${styles.title}`}>Live Class</h1>
               <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {view}
                        </h3>
                      
                        <Menu options={[
                            {label:'Create Live Class',path:'create-class'},
                            {label:'View Classes',path:'view-class'},
                        ]} />
                    </div>
                  <LearningContext.Provider value={{modalObject,setModalObject,modalToggle,setModalToggle,editClass,setEditClass,editClassObj,setEditClassObj,setView}}>
                      <Routes >
                         <Route path="*" element={<Create setTitle={setTitle}/>} />
                         <Route path="/create-class" element={<Create  setTitle={setTitle}/>} />
                         <Route path="/view-class" element={<View setTitle={setTitle} />} />
                         <Route path="/*" element={<PageNotFound setTitle={setTitle}/>}/>
                      </Routes>
                      <Modal modalObject={modalObject} modalTogglee={modalToggle} closeModal={()=>{setModalToggle(false)}} />
                 </LearningContext.Provider>
               </div>
            </div>
        </staffContext.Provider>
      
        </>
      );
}
 
export default OnlineClassIndex;