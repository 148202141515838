import React from "react";
import styling from '../../../css/Categories.module.css';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Loading from '../../../components/loading';
import { eventModalContext } from "./events";
import { useSelector, useDispatch } from 'react-redux';
import css from './Css/view-event.module.css';
import { EditComponent } from "../../../components/EditComponent";
import Api from "../../../helperFunctions/Api2";
import { deleteAppointment } from "../../../redux/reducers/eventReducer";
import PageNumber from '../../../components/PageNumber';
import HTMLReactParser from "html-react-parser";
// import { formatDate } from "../../../helperFunctions/calendar";
import ReactPlayer from "react-player";
const NO_OF_ITEMS_PER_PAGE = 30;


const ViewAppointments = ({ setTitle }) => {

    const navigate = useNavigate();
    const [menuIndex, setMenuIndex] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const dispatch = useDispatch();
    const appointments = useSelector(state => state.event.appointments);
    const institutions = useSelector(state => state.questions.user_institutions);
    const { setEditAppointmentCon, setModalToggle, setModalObject } = useContext(eventModalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);

    const handleDelete = (id) => {
        if (!Api.checkNetwork()) return false;

        setShowLoading(true);
        Api.PostApi('/event/delete-appointment', { id }, true)
            .then(result => {
                console.log(result, "Server result");
                setShowLoading(false);
                setModalObject({ header: 'Message', body: result.message, footer: '' });
                setModalToggle(true);
                if (result.isSuccessful) {
                    dispatch(deleteAppointment({ id }));
                    // setMenuIndex(null);
                }
            })
            .catch(error => {
                setShowLoading(false);
                console.log(error, "Server Error");
            });
    };

    const handleDeleteBtn = (id) => {
        setModalObject({
            header: <>Confirmation Message</>,
            body: <>Are you want to delete this Appointment. Deleting this
                This event will remove all related record to the event.
            </>,
            footer: <>
                <div>
                    <button onClick={() => setModalToggle(false)}>
                        Cancel
                    </button>
                    <button onClick={() => handleDelete(id)}>
                        Delete
                    </button>
                </div>

            </>
        });
        setModalToggle(true);
    };

    const handleEditBtn = (ev) => {
        setEditAppointmentCon(ev);
        navigate('../create-appointment');
    };

    useEffect(() => {
        setTitle('View appointments');
    }, [setTitle]);

    useEffect(() => {
        if (appointments.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((appointments.length / NO_OF_ITEMS_PER_PAGE) + ((appointments.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0));
        }
    }, [appointments.length]);

    return (
        <>
            <Loading shouldShow={showLoading} />
            <div className={styling.form}>
                <div className={`${styling.viewQuestionContainer} ${css.container}`}>
                    {appointments.length > 0 ? appointments.map((eAppointment, index) =>
                        index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                        <div key={index}>
                            <EditComponent index={index} menuIndex={menuIndex} clickDelete={() => handleDeleteBtn(eAppointment.id)} setMenuIndex={setMenuIndex}
                                clickEdit={() => handleEditBtn(eAppointment)}
                            />
                            {eAppointment.thumbnail && <img className={css.thumbnail} src={eAppointment.thumbnail} alt='' />}
                            <div>
                                <span>Title: </span>
                                <span>{eAppointment.title}</span>
                            </div>
                            <div>
                                <span>Description: </span>
                                <span>{HTMLReactParser(eAppointment.description)}</span>
                            </div>
                            <div>
                                <span>Sessions: </span>
                                <span>{eAppointment.sessions.length}</span>
                            </div>
                            {eAppointment.institution_id && <div>
                                <span>Institution: </span>
                                <span>{institutions.find(inst => inst.id === eAppointment.institution_id)?.name}</span>
                            </div>}
                            {/* {eAppointment.sessions.map(date => <div className={css.date} key={date.session_date}>
                                <span>{formatDate(date.session_date)}: </span>
                                {date.bookings && date.bookings.map((times, index) => <span className={css.times} key={index}>
                                    <span>{times.start_time.slice(0, 5)}</span> - <span>{times.end_time.slice(0, 5)}</span>, 
                                    <span> {times.slot} slots</span>
                                </span>)}
                                
                            </div>)} */}
                            {eAppointment.preview_url && <ReactPlayer height={'100%'} width={'100%'} url={eAppointment.preview_url} controls />}
                        </div>
                    ) : "No Appointment Created yet"}
                </div>
                {appointments.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

            </div>
        </>
    );
};

export default ViewAppointments;