import { useState } from 'react';
import { BsCheck2Circle, BsRecord } from "react-icons/bs";
import styles from './../css/Login.module.css';
import styling from './../css/Fields.module.css';
import InputField from './InputField';
import Loading from './loading';
import Modal from "./modal";
import Api from "../helperFunctions/Api2";
import {useParams} from "react-router-dom";
import { useNavigate } from 'react-router';

const ResetPassword = () => {
    const [inputs, setInputs] = useState({});
    const [hasAlpha, setHasAlpha] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSymbol, setHasSymbol] = useState(false);
    const [properLength, setProperLength] = useState(false);
    const [match, setMatch] = useState(false);
    const [showFirst, setShowFirst] = useState(false);
    const [showLast, setShowLast] = useState(false);
    //const [modalToggle, setModalToggle] = useState(false);
    //const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const { d } = useParams();
    const navigate = useNavigate();

    const onInputChange = (e = this) => {

        const name = e.target.name;
        const value = e.target.value;
        setInputs(inputs => ({ ...inputs, [name]: value }));
        if (name === 'user-pass') {
            value.length >= 1 ?
                setShowFirst(true) : setShowFirst(false);
            value.length >= 6 ?
                setProperLength(true) : setProperLength(false);

            /\d+/.test(value) ?
                setHasNumber(true) : setHasNumber(false);

            /\W+/.test(value) ?
                setHasSymbol(true) : setHasSymbol(false);

            /[a-zA-Z]+/.test(value) ?
                setHasAlpha(true) : setHasAlpha(false);

            if (value.length >= 6 && (/\d+/.test(value)) && (/\W+/.test(value)) && (/[a-zA-Z]+/.test(value)))
                inputs['con-pass'] === value ?
                    setMatch(true) : setMatch(false);
        }

        if (name === 'con-pass') {
            value.length >= 1 ?
                setShowLast(true) : setShowLast(false);

            value.length >= 6 && value === inputs['user-pass'] ?
                setMatch(true) : setMatch(false);
        }
    };

    const onSubmit = (e) => {
        setShowLoading(true);
        e.preventDefault();
        Api.PostApi('/account/login/change-password', {
            password: inputs['user-pass'],
            email: d
        }, false)
            .then(result => {
                alert(result.message);
                setShowLoading(false);
                if(result.data.institution_id)
                   navigate('/account/sign-in/'+result.data.institution_id)
                else navigate("/account/sign-in");
            })
            .catch(error => {
                alert(error);
                setShowLoading(false);
            });
    };
    return (
        <>
            <div className={`${styles.formParent}`}>
                <Loading shouldShow={showLoading} />
                <form className={`${styles.defaultLog}`} onSubmit={onSubmit}>
                    <h1 className={`${styles.signIn}`}>
                        Reset Password
                    </h1>
                    <div className={`${styles.inputDiv}`}>
                        <InputField title='New Password' inputID='user-pass' placeholder='Enter your New Password' type='password' onChange={onInputChange} />
                        {showFirst &&
                            <div className={`${styling.conditionDiv}`}>
                                <span className={`${styling.condition}`}>{hasAlpha ? <BsCheck2Circle className={`${styling.checked}`} /> : <BsRecord className={`${styling.unchecked}`} />}Password must have alphabetical characters</span>
                                <span className={`${styling.condition}`}>{hasNumber ? <BsCheck2Circle className={`${styling.checked}`} /> : <BsRecord className={`${styling.unchecked}`} />}Password must have at least one number</span>
                                <span className={`${styling.condition}`}>{hasSymbol ? <BsCheck2Circle className={`${styling.checked}`} /> : <BsRecord className={`${styling.unchecked}`} />}Password must have at least one symbol</span>
                                <span className={`${styling.condition}`}>{properLength ? <BsCheck2Circle className={`${styling.checked}`} /> : <BsRecord className={`${styling.unchecked}`} />}Password must be of 6 or more characters</span>
                            </div>}
                        <InputField title='Confirm Password' inputID='con-pass' placeholder='Re-enter Password' type='password' onChange={onInputChange} />
                        {showLast && <span className={`${styling.condition}`}>{match ? <BsCheck2Circle className={`${styling.checked}`} /> : <BsRecord className={`${styling.unchecked}`} />}{match ? 'Password accepted' : `Passwords don't match`}</span>}
                    </div>
                    <div className={`${styles.btnDiv}`}>
                        <button type='submit' className={`${styles.submitButton}`} disabled={!(hasAlpha && hasNumber && hasSymbol && properLength && match)}>Reset Password</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ResetPassword;