import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoriesCss from '../../../css/Categories.module.css';
import ResultCss from '../../../css/Result.module.css';
import { deleteGroup } from "../../../redux/reducers/resultReducer";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/modal";
import { ResultContext } from "./ResultMgmt";
import { MenuContext } from "../DashBoard";
import { useContext } from "react";
import SideOption from "../../../components/SideOption";
import Select from "../../../components/Select";
import restApi from "../../../helperFunctions/Api2";
import Institution from "../institution/Institution";


const ViewGroup = () => {


    const Sets = useSelector((state) => state.results.sets);
    const [sets, setSets] = useState(Object.values(Sets));
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState('');
    const [selSet, setSelSet] = useState({});
    const { setEditing, setEditData, setEditType, setTitle } = useContext(ResultContext);
    const { selected, changeSelected, setShowLoading } = useContext(MenuContext);
    const [groups, setGroups] = useState([]);
    const [reset, setReset] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();



    const handleDelete = (element) => {
        if (element.id === 'default') {
            setModalText('Cannot delete Default group');
            setModalToggle(true);
        } else {
            if(window.confirm('Are you sure you want to delete this result group?')) {
                setShowLoading(true);
                restApi.PostApi('/result/delete-result-group', {id: element.id}, true)
                .then(result=>{
                    setModalText(result.message);
                    console.log(result);
                    if(result.isSuccessful || result.isSuccesful){
                        dispatch(deleteGroup(element))
                    }
                })
                .catch(error=>{
                    setModalText(error.message);
                })
                .finally(()=>{
                    setShowLoading(false);
                    setModalToggle(true);
                })
            }
        }
    }
    const handleEdit = (element) => {
        if (element.id === 'default') {
            setModalText('Cannot edit Default group');
            setModalToggle(true);
        } else {
            setEditData(element);
            setEditType('result-group');
            setEditing(true);
            navigate('../add-result-group');
        }
    };


    useEffect(() => {
        setTitle('View Result Group');
    }, []);
    
    useEffect(() => {
        if (Object.values(Sets).length > 0) {
            setSets(Object.values(Sets).sort((a,b)=> b.id - a.id))
        }
        else setSets([]);
    }, [Sets])
    useEffect(() => {
        if (selSet.id) {
            setGroups(selSet.groups)
        } 
        else {
            let allgroups = [];
            sets.forEach(set => allgroups=[...allgroups, ...set.groups])
            setGroups(allgroups)
        }
    }, [sets, selSet])

    return (
        <Fragment>
            <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={CategoriesCss.form}>
                <label className={CategoriesCss.label}>
                    <p>Select set</p>
                    <Select onChange={setSelSet} options={sets} className={CategoriesCss.fields} label='title' value='id' reset={reset} placeholder={'All Groups'}/>
                </label> 
                <div className={CategoriesCss.viewQuestionContainer} onClick={() => (selected !== null) && changeSelected(null)}>
                    {groups.length ? groups.map((group, index) => {
                        return <EachSubjectGroup key={index} group={group} index={index} selected={selected} changeSelected={changeSelected} handleDelete={handleDelete} handleEdit={handleEdit} sets={Sets} />
                    }) : 'No Group added'}
                </div>
            </div>
        </Fragment>
    )

}

export default ViewGroup;

const EachSubjectGroup = ({ sets, group, index, selected, changeSelected, handleEdit, handleDelete }) => {
    const [showOption, setShowOption] = useState(false);
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {
        if (selected == index) {
            setShowOption(true);
        } else {
            setShowOption(false);
        }
    }, [selected])

    return (
        <div className={CategoriesCss.eachQuestion}>
            <div><span>Title: <span className="purpleText">{group.title}</span></span></div>
            <SideOption element={group} index={index} changeSelected={changeSelected} handleEdit={handleEdit} handleDelete={handleDelete} showOption={showOption} />
            <button onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'see less...' : 'see more...'} </button>
            <div style={{ display: seeMore ? 'flex' : 'none' }} className={ResultCss.eachTemplate}>
                <div >Description: <span className="purpleText">{group.description}</span></div>
                <div>Result Set: <span className="purpleText">{sets[group.set_id].title}</span></div>
                {group.institution_id && <div>Institution Group: <span className="purpleText">{JSON.parse(group.institution_categories).name}</span></div>}
            </div>
        </div>
    )

}
