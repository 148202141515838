import { createSlice } from "@reduxjs/toolkit";

export const ChatReducer = createSlice({
    name:"chat",
    initialState:{messages:[]},
    reducers:{
       setMessages:(state, action) => {
          state.messages = action.payload;
       },
       sendMessage:(state, action) => {
        console.log(action.payload);
        state.messages.push(action.payload);
      }
    }
})
export const {setMessages, sendMessage} = ChatReducer.actions;
export default ChatReducer.reducer;