import styles from './../css/CustomerAgreement.module.css';
import Nav from './Nav';

const PrivacyNotice = ({ cookieRef, cookieDesRef }) => {
    return (
        <>
            <div className={`${styles.myDiv1}`}>
                <div className={`${styles.myDiv12}`}>
                    <Nav />
                    <h1 className={`${styles.heading26}`}>Privacy Policy</h1>
                </div>
            </div>
            <div className={`${styles.myDiv2}`}>
                <div className={`${styles.myDiv21}`}>
                    <div className={`${styles.myDiv22}`}>
                        <h4 className={`${styles.heading27}`}> Sight-EduPlus is an Educational Management Service(E.M.S) and a
                            software service that automates educational process from enrolment, and registration to result
                            computation and publishing. It is a comprehensive Educational Portal system to service the
                            information needs of students, their parents, and educators. It contains administrative
                            information and helps school administrator, retrieve and analyze the data of an educational
                            institution. It also has a financial technological input in it to help pay necessary fees. It
                            serves as a platform for communicating and educating as well. In summary, the portal is an
                            advanced system that serves as a nexus between, Educators, Students, ant their Parents.<br /><br />This Privacy Policy governs the ways and manner, in which Sight-EduPlus collects, uses, maintains
                            information derived from users and discloses same. This Privacy Policy applies to the site and
                            all products and services offered by our portal system.<br /><br /> ‍<strong
                                className={`${styles.strong} ${styles.boldText6}`}>Personal Identification Information: <br />‍</strong> We may collect
                            personal identification from users in a variety of ways, including, but not limited to, when
                            Users visit our site to register on the site, subscribe to the portal service, fill out a form,
                            and in connection with other activities, services, features or resources we make available on
                            our site. The following details may be required from users as appropriate; name, email address,
                            and phone number. Users may, however, visit our site anonymously. We will collect personal
                            identification information from Users only if they voluntarily submit such information to us.
                            Users can always refuse to supply personal identification details, only that it may preclude
                            them from engaging in certain site related activities.<br /><br /> ‍<strong
                                className={`${styles.strong} ${styles.boldText6}`}>Non-Personal Identification Information:<br />‍</strong> We may collect
                            non-personal identification about Users in the course of their interaction with our site.
                            Non-personal identification information about users means of connection to our site, such as the
                            operating system and the Internet Service Providers utilized and other similar
                            information.<br /> ‍
                            <div id='cookie-notice' ref={cookieDesRef}>
                                <strong className={`${styles.strong} ${styles.boldText6}`} ref={cookieRef}>Web Browser Cookies:</strong> <br />
                                Our site
                                may use “Cookies” to enhance User experience. User’s web browser places cookies on their hard
                                drive for record-keeping purpose and sometimes to track information about them. User may choose
                                to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they
                                do so, some parts of the site may not function properly.
                            </div>
                            <strong className={`${styles.strong} ${styles.boldText6}`}><br />How
                                We Use Collected Information:<br />‍</strong> The information collected on our platform is
                            used for the following purposes: To improve customer service. Information you provide serves as
                            guide for us in responding to customer service request.<br /> Information you provide helps us
                            respond to your customer service requests and support needs more efficiently.<br /><br /> To
                            personalize user experience.<br />We may use information in the aggregation to understand how our
                            Users as a group use the service and resources provided on our site.<br /><br /> To improve our
                            site.<br /><br />To process payment.<br /> We may utilize the information supplied by Users when
                            requesting a service solely to provide the service requested. We do not share the information
                            with outside parties except to the extent necessary to provide the service.<br /><br /> To send
                            periodic mails and messages. We may use the email address to send User information and updates
                            pertaining to their subscription.<br /><br />
                            <strong className={`${styles.strong} ${styles.boldText6}`}>How We Protect Your Information:</strong><br /> We adopt good industry
                            standards as well as the appropriate data collection, storage and processing practices to
                            protect your information against unauthorized access, alteration, disclosure or destruction or
                            any other encroachment on your personal information, username, password, transaction information
                            and data stored on our site.<br /><br /> ‍<strong className={`${styles.strong} ${styles.boldText6}`}>Sharing Your Personal
                                Information:<br />‍</strong> We do not sell, trade, or rent Users personal Identification
                            Information with others. We may share generic demographic information or statistics not linked
                            to any personal identification regarding visitors and Users with our business partners, trusted
                            affiliates and advertisers for the purposes outlined above. We may use third party service
                            providers to help us operate our business and the Site or administer on our behalf, such as
                            sending out newsletters or surveys. We may share your information with these third parties for
                            those limited purpose provided that you have given us permission.<br /><br /> ‍<strong
                                className={`${styles.strong} ${styles.boldText6}`}>Third Party Websites:<br />‍</strong> Users may find advertising or other
                            content on our site that link to the sites and services of our partners, suppliers, advertisers,
                            sponsors, licensor and other third parties. We do not control the content links that appear on
                            these sites and are not responsible for the practices employed by websites linked to or from our
                            site. In addition, these sites or services, including their content and links, may be constantly
                            changing.
                        </h4>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyNotice;;