import React,{useState,useRef} from 'react';
import { useEffect } from 'react';
import css from './emailList.module.css';

const EmailLIst = ({onChange,defaultValue,className,styles,placeholder,reset}) => {
  const [inputVal,setInputVal] = useState('');
  const [emailList,setEmailList] = useState([]);

  const divRef = useRef(null);

  const handleInput = (e)=>{
    let val = e.target.value;
    setInputVal(val);
    let arrList = [];
    if(val == '' || typeof val == 'undefined' || val == null || val.length == 0){}
    else{
        let status = val.search(',');
        if(status == -1){
            let status2 = val.search(/\s/g);
            if(status2 == -1){
                return false;
            }
            else{
                arrList = val.split(' ');
                arrList = arrList.filter(element => element.trim() != '' );
            }    
        }
        else{
            arrList = val.split(',');
            arrList = arrList.filter(element => element.trim() != '' );
        }
        
    }
    

    let combineArr = [...emailList,...arrList]
    
    setEmailList(combineArr); 
    setInputVal('');
  }

  const handleCancelEmail = (ind)=>{
        setEmailList(emailList.filter((email,index) => ind != index ))
  }

  const handleFocus = ()=>{
      if(divRef && divRef.current){
        if(emailList.length ==0 && inputVal.length == 0){
            divRef.current.innerHTML = '';
        }
      }
  }
  const handleBlur = ()=>{
    if(divRef && divRef.current){
      if(emailList.length ==0 && inputVal.length == 0){
          divRef.current.innerHTML = placeholder? placeholder.toString():'Enter Email';
      }
    }
}

  useEffect(()=>{
    if(emailList.length > 0){
        onChange(emailList);
    }
  
     
  },[emailList])

  useEffect(()=>{
    if(divRef && divRef.current){
        divRef.current.innerHTML = placeholder?placeholder.toString():'Enter Email'
    }
  },[])

  useEffect(()=>{
    setEmailList([]);
    setInputVal('');
  },[reset])

  useEffect(()=>{
    if(Array.isArray(defaultValue) && defaultValue.length > 0){
        if(divRef && divRef.current){
          divRef.current.innerHTML = '';
        }
        setEmailList(defaultValue);
    }
  },[defaultValue])

  return (
    <div className={`${css.emailContainer} ${className?className:''}`} style={styles?styles:{}}>
        <div style={emailList.length == 0 &&
          inputVal.length ==0?{color:'lightgrey'}:{color:'inherit'}} ref={divRef} >
            {emailList.length > 0 && emailList.map((email,ind) => 
            <span key={ind}>{email} 
              <span className={css.cancelBtn} onClick={()=>handleCancelEmail(ind)}>&times;</span>
            </span>)}
        </div><input onFocus={handleFocus} onBlur={handleBlur} value={inputVal} onChange={(e)=>handleInput(e)} type='text' />
    </div>
  )
}

export default EmailLIst