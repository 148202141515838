import styles from '../../../css/Categories.module.css';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Api from '../../../helperFunctions/Api2';
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addPaymentLink, editPaymentLink, } from '../../../redux/reducers/accountReducer';
import { EditContext } from './Payments';
import Select from '../../../components/Select';
import styled from 'styled-components';

const AddPayment = ({ setTitle }) => {

    const serviceArr = useSelector(state => state.account.service)
    const title = useRef();
    const description = useRef();
    const amount = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { editing, editData, clearEdit } = useContext(EditContext);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [service, selService] = useState(null);
    const [del, setDel] = useState(null);
    const institutions = useSelector(state=>state.questions.user_institutions);
    const [assignInst, setAssignInst] = useState(false);
    const [currency, setCurrency] = useState('USD')
    const [selInst, setSelInst] = useState(null)

    const clearFields = () => {
        title.current.value = '';
        description.current.value = '';
        amount.current.value = '';
        setCurrency('USD');
        setDel(null);
        selService(null);
        setSelInst(null);
    }

    //this function validates select before going to the database.
    const validateSelect = (value, body) => {
        if (Object.keys(value).length <= 0) {
            setModalText(`Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        else if (value.length <= 0) {
            setModalText(`Payment link could not be created, Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        return true;
    }
    const validateInput = (value, body) => {
        if (value === null || value === '' || value === 'undefined' || value <= 0) {
            setModalText(`Payment could not be created, Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateInput(title.current.value, 'A Title')) {
            return false;
        }
        if (!validateInput(amount.current.value, 'An Amount')) {
            return false;
        }
        if (!validateSelect(service, 'A Service')) {
            return false;
        }
        else {
            const payment = {
                title: title.current.value,
                description: description.current.value,
                amount: Number(amount.current.value),
                service_id: service.id,
                currency: currency
            }
            if(assignInst){
                payment.institution_id = selInst.id;
            }
            console.log(payment)
            if (Object.values(payment).some(value => value === '' || value === undefined || value === null)) {
                setModalText(`Please completely fill out all required fields`);
                setModalToggle(true);
            }
            else if (editing) {
                payment.id = editData.id;
                setShowLoading(true);
                Api.PostApi('/payment/edit-payment-link', payment, true)
                    .then(result => {
                        setShowLoading(false);
                        setModalText(result.message);
                        setModalToggle(true);
                        console.log(result)
                        if (result.isSuccessful) {
                            dispatch(editPaymentLink(payment));
                            clearEdit();
                            clearFields();
                        };
                    })
                    .catch(error => {
                        setShowLoading(false);
                        setModalText(error.message);
                        setModalToggle(true);
                    })
            }
            else {
                setShowLoading(true);
                Api.PostApi('/payment/create-payment-link', payment, true)
                    .then(result => {
                        setShowLoading(false);
                        setModalText(result.message);
                        setModalToggle(true);
                        console.log(result)
                        if (result.isSuccessful) {
                            payment.id = result.data;
                            dispatch(addPaymentLink(payment));
                            clearFields();
                        };
                    })
                    .catch(error => {
                        setShowLoading(false);
                        setModalText(error.message);
                        setModalToggle(true);
                    })

            }
        }

    }

    const handleCancel = () => {
        clearFields();
        clearEdit();
        navigate('../view-payment-link');
    }

    useEffect(() => {
        setTitle('Add Payment link')
    });

    useEffect(() => {
        if (editing) {
            title.current.value = editData.title;
            description.current.value = editData.description;
            amount.current.value = editData.amount || 0;
            setCurrency(editData.currency)
            selService(serviceArr.find(service => service.id === editData.service_id));
            if (editData.institution_id) {
                setAssignInst(true);
                setSelInst(institutions.find(inst => inst.id === editData.institution_id));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing, editData, serviceArr])

    useEffect(() => {
        if (editData.service_id) {
            let result = serviceArr.find(service => service.id === editData.service_id)
            setDel(result);
        }
    }, [editData.service_id, serviceArr])

    return (<Fragment>
        <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
        <Loading shouldShow={showLoading} />
        <form className={styles.form}>
            <label htmlFor='title' className={`${styles.label}`}>
                <span className={styles.isRequired}>Title</span>
                <input ref={title} type='text' id='title' className={`${styles.input}`} placeholder='Title' required />
            </label>
            <label htmlFor='description' className={`${styles.label}`}>
                <span >Description</span>
                <input ref={description} type='text' id='description' className={`${styles.input}`} placeholder='Description' required />
            </label>
            <label htmlFor='currency' className={`${styles.label}`}>
                <span className={styles.isRequired}>Currency</span>
                <select id='currency' onChange={e=>setCurrency(e.target.value)} value={currency} className={`${styles.input}`} placeholder='Amount'>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="NGN">NGN</option>
                    <option value="INR">INR</option>
                    <option value="GHS">GHS</option>
                    <option value="KES">KES</option>
                    <option value="ZAR">ZAR</option>
                    <option value="TZS">TZS</option>
                    <option value="UGX">UGX</option>
                    <option value="RWF">RWF</option>
                    <option value="ZMW">ZMW</option>
                    <option value="XOF">XOF</option>
                    <option value="MUR">MUR</option>
                    <option value="ETB">ETB</option>
                </select>
            </label>
            <label htmlFor='amount' className={`${styles.label}`}>
                <span className={styles.isRequired}>Amount</span>
                <input ref={amount} type='number' id='amount' className={`${styles.input}`} placeholder='Amount' required />
            </label>
            <label htmlFor='eduplus-service' className={`${styles.label} `}>
                <span className={styles.isRequired}>Eduplus Service</span>
                {<Select name='Service' options={serviceArr} onChange={selService} className={styles.selectFields} style={{ width: '100%' }}
                    defaultValue={del} value='id' label='name' />}
            </label>
            <Container>
                <CheckLabel className='second'>
                    <input type='checkbox' checked={assignInst} onChange={() => setAssignInst(!assignInst)} />
                    <span>Attach Institution</span>
                </CheckLabel>
            </Container>
            {
                assignInst && 
                <label className={styles.label}>
                    <span className={styles.isRequired}>User institution</span>
                    <Select name='institution' options={institutions} onChange={setSelInst} defaultValue={selInst} className={styles.fields} label='name' value='institution_id' />
                </label>

            }
            <div className={styles.buttonContainer}>
                {editing && <button className={`cancelEditButton`} onClick={() => handleCancel()}>Cancel Edit</button>}
                <button className={`${styles.saveCatBtn}`} onClick={(e) => handleSubmit(e)}>{editing ? 'Save Changes' : 'Add payment link'}</button>
            </div>
        </form>
    </Fragment>)
}

export default AddPayment;

const Container = styled.div`
    display: flex;
    width:100%;
    gap:20px;
    justify-content: space-between;
    @media screen and (max-width:850px){
        flex-direction: column;
        justify-content: flex-start;
        gap:10px;
    }
`
const CheckLabel = styled.label`
    display:flex;
    flex:1;
    cursor: pointer;
    @media screen and (max-width:850px){
        &.second{
            flex-direction: row;
        }
        
    }
    
`