import React from 'react';
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import styles from './../../../css/InstitutionMgmt.module.css';
import Institution from './Institution';
import StaffMgmt from './StaffMgmt';
import InstitutionDashboard from './Dashboard';
import Attendance from './AttendanceManagement';
import GroupMgmt from './GroupMgmt';
import Timetable from './Timetable';


const InstitutionMgmt = () => {
    return (
        <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Routes>
                
            <Route path='/dashboard' element={<InstitutionDashboard />} />
                <Route path="institution/*" element={<Institution />} />
                <Route path="group-management/*" element={<GroupMgmt />} />
                <Route path="user-management/*" element={<StaffMgmt />} />
                <Route path="group-attendance/*" element={<Attendance />} />
                <Route path="timetable/*" element={<Timetable />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </div>
    );
};

export default InstitutionMgmt;