import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { WebsiteContext } from "./Website";
import ResultCss from '../../../css/Result.module.css';
import CategoriesCss from '../../../css/Categories.module.css';
import { addWebsite, editWebsite, } from "../../../redux/reducers/portalReducer";
import { useNavigate } from "react-router-dom";
import Api from '../../../helperFunctions/Api2';
import { MenuContext } from "../DashBoard";

const CreateWebsite = () => {
    const { setTitle, editing, editData, clearEdit, setFullsc} = useContext(WebsiteContext);
    const { setModalToggle, setModalText, setShowLoading} = useContext(MenuContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [websiteTitle, setWebsiteTitle] = useState('');
    const [websiteName, setWebsiteName] = useState('');
    const [description, setdescription] = useState('');
    const [isAvailable, setIsAvailable] = useState(false);
    const [should_reserve, setshould_reserve] = useState(false);

    const clearFields = () => {
        setWebsiteTitle('');
        setWebsiteName('');
        setdescription('');
        setshould_reserve(false)
    }

    //this function validates select before going to the database.
    const validate = (value, body) => {
        if (Object.keys(value).length <= 0) {
            setModalText(`Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        else if (value && value.length <= 0) {
            setModalText('Website could not be created, ' `Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        else if (value === null || value === '' || value === 'undefined' || value <= 0) {
            setModalText('Website could not be created ' `Please Set ${body}`);
            setModalToggle(true);
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate(websiteTitle, 'A Title')) {
            return false;
        }
        if (!isAvailable && !validate(isAvailable, 'An Available Domain Name')) {
            return false;
        }
        if (!validate(description, 'A Description')) {
            return false;
        }
        const website = {
            title: websiteTitle,
            domain: websiteName,
            description, should_reserve: should_reserve? 1 : 0,
        }
        // console.log(website, "websites");
        if (editing) {
            website.id = editData.id;
            setShowLoading(true);
            Api.PostApi('/website/edit-website', website, true)
                .then(result => {
                    setModalText(result.message);
                    //console.log(result)
                    if (result.isSuccessful) {
                        dispatch(editWebsite(website));
                        clearEdit();
                        clearFields();
                    }
                    else{
                        setModalText('Error, Try again');
                    };
                })
                .catch(error => {
                    setModalText('Error, Try again');
                })
                .finally(()=>{
                    setShowLoading(false);
                    setModalToggle(true);
                })
        }
        else {
            setShowLoading(true);
            Api.PostApi('/form/add-website', website, true)
                .then(result => {
                    setModalText(result.message);
                    if (result.isSuccessful) {
                        dispatch(addWebsite(result.data));
                        clearFields();
                    }
                    // else{
                    //     dispatch(addWebsite({...website, id: Math.floor(Math.random()*1000)}));
                    //     setModalText('Error, Try again');
                    // };
                })
                .catch(error => {
                    setModalText('Error, Try again');
                })
                .finally(()=>{
                    setShowLoading(false);
                    setModalToggle(true);
                })

        }
    }

    const handleCancel = () => {
        clearFields();
        clearEdit();
        navigate('../view-websites');
    }

    function checknameAvailability () {
        if (!(/^\w[\w_-]+\w$/.test(websiteName))) {
            setModalText(`Usernames can only have:  Lowercase Letters (a-z), Numbers (0-9), Hyphen (-), Underscores (_)`)
            setModalToggle(true)
            return false
        }
        setShowLoading(true);
        Api.PostApi('/form/find-website-name', { domain: websiteName }, true)
            .then(result => {
                setModalText(result.message);
                console.log(websiteName, result);
                if (result.isSuccessful) {
                    setIsAvailable(true);
                }
            })
            .catch(error => {
                setModalText('Error, Try again');
            })
            .finally(() => {
                setShowLoading(false);
                setModalToggle(true);
            })
    }

    useEffect(() => {
        setTitle('Create Website');
        setFullsc('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // **editing useffect**
    useEffect(() => {
        if (editing && editData.website) {
            setWebsiteTitle(editData.title);
            setWebsiteName(editData.name);
            setdescription(editData.description);
        };
    }, [editing, editData])

    return (
        <>
            <div className={ResultCss.mainView}>
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Website Title</span>
                    <input className={CategoriesCss.input} value={websiteTitle} onChange={(e) => setWebsiteTitle(e.target.value)} />
                </label>
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Website Name </span><br/>
                    <span style={{width: 200,display:'inline-block'}}>
                    <input className={CategoriesCss.input} value={websiteName} onChange={(e) => setWebsiteName(e.target.value.toLowerCase())} />
                    </span>.sight-eduplus.com &nbsp;
                    <button onClick={checknameAvailability} style={{
                        width: 'fit-content', border: '1px solid #fff',
                        borderRadius: '5px', padding: '5px 5px',
                        fontSize: '16px', marginBottom: '10px', color: 'var(--primary-color)',
                        backgroundColor: '#fff', textDecoration: 'underline'}}>Check name availability</button>
                   
                </label>
                {isAvailable && <label className={CategoriesCss.label} style={{ display: 'flex', marginTop: '-25px' }}>
                    <input type="checkbox" checked={should_reserve} onChange={(e)=>setshould_reserve(e.target.checked)}  />
                    <span className={CategoriesCss.isRequired}>Reserve name for a maximum of 30days</span>
                </label>}
                <label className={CategoriesCss.label}>
                    <span className={CategoriesCss.isRequired}>Description</span>
                    <textarea className={CategoriesCss.input} value={description} onChange={(e) => setdescription(e.target.value)} ></textarea>
                </label>

                <div className={CategoriesCss.buttonContainer}>
                    {editing && <button className={'cancelEditButton'} onClick={handleCancel} >Cancel</button>}
                    <button className={CategoriesCss.viewBtn} onClick={handleSubmit} >{!editing ? 'Add' : 'Save'} website</button>
                </div>
            </div>
        </>
    )

}
export default CreateWebsite;
