import { useContext, useEffect, useRef } from "react";
import { forwardRef } from "react";
import { ModalContext } from "../App";

const Base64FileInput = forwardRef((props, ref) => {
    const {popup} = useContext(ModalContext);
    const nref = useRef();
    const inputRef = ref? ref : nref;

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result;
                // console.log(base64String);
                resolve(base64String);
            }
            reader.onerror = (error) => {
                // console.error('ERROR:', error);
                reject(error);
            }
        })
    }

    function handleFileInput(e) {
        let file = e.target.files[0];
        if (file.size > 300000) {
            inputRef.current.value = '';
            popup('file size too large');
            return;
        }
        

        getBase64(file)
            .then(result => {
                file.base64 = result;
                props.onChange(file);
            })
            .catch(error => {
                console.error(error);
            })
    }

    return <><input {...props} onChange={handleFileInput} type="file" ref={ inputRef}/></>

})

export default Base64FileInput;

export function base64ToFile(file) {
    const b64 = file.base64.split(',')[1];
    const byteCharacters = atob(b64);
    const sliceSize = /* sliceSize || */ 512;
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    return new File(byteArrays, file.name );
}
