import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoriesCss from '../../../css/Categories.module.css';
import ResultCss from '../../../css/Result.module.css';
import { deleteCourse } from "../../../redux/reducers/resultReducer";
import { useNavigate } from "react-router-dom";
import { MenuContext } from "../DashBoard";
import { useContext } from "react";
import SideOption from "../../../components/SideOption";
import Api from "../../../helperFunctions/Api2";
import { CourseContext } from "./Courses";

export default function ViewCourses() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const courses = Object.values(useSelector((state) => state.results.courses)).sort((a,b)=> b.id - a.id);
    const { setEditing, setEditData, setTitle } = useContext(CourseContext);
    const { selected, changeSelected, setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);



    const handleDelete = (element) => {
        if (window.confirm('Please confirm delete')) {
            setShowLoading(true);
            const payload = {id : element.id}
            Api.PostApi('/result/delete-course', payload, true)
                .then(result => {
                    setModalText(result.message);
                    console.log(result);
                    if (result.isSuccessful || result.isSuccesful) {
                        dispatch(deleteCourse(payload))
                    }
                })
                .catch(error => {
                    setModalText(error.message);
                })
                .finally(() => {
                    setModalToggle(true);
                    setShowLoading(false);
                })
        }
    }
    const handleEdit = (element) => {
        // console.log(element)
        setEditData(element);
        setEditing(true);
        navigate('../add-course');
    };


    useEffect(() => {
        setTitle('View Courses');
    }, [setTitle]);

    return (<>
        <div className={CategoriesCss.form}>
            <div className={CategoriesCss.viewQuestionContainer} onClick={() => (selected !== null) && changeSelected(null)}>
                {courses.length ? courses.map((course, index) => {
                    return <EachCourse key={index} course={course} index={index} selected={selected} changeSelected={changeSelected} handleDelete={handleDelete} handleEdit={handleEdit} />
                }) : 'No course added!'}
            </div>
        </div>
    </>)
}

const EachCourse = ({ course, index, selected, changeSelected, handleEdit, handleDelete }) => {
    const [showOption, setShowOption] = useState(false);
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {
        if (selected === index) {
            setShowOption(true);
        } else {
            setShowOption(false);
        }
    }, [index, selected])

    return (
        <div className={CategoriesCss.eachQuestion} style={{minHeight: course.image? '165px': '', paddingBottom: '30px'}}>
            <div>
                {course.image && <img src={course.image} alt={course.title} height='100' />}
                <div>Title: <span className="purpleText">{course.title}</span></div>
            </div>
            <SideOption element={course} index={index} changeSelected={changeSelected} handleEdit={handleEdit} handleDelete={handleDelete} showOption={showOption} />
            <button onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'see less...' : 'see more...'} </button>
            {seeMore && <>
                <div >Description: <span className="purpleText">{course.description}</span></div>
                <div >Course code: <span className="purpleText">{course.code}</span></div>
                <div >Course weight: <span className="purpleText">{course.unit}</span></div>
                {course.preview_video && <video controls controlsList="nodownload" src={course.preview_video} style={{width: '100%'}}></video>}
                <div></div>
            </>}
        </div>
    )

}
