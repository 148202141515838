import { createContext, useContext, useState } from "react";
import { MenuContext } from "../DashBoard";
import { Link, Route, Routes } from "react-router-dom";
import titleStyle from '../../../css/SectionTitle.module.css';
import styles from '../../../css/StaffMgmt.module.css';
import { BsList } from "react-icons/bs";
import PageNotFound from "../../404/PageNotFound";
import ViewPaymentLinks from "./ViewPaymentLink";
import AddPayment from "./AddPaymentLink";
import ViewPayments from "./viewPayments"


export const staffContext = createContext();
export const EditContext = createContext();
const Payments =()=>{

    const {showMenu, setShowMenu} = useContext(MenuContext);
    const [title, setTitle] = useState('');
    const [view, setView] = useState();
    const [editData, setEditData]= useState({});
    const [editing, setEditing]= useState(false);

    const clearEdit=()=>{
        setEditData({});
        setEditing(false)
    }

    return (
        <staffContext.Provider value={{ view, setView }}>
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Payments</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`} >
                                    <ul onClick={clearEdit}>
                                        <li><Link to='add-payment-link'>Add payment link</Link></li>
                                        <li><Link to='view-payment-link'>View payment links</Link></li>
                                        <li><Link to='view-payment'>View Payment</Link></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                        <EditContext.Provider value={{editData,setEditData,editing,setEditing,clearEdit}}>
                        <Routes>
                            <Route path='*' element={<AddPayment setTitle={setTitle} />} />
                            <Route path='/add-payment-link/*' element={<AddPayment setTitle={setTitle} />} />
                            <Route path='/view-payment-link/*' element={<ViewPaymentLinks setTitle={setTitle} />} />
                            <Route path='/view-payment/*' element={<ViewPayments setTitle={setTitle} />} />
                            <Route path="/*" element={<PageNotFound />} />
                        </Routes>
                        </EditContext.Provider>
                    </div>
                </div>
            </div>
        </staffContext.Provider>
    )
}
export default Payments
